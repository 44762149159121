<div class="sub-container">
  <h2 class="headline-highlight">How Does It Work?</h2>
  <app-how-it-works [showHeader]="false" [showLearnMoreButton]="false" [showDivider]="true"></app-how-it-works>

  <h2 class="headline-highlight">How To Earn Rewards &amp; Cash?</h2>

<!-- How to Earn Rewards & Cash Section Starts Here -->
<!-- <table class="earn-rewards-cash">
  
    <tr>
      <th></th> 
      <th>Ways to Earn</th>
      <th>DeliveryDefense<sup>TM</sup> Address Confidence <br>
        <p class="terms">
          <a (click)="openDeliveryDefenseModal()">Terms and conditions
            <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
          </a>
        </p>
      </th>
      <th>InsureShield Shipping Address
        <p class="terms">
          <a (click)="openInsureShieldModal()">Terms and conditions
            <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
          </a>
        </p>
      </th>
    </tr>
  
    <tr *ngFor="let item of tableData; let i = index">
      <td *ngIf="i === 0" rowspan="11" class="rewards-cell">REWARDS</td>
      <td>
        <span [innerHTML]="item.column1"></span>
        <span *ngIf="item.hasDialog" class="material-icons clickable-text" [appClickableTooltip]="getTooltipInfo(item)">
          <img src="../../../../assets/icons/info.svg" alt="Info">
        </span>
      </td>
      <td class="column-2 coming-soon-cell" [class.coming-soon]="item.column2 === 'N/A' && i === 0">
        <span class="original-text">{{ item.column2 }}</span>
      </td>
      <td [ngClass]="{'disabled-cell': item.column3 === 'N/A'}">{{ item.column3 }}</td>
    </tr>
  
    <tr *ngFor="let item of cashData; let i = index">
      <td *ngIf="i === 0" rowspan="2" class="cash-cell">CASH</td>
      <td>
        {{ item.column1 }}
        <span *ngIf="item.hasDialog" class="material-icons clickable-text" [appClickableTooltip]="getTooltipInfo(item)">
          <img src="../../../../assets/icons/info.svg" alt="Info">
        </span>
      </td>
      <td class="column-2 coming-soon-cell" >
        <span class="original-text">{{ item.column2 }}</span>
      </td>
      <td [ngClass]="{'disabled-cell': item.column3 === 'N/A'}">{{ item.column3 }}</td>
    </tr>
  </table> -->
  
  <!-- How to Earn Rewards & Cash Section Ends Here -->


  <!-- How to Grow Your Points Starts Here -->

  <div class="grow-your-points">
    <div class="block">
        <img src="../../../../assets/icons/acute.svg" alt="Early Bill Pay" class="block-icon">
        <h2 class="block-title">Early Bill Pay</h2>
        <p class="block-text">Earn 0.25% back on your invoice for paying within 7 days of the invoice date.</p>
        <a (click)="handlePayMyBill($event)" class="block-link pay-bill-link">Pay My Bill ></a>
    </div>
    <div class="block">
        <img src="../../../../assets/icons/account_balance_2.svg" alt="ACH Bill Pay" class="block-icon">
        <h2 class="block-title">ACH Bill Pay</h2>
        <p class="block-text">Earn 0.75% back on your invoice when paying through ACH</p>
        <a (click)="handlePayMyBill($event)" class="block-link pay-bill-link">Pay My Bill ></a>
    </div>
    <div class="block">
        <img src="../../../../assets/icons/paid.svg" alt="cash bill pay" class="block-icon">
        <h2 class="block-title">Cash Bill Pay</h2>
        <p class="block-text">Earn 1% back on UPS Wallet cash balances applied to your UPS Capital® invoice</p>
        <a (click)="handlePayMyBill($event)" class="block-link pay-bill-link">Pay My Bill ></a>
    </div>
    <div class="block">
        <img src="../../../../assets/icons/assignment_ind.svg" alt="Profile Validation" class="block-icon">
        <p class="block-info">Annual</p>
        <h2 class="block-title">Profile Validation</h2>
        <p class="block-text">Earn 1,000 points by validating your contact information in the Insure Shield Online Portal.</p>
        <a [href]="loginLink" target="_blank" class="block-link">Go to InsureShield </a>&nbsp;<img src="../../../../assets/icons/open_in_new.png" alt="open new tab">
    </div>
    <div class="block">
        <img src="../../../../assets/icons/folder_supervised.svg" alt="account creation" class="block-icon">
        <p class="block-info">One-Time</p>
        <h2 class="block-title">Account Creation</h2>
        <p class="block-text">Earn 1,000 points by creating an account in the Insure Shield online portal.</p>
        <a [href]="createAccountLink" target="_blank" class="block-link">Go to InsureShield </a>&nbsp;<img src="../../../../assets/icons/open_in_new.png" alt="open new tab">
    </div>
    <div class="block">
        <img src="../../../../assets/icons/group_add.svg" alt="invite users" class="block-icon">
        <p class="block-info">Up to 3 Users</p>
        <h2 class="block-title">Invite Users</h2>
        <p class="block-text">Earn 1,000 reward points by adding a user to the online portal (up to 3 users).</p>
        <a [href]="loginLink" target="_blank" class="block-link">Go to InsureShield </a>&nbsp;<img src="../../../../assets/icons/open_in_new.png" alt="open new tab">
    </div>
  </div>
  
  <!-- How to Grow Your Points Ends Here -->


  


  
</div>
