import { Inject, Injectable, NgZone, Optional, TemplateRef } from '@angular/core';
import { DialogPosition, MatDialog, MatDialogConfig as _MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { Subject } from 'rxjs';

// Code taken from the following stackblitz with very slight modifications (mentioned below)
// https://stackblitz.com/edit/angular-material-dialog-animation?file=app%2Fd.service.ts,app%2Fdialog-overview-example.ts

const diractionMap = { left: 'left', right: 'left', top: 'top', bottom: 'top' };
const multyMap = { left: 1, right: -1, top: 1, bottom: -1 };


interface AnimationOption {
  keyframes?: Keyframe[];
  keyframeAnimationOptions: KeyframeAnimationOptions;
}

export interface MatDialogConfig extends _MatDialogConfig {
  animation?:
  | {
    to: 'aside' | 'top' | 'bottom' | 'left' | 'right';
    incomingOptions?: { keyframes?: Keyframe[]; keyframeAnimationOptions: KeyframeAnimationOptions };
    outgoingOptions?: { keyframes?: Keyframe[]; keyframeAnimationOptions: KeyframeAnimationOptions };
  }
  | {
    to?: 'aside' | 'top' | 'bottom' | 'left' | 'right';
    incomingOptions?: { keyframes: Keyframe[]; keyframeAnimationOptions: KeyframeAnimationOptions };
    outgoingOptions?: { keyframes: Keyframe[]; keyframeAnimationOptions: KeyframeAnimationOptions };
  };
  position?: DialogPosition & { rowStart?: string; rowEnd?: string };
}

@Injectable({
  providedIn: 'root',
})
export class NgDialogAnimationService {
  constructor(
    private dialog: MatDialog,
    private ngZone: NgZone,
    @Optional()
    @Inject('INCOMING_OPTION')
    private incomingOptions?: AnimationOption,
    @Optional()
    @Inject('OUTGOING_OPTION')
    private outgoingOptions?: AnimationOption,
  ) { }

  open<T, D = any, R = any>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config?: MatDialogConfig,
  ): MatDialogRef<T, R> {
  
    const dir: 'ltr' | 'rtl' = config.direction || (document.querySelectorAll('[dir="rtl"]').length ? 'rtl' : 'ltr');
    config.direction = config.direction || dir;
    if (config.animation) {
      if (config.position && config.position.rowEnd) {
        if (dir === 'rtl') {
          config.position.right = config.position.rowEnd;
        } else {
          config.position.left = config.position.rowEnd;
        }
      }

      if (config.position && config.position.rowStart) { // Changed 'rowEnd' to 'rowStart' here for proper alignment with flyouts positioned on the right side of the screen
        if (dir === 'rtl') {
          config.position.left = config.position.rowStart;
        } else {
          config.position.right = config.position.rowStart;
        }
      }
    }

    const ref = this.dialog.open(componentOrTemplateRef, config);

    const container = document.getElementsByTagName('mat-dialog-container')[0] as HTMLElement;

    if (config.animation) {
      const incomingOptions: AnimationOption = { keyframeAnimationOptions: { duration: 500, easing: 'ease-in' } };
      const outgoingOptions: AnimationOption = { keyframeAnimationOptions: { duration: 400, easing: 'ease-out' } };

      const wrapper = document.getElementsByClassName('cdk-global-overlay-wrapper')[0];

      const animate = (keyframes, options) => wrapper.animate(keyframes, options);

      const _afterClosed = new Subject();
      // ref.afterClosed = () => _afterClosed.asObservable(); 
      // ^ This line of code actually messed with the 'afterClosed' subscription
      // It would only return boolean true upon a dialog close
      // Even if in the dialog component you tried calling something like this.dialog.close('test'),
      // Due to the observable getting altered, upon closing the dialog, the observable would fire as true and not 'test'
      // Upon removal, it reverts to 'test'. Quite frankly, I'm not experienced enough to fully understand what is going on here
      // But it seems like keeping this line of code removed is for the best (as sometimes you rely on the data/values that get emitted from closing a matdialog)

      const closeFunction = ref.close;

      let incomeKeyFrames = incomingOptions.keyframes;
      let outgoingKeyFrames = outgoingOptions.keyframes;

      if (config.animation.to) {
        const to = diractionMap[config.animation.to];
        const keyFrame100 = {};
        const keyFrame0 = {};
        keyFrame0[to] = 0;
        keyFrame100[to] =
          to === 'top' || to === 'bottom'
            ? container.clientHeight * multyMap[config.animation.to] + 'px'
            : container.clientWidth * multyMap[config.animation.to] + 'px';
        incomeKeyFrames = incomeKeyFrames || [keyFrame100, keyFrame0];
        outgoingKeyFrames = outgoingKeyFrames || [keyFrame0, keyFrame100];
      }
      animate(incomeKeyFrames, incomingOptions.keyframeAnimationOptions);
      const closeHandler = (dialogResult?: R) => {
        _afterClosed.next(true); // Added a 'true' argument for next() as a placeholder, latest package updates now require 1 argument here and not 0, even if the placeholder accomplishes nothing.
        const animation = animate(outgoingKeyFrames, outgoingOptions.keyframeAnimationOptions);
        animation.onfinish = () => {
          (wrapper as HTMLElement).style.display = 'none';
          this.ngZone.run(() => ref.close(dialogResult));
        };
        ref.close = closeFunction;
      };
      ref.close = (dialogResult?: R) => closeHandler(dialogResult);
    }
    return ref;
  }
}

