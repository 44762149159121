import { Component, HostListener, ViewChild, ElementRef, OnInit, Input } from '@angular/core';
import { WalletSummaryService } from '../../services/api/wallet-summary.service';
import { WalletSummary } from '../../models/wallet-summary.interface';

@Component({
  selector: 'app-redeem-card',
  templateUrl: './redeem-card.component.html',
  styleUrls: ['./redeem-card.component.scss']
})
export class RedeemCardComponent implements OnInit {
  walletSummary: WalletSummary | null = null;
  @Input() isLoading: boolean = false;

  autoPay:number = 0;
  cashEligible = false;
  rewardEligible = false;
  defaultPayment = false;
  tooltipMessage = '';

  @ViewChild('tooltipRewards') tooltipRewardsElement: ElementRef;
  @ViewChild('tooltipBank') tooltipBankElement: ElementRef;
  @ViewChild('tooltipAutoRedeem') tooltipAutoRedeemElement: ElementRef;

  tooltipVisibleForRewards: boolean;
  tooltipVisibleForBank: boolean;
  tooltipVisibleForAutoRedeem: boolean;

  ngAfterViewInit() {
    this.positionTooltips();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.positionTooltips();
  }

  constructor(private walletSummaryService: WalletSummaryService) {}

  showMenu = false;

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  closeMenu() {
    this.showMenu = false;
  }

  onMenuClosed() {
    this.hideTooltip();
  }

  onLinkClicked(event: MouseEvent) {
    event.preventDefault();
    // Handle link click logic here
    this.closeMenu();
  }

  showTooltip(event: MouseEvent, tooltipType: 'rewards' | 'autoRedeem' | 'banks'): void {
    event.stopPropagation();
    if (this.cashEligible && !this.rewardEligible) {
      if (tooltipType === 'rewards') {
        this.tooltipVisibleForRewards = true;
        this.tooltipVisibleForAutoRedeem = false;
        this.tooltipVisibleForBank = false;
      }
      else if (tooltipType === 'autoRedeem') {
        this.tooltipVisibleForRewards = false;
        this.tooltipVisibleForAutoRedeem = true;
        this.tooltipVisibleForBank = false;
      }
      else {
        this.tooltipVisibleForRewards = false;
        this.tooltipVisibleForAutoRedeem = false;
        this.tooltipVisibleForBank = true;
      }
    }
  }

  hideTooltip(event?: MouseEvent): void {
    if (event) {
      event.stopPropagation(); // Stop propagation if event provided
    }
    this.tooltipVisibleForRewards = false;
    this.tooltipVisibleForAutoRedeem = false;
    this.tooltipVisibleForBank = false;

  }

  handleClick(event: MouseEvent) {
    event.preventDefault(); // Prevent default behavior (if any)
    this.hideTooltip();
  }

  @HostListener('window:scroll', ['$event'])
onScroll(event: Event) {
  this.hideTooltip();
}

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    const isInsideMenu = targetElement.closest('.redeem-menu');
    const isInsideTooltip = targetElement.closest('.tooltip');

    if (!isInsideMenu && !isInsideTooltip) {
      this.hideTooltip();
    }
  }


positionTooltips() {
  this.positionTooltip(this.tooltipRewardsElement, 'left');
  this.positionTooltip(this.tooltipBankElement, 'center');
  this.positionTooltip(this.tooltipAutoRedeemElement, 'right');
}

positionTooltip(tooltipElement: ElementRef, alignment: 'left' | 'center' | 'right') {
  if (tooltipElement && tooltipElement.nativeElement) {
    const tooltip = tooltipElement.nativeElement;
    const button = tooltip.previousElementSibling;
    const buttonRect = button.getBoundingClientRect();
    const tooltipRect = tooltip.getBoundingClientRect();

    // Position tooltip above the button
    tooltip.style.top = `${buttonRect.top - tooltipRect.height - 20}px`;

    switch (alignment) {
      case 'left':
        let leftPosition = buttonRect.left;
        leftPosition = Math.max(20, leftPosition);
        leftPosition = Math.min(leftPosition, window.innerWidth - tooltipRect.width - 20);
        tooltip.style.left = `${leftPosition}px`;
        tooltip.style.right = 'auto';
        break;
      case 'center':
        let centerPosition = buttonRect.left + (buttonRect.width / 2) - (tooltipRect.width / 2);
        centerPosition = Math.max(20, centerPosition);
        centerPosition = Math.min(centerPosition, window.innerWidth - tooltipRect.width - 20);
        tooltip.style.left = `${centerPosition}px`;
        tooltip.style.right = 'auto';
        break;
      case 'right':
        let rightPosition = window.innerWidth - buttonRect.right;
        rightPosition = Math.max(20, rightPosition);
        rightPosition = Math.min(rightPosition, window.innerWidth - tooltipRect.width - 20);
        tooltip.style.right = `${rightPosition}px`;
        tooltip.style.left = 'auto';
        break;
    }
  }
}

  ngOnInit(): void {
    this.walletSummaryService.walletSummary$.subscribe(summary => {
      this.walletSummary = summary;
      this.autoPay = Number(this.walletSummary?.wallet.isDWAutoPay) || 0;
      this.cashEligible = summary?.wallet?.cashEligible === "True";
      this.rewardEligible = summary?.wallet?.rewardEligible === "True";
      this.defaultPayment = summary?.wallet?.defaultPaymentMethod === 'True';

      //this.tooltipVisibleForAutoRedeem = this.cashEligible && !this.rewardEligible;


   //   console.log("From Redeem Card: Cash Eligible: ", this.cashEligible, " and Reward Eligible: ", this.rewardEligible, "Default Payment: ", this.defaultPayment);

      if (this.walletSummary?.wallet?.defaultPaymentMethod !== undefined) {
      //  console.log('Default Payment Method', this.walletSummary.wallet.defaultPaymentMethod);
      } else {
      //  console.log('Default Payment Method: undefined');
      }
    });
  }

}
