<div class="app-header">
    <div class="logo">
        <img src="../../../../assets/icons/Logo.svg" alt="logo">
    </div>
</div>

<div class="logged-out">
    <p>Thank You for Accepting the Terms and Conditions for UPS Wallet! For the new and improved 5-step claim filing process, please <strong>log back into your InsureShield<sup>®</sup> Online Portal</strong>.</p>   
    <p>With your new UPS Wallet experience, you now have more transfer options like Instant Transfer, ACH, and Zelle or you can choose to apply your claim payouts to your UPS Capital invoices for cashback perks!</p>   
    <p>If you have any questions or need assistance, please feel free to reach out to 1-877-263-8772 or email us capital&#64;ups.com We’re here to help! </p> 
</div>