import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss']
})
export class HowItWorksComponent {

  constructor(public route: Router){}

  @Input() showLearnMoreButton: boolean = true;
  @Input() showDivider: boolean = true;
  @Input() showHeader: boolean = true;
  @Input() disabled: boolean = false;

  sectionItems = [
    { iconUrl: '../../../../assets/images/ups-money-manage.png', title: '1. Earn Rewards and Cash', description: 'Earn Rewards from your connected solutions and receive cash through claims and refunds.' },
    { iconUrl: '../../../../assets/images/ups-pay-bill.png', title: '2. Redeem or Transfer', description: 'Apply rewards and/or cash balance to the invoice. Cash balances can also be transferred to a designated bank account.' },
    { iconUrl: '../../../../assets/images/ups-payment-options-cc.png', title: '3. Pay Balance', description: 'Any remaining balance can be paid using your stored payment option.' }
  ];

  learnMore(){
    this.route.navigate(['my-wallet', 'all-rewards', 'ways-to-earn']);
  }

}
