

<footer class="ups-footer">
    <div class="top-section">
        <div class="column" *ngFor="let column of columns; let i = index">
            <h3 (click)="toggleColumn(i)"> 
                    {{ column.title}}
                <mat-icon *ngIf="isMobileView"> {{  column.isExpanded ? 'expand_less' : 'expand_more'}}</mat-icon>
            </h3>
            <ul *ngIf="!isMobileView || column.isExpanded">
                <li *ngFor="let link of column.links">
                    <a *ngIf="link.route; else externalLink" [routerLink]="link.route">
                    <span class="link-text">    <img *ngIf="link.svg" [src]="link.svg" alt="Icon" class="link-icon">
                        {{ link.text }} </span>
                        <img
                            *ngIf="column.title === 'Connect With Us'"
                            src="../../../../assets/icons/open_in_new.svg"
                            alt="Connect"
                            class="open-link"/>
                    </a>
                    <ng-template #externalLink>
                        <a [href]="link.href" [target]="link.svg ? '_blank' : '_self'" rel="noopener noreferrer">
                          <span class="link-href">
                            {{ link.text }}
                            <img *ngIf="link.svg" [src]="link.svg" alt="Icon" class="link-icon">
                          </span>
                        </a>
                      </ng-template>
                </li>
            </ul>
            
        </div>
    </div>

    <div class="bottom-section">
        <div class="legal-links">
            <ul>
                <li><a href="https://upscapital.com/technology-agreement">Technology Agreement</a></li>
                <li><a href="https://www.ups.com/us/en/support/shipping-support/legal-terms-conditions.page" target=”_blank”>Website Terms of Use <img src="assets/icons/Assets.svg"></a></li>
                <li><a href="https://upscapital.com/us-product-disclosures/">Disclosures</a></li>
                <li><a href="https://www.ups.com/us/en/support/shipping-support/legal-terms-conditions/california-privacy-rights.page" target=”_blank”>Your California Privacy RIghts <img src="assets/icons/Assets.svg"></a></li>
                <li><a href="https://upscapital.com/privacy-notice/">Privacy Notice</a></li>
                <li><a href="https://upscapital.com/">Cookie Settings</a></li>
                <li><a href="https://upscapital.com/opt-out/" target=”_blank”>Do Not Sell or Share My Personal Informatoin <img src="assets/icons/Assets.svg"></a></li>
            </ul>
        </div>

   
        <div class="copyright-section">
        <p>Copyright ©1994 - 2024 United Parcel Service of America, Inc. All rights reserved.
        </p>
        </div>
</div>

</footer>


