<div class="settings-wrapper">
  <div class="sidebar-wrapper">
    <div class="sidebar-options">
      <div class="subtab-option" [class.active]="myAccountActive" [routerLink]="['/my-account']">
        <img src="../../../../assets/images/customers_major.png" class="subtab-icon">
        <span>My Account</span>
      </div>
      <div *ngIf="!(cashEligible && !rewardEligible)" class="subtab-dropdown" [class.active]="preferencesActive">
        <div [routerLink]="['/preferences']">
          <img src="../../../../assets/images/settings.png" class="subtab-icon">
          <span>Preferences</span>
        </div>
        <div class="dropdown-wrapper">
          <div class="dropdown-content">
            <div class="dropdown-option" [routerLink]="['/preferences/manage-payment']">
              <div class="vertical-yellow-bar" [class.active]="managePaymentActive"></div>
              <div>Manage Payment Methods</div>
            </div>
            <div class="dropdown-option" [routerLink]="['/preferences/auto-redeem']">
              <div class="vertical-yellow-bar" [class.active]="autoRedeemActive"></div>
              <div>Auto Redeem & Pay</div>
            </div>
          </div>
        </div>
      </div>
      <div class="subtab-option" [class.active]="contactUsActive" [routerLink]="['/contact-us']">
        <img src="../../../../assets/images/contact_support.png" class="subtab-icon">
        <span>Contact Us</span>
      </div>
    </div>
  </div>
  <div class="main-content-wrapper my-account-form">
    <div class="sub-container">
      <h2 class="headline-highlight">My Account</h2>
      <div class="box-dw-primary">
        <div class="column">
          <span class="user-initial">{{ displayName | nameInitials }}</span>
          <span class="title-1 flex-center padding-left-10">{{ displayName }}</span>
        </div>
        <div class="column">
          <span class="flex-center">Joined in {{ memberSince | date: 'yyyy' }}</span>
        </div>
      </div>
      <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
        <div class="box-border">
          <h3>Company Information</h3>
          <div class="form-container double-field">
            <div class="form-group">
              <mat-form-field appearance="fill">
                <mat-label class="custom-mat-label">Phone Number</mat-label>
                <input matInput type="tel" formControlName="phoneNumber" placeholder="Phone Number">
                <mat-error *ngIf="myForm.get('phoneNumber')?.invalid && (myForm.get('phoneNumber')?.dirty || myForm.get('phoneNumber')?.touched)">
                  <div *ngIf="myForm.get('phoneNumber')?.errors?.['required']">Phone number is required.</div>
                  <div *ngIf="myForm.get('phoneNumber')?.errors?.['invalidPhoneNumber']">Phone number must be 10 digits.</div>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field appearance="fill">
                <mat-label  class="custom-mat-label">Email</mat-label>
                <input matInput type="email" formControlName="email" placeholder="Email">
                <mat-error *ngIf="myForm.get('email')?.invalid && (myForm.get('email')?.dirty || myForm.get('email')?.touched)">
                  <div *ngIf="myForm.get('email')?.errors?.['required']">Email is required.</div>
                  <div *ngIf="myForm.get('email')?.errors?.['email']">Please enter a valid email address.</div>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="box-border">
          <h3>Company Address</h3>
          
          <div class="form-container single-field">
            <div class="form-group">
              <mat-form-field appearance="fill">
                <mat-label>Street Address</mat-label>
                <input matInput type="text" formControlName="streetAddress" placeholder="Street Address">
              </mat-form-field>
            </div>
            <div class="form-group apartment-block">
              <mat-form-field appearance="fill">
                <mat-label>Apartment/Suite</mat-label>
                <input matInput type="text" formControlName="apartment" placeholder="Apartment/Suite">
              </mat-form-field>
            </div>
          </div>
          


          <div class="form-container three-field">
            <div class="form-group">
              <mat-form-field appearance="fill">
                <mat-label>City</mat-label>
                <input matInput type="text" formControlName="city" placeholder="City">
              </mat-form-field>
            </div>
              
              <div class="form-group">
              <mat-form-field appearance="fill" class="state-select custom-select">
              <mat-label>State</mat-label>
              <mat-select formControlName="state">
                <mat-option value="">Select State</mat-option>
                <mat-option *ngFor="let state of states" [value]="state">{{ state }}</mat-option>
              </mat-select>
              </mat-form-field>
            </div>

            
            <div class="form-group">
              <mat-form-field appearance="fill">
                <mat-label>Zip Code</mat-label>
                <input matInput type="text" formControlName="zipCode" placeholder="Zip Code">
                <mat-error *ngIf="myForm.get('zipCode')?.invalid && (myForm.get('zipCode')?.dirty || myForm.get('zipCode')?.touched)">
                  <div *ngIf="myForm.get('zipCode')?.errors?.['required']">Zip code is required.</div>
                  <div *ngIf="myForm.get('zipCode')?.errors?.['invalidZipCode']">Zip code must be 5 digits.</div>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>


        <div class="button-container">
          <div class="btn">
            <button (click)="updateMyAccount()" mat-raised-button class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button primary">
              <span class="mdc-button__label">
                <span class="button-label">
                  <span>Update My Account</span>
                </span>
              </span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
