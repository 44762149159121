<!-- flyout.component.html -->

<div class="flyout">
  
    <button class="close" (click)="closeFlyout()">&#x2715;</button>
    <div *ngIf="loading" class="loading-container" >
      <div class="loading">
        <div class="spinner-border" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
    </div>
    <div class="flyout-container" *ngIf="!loading">
        <h2 class="headline-highlight underline">Transfer To</h2>
        
        <p class="account-type">Transfer Account</p>
               
        <div *ngIf="!nopayments">
          <div *ngFor="let ectoken of payoutaccounts">
              <div class="tokendiv">
                  <div class="iconbox"><img class="pay-icon" src="{{ectoken.cardImg}}">
                  </div>
                  <div class="pay-info" *ngIf="ectoken.achIndicator =='T'">

                      <span>{{ectoken.counterpartyPayOrg}}&nbsp;{{ectoken.counterPartyBankAccountCategory}}&nbsp;{{ectoken.counterPartyBankAccountType}}</span>
                      <span>Bank **** {{ectoken.counterPartyAccountNumber}}</span>
                  </div>
                  <div class="pay-info" *ngIf="ectoken.zelleIndicator =='T'">
                      <span>ZELLE</span>
                      <span>{{ectoken.counterPartyAccountNumber}}</span>
                  </div>
                  <div class="delete">
                    <img (click)="deleteToken(ectoken.payeeID)" class="delete-icon" src="../../../../assets/icons/delete.svg">
                </div>
              </div>
              <div *ngIf="!ectoken.rtpEligible && ectoken.achIndicator =='T'" class="style_italic">
                  *This bank does not support instant transfer
              </div>
              <div *ngIf="!ectoken.isLastOption">
                  <hr>
              </div>
            
          </div>

      </div>
    </div>
    <br>
    <div *ngIf="!loading" class="add-payment" (click)="addPaymentMethod()" >
      <img src="../../../../assets/icons/add.svg" ><a>Add New Transfer Account</a>
  </div>
   
  </div>

