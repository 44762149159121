import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DWInitiateCashTransactionRequest } from 'src/app/shared/models/DWInitiateCashTransactionRequest.model';
import { transferCarrier } from 'src/app/shared/models/payouttokens.model';

@Component({
  selector: 'app-transfer-requested',
  templateUrl: './transfer-requested.component.html',
  styleUrls: ['./transfer-requested.component.scss']
})

export class TransferRequestedComponent implements OnInit{

transferDate:any;
transactionRefNo:any;
transfer:transferCarrier=new transferCarrier();
errorMessage:any;

  constructor(public route:Router){}


  ngOnInit(){
    const datepipe: DatePipe = new DatePipe('en-US');
    this.transferDate = datepipe.transform(new Date, 'MM/dd/YYYY');
    this.transfer=JSON.parse(sessionStorage.getItem('transferCarrier') || '{}');
    this.transactionRefNo=sessionStorage.getItem('transactionRefNo');
    this.errorMessage = sessionStorage.getItem('errorMsg');
  }

goToHome(){
  this.route.navigate(['home']);
}

}
