import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Redeem_EarnService } from 'src/app/shared/services/api/redeem-earn.service';
import { UserService } from 'src/app/shared/services/user.service';


@Component({
  selector: 'app-payment-summary-dialog',
  templateUrl: './payment-summary-dialog.component.html',
  styleUrls: ['./payment-summary-dialog.component.scss']
})
export class PaymentSummaryDialogComponent {

  selectedInvoice: any;
  paymentSummaryValues: any;
  conversionRates: any;
  currentDefaultPaymentMethod: any;
  walletDetails;
  ONLpolicyDetails;
  cashPointsEarned;
  achPointsEarned;
  earlyPointsEarned;
  systemError: boolean = false;
  loadingButton: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<PaymentSummaryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private redeemService: Redeem_EarnService,
    private userService: UserService
  ){
    this.walletDetails = this.userService.getWalletDetails();
    this.ONLpolicyDetails = this.userService.getONLSessionDetails();

    this.selectedInvoice = data?.selectedInvoice;
    this.paymentSummaryValues = data?.paymentSummaryValues;
    this.conversionRates = data?.conversionRates;
    this.currentDefaultPaymentMethod = data?.currentDefaultPaymentMethod

    if (this.paymentSummaryValues?.cash?.input) {
      this.cashPointsEarned = Number(this.paymentSummaryValues?.cash?.input * this.conversionRates?.cashBillPayConversion).toFixed(0);
    }

    if (this.paymentSummaryValues?.default?.input && (this.currentDefaultPaymentMethod?.cardType == 'ECP' || this.currentDefaultPaymentMethod?.cardType == 'EC')) {
      this.achPointsEarned = Number(this.paymentSummaryValues?.default?.input * this.conversionRates?.achBillPayConversion).toFixed(0);
    }

    if (this.selectedInvoice?.earlyBillPayMessage) {
      this.earlyPointsEarned = Number(this.selectedInvoice?.dueAmount * this.conversionRates?.earlyBillPayConversion).toFixed(0);
    }   
  }

  close() {
    this.dialogRef.close();
  }

  submit() {
    this.systemError = false;
    this.loadingButton = true;
    let walletRole = JSON.parse(sessionStorage.getItem('WalletRole')!);
    let request = {
      walletID: this.walletDetails?.walletID,
      walletRole: walletRole,
      invoiceID: this.selectedInvoice?.invoiceNumber,
      productSystem: this.ONLpolicyDetails?.productSystem,
      inProgressRewards: this.paymentSummaryValues?.rewards?.inputInPoints ? Number(this.paymentSummaryValues?.rewards?.inputInPoints.toFixed(0)) : 0,
      inProgressRewardsValue: this.paymentSummaryValues?.rewards?.input ? Number(this.paymentSummaryValues?.rewards?.input.toFixed(2)) : 0,
      inProgressCashValue: this.paymentSummaryValues?.cash?.input ? Number(this.paymentSummaryValues?.cash?.input.toFixed(2)) : 0,
      paymentValue: this.paymentSummaryValues?.default?.input ? Number(this.paymentSummaryValues?.default?.input.toFixed(2)) : 0, 
      totalInvoiceAmount: Number(this.selectedInvoice?.dueAmount),
      currency: this.walletDetails?.cash?.currency,
      tokenReference: this.currentDefaultPaymentMethod?.token,
      policyNo: this.ONLpolicyDetails?.productSystemIdentifier1,
      pcAccountNo: this.ONLpolicyDetails?.productSystemIdentifier2,
      cardType: this.currentDefaultPaymentMethod?.cardType,
      last4Digits: this.currentDefaultPaymentMethod?.last4Digits,
      ccHolderName: this.currentDefaultPaymentMethod?.ccHolderName,
      ccExpiration: this.currentDefaultPaymentMethod?.ccExpiration,
      cashPointsEarned: this.cashPointsEarned ? this.cashPointsEarned : "0",
      achPointsEarned: this.achPointsEarned ? this.achPointsEarned : "0",
      earlyPointsEarned: this.earlyPointsEarned ? this.earlyPointsEarned : "0"
    };

    this.redeemService.makeinvoicepayment(request).subscribe(
      data => {
        this.loadingButton = false;
        // Not 100% sure what the success response looks like yet, so just putting a default check:
        if (data) {
          this.goToSuccessPage();
        } else {
          this.systemError = true;
        }
      }, error => {
        this.loadingButton = false;
        this.systemError = true;
      }
    )
  }

  goToSuccessPage() {
    let paidInvoice =  {
      invoiceNumber: this.selectedInvoice?.invoiceNumber,
      cashPointsEarned: this.cashPointsEarned ? this.cashPointsEarned : 0,
      achPointsEarned: this.achPointsEarned ? this.achPointsEarned : 0,
      earlyPointsEarned: this.earlyPointsEarned ? this.earlyPointsEarned : 0
    }

    sessionStorage.setItem('paidInvoice', JSON.stringify(paidInvoice));
    this.dialogRef.close('successful-invoice-paid');
  }
}
