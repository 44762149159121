
<div class="sub-container">

    <div class="modal-terms">
    
    <h2 class="headline-highlight" [innerHTML]="message"></h2>
    
    <button mat-icon-button class="close-button" aria-label="Close dialog" (click)="onDecline()">
      <mat-icon>close</mat-icon>
    </button>

    <div id="modal-content-images">
    <div class="tnc-wallet-content">
        <div class="section">
          <img src="../../../../assets/images/ups-accept-document 1.png" alt="icon">
          <h3>Claims Approved</h3>
        </div>
        <div class="section">
          <img src="../../../../assets/icons/east.svg" alt="Image 1">
        </div>
        <div class="section">
          <img src="../../../../assets/images/ups-package-ontime-box-fast 555-02-04 1.png" alt="Image 2">
          <h3>Claims Paid to Wallet</h3>
        </div>
        <div class="section">
          <img src="../../../../assets/icons/east.svg" alt="icon">
        </div>

        <div class="section">
          <img src="../../../../assets/images/ups-pay-bill 1.png" alt="Image 3">
          <h3>Offset invoices or <br /> Cash Out</h3>
        </div>
      </div>
    </div>
      

  <div mat-dialog-content>
    <!-- Your terms and conditions content here -->

    <!-- <p>Exciting News! Your UPS Wallet has been created. You now have more transfer options for your approved claims payouts with instant transfer, ACH or Zelle. You are also eligible to start earning reward points that you can redeem to offset your UPS Capital invoices! 
    </p> -->

    <p *ngIf="data.productType === 'IS4U' && data.billingType === 'UPS'">
      Exciting News! Your UPS Wallet has been created. You now have more transfer options for your approved claims payouts with instant transfer, ACH or Zelle. Eligibility for rewards may vary depending on policy.
    </p>
    <p *ngIf="data.productType !== 'IS4U' || data.billingType !== 'UPS'">
      Exciting News! Your UPS Wallet has been created. You now have more transfer options for your approved claims payouts with instant transfer, ACH or Zelle. You are also eligible to start earning reward points that you can redeem to offset your UPS Capital invoices!
    </p>
    
   
    <p>  
      Simply click ‘Accept’ to unlock this new feature and start enjoying the convenience of UPS Wallet today!
    </p>
    
 

    <p class="bold"><i>By clicking Accept, I agree to both <a href="../../../../assets/pdf/Digital Wallet Terms and Conditions.pdf" alt="UPS Digital Wallet Terms and Conditions" target="_blank">UPS Wallet</a> and <a href="../../../../assets/pdf/UPS InsureShield Reward Program Terms.pdf" alt="UPS Digital Wallet Terms and Conditions" target="_blank">Rewards Program</a> Terms &amp; Conditions</i></p>
    
  </div>

  <div *ngIf="showRemindMeLaterContent">
    <div class="modal-remind-me-later-content">
      <p>It appears you haven't accepted our Terms and Conditions yet. If you wish to continue using the InsureShield Online Portal, please return to the InsureShield Online Portal tab.</p>

    </div>

    <div class="button-container">
      <div class="btn">
        <button id="thank-you-button" (click)="redirectToLoggedOut()" type="submit" mat-raised-button class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button primary">
          <span class="mdc-button__label">
            <span class="button-label">
              <span>Thank You</span>
              <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
            </span>
          </span>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="showAdditionalContent">
    <div class="modal-additional-content">
      <p>The policy administrator can access the wallet through the InsureShield Online Portal, Billing & Wallet tab. All approved claim payouts can be accessed through your UPS Wallet. If you’d like to continue with filing your claim, please log back into your InsureShield Online Portal and continue with the new UPS Wallet 5-step claims filing process!</p>
 
      <!-- <p><b>Please note: All approved claim payouts can be accessed through your UPS Wallet.</b></p> -->
    </div>

    <div class="button-container">
      <div class="btn">
        <button id="thank-you-button" (click)="redirectToLoggedOutTnc()" type="submit" mat-raised-button class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button primary">
          <span class="mdc-button__label">
            <span class="button-label">
              <span>Thank You</span>
              <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
            </span>
          </span>
          
        </button>
      </div>
    </div>
  </div>

  <div mat-dialog-actions>

    <div id="modal-content" class="button-container">
        <div class="btn">
            <button (click)="onAccept()" type="submit" mat-raised-button class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button primary">
                <span class="mdc-button__label">
                    <span class="button-label">
                        <span>Accept</span>
                        <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                    </span>
                </span>
            </button>
        </div>
        <div class="btn">
            <button mat-button (click)="onDecline()" mat-raised-button class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button secondary">
                <span class="mdc-button__label">
                    <span class="button-label">                  
                        <span>
                           Remind Me Later
                        </span>
                        <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>         
                    </span>
                </span>
            </button>
        </div>
    </div>

    <!-- <button mat-button (click)="onAccept()" [disabled]="!canAccept()">Accept</button>
    <button mat-button (click)="onDecline()">Decline</button> -->
  </div>

</div>
</div>

