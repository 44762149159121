import { Component } from '@angular/core';

@Component({
  selector: 'app-default-payment',
  templateUrl: './default-payment.component.html',
  styleUrls: ['./default-payment.component.scss']
})
export class DefaultPaymentComponent {

}
