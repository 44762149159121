import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DWDeletePayoutTokens, PayoutTokens, PayoutTokensResponse } from 'src/app/shared/models/payouttokens.model';
import { DWDPATSessionModel } from 'src/app/shared/models/session.model';
import { GetGuidService } from 'src/app/shared/services/api/get-guid.service';
import { PayoutTokensService } from 'src/app/shared/services/api/get-payout-tokens.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ApiEndpoints } from 'src/config/api-endpoints';
import { TransferDeleteComponent } from '../transfer-delete/transfer-delete.component';

@Component({
  selector: 'app-transfer-flyout',
  templateUrl: './transfer-flyout.component.html',
  styleUrls: ['./transfer-flyout.component.scss']
})
export class TransferFlyoutComponent {
  [x: string]: any;

  isVisible = false;
  payouttokens: any;
  paymenttokens: any;
  instantaccounts: any;
  zelleaccounts: any;
  selectedtoken:any;
  WalletID: any;
  dwdpatsession: any;
  sessionService: any;
  payoutaccounts: any;
  nopayments: boolean = true;
  walletDetails:any;
  loading:boolean=true;
  constructor(public sessionservice:GetGuidService,public payouttoken:PayoutTokensService, public dialogRef: MatDialogRef<TransferFlyoutComponent>, public dialog:MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,public userService:UserService){}
  openFlyout() {
    this.dialogRef.close('accept');
    this.isVisible = true;
  }

  closeFlyout() {
    this.dialogRef.close('back');
    this.isVisible = false;
  }

  ngOnInit()
  {
    this.loading=true;
    this.walletDetails = this.userService.getWalletDetails();
    this.loadpayouttokens();
  }
  addPaymentMethod():void{
this.loading=true;

    if(sessionStorage.length>0)
{
this.WalletID=JSON.parse(sessionStorage.getItem("WalletID") || '{}');
}    
  
 
this.dwdpatsession=new DWDPATSessionModel()
this.dwdpatsession.action='addpayoutpayment';
this.dwdpatsession.callBackURL=`${ApiEndpoints.startWalletSessionCallBack}/my-wallet/my-cash/transfer-balance` ;
this.dwdpatsession.country='US';
this.dwdpatsession.createdByUserId='appdw';
this.dwdpatsession.locale='en';
this.dwdpatsession.navigatingSystem='dw';
this.dwdpatsession.paymentDirection='payout';
this.dwdpatsession.walletID=this.WalletID;
this.dwdpatsession.cashbalance=this.walletDetails?.cash?.totalCashBalance.toString(),
this.dwdpatsession.rewardsBalance=this.walletDetails?.rewards?.totalRewardBalance.toString(),

this.sessionservice.createdwdpatwalletsession(this.dwdpatsession);
this.loading=false;
  }

  deleteToken(token: string) {
    let dialogRef=this.dialog.open(TransferDeleteComponent);
      dialogRef.afterClosed().subscribe(
        result => {
          
          if (result=='accept') {
            this.loading=true;
            let dwDeletePayRequest: DWDeletePayoutTokens = new DWDeletePayoutTokens();
            if(sessionStorage.length>0)
            {
              dwDeletePayRequest.walletID=JSON.parse(sessionStorage.getItem("WalletID") || '{}');
              dwDeletePayRequest.walletRole=JSON.parse(sessionStorage.getItem("WalletRole") || '{}');
              dwDeletePayRequest.payeeId=token;
            }
            
            
            this.payouttoken.deletepaymentmethod(dwDeletePayRequest).subscribe({
              next: (data: PayoutTokensResponse) => {
                console.log("delete token " + data.success);
                this.loadpayouttokens();
                window.location.reload();
        
              }
            });
            console.log(token + 'deleted');
        
            //this.currentDefaultPaymentMethod = result; // User selected a payment method in the flyout, update to that method.
          }
          
        }
      );

  }

  loadpayouttokens():void{
    let payouttokens:PayoutTokens= new PayoutTokens() ;
    payouttokens.transferType='NonRTP';
    payouttokens.walletID=JSON.parse(sessionStorage.getItem("WalletID") || '{}');
    payouttokens.walletRole=JSON.parse(sessionStorage.getItem("WalletRole") || '{}');
   
   
       let listoftokens= this.payouttoken.fetchpayouttokens(payouttokens).subscribe({
         next: (data:PayoutTokensResponse) => {
           console.log("is payouttokens call successfull "+ data.success);
           if(data.success==true)
           this.nopayments=false;
           console.log("payintokens "+ data.payoutTokens);
           this.paymenttokens= data.payoutTokens;
           this.paymenttokens.forEach((token:any) => {
            if(token.zelleIndicator=='T')
            {
          token.cardImg=this.payouttoken.getcardImage('zelle');
          
            }
            else{
              token.counterpartyPayOrg=token.counterpartyPayOrg.replace('NA','');
             token.cardImg= this.payouttoken.getcardImage(token.counterpartyPayOrg);
             if(token.counterPartyAccountNumber!=null && token.counterPartyAccountNumber!=undefined)
             {
              let accnumber=token.counterPartyAccountNumber;
              token.counterPartyAccountNumber=accnumber.substring(accnumber.length-4,accnumber.length+1);
             }
             else{
              token.counterPartyAccountNumber='0000';
             }
            }
            /*  let expiration=token.ccexpiration;
             token.ccexpiration=expiration.slice(4,6)+"/"+ expiration.slice(2,4) */
           });
           
           this.instantaccounts=this.paymenttokens.filter((x:any)=>x.achIndicator=='T');
           this.zelleaccounts=this.paymenttokens.filter((x:any)=>x.achIndicator=='F');
           this.payoutaccounts=this.paymenttokens;
        this.payoutaccounts[this.payoutaccounts.length-1].isLastOption=true;
        this.loading=false; 
           
         }
        });
   
   
  }


}
