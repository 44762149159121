
<div *ngIf="isLoading; else content" class="skeleton-container">
  <app-skeleton type="text"></app-skeleton>
  <app-skeleton type="image"></app-skeleton>
  <app-skeleton type="button"></app-skeleton>
</div>

<ng-template #content>
<div class="home-container">
    <div class="image-container">

  
    <ngb-carousel #carousel="ngbCarousel" [showNavigationArrows]="false" >
        <ng-template ngbSlide *ngFor="let img of images; let i=index">
            <img [src]="img.src" [alt]="img.title" style=" width:100%;">
        </ng-template>
    </ngb-carousel> 
   
            
         <div class="overlay">
        
                <div class="carousel-block-content">
                
                    <div class="balance-container">
                        <h2 class="headline-highlight">{{ 'home.myBalance' | translate }}</h2>
                        <ng-container *ngIf="cashEligible && !rewardEligible; else defaultInfo">
                          <p class="info">{{ 'home.info-is4u' | translate }}</p>
                        </ng-container>
                        
                        <ng-template #defaultInfo>
                          <p class="info">{{ 'home.info' | translate }}</p>
                        </ng-template>
                        
                        
                        <div class="balance-details">
                            <div class="rewards-section">
                                <div class="svg-headline">
                                    <span class="svg">
                                        <ng-container *ngIf="cashEligible && !rewardEligible; else defaultSvg">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24" fill="none">
                                            <path d="M11 18.8287L5.83643 22.7712C5.58058 22.9805 5.30147 23.0794 4.99909 23.0677C4.69672 23.0561 4.42924 22.9689 4.19665 22.8061C3.96405 22.6432 3.78379 22.4223 3.65587 22.1432C3.52794 21.8641 3.52213 21.5617 3.63842 21.2361L5.6271 14.7816L0.568192 11.188C0.28908 11.002 0.114635 10.7577 0.0448573 10.4554C-0.0249207 10.153 -0.013291 9.87387 0.0797463 9.61802C0.172784 9.36217 0.335599 9.13539 0.568192 8.93769C0.800785 8.73998 1.0799 8.64113 1.40553 8.64113H7.65066L9.67422 1.94244C9.79051 1.61681 9.97077 1.36678 10.215 1.19233C10.4592 1.01789 10.7209 0.930664 11 0.930664C11.2791 0.930664 11.5408 1.01789 11.785 1.19233C12.0292 1.36678 12.2095 1.61681 12.3258 1.94244L14.3493 8.64113H20.5945C20.9201 8.64113 21.1992 8.73998 21.4318 8.93769C21.6644 9.13539 21.8272 9.36217 21.9203 9.61802C22.0133 9.87387 22.0249 10.153 21.9551 10.4554C21.8854 10.7577 21.7109 11.002 21.4318 11.188L16.3729 14.7816L18.3616 21.2361C18.4779 21.5617 18.4721 21.8641 18.3441 22.1432C18.2162 22.4223 18.0359 22.6432 17.8034 22.8061C17.5708 22.9689 17.3033 23.0561 17.0009 23.0677C16.6985 23.0794 16.4194 22.9805 16.1636 22.7712L11 18.8287Z" fill="#BFB8AF"/>
                                          </svg>
                                        </ng-container>
                                        <ng-template #defaultSvg>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24" fill="none">
                                            <path d="M11 18.8292L5.83643 22.7717C5.58058 22.981 5.30147 23.0798 4.99909 23.0682C4.69672 23.0566 4.42924 22.9694 4.19665 22.8065C3.96405 22.6437 3.78379 22.4228 3.65587 22.1437C3.52794 21.8645 3.52213 21.5622 3.63842 21.2365L5.6271 14.7821L0.568192 11.1885C0.28908 11.0024 0.114635 10.7582 0.0448573 10.4558C-0.0249207 10.1535 -0.013291 9.87436 0.0797463 9.61851C0.172784 9.36266 0.335599 9.13588 0.568192 8.93818C0.800785 8.74047 1.0799 8.64162 1.40553 8.64162H7.65066L9.67422 1.94293C9.79051 1.6173 9.97077 1.36726 10.215 1.19282C10.4592 1.01837 10.7209 0.931152 11 0.931152C11.2791 0.931152 11.5408 1.01837 11.785 1.19282C12.0292 1.36726 12.2095 1.6173 12.3258 1.94293L14.3493 8.64162H20.5945C20.9201 8.64162 21.1992 8.74047 21.4318 8.93818C21.6644 9.13588 21.8272 9.36266 21.9203 9.61851C22.0133 9.87436 22.0249 10.1535 21.9551 10.4558C21.8854 10.7582 21.7109 11.0024 21.4318 11.1885L16.3729 14.7821L18.3616 21.2365C18.4779 21.5622 18.4721 21.8645 18.3441 22.1437C18.2162 22.4228 18.0359 22.6437 17.8034 22.8065C17.5708 22.9694 17.3033 23.0566 17.0009 23.0682C16.6985 23.0798 16.4194 22.981 16.1636 22.7717L11 18.8292Z" fill="#FFC400"/>
                                          </svg>
                                        </ng-template>
                                      </span>
                                    
                                <span [ngClass]="{'disabled': cashEligible && !rewardEligible}" class="label rewards-label">
                                    {{ 'home.rewards' | translate | uppercase }} 
                                </span>
                            </div>
        
                                    <div class="rewards-value" [ngClass]="{'disabled': cashEligible && !rewardEligible}">
                                        <span class="points value">{{ walletSummary?.wallet?.rewards?.totalRewardBalance ?? 0 | number }}
                                            <span class="pts">PTS</span></span>
                                        <span class="points-value">{{ walletSummary?.wallet?.rewards?.totalRewardValue ?? 0 | currency}} Value</span> 
                                    </div>
                            </div>
        
                            <div class="divider"></div>
        
                            <div class="cash-section">
                                <div class="svg-headline">
                                    <span class="svg"> 
                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none">
                                        <path d="M10.465 20.05H12.4775V18.6125C13.4358 18.44 14.26 18.0663 14.95 17.4913C15.64 16.9163 15.985 16.0633 15.985 14.9325C15.985 14.1275 15.755 13.3896 15.295 12.7188C14.835 12.0479 13.915 11.4633 12.535 10.965C11.385 10.5817 10.5896 10.2463 10.1487 9.95875C9.70792 9.67125 9.4875 9.27833 9.4875 8.78C9.4875 8.28167 9.66479 7.88875 10.0194 7.60125C10.374 7.31375 10.8867 7.17 11.5575 7.17C12.1708 7.17 12.65 7.31854 12.995 7.61562C13.34 7.91271 13.5892 8.28167 13.7425 8.7225L15.5825 7.975C15.3717 7.30417 14.9835 6.71958 14.4181 6.22125C13.8527 5.72292 13.225 5.445 12.535 5.3875V3.95H10.5225V5.3875C9.56417 5.59833 8.81667 6.02 8.28 6.6525C7.74333 7.285 7.475 7.99417 7.475 8.78C7.475 9.68083 7.73854 10.4092 8.26562 10.965C8.79271 11.5208 9.62167 12 10.7525 12.4025C11.96 12.8433 12.7985 13.2362 13.2681 13.5813C13.7377 13.9263 13.9725 14.3767 13.9725 14.9325C13.9725 15.565 13.7473 16.0298 13.2969 16.3269C12.8465 16.624 12.305 16.7725 11.6725 16.7725C11.04 16.7725 10.4794 16.576 9.99062 16.1831C9.50187 15.7902 9.1425 15.2008 8.9125 14.415L7.015 15.1625C7.28333 16.0825 7.70021 16.8252 8.26562 17.3906C8.83104 17.956 9.56417 18.3442 10.465 18.555V20.05ZM11.5 23.5C9.90917 23.5 8.41417 23.1981 7.015 22.5944C5.61583 21.9906 4.39875 21.1712 3.36375 20.1362C2.32875 19.1012 1.50938 17.8842 0.905625 16.485C0.301875 15.0858 0 13.5908 0 12C0 10.4092 0.301875 8.91417 0.905625 7.515C1.50938 6.11583 2.32875 4.89875 3.36375 3.86375C4.39875 2.82875 5.61583 2.00938 7.015 1.40562C8.41417 0.801875 9.90917 0.5 11.5 0.5C13.0908 0.5 14.5858 0.801875 15.985 1.40562C17.3842 2.00938 18.6012 2.82875 19.6362 3.86375C20.6712 4.89875 21.4906 6.11583 22.0944 7.515C22.6981 8.91417 23 10.4092 23 12C23 13.5908 22.6981 15.0858 22.0944 16.485C21.4906 17.8842 20.6712 19.1012 19.6362 20.1362C18.6012 21.1712 17.3842 21.9906 15.985 22.5944C14.5858 23.1981 13.0908 23.5 11.5 23.5Z" fill="#0A8080"/>
                                        </svg>  
                                    </span>
                                <span class="label cash-label">
                                     {{ 'home.cash' | translate | uppercase }}
                                </span>
                                </div>
        
                                <span class="cash-value value">{{ walletSummary?.wallet?.cash?.totalCashBalance ?? 0 | currency}}</span>
                            </div>
                        </div>
                    </div>
        
                    <div class="button-container">
                        <div class="btn">
                          <button (click)="goToRedeem()" 
                          [disabled]="loading || autoPay === 1 || (cashEligible && !rewardEligible)" 
                          mat-raised-button 
                          class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button primary btn-disable">
                            <span class="mdc-button__label">
                              <span class="button-label">
                                <span>Redeem &amp; Pay</span>
                                <mat-icon *ngIf="!(cashEligible && !rewardEligible)" class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                                <mat-icon *ngIf="cashEligible && !rewardEligible || autoPay === 1" class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">lock_outline</mat-icon> 
                              </span>
                            </span>
                          </button>
                        </div>
                        <div class="btn">
                          <button (click)="goToTransferBalance()" [disabled]="loading" mat-raised-button class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button secondary">
                            <span class="mdc-button__label">
                              <span class="button-label">
                                <span>Transfer Cash</span>
                                <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                              </span>
                            </span>
                          </button>
                        </div>
                      </div>
                      
            </div>
     
            </div>
    </div>
</div>
</ng-template> 


<app-redeem-card  [isLoading]="isLoading"></app-redeem-card>
<app-transaction-history [isLoading]="isLoading" ></app-transaction-history>


<app-how-it-works  [isLoading]="isLoading" [showDivider]="false" [disabled]="cashEligible && !rewardEligible"></app-how-it-works>

  