import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { WalletSummaryService } from 'src/app/shared/services/api/wallet-summary.service';
import { AutoTransferService } from 'src/app/shared/services/api/auto-transfer.service';
import { WalletSummary } from 'src/app/shared/models/wallet-summary.interface';

@Component({
  selector: 'app-deactivate-transfer-pop-up',
  templateUrl: './deactivate-transfer-pop-up.component.html',
  styleUrls: ['./deactivate-transfer-pop-up.component.scss']
})
export class DeactivateTransferPopUpComponent {
  walletSummary: WalletSummary | null = null;
  constructor(
    public dialogRef: MatDialogRef<DeactivateTransferPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private autoTransferService: AutoTransferService,
    public walletSummaryService:WalletSummaryService

  ) {}

  isProcessing = false; // Add this property at the top of the class
  onConfirm(): void {

    if (this.isProcessing) return; // Prevent multiple clicks
    this.isProcessing = true; // Disable button immediately
    
    const walletDetails = JSON.parse(sessionStorage.getItem('walletDetails') || '{}');
  //  const payeeID = walletDetails?.autotransfer?.payeeID || '';
  //  const transferType = walletDetails?.autotransfer?.transferType || 'RTP';

    const autoTransferData = JSON.parse(sessionStorage.getItem('AutoTransfer') || '{}');
     const payeeID = autoTransferData.defaultToken;
     const transferType = autoTransferData?.transferType || 'RTP';

  

    let walletID = JSON.parse(sessionStorage.getItem("WalletID") || '{}');
    let walletRole = JSON.parse(sessionStorage.getItem("WalletRole") || '{}');

   this.walletSummaryService.fetchWalletSummary(walletID,walletRole).subscribe(summary => {
      if (summary && summary.wallet) {
    
      this.autoTransferService.setautotransfer(false, payeeID, transferType,summary.wallet?.cash.totalCashBalance).subscribe({
      next: () => {
        console.log('Auto Transfer deactivated successfully.');

        // Clear session storage
        sessionStorage.setItem('isAutoTransfer', JSON.stringify(false));
        sessionStorage.removeItem('AutoTransfer');


        // Close the dialog
        this.dialogRef.close(true);
        
        // Navigate to Transfer Balance page
        //this.router.navigate(['my-wallet', 'my-cash', 'transfer-balance']);
        this.router.navigate(['/my-wallet/my-cash/transfer-balance']).then(() => {
          window.location.reload(); // Refresh the page to reflect the new state
      });

  
      },
      error: (error) => {
        console.error('Error deactivating Auto Transfer:', error);
        this.dialogRef.close(false); // Close dialog without success
        this.isProcessing = false; // Re-enable button and hide spinner on error
      }
    });
  }
  });
  }

  closeFlyout() {
    this.dialogRef.close('back');
  }

  // onConfirm(): void {
    
  //   const walletDetails = JSON.parse(sessionStorage.getItem('walletDetails') || '{}');
  //   const walletID = JSON.parse(sessionStorage.getItem('WalletID') || '{}');
  //   const walletRole = JSON.parse(sessionStorage.getItem('WalletRole') || '{}');
  //   const payeeID = walletDetails?.autotransfer?.payeeID || '';
  //   const transferType = walletDetails?.autotransfer?.transferType || 'RTP';

  //   const payload = {
  //     walletID: walletID,
  //     walletRole: walletRole,
  //     isAutoTransfer: false,
  //     payeeID: payeeID,
  //     transferType: transferType,
  //     cashAmount: 0
  //   };

  //    // Call API to deactivate auto-transfer
  //    this.walletSummaryService.deactivateAutoTransfer(payload).subscribe({
  //     next: () => {
  //       console.log('Auto Transfer deactivated successfully.');

  //       // Clear session storage and close dialog
  //       sessionStorage.removeItem('AutoTransfer');
  //       this.dialogRef.close(true);

  //       // Refetch wallet summary
  //       this.walletSummaryService.fetchWalletSummary(walletID, walletRole).subscribe();

  //       // Navigate to Transfer Balance page
  //       this.router.navigate(['my-wallet', 'my-cash', 'transfer-balance']);
  //     },
  //     error: (error) => {
  //       console.error('Error deactivating Auto Transfer:', error);
  //       this.dialogRef.close(false); // Close dialog without success
  //     }
  //   });
  
  //   sessionStorage.removeItem('AutoTransfer'); // Clear Auto-Transfer data
  //   this.dialogRef.close(true); // Close the dialog and confirm deactivation
  //   this.router.navigate(['my-wallet', 'my-cash', 'transfer-balance']); // Navigate to Transfer Balance
  
  
  // }

  onCancel(): void {
    this.dialogRef.close(false); // Close the dialog and cancel deactivation
  }


}
