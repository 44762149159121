import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { ApiEndpoints } from 'src/config/api-endpoints';

interface SourceIdentifier {
  insureShield: {
    policyNo: string;
    policyStartDate: string;
    policyEndDate: string;
  };
  deliveryDefence: {
    policyNo: string;
    contractNo: string;
    contractStartDate: string;
    contractEndDate: string;
  };
  success: boolean;
  message: string;
}


@Injectable({
  providedIn: 'root'
})
export class SourceIdentifierService {

 // private apiUrl = 'https://ib-wallet-dev-api.azurewebsites.net/api/DigitalWalletAPI/dwgetsourceidentifierdetails';
    private apiUrl = ApiEndpoints.sourceIdentifier;

  constructor(private http:HttpClient) { }

  fetchIdentifierDetails(walletID: string, walletRole: string): Observable<SourceIdentifier>{
    let sessionID=sessionStorage.getItem("SessionID");
    const requestBody = { walletID, walletRole,sessionID };
    let wallettoken=sessionStorage.getItem("walletheaders");
    let httpOptions = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET",
        'Content-Type': 'application/json',
        "Authorization": "bearer " + wallettoken
      }),
    };
    return this.http.post<SourceIdentifier>(this.apiUrl, requestBody,httpOptions);
  }



}
