import { Component, OnInit } from '@angular/core';
import { WalletSummaryService } from 'src/app/shared/services/api/wallet-summary.service';
import { WalletSummary } from 'src/app/shared/models/wallet-summary.interface';
import { SourceIdentifierService } from 'src/app/shared/services/api/source-identifier.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-connected-solutions',
  templateUrl: './connected-solutions.component.html',
  styleUrls: ['./connected-solutions.component.scss']
})
export class ConnectedSolutionsComponent implements OnInit {

  walletID: string = '';
  walletRole: string = '' ;

  insureShieldPolicyNo: string = '';
  insureShieldPolicyStartDate: string = '';
  insureShieldPolicyEndDate: string = '';
  deliveryDefencePolicyNo: string = '';

  constructor(
      private walletSummaryService:WalletSummaryService,
      private sourceIdentifierService: SourceIdentifierService,
      private router: Router
    ){}

  walletSummary: WalletSummary | null = null;

  ngOnInit(): void {
     this.walletSummaryService.walletSummary$.subscribe(summary => {
      if (summary && summary.wallet) {
        this.walletID = summary.wallet.walletID;
        this.walletRole = 'admin'; // Replace 'yourRole' with the actual logic to determine walletRole
        this.walletSummary = summary;
        this.fetchIdentifierDetails();
      }
    });
  }

  fetchIdentifierDetails() {
    this.sourceIdentifierService.fetchIdentifierDetails(this.walletID, this.walletRole).subscribe(
      (response) => {
        this.insureShieldPolicyNo = response.insureShield.policyNo;
        this.insureShieldPolicyStartDate = response.insureShield.policyStartDate;
        this.insureShieldPolicyEndDate = response.insureShield.policyEndDate;
        this.deliveryDefencePolicyNo = response.deliveryDefence.policyNo;
      },
      (error) => {
        console.error('Error fetching identifier details:', error);
      }
    );
  }

  contactUs() {
    this.router.navigate(['contact-us']);
  }

}
