import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

interface LinkItem {
  text: string;
  svg?: string;
  route?: string; // For internal routes
  href?: string; // For external URLs
}

interface ColumnItem {
  title: string;
  links: LinkItem[];
  isExpanded: boolean;
}


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  columns: ColumnItem[] = [
    { title: 'This Site', links: [{text:  'Home', svg:''}, {text: 'My Wallet', svg:'', route: 'my-wallet/my-wallet-details'}, {text:'Connected Solutions', svg:'', route:'connected-solution'}], isExpanded:false},
    { title: 'Customer Service', links: [{text: 'Claims', svg:'', href: 'https://upscapital.com/claims/'}, {text: 'Help & Support', svg:'', href: 'https://upscapital.com/about/help-and-support/'}, {text: 'Contact Us', svg:'', href: 'https://upscapital.com/about/contact-us/'} ] , isExpanded:false},
    { title: 'Company', links: [ {text: 'About Us', svg:'', href: 'https://upscapital.com/about/'}, {text:'Offices', svg:'', href:'https://upscapital.com/about/offices/'}], isExpanded:false},
    { title: 'Other UPS Sites', links: [ 
          {text: 'Parcel Pro', svg:'assets/icons/Assets.svg', href:'https://www.parcelpro.com/us/en/home.html'}, 
          {text: 'InsureShield', svg:'assets/icons/Assets.svg', href:'https://www.insureshield.com/us/en/home.html'}, 
          {text: 'UPS', svg:'assets/icons/Assets.svg', href:'https://www.ups.com/us/en/Home.page'}, 
          {text: 'UPS Supply Chain Solutions', svg:'assets/icons/Assets.svg', href:'https://www.ups.com/us/en/supplychain/Home.page'},
          {text: 'About UPS', svg:'assets/icons/Assets.svg', href:'https://about.ups.com/us/en/home.html'}], isExpanded:false},
          { 
        title: 'Connect With Us', links: [ 
          {text: 'Twitter', svg: 'assets/icons/Assets.svg', href:'https://twitter.com/upscapital' }, 
          {text: 'LinkedIn', svg:'assets/icons/Assets.svg', href:'https://www.linkedin.com/company/ups-capital'}, 
          {text: 'YouTube', svg:'assets/icons/Assets.svg', href:'https://www.youtube.com/@InsureShield'},
          {text: 'Facebook', svg:'assets/icons/Assets.svg', href:'https://www.facebook.com/UPSCapitalInsuranceAgency'}
        
      ], isExpanded:false},
      { title: 'Legal', links: [ 
        {text: 'Technology Agreement', svg:'', href: 'https://upscapital.com/technology-agreement/'},
        {text: 'Website Terms of Use', svg:'assets/icons/Assets.svg', href:'https://www.ups.com/us/en/support/shipping-support/legal-terms-conditions.page'}, 
        {text:'Disclosures', svg:'', href:'https://upscapital.com/us-product-disclosures/'},
        {text: 'Your California Privacy Rights', svg:'assets/icons/Assets.svg', href:'https://www.ups.com/us/en/support/shipping-support/legal-terms-conditions/california-privacy-rights.page'},
        {text: 'Privacy Notice', svg:'', href:'https://upscapital.com/privacy-notice/'},
        {text: 'Cookie Settings', svg:'', href:'https://upscapital.com/'}, 
        {text:'Do Not Sell or Share My Personal Information', svg:'assets/icons/Assets.svg', href:'https://upscapital.com/opt-out/'}]
        , isExpanded:false},
      
  ];

  isMobileView: boolean = window.innerWidth < 768;

  @HostListener('window:resize', ['$event'])
  
  onResize(event: any){
    this.isMobileView = window.innerWidth < 768;
  }

  toggleColumn(index:number):void{
    if (this.isMobileView){
      this.columns[index].isExpanded = !this.columns[index].isExpanded
    }
  }

  ngOnInit(): void {
    
  }

}
