import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-modal-content',
  templateUrl:'./modal-content.component.html',
  styleUrls: ['./modal-content.component.scss']
})

export class ModalContentComponent {

  constructor(public activeModal:NgbActiveModal, public router:Router, public route:ActivatedRoute){}

 gotoNotification(){
  this.router.navigate(['/preferences/manage-payment'], { relativeTo:this.route });
  this.activeModal.close();
 }

}
