export  class ONLWalletSession
{
  result!: string;
  message!: string;
  walletRole!: string;
  walletID!: string;
  onlStartWalletSession!: ONLStartWalletSession;

}
export  class ONLWalletSessionResponse
{
  result!: string;
  message!: string;
  walletRole!: string;
  walletID!: string;
  onlStartWalletSession!: ONLStartWalletSession;
  ibJWT!:string;
  dpatJWT!:string;
}
export  class ONLWalletSessionRequest
{
  sessionId:string='';
  secret:string='';
  user:string='';
  pass:string='';

}
export class ONLStartWalletSession
{
     action!: string;
     navigatingSystem!: string;
     navigatingSystemRole!: string;
     productSystem!: string;
     productSystemIdentifier1!: string;
     productSystemIdentifier1Name!: string;
     productSystemIdentifier2!: string;
     productSystemIdentifier2Name!: string;
     productType?:string;
     country!: string;
     locale!: string;
     userIdentifier1Name!: string;
     userIdentifier1!: string;
     displayName?:string;
     userEmail!: string;
     callBackURL!: string;
     returnURL?:string;
     objectUID?:string;

}

export class  DWDPATSessionModel
{
  action: string=' ';
  paymentDirection: string=' ';
  navigatingSystem: string=' ';
  country: string=' ';
  walletID: string=' ';
  locale: string=' ';
  callBackURL: string=' ';
  cashBalance: string=' ';
  rewardsBalance: string=' ';
  createdByUserId: string ='';
}

export class sessionResponse
{
  url:string='';
}
export class AuthToken
{
  success:boolean=false;
  token:string='';
}
