import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceSpendIdentifierDescription'
})
export class ReplaceSpendIdentifierDescriptionPipe implements PipeTransform {
  transform(item: any): string {
    if (item.spendIdentifierDescription === 'PayableReferenceNumber') {
      return `${item.spendAccountBankName} ***${item.spendAccountAlias}`;
    }
    return `${item.spendIdentifierDescription} #${item.spendIdentifier}`;
  }
}