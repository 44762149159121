import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InsureShieldTncDialogComponent } from 'src/app/shared/dialog/insure-shield-tnc-dialog/insure-shield-tnc-dialog.component';
import { DeliveryDefenseTncDialogComponent } from 'src/app/shared/dialog/delivery-defense-tnc-dialog/delivery-defense-tnc-dialog.component';
import { MatTooltip } from '@angular/material/tooltip';
import {MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions, MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import { ClickableTooltipDirective } from 'src/app/shared/directives/clickable-tooltip.directive';

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 1000,
  hideDelay: 1000,
  touchendHideDelay: 1000,
};

@Component({
  selector: 'app-ways-to-earn',
  templateUrl: './ways-to-earn.component.html',
  styleUrls: ['./ways-to-earn.component.scss'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults }
  ]
})
export class WaysToEarnComponent implements OnInit {
  @ViewChildren(MatTooltip) tooltips: QueryList<MatTooltip>;

  constructor(public dialog: MatDialog, private modalService: NgbModal) {}

  middleRowIndexTableData: number;
  middleRowIndexCashData: number;


  openDeliveryDefenseModal(): void {
    const modalRef = this.modalService.open(DeliveryDefenseTncDialogComponent, {
      size: 'lg', // Setting the modal size to 'lg' (large)
    });
  }

  openInsureShieldModal(): void {
    const modalRef = this.modalService.open(InsureShieldTncDialogComponent, {
      size: 'lg', // Setting the modal size to 'lg' (large)
    });
  }

  showRewards = true;
  showCash = true;
  tableData = [
    { column1: 'Early Bill Pay', column2: 'N/A', column3: '0.25% of Invoice', hasDialog: false },
    { column1: 'ACH Bill Pay', column2: 'N/A', column3: '0.75% of Invoice', hasDialog: false },
    { column1: 'Cash Bill Pay', column2: 'N/A', column3: '1% Cash Amt Applied', hasDialog: false },
    { column1: 'Profile Validation (Annual)', column2: 'N/A', column3: '1,000 pts', hasDialog: true, 
    tooltipText:  
      { title: 'Profile Validation', 
        content: 'Validate your contact information on your connected solution.'} 
      },
    { column1: 'Account Creation (one-Time)', column2: 'N/A', column3: '1,000 pts', hasDialog: true, 
      tooltipText: { title: 'Account Creation', 
      content: 'Validate your contact information on your connected solution.'}  
    },
    { column1: 'Invite Users (Up to 3 users)', column2: 'N/A', column3: '1,000 pts', hasDialog: false },
    { column1: 'Subscription Upgrade', column2: 'N/A', column3: 'N/A', hasDialog: true,
        tooltipText: { title: 'Subscription Upgrade', 
        content: 'Upgrade your DeliveryDefense subscription tier to earn rewards back on the first month.'} 
      },
    { column1: '3 Month Milestone', column2: 'N/A', column3: 'N/A', hasDialog: false },
    { column1: 'First Time Search', column2: 'N/A', column3: 'N/A', hasDialog: false },
    { column1: 'Every 10<sup>th</sup> Search', column2: 'N/A', column3: 'N/A', hasDialog: false },
    { column1: 'First Time API', column2: 'N/A', column3: 'N/A', hasDialog: false }
  ];

  cashData = [
    { column1: 'Approved Claims', column2: 'N/A', column3: 'Per Claim', hasDialog: false },
    { column1: 'Experience Refund', column2: 'N/A', column3: 'N/A', hasDialog: true, 
    tooltipText: 
    { title: 'Experience Refund', 
    content: 'Refund in the event your claims ratio is below the original estimate from annual policy'} 
     },
  ];

  openDialog(item: any): void {
    console.log('test');
  }

  getTooltipInfo(item: any) {
    return {
      title: item.tooltipText.title,
      content: item.tooltipText.content
    };
  }

  ngOnInit() {
    if (this.tableData.length === 10) {
      this.showRewards = true;
      this.showCash = true;
    }

    this.middleRowIndexTableData = Math.floor(this.tableData.length / 2);
    this.middleRowIndexCashData = Math.floor(this.cashData.length / 2);
  }

 
}
