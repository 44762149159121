<div class="sub-container">

  <div class="header-container">
    <div class="header-content">
      <h2 class="headline-highlight">Payment History</h2>
      <div class="button-container">
        <button (click)="managePayMethod()" mat-raised-button class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button primary">
          <span class="mdc-button__label">
            <span class="button-label">
              <span>Manage Payment Method</span>
              <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
            </span>
          </span>
        </button>
      </div>
    </div>
  </div>  

  <div class="search-box-container box-border">
        
    <div class="search-container">
    <mat-form-field class="custom-placeholder">
      <mat-icon matPrefix>search</mat-icon>
      <input class="search-box" matInput placeholder="Search Table" [(ngModel)]="searchValue" (keyup.enter)="applySearch(searchValue)">
    </mat-form-field>
            
    <div class="button-container">
        <div class="btn">
            <button (click)="applySearch(searchValue)" mat-raised-button class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button primary">
                <span class="mdc-button__label">
                    <span class="button-label">
                        <span>Search</span>
                        <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon> 
                    </span>
                </span>
            </button>
        </div>
      </div>      
  </div>

</div>


<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef class="dw-header-cell">
        <mat-checkbox (change)="masterToggle()"
                      [checked]="isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      disableRipple>
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="selection.toggle(row)"
                      [checked]="selection.isSelected(row)"
                      disableRipple>
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Date Column -->
    <ng-container matColumnDef="paymentDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell custom-sort-header">Date</th>
      <td mat-cell *matCellDef="let element">{{ element.paymentDate | date: "shortDate" }}</td>
    </ng-container>

    <!-- Amount Column -->
    <ng-container matColumnDef="paymentTotal">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell custom-sort-header">Amount</th>
      <td mat-cell *matCellDef="let element">{{ element.paymentTotal | currency }}</td>
    </ng-container>

       <!-- Reference Number Column -->
    <ng-container matColumnDef="paymentIdentifier">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell custom-sort-header">Reference Number</th>
      <td mat-cell *matCellDef="let element">{{ element.paymentIdentifier }}</td>
    </ng-container>

       <!-- Account Column -->
       <ng-container matColumnDef="paymentCategory">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell custom-sort-header">Account</th>
        <td mat-cell *matCellDef="let element">{{ element.tokenDetails.cardType }} {{ element.tokenDetails.last4Digits }}</td>
      </ng-container>

    <!-- Connected Solution Column -->
    <!-- <ng-container matColumnDef="productName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell">Connected Solution</th>
      <td mat-cell *matCellDef="let element">{{ element.productName }}</td>
    </ng-container> -->





    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons class="custom-paginator"></mat-paginator>
</div>

</div>
