<div class="col-md-12 loading-container" *ngIf="loading">
  <div class="loading">
    <div class="spinner-border" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
</div>
<div class="col-md-12" *ngIf="!loading">
  <!-- We have to double wrap because the flyout ngClass disables pointer-events, while the outer wrapper (flyout-closed-wrapper) still lets you close the flyout by clicking anywhere-->
  <div class="one-time-payment-wrapper">
    <!-- Cash/Rewards Section (Mobile view only - desktop view in payment summary section later down) -->
    <div class="summary-wrapper">
      <div class="cash-rewards-section mobile">
        <div class="cash">
          <div class="type">CASH</div>
          <div class="max-amount">{{ walletDetails?.cash?.totalCashBalance | currency: 'USD' }}</div>
          <div class="subtract" *ngIf="paymentSummaryValues?.cash?.input">-{{ paymentSummaryValues?.cash?.input |
            currency: 'USD' }}</div>
          <div class="new-balance" *ngIf="paymentSummaryValues?.cash?.input">New Balance: {{
            paymentSummaryValues?.cash?.remainingBalance | currency: 'USD' }}</div>
        </div>
        <div class="gray-divider"></div>
        <div class="rewards">
          <div class="type">REWARDS</div>
          <div class="max-amount">{{ walletDetails?.rewards?.totalRewardBalance | number }}<span
              class="supination">PTS</span>
          </div>
          <div class="subtract" *ngIf="paymentSummaryValues?.rewards?.inputInPoints">-{{
            paymentSummaryValues?.rewards?.inputInPoints | number }} pts</div>
          <div class="new-balance" *ngIf="paymentSummaryValues?.rewards?.inputInPoints">New Balance: {{
            paymentSummaryValues?.rewards?.remainingBalanceInPoints | number }} PTS</div>
        </div>
      </div>
    </div>
    <!-- TO-DO: The below placeholder div is for the sticky functionality -->
    <!-- <div class="placeholder"></div> -->
    <!-- Main Section (Both Desktop/Mobile) -->
    <!-- Includes header area, the collapsible panels, and payment summary section -->
    <div class="main-wrapper">
      <!-- Header + Collapsible Panel Section (Both Desktop/Mobile) -->
      <div class="header-and-panel-wrapper">
        <!-- Header -->
        <div class="header-section">
          <div class="title">Apply Your Balance</div>
          <div class="yellow-bar"></div>
          <div class="autopay-indicator">
            <img src="../../../../assets/images/autopay_dot_off.png" class="icon"
              *ngIf="walletDetails?.isDWAutoPay == '0'">
            <img src="../../../../assets/images/autopay_dot_on.png" class="icon"
              *ngIf="walletDetails?.isDWAutoPay == '1'">
            <div class="text">Auto Pay:
              <span class="gray" *ngIf="walletDetails?.isDWAutoPay == '0'">OFF</span>
              <span class="green" *ngIf="walletDetails?.isDWAutoPay == '1'">ON</span>
            </div>
            <div class="link" *ngIf="walletDetails?.isDWAutoPay == '0'" (click)="navigateToAutopayPage()">
              Set Up<mat-icon>keyboard_arrow_right</mat-icon>
            </div>
            <div class="link" *ngIf="walletDetails?.isDWAutoPay == '1'" (click)="navigateToAutopayPage()">
              Turn Off<mat-icon>keyboard_arrow_right</mat-icon>
            </div>
          </div>
        </div>
        <!-- 1. Select Outstanding Invoice Collapsible Panel -->
        <div class="collapsible-panel-wrapper">
          <div class="collapsible-panel-header outstanding-invoice" (click)="openOutstandingInvoicePanel()"
            [ngClass]="{unclickable : !allowUserToOpenOutstandingInvoicePanel}">
            <div class="panel-title">
              <div>1. Select Outstanding Invoice</div>
              <mat-icon *ngIf="selectOutstandingInvoiceCollapsed">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="!selectOutstandingInvoiceCollapsed">keyboard_arrow_up</mat-icon>
            </div>
          </div>
          <div class="collapsible-panel-content select-outstanding-invoice"
            [class.collapsed]="selectOutstandingInvoiceCollapsed" id="outstanding_invoice">
            <div class="panel-content">
              <div class="subtext mb-2">Please choose your connected solution to redeem your points or pay with your
                stored payment options.</div>
              <hr>
              <!-- Full List of Invoices -->
              <div *ngFor="let invoice of invoiceList">
                <div class="collapsible-panel-header invoices" (click)="invoicePanel($event, invoice, 'toggle-panel')">
                  <div class="invoices-header">
                    <div class="invoice-title">
                      <input class="invoice-selection" type="radio" [checked]="invoice.selected"
                        (click)="invoicePanel($event, invoice, 'select-invoice')">
                      <img src="../../../../assets/icons/frame.svg" class="invoice-icon">
                      <div>InsureShield<sup>®</sup> Shipping Insurance</div>
                      <mat-icon *ngIf="invoice.collapsed">keyboard_arrow_down</mat-icon>
                      <mat-icon *ngIf="!invoice.collapsed">keyboard_arrow_up</mat-icon>
                    </div>
                    <div class="invoice-details-preview">
                      <div class="ml-auto due-amount">{{ invoice.dueAmount | currency: 'USD' }}</div>
                      <div class="ml-auto due-message" *ngIf="invoice.pastDueMessage">Due {{ invoice.pastDueDays |
                        number }} day<span *ngIf="invoice.pastDueDays != 1">s</span> ago</div>
                      <div class="ml-auto rewards-message" *ngIf="invoice.earlyBillPayMessage">
                        Earn <span class="rewards">{{ invoice.earlyBillPayPoints | number: '1.0-0' }} pts</span> back
                        in
                        rewards if paid by {{ invoice.earlyBillPayDeadline | date: 'MM/dd/yyyy' }}
                      </div>
                      <div class="ml-auto normal-message" *ngIf="invoice.normalBillPayMessage && invoice.normalDueDays != 0">Due in {{ invoice.normalDueDays |
                        number }} day<span *ngIf="invoice.normalDueDays != 1">s</span>
                      </div>
                      <div class="ml-auto normal-message" *ngIf="invoice.normalBillPayMessage && invoice.normalDueDays == 0">Due today</div>
                    </div>
                  </div>
                </div>
                <div class="collapsible-panel-content invoice" [class.collapsed]="invoice.collapsed">
                  <div class="invoice-content">
                    <div class="invoice-number">
                      <div>INV {{ invoice.invoiceNumber }}</div>
                      <!-- <div class="blue-link">View Bill in Portal<mat-icon>launch</mat-icon></div> -->
                    </div>
                    <div class="invoice-details">
                      <div class="column-1">
                        <div class="item">
                          <div class="subtitle">Invoice Number</div>
                          <div class="subtext">{{ invoice.invoiceNumber }} </div>
                        </div>
                        <div class="item">
                          <div class="subtitle">Invoice Period</div>
                          <div class="subtext">{{ invoice.invoiceBillDate | date:'MM/dd/yyyy'}} - {{ invoice.dueDate |
                            date:'MM/dd/yyyy' }}</div>
                        </div>
                        <div class="item">
                          <div class="subtitle">Invoice Date</div>
                          <div class="subtext">{{ invoice.invoiceBillDate | date:'MM/dd/yyyy' }}</div>
                        </div>
                      </div>
                      <div class="column-2">
                        <div class="item">
                          <div class="subtitle">Policy Number</div>
                          <div class="subtext">{{ ONLpolicyDetails.policyNumber }}</div>
                        </div>
                        <div class="item">
                          <div class="subtitle">Policy Period</div>
                          <div class="subtext">{{ ONLpolicyDetails.policyStartDate | date:'MM/dd/yyyy' }} - {{
                            ONLpolicyDetails.policyEndDate | date:'MM/dd/yyyy' }}</div>
                        </div>
                        <!-- Hiding customer number for now since we don't know where that value comes from -->
                        <!-- <div class="item">
                            <div class="subtitle">Customer No.</div>
                            <div class="subtext">999999</div>
                          </div> -->
                      </div>
                      <div class="column-3">
                        <div class="item">
                          <div class="subtitle">Due Date</div>
                          <div class="subtext">{{ invoice.dueDate | date:'MM/dd/yyyy'}}</div>
                        </div>
                        <div class="item">
                          <div class="subtitle">Total Amount Due</div>
                          <div class="subtext">{{ invoice.dueAmount | currency: 'USD' }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr>
              </div>
              <div *ngIf="invoiceList?.length == 0">
                No invoices found.
              </div>
            </div>
          </div>
        </div>
        <!-- 2. Select Payment Method Collapsible Panel -->
        <!-- selectPaymentMethodCollapsed is supposed to be used for collapsed vs not collapsed, but
              because this panel will always be open and not closeable, we can also use it as an ngIf
              to just hide the panel entirely if it's not currently open -->
        <div class="collapsible-panel-wrapper payment-method-unique-identifier" *ngIf="!selectPaymentMethodCollapsed">
          <div class="collapsible-panel-header payment-method">
            <div class="panel-title">
              <div>2. Select Payment Method</div>
              <!-- <mat-icon *ngIf="selectPaymentMethodCollapsed">keyboard_arrow_down</mat-icon> -->
              <!-- <mat-icon *ngIf="!selectPaymentMethodCollapsed">keyboard_arrow_up</mat-icon> -->
            </div>
          </div>
          <div class="collapsible-panel-content select-payment-method" [class.collapsed]="selectPaymentMethodCollapsed">
            <div class="panel-content payment-form">
              <form [formGroup]="paymentOptionsFormGroup">
                <div>Please choose your payment method(s) to pay your invoice.</div>
                <br>
                <div *ngIf="isAutoTransferOn">It appears that your Auto-Transfer is currently enabled.If you'd like to pay with<img class="hw-24" src="assets/icons/monetization_on.svg"> My Cash balance
                ,please disable Auto-Transfer <a href="/my-wallet/my-cash/auto-transfer">here.</a>
                </div>
                <div class="d-flex justify-content-end mt-2 mb-2" *ngIf="!isAutoTransferOn">
                  <div class="autofill">Autofill using cash and rewards first:</div>
                  <mat-slide-toggle formControlName="useAutofill" (click)="updateForm()"></mat-slide-toggle>
                </div>
                <hr *ngIf="!isAutoTransferOn">
                <!-- Cash payment method option -->
                <div class="payment-option" *ngIf="!isAutoTransferOn">
                  <div class="left-side">
                    <input type="checkbox" formControlName="cashSelected">
                    <img src="../../../../../assets/icons/monetization_on.svg" class="icon">
                    <div class="title">My Cash</div>
                  </div>
                  <div class="right-side">
                    <div class="input-section">
                      <mat-form-field>
                        <input matInput formControlName="cashAmount" currencyMask
                          [options]="{prefix: '$', allowNegative: false}">
                        <mat-error>
                          <div class="errors" *ngIf="paymentOptionsFormGroup?.controls?.cashAmount?.errors">
                            <div
                              *ngIf="paymentOptionsFormGroup?.controls?.cashAmount?.errors['required'] || paymentOptionsFormGroup?.controls?.cashAmount?.errors['min']">
                              Minimum amount allowed is $0.01.
                            </div>
                            <div *ngIf="paymentOptionsFormGroup?.controls?.cashAmount?.errors['max']">
                              Maximum amount allowed is {{ walletDetails?.cash?.totalCashBalance | currency: 'USD' }}.
                            </div>
                          </div>
                        </mat-error>
                      </mat-form-field>
                      <div class="max-amount">/ {{ walletDetails?.cash?.totalCashBalance | number: '1.2-2' }} <span
                          class="blue">USD</span></div>
                    </div>
                    <div class="earn-points-message">
                      <span>
                        Earn <span class="blue">1%</span> back in rewards each time you pay with Cash
                      </span>
                    </div>
                  </div>
                </div>
                <hr *ngIf="!isAutoTransferOn">
                <!-- Reward payment method option -->
                <div class="payment-option rewards">
                  <div class="left-side">
                    <input type="checkbox" formControlName="rewardsSelected">
                    <img src="../../../../../assets/icons/star_rate.svg" class="icon">
                    <div class="title">My Rewards</div>
                  </div>
                  <div class="right-side">
                    <div class="input-section">
                      <mat-form-field>
                        <input matInput formControlName="rewardsAmount" currencyMask
                          [options]="{prefix: '$', allowNegative: false}">
                        <mat-error>
                          <div class="errors" *ngIf="paymentOptionsFormGroup?.controls?.rewardsAmount?.errors">
                            <div
                              *ngIf="paymentOptionsFormGroup?.controls?.rewardsAmount?.errors['required'] || paymentOptionsFormGroup?.controls?.rewardsAmount?.errors['min']">
                              Minimum amount allowed is $0.01 (1 pt).
                            </div>
                            <div *ngIf="paymentOptionsFormGroup?.controls?.rewardsAmount?.errors['max']">
                              Maximum amount allowed is {{ walletDetails?.rewards?.totalRewardValue | currency: 'USD'
                              }}
                              ({{ walletDetails?.rewards?.totalRewardBalance | number }} pts).
                            </div>
                          </div>
                        </mat-error>
                        <mat-hint>
                          <div class="hints" *ngIf="paymentSummaryValues?.rewards?.inputInPoints">
                            ({{ paymentSummaryValues?.rewards?.inputInPoints | number }} pts)
                          </div>
                        </mat-hint>
                      </mat-form-field>
                      <div class="max-amount">/
                        <span class="rewards">
                          <div>{{ walletDetails?.rewards?.totalRewardValue | number: '1.2-2' }} <span
                              class="blue">USD</span></div>
                          <div>({{ walletDetails?.rewards?.totalRewardBalance | number }} <span class="blue">pts</span>)
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr>
                <!-- Default payment method option -->
                <div class="payment-option">
                  <div class="left-side">
                    <input type="checkbox" formControlName="defaultSelected">
                    <img src="../../../../../assets/icons/account_balance.png" class="icon"
                      *ngIf="currentDefaultPaymentMethod?.cardType == 'ECP' || currentDefaultPaymentMethod?.cardType == 'EC'">
                    <img src="../../../../../assets/images/card/chkt_visa.svg" class="icon"
                      *ngIf="currentDefaultPaymentMethod?.cardType == 'Visa'">
                    <img src="../../../../../assets/images/card/chkt_mastercard.svg" class="icon"
                      *ngIf="currentDefaultPaymentMethod?.cardType == 'Mastercard'">
                    <img src="../../../../../assets/images/card/chkt_amex.svg" class="icon"
                      *ngIf="currentDefaultPaymentMethod?.cardType == 'Amex'">
                    <div class="default">
                      <div class="title"
                        *ngIf="currentDefaultPaymentMethod?.cardType == 'ECP' || currentDefaultPaymentMethod?.cardType == 'EC'">
                        ACH</div>
                      <div class="title"
                        *ngIf="!(currentDefaultPaymentMethod?.cardType == 'ECP' || currentDefaultPaymentMethod?.cardType == 'EC')">
                        {{
                        currentDefaultPaymentMethod?.cardType }}</div>
                      <!-- should be 'Visa', 'Mastercard', or 'Amex'-->
                      <div
                        *ngIf="currentDefaultPaymentMethod?.cardType == 'ECP' || currentDefaultPaymentMethod?.cardType == 'EC'">
                        Bank •••• {{
                        currentDefaultPaymentMethod?.last4Digits }} <span class="default-label"
                          *ngIf="currentDefaultPaymentMethod?.isDefaultPaymentMethod == 'true'">Default</span></div>
                      <div
                        *ngIf="!(currentDefaultPaymentMethod?.cardType == 'ECP' || currentDefaultPaymentMethod?.cardType == 'EC')">
                        <div>
                          <span>•••• {{ currentDefaultPaymentMethod.last4Digits }} • </span>
                          <span
                            [ngClass]="{expired : currentDefaultPaymentMethod?.isExpired || currentDefaultPaymentMethod?.isWarning}">
                            {{ currentDefaultPaymentMethod.ccExpiration?.substring(4,6) }}/{{
                            currentDefaultPaymentMethod.ccExpiration?.substring(0,4) }} </span>
                          <img src="../../../../../assets/images/error_warning_icon.png"
                            *ngIf="currentDefaultPaymentMethod?.isExpired || currentDefaultPaymentMethod?.isWarning"
                            class="mb-1">
                          <span class="default-label"
                            *ngIf="currentDefaultPaymentMethod?.isDefaultPaymentMethod == 'true'">Default</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="right-side mt-auto">
                    <div class="input-section">
                      <mat-form-field>
                        <input matInput formControlName="defaultAmount" currencyMask
                          [options]="{prefix: '$', allowNegative: false}">
                        <mat-error>
                          <div class="errors" *ngIf="paymentOptionsFormGroup?.controls?.defaultAmount?.errors">
                            <div
                              *ngIf="paymentOptionsFormGroup?.controls?.defaultAmount?.errors['required'] || paymentOptionsFormGroup?.controls?.defaultAmount?.errors['min']">
                              Minimum amount allowed is $0.01.
                            </div>
                            <div *ngIf="paymentOptionsFormGroup?.controls?.defaultAmount?.errors['max']">
                              Maximum amount allowed is {{ selectedInvoice?.dueAmount | currency: 'USD' }}.
                            </div>
                          </div>
                        </mat-error>
                      </mat-form-field>
                      <div class="max-amount">/ {{ selectedInvoice?.dueAmount | number: '1.2-2' }} <span
                          class="blue">USD</span></div>
                    </div>
                    <div class="earn-points-message">
                      <span>
                        Earn <span class="blue">0.75%</span> back in rewards each time you pay with ACH
                      </span>
                    </div>
                  </div>
                </div>
                <hr>
                <!-- Other payment methods -->
                <div class="other-option" (click)="viewOtherPaymentMethods()">Other Payment
                  Methods<mat-icon>keyboard_arrow_right</mat-icon></div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- Payment Summary Section (Both Desktop/Mobile) -->
      <div class="summary-wrapper" [ngClass]="{top_alignment : summaryWrapperAlignTop}">
        <!-- Cash/Rewards Section (Desktop only - mobile section at the top of the page) -->
        <div class="cash-rewards-section desktop">
          <div class="cash">
            <div class="type">CASH</div>
            <div class="max-amount">{{ walletDetails?.cash?.totalCashBalance | currency: 'USD' }}</div>
            <div class="subtract" *ngIf="paymentSummaryValues?.cash?.input">-{{ paymentSummaryValues?.cash?.input |
              currency: 'USD' }}</div>
            <div class="new-balance" *ngIf="paymentSummaryValues?.cash?.input">New Balance: {{
              paymentSummaryValues?.cash?.remainingBalance | currency: 'USD' }}</div>
          </div>
          <div class="gray-divider"></div>
          <div class="rewards">
            <div class="type">REWARDS</div>
            <div class="max-amount">{{ walletDetails?.rewards?.totalRewardBalance | number }}<span
                class="supination">PTS</span></div>
            <div class="subtract" *ngIf="paymentSummaryValues?.rewards?.inputInPoints">-{{
              paymentSummaryValues?.rewards?.inputInPoints | number }} pts</div>
            <div class="new-balance" *ngIf="paymentSummaryValues?.rewards?.inputInPoints">New Balance: {{
              paymentSummaryValues?.rewards?.remainingBalanceInPoints | number }} PTS</div>
          </div>
        </div>
        <!-- Payment Summary Box Section (Both Desktop/Mobile) -->
        <div class="payment-summary-box">
          <div class="title">Payment Summary</div>
          <div class="subtitle">Selected Invoice</div>
          <hr>
          <div class="invoices-list" *ngIf="selectedInvoice">
            <div>INV {{ selectedInvoice?.invoiceNumber }}</div>
            <div>|</div>
            <div>Due {{ selectedInvoice?.dueDate | date:'MM/dd/yyyy' }}</div>
            <div>|</div>
            <div>{{ selectedInvoice?.dueAmount | currency: 'USD' }}</div>
          </div>
          <div class="invoices-list" *ngIf="!selectedInvoice">
            No invoice selected.
          </div>
          <div class="subtitle">Payment Method</div>
          <hr>
          <div class="methods" *ngIf="paymentSummaryValues?.cash?.input">
            <div class="method-text">
              <img src="../../../../../assets/icons/monetization_on.svg" class="icon">
              <div>My Cash</div>
            </div>
            <div class="method-balance">-{{ paymentSummaryValues?.cash?.input | currency: 'USD' }}</div>
          </div>
          <div class="methods" *ngIf="paymentSummaryValues?.rewards?.input">
            <div class="method-text">
              <img src="../../../../../assets/icons/star_rate.svg" class="icon">
              <div>My Rewards</div>
            </div>
            <div class="method-balance">-{{ paymentSummaryValues?.rewards?.input | currency: 'USD' }} (- {{
              paymentSummaryValues?.rewards?.inputInPoints | number }} pts)</div>
          </div>
          <div class="methods" *ngIf="paymentSummaryValues?.default?.input">
            <div class="method-text">
              <img src="../../../../../assets/icons/account_balance.png" class="icon"
                *ngIf="currentDefaultPaymentMethod?.cardType == 'ECP' || currentDefaultPaymentMethod?.cardType == 'EC'">
              <img src="../../../../../assets/images/card/chkt_visa.svg" class="icon"
                *ngIf="currentDefaultPaymentMethod?.cardType == 'Visa'">
              <img src="../../../../../assets/images/card/chkt_mastercard.svg" class="icon"
                *ngIf="currentDefaultPaymentMethod?.cardType == 'Mastercard'">
              <img src="../../../../../assets/images/card/chkt_amex.svg" class="icon"
                *ngIf="currentDefaultPaymentMethod?.cardType == 'Amex'">
              <div
                *ngIf="currentDefaultPaymentMethod?.cardType == 'ECP' || currentDefaultPaymentMethod?.cardType == 'EC'">
                ACH Payment</div>
              <div
                *ngIf="!(currentDefaultPaymentMethod?.cardType == 'ECP' || currentDefaultPaymentMethod?.cardType == 'EC')">
                {{ currentDefaultPaymentMethod?.cardType }}
                Payment</div> <!-- should be 'Visa', 'Mastercard', or 'Amex'-->
            </div>
            <div class="method-balance">-{{ paymentSummaryValues?.default?.input | currency: 'USD' }}</div>
          </div>
          <div class="methods"
            *ngIf="paymentSummaryValues?.total?.remainingDue || paymentSummaryValues?.total?.remainingDue == 0">
            <div class="text">Remaining Due</div>
            <div>{{ paymentSummaryValues?.total?.remainingDue | currency: 'USD' }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Error messages for payment form validation -->
    <div class="error-notification-section" *ngIf="reviewPaymentError">
      <div class="error-box">
        <app-notification-box [isDanger]="true">
          <div *ngIf="totalMismatchError">Please try again, the amount entered does not match the selected invoice
            balance.</div>
          <div *ngIf="defaultPaymentMethodError">Please try again, the default payment method is not valid.</div>
          <div *ngIf="ccExpiredMessage">The payment method selected has expired. To continue, please select another or
            add a new one.</div>
          <div *ngIf="ccWarningMessage">The payment method selected is close to expiring. To continue, please select
            another or add a new one.</div>
        </app-notification-box>
      </div>
    </div>
    <!-- Button/Error Message Section (Both Desktop/Mobile) -->
    <div class="bottom-wrapper">
      <div class="button-wrapper">
        <button class="dw-secondary-button icon-left" (click)="backToHome()" *ngIf="currentStep == 0"><mat-icon>keyboard_arrow_left</mat-icon>Back</button>
        <button class="dw-primary-button icon-right" (click)="nextStep()" *ngIf="currentStep == 0" [disabled]="!invoiceSelected">Next<mat-icon>keyboard_arrow_right</mat-icon></button>
        <button class="dw-secondary-button icon-left" (click)="backStep()" *ngIf="currentStep == 1"><mat-icon>keyboard_arrow_left</mat-icon>Back</button>
        <button class="dw-primary-button icon-right" (click)="reviewPayment()" *ngIf="currentStep == 1"
          [disabled]="disableReviewPaymentButton">Review Payment<mat-icon>keyboard_arrow_right</mat-icon></button>
      </div>
      <div class="error-wrapper">
        <div *ngIf="systemError">Something went wrong! Please try again later.</div>
        <div *ngIf="disallowPageVisitAutopayMessage">To proceed with invoice payments, please ensure that you have
          auto
          pay set to OFF.</div>
        <div *ngIf="disallowPageVisitDefaultMessage">To proceed with invoice payments, please ensure that you
          have set your default payment method under Manage Payment Methods.</div>
        <div *ngIf="disallowPageVisitIS4UMessage">This account is not eligible for invoice payments through digital
          wallet.</div>
      </div>
    </div>
  </div>

</div>