import { Component, Inject, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WalletSetupService } from '../../services/api/wallet-setup.service';

@Component({
  selector: 'app-modal-tnc',
  templateUrl: './modal-tnc.component.html',
  styleUrls: ['./modal-tnc.component.scss']
})
export class ModalTncComponent {

  agreeTermA: boolean = false;
  agreeTermB: boolean = false;

  //onAcceptCallback: () => void;

  constructor(
    public dialogRef: MatDialogRef<ModalTncComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private walletSetupService: WalletSetupService
    ){}
      // @Inject(MAT_DIALOG_DATA) public data: { onAcceptCallback?: () => void }
      // ) {
      //   this.onAcceptCallback = data.onAcceptCallback || (() => {});
      // }


    onAccept(): void{
      console.log('onAccept clicked');
      const walletSetupData = {
        policyNumber: this.data.policyNumber,
        walletId: this.data.walletId,
        productType: this.data.productType,
        productSystem: this.data.productSystem
      };

      this.walletSetupService.updateWalletSetup(walletSetupData).subscribe({
        next: (response: any) => {
          console.log('Wallet setup updated successfully', response);
          this.dialogRef.close('accept');
          this.data.checkTcAcceptanceAndProceed(); // Call the checkTcAcceptanceAndProceed function from the parent component
        },
        error: (error: any) => {
          console.error('Failed to update wallet setup', error);
          this.dialogRef.close('error');
        }
      });

     // this.dialogRef.close('accept');
    //  this.onAcceptCallback();
    }

    onDecline(): void{
      this.dialogRef.close('decline');
    }

    canAccept(): boolean {
      return this.agreeTermA && this.agreeTermB;
    }

}
