import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-delivery-defense-tnc-dialog',
  templateUrl: './delivery-defense-tnc-dialog.component.html',
  styleUrls: ['./delivery-defense-tnc-dialog.component.scss']
})
export class DeliveryDefenseTncDialogComponent {
  isExpanded = false;

  constructor(public activeModal:NgbActiveModal){}



   close() {
    this.activeModal.dismiss('Cross click');
  }

  toggleExpand(event: Event) {
    event.preventDefault(); // Prevent the default link behavior
    this.isExpanded = !this.isExpanded;
  }

}
