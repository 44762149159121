<div class="settings-wrapper">
  <!-- Sidebar Section (Desktop only) -->
  <div class="sidebar-wrapper">
    <div class="sidebar-options">
      <div class="subtab-option" [class.active]="myAccountActive" [routerLink]="['/my-account']">
        <img src="../../../../assets/images/customers_major.png" class="subtab-icon">
        <span>My Account</span>
      </div>
      <div *ngIf="!(cashEligible && !rewardEligible)" class="subtab-dropdown" [class.active]="preferencesActive">
        <div [routerLink]="['/preferences']">
          <img src="../../../../assets/images/settings.png" class="subtab-icon">
          <span>Preferences</span>
        </div>
        <div class="dropdown-wrapper">
          <div class="dropdown-content">
            <div class="dropdown-option" [routerLink]="['/preferences/manage-payment']">
              <div class="vertical-yellow-bar" [class.active]="managePaymentActive"></div>
              <div>Manage Payment Methods</div>
            </div>
            <div class="dropdown-option" [routerLink]="['/preferences/auto-redeem']">
              <div class="vertical-yellow-bar" [class.active]="autoRedeemActive"></div>
              <div>Auto Redeem & Pay</div>
            </div>
          </div>
        </div>
      </div>
      <div class="subtab-option" [class.active]="contactUsActive" [routerLink]="['/contact-us']">
        <img src="../../../../assets/images/contact_support.png" class="subtab-icon">
        <span>Contact Us</span>
      </div>
    </div>
  </div>
  <!-- Contact Us Section -->
  <div class="main-content-wrapper">
    <div>
      <div class="contact-us">
        <div class="header-section">
          <div>Contact Us</div>
          <div class="global-yellow-bar"></div>
        </div>
        <div class="contact-options">
          <div class="option" *ngIf="!(cashEligible && !rewardEligible)">
            <div class="icon"><img src="../../../../assets/icons/frame.svg"></div>
            <div class="info-section">
              <div class="title">DeliveryDefense™ Address Confidence</div>
              <div class="time">{{ 'contactUs.timeInfo' | translate}}</div>
              <div class="nav-links">
                <a class="dw-secondary-button icon-right" href="tel:18772638772">1-877-263-8772<mat-icon>keyboard_arrow_right</mat-icon></a>
                <a class="link" href="https://www.insureshield.com/us/en/delivery-defense.html" target="_blank">{{'links.gotoDeliveryDefense' | translate }}<mat-icon class="launch">launch</mat-icon></a>
              </div>
            </div>
          </div>
          <hr>
          <div class="option">
            <div class="icon"><img src="../../../../assets/icons/frame.svg"></div>
            <div class="info-section">
              <div class="title"  [innerHTML]="insureShieldShippingInsurance"></div>
              <div class="time">{{ 'contactUs.timeInfo' | translate}}</div>
              <div class="nav-links">
                <a class="dw-secondary-button icon-right" href="tel:18772638772">1-877-263-8772<mat-icon>keyboard_arrow_right</mat-icon></a>
                <a class="link" href="https://www.insureshield.com/" target="_blank">{{'links.gotoInsureShield' | translate }}<mat-icon class="launch">launch</mat-icon></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>