import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ONLWalletSessionResponse } from '../models/session.model';

declare var pendo;

@Injectable({
  providedIn: 'root'
})

export class ScriptService {
  
  constructor() {
  //  this.pendoInit();
   }


  //Call "pendo.validateInstall()" from the browser console if you want to test if pendo has been initialized. It should show the ID's specified below.
  //Right now we only initialize pendo if the user is logged in
  pendoInit() {
    //Only init Pendo in the UAT environment.
    if (environment.baseURL.urlType !== 'uat' && environment.baseURL.urlType !== 'production') {
      return; 
    }

    console.log( environment.baseURL.urlType);

    this.downloadPendo();
    let userDetails = JSON.parse(sessionStorage.getItem('ONLWalletSession'));

    console.log( "objectUID for Pendo: ", userDetails.objectUID);
   

    //This part actually initializes the Pendo script and starts sending data.
    pendo.initialize({
      visitor: {
        id: userDetails?.objectUID ? userDetails.objectUID : 'uid-not-found,possibly-internal-user' // Originally they wanted it to be IP Address - but that requires API layer to return to the frontend the IP. Instead we use ObjectUID.
        // email:        // Recommended if using Pendo Feedback, or NPS Email
        // full_name:    // Recommended if using Pendo Feedback
        // role:         // Optional

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },
      account: {
        id: 'temp-test' // Highly recommended, required if using Pendo Feedback   //further differention between users?
        // name:         // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      }
    });
  }

    //Just downloads the pendo script from the CDN.
    downloadPendo() {
      let p = window;
      let e = document;
      let n = 'script';
      let d = 'pendo';
      let o;
  
      var v, w, x, y, z;
      o = p[d] = p[d] || {};
      o._q = o._q || [];
  
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
      for (w = 0, x = v.length; w < x; ++w)(function (m) {
        o[m] = o[m] || function () { o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0))); };
      })(v[w]);
  
      y = e.createElement(n);
      y.async = !0;
      y.src = 'https://cdn.pendo.io/agent/static/872f0080-bf61-42d7-5156-5f14cc1d189c/pendo.js';
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    }

}
