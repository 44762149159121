import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe, DecimalPipe } from '@angular/common';

@Pipe({
  name: 'transactionValue'
})
export class TransactionValuePipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe, private decimalPipe: DecimalPipe) {}

  transform(value: any, type: string): string {
    let numValue = parseFloat(value); // Ensure the value is treated as a float
    if (isNaN(numValue)) return value; // Return original value if it cannot be converted
  
    if (type === 'rewards') {
      return this.decimalPipe.transform(numValue) + ' pts';
    } else if (type === 'cash') {
      const formattedValue = this.currencyPipe.transform(numValue, 'USD', 'symbol'); // Assume USD for example
      return formattedValue ? formattedValue : value.toString();
    } else {
      return numValue.toString();
    }
  }
  
}