<div id="bootstrap-overrides">
    <div class="sub-container modal-header">
        <h2 class="headline-highlight">Terms and Conditions</h2>
        <i class="fas fa-times close-icon" style="cursor: pointer;" (click)="close()">
          <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          (click)="close()"
        >
          <path
            d="M6 6L18 18M6 18L18 6"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        </i>
    </div>
    
    
    <div class="modal-body" [ngStyle]="{ 'height': isExpanded ? 'auto' : '180px' }">
      <p>Delivery Defense<sup>TM</sup> Address Confidence</p>
      <p class="update-info">Last Updated: April 1, 2024</p>
      <p>IMPORTANT: PLEASE READ THE DeliveryDefense™ Address Confidence PROGRAM TERMS AND CONDITIONS (AS DEFINED BELOW) CAREFULLY BEFORE ENROLLING IN OR OTHERWISE PARTICIPATING IN THE DeliveryDefense™ Address Confidence LOYALTY PROGRAM.</p>
        
      <div *ngIf="!isExpanded" class="read-more-container">
        <a href="#" (click)="toggleExpand($event)">Read full terms &amp; conditions</a>
      </div>

     
      <div *ngIf="isExpanded" class="full-content">
        <p>THE DeliveryDefense™ Address Confidence PROGRAM TERMS AND CONDITIONS INCORPORATE THE DeliveryDefense™ Address Confidence (i.e., non-rewards program)] WHICH REQUIRE THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS. </p>
        <p>1.     Introduction Welcome to the DeliveryDefense™ Address Confidence Program (the “Program”), a loyalty program that allows you to earn rewards points for eligible [Insert general Program Name] purchases! By participating in the Program, on behalf of yourself, any entity you represent, and others who participate in the Program under your account (individually and collectively, “you”), you hereby represent and warrant that you have read, understand, and accept these DeliveryDefense™ Address Confidence Program Terms and Conditions (“Terms”), as they may be modified from time to time, and you expressly consent and agree to be bound by them. By accepting these Terms, you also agree to[JD 3] :       </p>
        <ul>
          <li>the <a class="modal-link" href="https://upscapital.com/technology-agreement/">UPS Capital Group Technology Agreement</a> and consent to the processing of your data in accordance with the <a class="modal-link" href="https://www.insureshield.com/us/en/legal/privacy-notice.html">UPS Capital Privacy Notice[JD 4] </a>;      </li>
          <li>the terms and conditions of the [Insert name of applicable general Program Terms that underly this rewards program]; and      </li>
          <li>any additional rules, policies, and terms that appear on the DeliveryDefense™ Address Confidence website[JD 5] [CS6] [CS7]  (the “Program Website”), all of which are hereby incorporated by reference. You further agree that the DeliveryDefense™ Address Confidence Program is considered a [Insert general Program Name] product or service as set forth in the [Insert general Program Terms and Conditions] and accordingly, all terms and conditions in the [Insert general Program Terms and Conditions] will apply to your participation in the Program, including but not limited to the agreement to arbitrate. </li>
        </ul>
       
        <p>2.     Modifications to Terms </p>
        <p>We may modify these Terms from time to time. If we revise the Terms, those changes will appear on this page, and the “Last Updated” line will be updated. Modifications take effect as soon as they are posted to the Program Website, delivered to you, reasonably made available to you in writing by us via email or in another similar manner or otherwise accepted by you. It is therefore important that you update your account information or notify us when your email address changes. Your continued participation in the Program after any modification confirms your acceptance of the revised Terms.</p>
       <p> 3.     Modifications to or Termination of Program </p>
       <p> We may at any time, for any reason, and without notice or liability to you: (i) suspend or terminate operation of or access to the Program or any portion thereof; (ii) change, revise, replace, or modify the Program or any portion thereof; (iii) interrupt the operation of the Program or any portion thereof, including for maintenance and support; (iv) impose limits on certain features and services, or restrict access to the Program; and/or (v) terminate the authorization, rights, and license granted herein.</p>
       <p> 4.     Program Participation Eligibility [JD 8] [CS9] [CS10] </p>
       <p> Membership in the Program is open to any [Insert general Program Name/UPS entity, as defined above] account holder who (“Member”):
        ·       Enrolls in the Program; and
        ·       Holds a current [Insert general Program Name/UPS entity, as defined above] account in good standing.
        The Program is void outside of the 50 United States and DC and where otherwise prohibited by law. In the event the Program is terminated, any accumulated Points (as defined below) you have earned but not used will expire on the date the Program ends, unless otherwise expressly notified by us or expressly stated on the Program portion of the Program Website or in the DeliveryDefense™ Address Confidence Program Terms and Conditions.
        You may hold only one Program account (each, a “Member Account” and collectively, “Member Accounts”) for each [Insert general Program Name/UPS entity] account. The individual who is the authorized email account holder of the email address provided at the time of enrollment will be deemed to be the Member. Member Accounts under the Program may not be sold, transferred, or assigned to any third party.</p>
        5.     Account Credentials
        You are responsible for maintaining the confidentiality of the information you hold for your associated Member Account, including your password. You agree that: (i) you will not use a username (or email address) that is already being used by someone else, may impersonate another person, belongs to another person, violates the intellectual property or other right of any person or entity, or is offensive; (ii) you will provide accurate, current, and complete registration information about yourself in connection with the registration process and, as permitted, will maintain and update it continuously and promptly to keep it accurate, current, and complete; (iii) you are solely responsible for all activities (including usage of Points) that occur under your Member Account, password, and username – whether or not you authorized the activity; (iv) you are solely responsible for maintaining the confidentiality of your password and for restricting access to your Member Account; and (v) you will immediately notify us of any unauthorized use of your Member Account, password, or username, or any other breach of security. We will not be liable for any loss or damage (of any kind and under any legal theory) to you or any third party arising from your inability or failure for any reason to comply with any of the foregoing obligations or any unauthorized use of your Member Account or Points not caused by us. 
        6.     Account Activity
        Each Member is responsible for ensuring the accuracy of account information, including the contact information we have on file. Changes to an account may only be made by the Member to whom the account belongs. We are not responsible for any incorrect or inaccurate information supplied by any Member participating in the Program. Upon termination of a Member Account for any reason, all Points are lost and forfeited, regardless of how they were acquired by the Member, and [Insert UPS Entity Name] will not provide a cash equivalent for Points.
        7.     Earning Program Points
        Points can only be earned if all of the following criteria are met (collectively, the “Eligible Program Purchases”):  
        ·       [Insert list of specific requirements for earning points. These may include minimum spends, specific purchase categories, etc.]
        Without limiting the foregoing, Eligible Program Purchases exclude the following:
        ·       [Insert list of exclusions. These may include specific purchase categories, returned purchases, etc.]
        The total United States dollars spent on an Eligible Program Purchase will earn a Member one (1) point to use as a credit as set forth herein (each, a “Point”) and the total United States dollars that one (1) point corresponds to will be set forth on the Program Website (“Conversion Rates”). It is your responsibility to visit the Program Website  to ascertain the then-current Conversion Rates. 
        Points will be deposited into the Member Account after successful completion of a Member’s relevant payment for Eligible Program Purchases as determined by [Insert UPS Entity Name] at its sole discretion. [Insert UPS Entity Name] must receive payment on or before the due date of the invoice for the Eligible Program Purchases to be eligible for Points. Points will not accrue during any period that your account is past due. Accounts with a past due balance will not be eligible to accrue Points during the period the account is past due.
        In the event of a billing dispute, Members are required to pay the non-disputed shipment(s) to receive Points. Points for the disputed shipments will be issued once the dispute has been resolved between the Member and [Insert UPS Entity Name].
        Points that accrue in a Member Account are not transferable, including via sale, barter, assignment, bequest, inheritance, divorce, property settlement, or other domestic court action, and are subject to the restrictions set forth in the Terms. Points do not constitute personal property or income of the Member; rather, Points are the property of [Insert UPS Entity Name] and have no cash value.  Points are only redeemable in accordance with the Terms.
        Without limiting anything in the Terns, [Insert UPS Entity Name] may add, reduce or change Eligible Program Purchases and Conversion Rates at any time and at its sole discretion, even if such action affects the value of or limits your use of Points. 
        8.     Redeeming Program Points; Points Expiration
        Points must be redeemed through the UPS Digital Wallet and are subject to the Digital Wallet Terms and Conditions. Unused points will expire if there is no activity associated with a Member Account for a period of twenty-four (24) months[JD 11] [CS12] [CS13] .
        For additional information regarding redemption, including redemption options and restrictions, visit the Digital Wallet website[JD 14] [CS15]  and consult the Digital Wallet Terms and Conditions[JD 16] [CS17] . [JD 18] [CS19] [Points may not be combined with other discounts or special offers, and Points from different Member Accounts may not be pooled or combined.]
        9.     Account Suspension, Deactivation, and Cancellation
        In the event a Member Account is determined by [Insert UPS Entity Name] to be inactive for any twenty-four (24) month period, [JD 20] [CS21] the Member Account may be subject to suspension and deactivation at our sole discretion. Any Points accrued in a Member Account at the time of deactivation will automatically expire. Expired Points will not be refunded or otherwise added back to any Member or Member Account for any reason.
        Member shall not and shall not attempt to earn Points by any means that simulates applicable requirements or in a manner that is fraudulent or in bad faith, including but not limited to having multiple Member Accounts, falsifying information, redeeming or attempting to redeem rewards on the behalf of other Program participants, participating in purchasing or redemption fraud, or using any script, data mine, robot, spider, or other automatic or manual process to transact with the Program.
        Point accruals and redemptions may be subject to verification by [Insert UPS Entity Name] and subject to reversal by [Insert UPS Entity Name] in its sole discretion.
        We reserve the right in our sole discretion to disqualify or suspend any Member and close, suspend, or deactivate any Member Account if we find or suspect that the Member or a third party has or has attempted to tamper with the registration process or the operation of the Program Website or if a Member has violated the Program Terms.
        You may cancel your membership/participation in the Program at any time by emailing us at [Insert contact email]. In the event your membership is cancelled, any accumulated Points you have earned but not used will expire on the date of cancellation.
        10.  Taxes
        Members who redeem Points will be responsible for all federal, state, and local taxes associated with the Points.
        11.  Program Conditions; Disclaimer of Warranties 
        [Insert UPS Entity Name] endeavors to keep the Program Website error free, but if Points are credited to a Member due to a programming error or other technical issues or events, we reserve the right to adjust or reverse such a transaction. We also reserve the right to immediately cancel, suspend, and/or modify the Program, or any part of it, if there is any evidence of a virus, worms, bugs, non-authorized human intervention, or any fraud, technical failures, or any other factor beyond our reasonable control that impairs the integrity or proper functioning of the Program, as determined by us in our sole discretion.
        THE PROGRAM AND ITS CONTENTS AND POINTS ARE PROVIDED ON AN “AS IS,” “AS AVAILABLE” AND “WITH ALL FAULTS” BASIS, AND [Insert UPS ENTITY NAME] HEREBY DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES OF ANY KIND, EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF NON-INFRINGEMENT, ACCURACY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, CUSTOM, TRADE, QUIET ENJOYMENT, OR SYSTEM INTEGRATION. WITHOUT LIMITING THE FOREGOING, [Insert UPS ENTITY NAME] DOES NOT GUARANTEE THAT THE PROGRAM WILL BE ERROR-FREE, UNINTERRUPTED, OR FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT ANY DEFECTS WILL BE CORRECTED. [Insert UPS ENTITY NAME] DOES NOT MAKE ANY WARRANTIES OR REPRESENTATIONS REGARDING THE COMPLETENESS, CORRECTNESS, ACCURACY, ADEQUACY, USEFULNESS, TIMELINESS, OR RELIABILITY OF THE PROGRAM OR ANY INFORMATION, PRODUCTS, OR SERVICES WE PROVIDE, OR ANY OTHER WARRANTY. THE INFORMATION, MATERIALS, PRODUCTS, AND SERVICES PROVIDED ON OR THROUGH THE PROGRAM MAY BE OUT OF DATE, AND WE MAKE NO COMMITMENT AND ASSUME NO DUTY TO UPDATE SUCH INFORMATION, MATERIALS, PRODUCTS, OR SERVICES. THE FOREGOING EXCLUSIONS OF IMPLIED WARRANTIES DO NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
        12.  Limitation of Liability 
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN ADDITION TO OTHER APPLICABLE LIMITATIONS AND EXCLUSIONS IN THE TERMS, IN NO EVENT WILL WE OR OUR DIRECTORS, OFFICERS, EMPLOYEES, AGENTS OR OTHER REPRESENTATIVES BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND, ARISING OUT OF OR RELATED TO THE PROGRAM. OUR TOTAL LIABILITY UNDER THESE TERMS AND IN CONNECTION WITH THE PROGRAM, WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, WILL NOT EXCEED $500. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW THESE EXCLUSIONS AND LIMITATIONS OF LIABILITY WILL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW AND WILL SURVIVE CANCELLATION OR TERMINATION OF YOUR PARTICIPATION IN THE PROGRAM.
        13.  Complete Agreement
        The DeliveryDefense™ Address Confidence Program Terms and Conditions, as defined in the “Introduction” section above, constitute the entire agreement with respect to the Program and supersede any prior or contemporaneous understandings, representations, statements, or agreements, written or oral. No amendment to, modification of, or waiver of any provision of the Terms will be binding on [Insert UPS Entity Name] without [Insert UPS Entity Name]’s written consent.
         [JD 1]Note to UPS: We used the latest version of Rewardship Terms as the base template for these terms.
         [JD 2]Note to UPS: Do all UPS program terms include an arbitration provision?
         [JD 3]Note to UPS: We have introduced the DW concept and terms below with respect to redemption but can also add references to DW here.  For discussion.
         [JD 4]Note to UPS:  To discuss whether both UTA and Capital Technology Agreement should be mentioned here or if one will always be applicable. 
         [JD 5]Note: Include hyperlink to program website here.
         [CS6]We need to provide hyperlink to UPSC website and have reward rules there?
         [CS7]Confirmed, we will provide hyperlink to UPSC wesbite where Reward rules will reside. 
         [JD 8]Note: Update this section as necessary. Suggested language is included in this template, but additional eligibility terms may apply.
         
        Additional Note: To confirm that this is accurate and flagging for a future discussion regarding multiple individuals using one account, entity accounts, pooling points, etc.
         [CS9]This is accurate. The open question I have is the definition on "members". If a member is a business, then there could be scenarios where they have multiple accounts and can merge their account and pool points together. However, if accounts are separate, they will not be able to pool their points.
         [CS10]Keep track of rewards and points so that customers can track. @Chad Smith to work with @Drew Shaffer if transaction history is not enough.
         [JD 11]Note to UPS: Please confirm that you do not wish to implement a points expiration policy tied to when points were issued.
         [CS12]Points should expire after 24 months of non-usage (no earn or redemption activity).
         [CS13]Accurate
         [JD 14]Note to UPS: Insert hyperlink to Digital Wallet website once available.
         [CS15]Hyperlink will send customers to website of the program for additional information.
         [JD 16]Note to UPS: Insert hyperlink to Digital Wallet T&Cs once available.
         [CS17]Hyperlink will be provided once available.
         [JD 18]Note to UPS: We understand that the Digital Wallet will govern the redemption options for all programs, correct? If so, we will want to be sure that the DW terms contain all necessary details, but for purposes of the rewards program template, we can also leave this out.
         [CS19]Points will be pooled amongst the programs that are offered. So Rewards coming from Parcel Pro, Insureshield, and UPS Programs will all be pooled.
         [JD 20]Note to UPS: Please confirm that 24 months makes sense here.
         [CS21]The rewards will be stripped after 24 months of inactivity, not necessarily the Wallet itself.
      </div>
    </div>
  
  </div>
      
