import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ButtonClickService {

  constructor(private route:Router){}

  goToRedeem(){
    this.route.navigate(['my-wallet', 'all-rewards', 'redeem', 'outstanding-invoice']);
  }

  goToTransferBalance(){
    this.route.navigate(['/my-wallet', 'my-cash', 'transfer-balance']);
  }

}
