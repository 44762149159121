import { Component, Inject } from '@angular/core';
import { GetPayinTokensService } from 'src/app/shared/services/api/get-payin-tokens.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/shared/services/user.service';
import { DWDPATSessionModel } from 'src/app/shared/models/session.model';
import { ApiEndpoints } from 'src/config/api-endpoints';
import { GetGuidService } from 'src/app/shared/services/api/get-guid.service';

@Component({
  selector: 'app-payment-method-flyout',
  templateUrl: './payment-method-flyout.component.html',
  styleUrls: ['./payment-method-flyout.component.scss']
})
export class PaymentMethodFlyoutComponent {
  
  // Flyout
  selectedToken; // Selected payment method within the flyout component
  achMethods;
  ccMethods;
  allMethods;
  flyoutLoading: boolean = false;
  flyoutSystemError: boolean = false;
  flyoutExpiredMessage: boolean = false;
  flyoutWarningMessage: boolean = false;

  walletDetails: any;
  selectedInvoice: any;
  paymentSummaryValues: any;
  currentDefaultPaymentMethod: any;
  useAutofill: any;


  constructor(
    public getpayintoken: GetPayinTokensService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<PaymentMethodFlyoutComponent>,
    private userService: UserService,
    public sessionService: GetGuidService,
  ) {
    this.walletDetails = this.userService.getWalletDetails();
    this.selectedInvoice = data?.selectedInvoice;
    this.paymentSummaryValues = data?.paymentSummaryValues;
    this.currentDefaultPaymentMethod = data?.currentDefaultPaymentMethod;
    this.selectedToken = data?.currentDefaultPaymentMethod?.token;
    this.useAutofill = data?.useAutofill;
  }

  ngOnInit() {
    this.getPaymentTokens();
  }

  getPaymentTokens() {
    this.flyoutLoading = true;
    this.flyoutSystemError = false;
    let walletID = JSON.parse(sessionStorage.getItem('WalletID')!);
    this.getpayintoken.fetchpayintokens(walletID).subscribe(
      data => {
        this.flyoutLoading = false;

        this.allMethods = data?.paymentMethods;
        this.ccMethods = this.allMethods?.filter((x: any) => x.paymentMethod == 'CC');
        this.achMethods = this.allMethods?.filter((x: any) => (x.paymentMethod == 'ECP' || x.paymentMethod == 'EC'));

        // go through all cc methods to check to see if any are expiring or close to expiring
        for (let i = 0; i < this.ccMethods?.length; i++) {
          this.ccMethods[i].isExpired = this.checkCCExpiration(this.ccMethods[i].ccexpiration, 'expired');
          this.ccMethods[i].isWarning = this.checkCCExpiration(this.ccMethods[i].ccexpiration, 'warning');
        }
      }, error => {
        this.flyoutLoading = false;
        this.flyoutSystemError = true;
      }
    );
  }

  closeFlyout() {
    this.dialog.close();
  }

  addNewPaymentMethod() {
    // Save their progress in sessionStorage, and then take them to DPAT
    let saveInvoicePaymentProgress = {
      selectedInvoice: this.selectedInvoice,
      paymentSummaryValues: this.paymentSummaryValues,
      currentDefaultPaymentMethod: this.currentDefaultPaymentMethod,
      useAutofill: this.useAutofill
    };

    sessionStorage.setItem('currentInvoiceProgress', JSON.stringify(saveInvoicePaymentProgress));

    let request = new DWDPATSessionModel();
    request = {
      action: 'addpayinpayment',
      callBackURL: `${ApiEndpoints.startWalletSessionCallBack}/my-wallet/all-rewards/redeem/outstanding-invoice`,
      country: 'US',
      createdByUserId: 'appdw',
      locale: 'en',
      navigatingSystem: 'dw',
      paymentDirection: 'payin',
      walletID: this.walletDetails?.walletID,
      cashBalance:this.walletDetails?.cash?.totalCashBalance.toString(),
      rewardsBalance:this.walletDetails?.rewards?.totalRewardBalance.toString()
    }

    this.sessionService.createdwdpatwalletsession(request);
  }

  displayExpirationMessage(option) {
    this.flyoutExpiredMessage = option.isExpired;
    this.flyoutWarningMessage = option.isWarning;
  }

  updatePaymentMethod() {
    this.flyoutSystemError = false;
    this.flyoutExpiredMessage = false;
    this.flyoutWarningMessage = false;

    let selectedMethod = this.allMethods.find(x => x?.token == this.selectedToken);

    // Validation just in case they somehow select a payment method that we don't have a token associated with it or choose an expired/warning credit card
    if (!selectedMethod?.token) {
      this.flyoutSystemError = true;
    } else if (selectedMethod?.isExpired) {
      this.flyoutExpiredMessage = true;
    } else if (selectedMethod?.isWarning) {
      this.flyoutWarningMessage = true;
    } else {
      this.currentDefaultPaymentMethod = {
        cardType: selectedMethod?.cardType == 'EC' ? 'ECP' : selectedMethod.cardType, // UI accounts for both 'EC' and 'ECP'
        ccExpiration: selectedMethod?.ccexpiration,
        last4Digits: selectedMethod?.last4Digits,
        ccHolderName: selectedMethod?.ccholderName,
        isDefaultPaymentMethod: selectedMethod?.isDefaultPaymentMethod,
        token: selectedMethod?.token,
        isExpired: selectedMethod?.isExpired,
        isWarning: selectedMethod?.isWarning
      };

      this.dialog.close(this.currentDefaultPaymentMethod); // Let the parent component know which updated payment method the user selected
    }
  }

  daysInMonth(m, y) {
    // month is 0-based: Jan = 0, Dec = 11
    // (m-1 ? Not February : February) checks if month is Feb
    // y & (y % 25 ? 3 : 15) is falsy for leap years, hence 31-2 days in Feb- otherwise, it's 31-3 days
    // m % 7 makes it so m is even for months with 31 days, odd for rest
    // subtracting lowest bit &1 results in 31-1 days for April/June/Sept/Nov, 31-0 for the rest
    // referenced stackexchange post for this function
    return 31 - (m - 1 ? m % 7 & 1 : y & (y % 25 ? 3 : 15) ? 3 : 2);
  }

  checkCCExpiration(ccExpiration, type) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns 0-based index
    const expirationYear = parseInt(ccExpiration.slice(0, 4));
    const expirationMonth = parseInt(ccExpiration.slice(4, 6));

    let isExpired;
    let isWarning;

    if (currentYear > expirationYear) {
      // Card is expired if current year is greater than expiration year
      isExpired = true;
      isWarning = false;
    } else if (currentYear === expirationYear && currentMonth > expirationMonth) {
      // Card is expired if current year is equal to expiration year and current month is greater than expiration month
      isExpired = true;
      isWarning = false;
    } else if (currentYear === expirationYear && currentMonth === expirationMonth) {
      const daysInMonth = this.daysInMonth(currentMonth, currentYear);
      const daysUntilExpiration = daysInMonth - currentDate.getDate();
      // Card technically does not expire until the end of the month, but if there are 5 or fewer days until expiration
      // Disallow them from using the payment method - so we set it as 'warning' and not 'expired' but user not allowed to use the payment method in either case.
      if (daysUntilExpiration <= 5) {
        isExpired = false;
        isWarning = true;
      } else {
        // There are still more than 5 days in the month before card expires, let them use the payment method.
        isExpired = false;
        isWarning = false;
      }
    } else {
      // Card is not expired
      isExpired = false;
      isWarning = false;
    }

    if (type == 'expired') {
      return isExpired;
    } else if (type == 'warning') {
      return isWarning;
    }
  }
}
