import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { InvoicesDetails } from "../../models/invoice-history.interface";
import { Observable } from "rxjs";
import { SessionService } from "../session.service";
import { cashtransaction, cashtransactionResponse, redeemRewards, redeemRewardsResponse } from "../../models/RewardCommon";
import { invoicePayment, invoicePaymentResponse } from "../../models/invoice-payment.model";
import { AuthToken } from "../../models/session.model";
import { GetPayinTokensService } from "./get-payin-tokens.service";
import { GetGuidService } from "./get-guid.service";
import { ApiEndpoints } from "src/config/api-endpoints";

@Injectable({
    providedIn: 'root'
  })
  export class Redeem_EarnService {
    httpOptions:any;
  //   private redeemApiUrl = 'https://ib-wallet-dev-api.azurewebsites.net/api/DigitalWalletAPI/redeemrewards';
  // private cashTransactionApiUrl="https://ib-wallet-dev-api.azurewebsites.net/api/DigitalWalletAPI/cashtransaction";
  // private invoicepaymentApiUrl="https://dpatinside-dev-api.azurewebsites.net/api/DigitalWallet/DWMakeInvoicePayment";
  // private dwdpattokenapiUrl='https://ib-wallet-dev-api.azurewebsites.net/api/Authentication/GetDPATToken?userIn=Vampire%231&passwordIn=%231Vampire';

  private redeemApiUrl = ApiEndpoints.redeemEarn ;
  private cashTransactionApiUrl = ApiEndpoints.cashTransactionApiUrl;
  private invoicepaymentApiUrl = ApiEndpoints.invoicepaymentApiUrl;

    constructor(private http: HttpClient,public getheader:GetGuidService) {

    }
  
  // In transaction-history.service.ts
  redeemrewards(redeemrewards:redeemRewards): Observable<redeemRewardsResponse> {
    // Construct the request body according to the API's expected format
    let wallettoken=sessionStorage.getItem("walletheaders");
    let httpOptions = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET",
        'Content-Type': 'application/json',
        "Authorization": "bearer " + wallettoken
      }),
    };
    return this.http.post<redeemRewardsResponse>(this.redeemApiUrl, redeemRewards,httpOptions);
  }
  
  cashtransaction(cashtrnsreq:cashtransaction): Observable<cashtransactionResponse> {
    // Construct the request body according to the API's expected format
    let wallettoken=sessionStorage.getItem("walletheaders");
    let httpOptions = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET",
        'Content-Type': 'application/json',
        "Authorization": "bearer " + wallettoken
      }),
    };
    return this.http.post<cashtransactionResponse>(this.cashTransactionApiUrl, cashtrnsreq,httpOptions);
  }

  makeinvoicepayment(invoicepayrequest:invoicePayment):any{
    
    let token=sessionStorage.getItem("headers");
    let httpOptions = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET",
        "Authorization": "bearer " + token
      }),
    };
        return this.http.post<invoicePaymentResponse>(this.invoicepaymentApiUrl, invoicepayrequest,httpOptions);
    
        }
       
    // Construct the request body according to the API's expected format
  }
