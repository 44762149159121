import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { InvoicesDetails } from "../../models/invoice-history.interface";
import { Observable } from "rxjs";
import { SessionService } from "../session.service";
import { DwPaymentProfiles, PayinTokens } from "../../models/payintokens.model";
import { DWDefaultPaymentRequest } from "../../models/DWDefaultPaymentRequest.model";
import { DWDeletePayRequest } from "../../models/DWDeletePayRequest.model";
import { GetGuidService } from "./get-guid.service";
import { AuthToken } from "../../models/session.model";
import { ApiEndpoints } from "src/config/api-endpoints";
import { DWDeletePayoutTokens, PayoutTokens, PayoutTokensResponse } from "../../models/payouttokens.model";
import { DWInitiateCashTransactionRequest, DWInitiateCashTransactionResponse } from "../../models/DWInitiateCashTransactionRequest.model";

@Injectable({
    providedIn: 'root'
})
export class PayoutTokensService {
    httpOptions: any;

    private apiUrl = ApiEndpoints.dwPayoutTokenInfo;
    private setdefaultpaymentUrl = ApiEndpoints.setdefaultpaymentUrl;
    private deletepaymentUrl = ApiEndpoints.dwDeletePayoutToken;
    private cashtransferapiUrl = ApiEndpoints.initiateCashTransfer;


    constructor(private http: HttpClient, private getheader: GetGuidService) {
        
    }

    // In transaction-history.service.ts
    fetchpayouttokens(payoutTokens: PayoutTokens): any {
        let token=sessionStorage.getItem("headers");
        let httpOptions = {
          headers: new HttpHeaders({
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST, GET",
            "Authorization": "bearer " + token
          }),
        };
        // Construct the request body according to the API's expected format
        
        return this.http.post<PayoutTokensResponse>(this.apiUrl, payoutTokens, httpOptions);
    }


    getcardImage(cardtype: string): string {
        let cardimage = '';
        if(cardtype !=null)
        {
            if (cardtype.toLowerCase().includes('wells')) {
                cardimage = ' ../../../../assets/icons/wellsFargo_icon_small.png';
            }
           else if (cardtype.toLowerCase().includes('morgan')) {
                cardimage = ' ../../../../assets/icons/chase.png';
            }
            else if (cardtype.toLowerCase() == 'zelle') {
                cardimage = '../../../../assets/icons/zelle.png';
            }
        else if (cardtype.toLowerCase() == 'mastercard') {
            cardimage = ' ../../../../assets/images/card/chkt_mastercard.svg';
        } else if (cardtype.toLowerCase() == 'visa') {
            cardimage = '../../../../assets/images/card/chkt_visa.svg';
        } else if (cardtype.toLowerCase() == 'amex') {
            cardimage = '../../../../assets/images/card/chkt_amex.svg';
        } else if (cardtype.toLowerCase() == 'jcb') {
            cardimage = '../../../../assets/images/card/chkt_jcb.svg';
        } else if (cardtype.toLowerCase() == 'discover') {
            cardimage = '../../../../assets/images/card/chkt_discover.svg';
        } else if (cardtype.toLowerCase() == 'unionpay') {
            cardimage = '../../../../assets/images/card/chkt_unionpay.svg';
        }
        else if (cardtype.toLowerCase() == 'ec') {
            cardimage = '../../../../assets/icons/account_balance.png';
        } 
        else {
            cardimage = '../../../../assets/icons/creditcard-v2.png';
        }
    }
        return cardimage;
    
    }

    
    initiateCashTransfer(initiateCashTransfer: DWInitiateCashTransactionRequest): any {
        let wallettoken=sessionStorage.getItem("walletheaders");
    let httpOptions = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET",
        'Content-Type': 'application/json',
        "Authorization": "bearer " + wallettoken
      }),
    };
        return this.http.post<DWInitiateCashTransactionResponse>(this.cashtransferapiUrl, initiateCashTransfer,httpOptions);

    }
    deletepaymentmethod(DWDeletePayoutRequest: DWDeletePayoutTokens): any {
        let token=sessionStorage.getItem("headers");
let httpOptions = {
  headers: new HttpHeaders({
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST, GET",
    "Authorization": "bearer " + token
  }),
};
        return this.http.post<PayinTokens>(this.deletepaymentUrl, DWDeletePayoutRequest, httpOptions);

    }

}
    

    

