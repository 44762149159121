import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceIdentifierDescription'
})
export class ReplaceIdentifierDescriptionPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'CashEarnClaimsPaid':
        return 'Cash';
      case 'InvoiceNumber':
        return 'Invoice';
      case 'ClaimNumber':
        return 'Claim';
      default:
        return value;
    }
  }
}
