<div class="popup-global-layout width-650 p-4 p-md-5">
  <div class="d-flex justify-content-end"><mat-icon class="icon" (click)="close()">close</mat-icon></div>
  <div class="global-title">Are you sure to delete?</div>
  <div class="global-yellow-bar"></div>
  <div class="global-text" *ngIf="isDefaultPaymentMethod">If you delete your <span class="bold">default payment method</span>, you'll be unenrolled from automatic
    payments and redemptions. Please be aware that even if you delete the payment method used for the invoice, the
    invoice will still be processed as usual.
  </div>
  <div class="global-text" *ngIf="!isDefaultPaymentMethod">If you delete payment method, it will be permanently deleted.</div>
  <div class="global-button-format">
    <button (click)="close()" class="dw-secondary-button icon-right">No, keep it<mat-icon class="blue">keyboard_arrow_right</mat-icon></button>
    <button (click)="delete()" class="dw-primary-button icon-right">Yes, delete it<mat-icon class="black">keyboard_arrow_right</mat-icon></button>
  </div>
</div>