<div class="flyout-open-wrapper">
  <div class="other-payment-methods-flyout-wrapper">
    <div class="d-flex justify-content-end close" (click)="closeFlyout()"><mat-icon class="close">close</mat-icon></div>
    <div class="title">Payment Method</div>
    <div class="yellow-bar"></div>
    <div class="loading-container" *ngIf="flyoutLoading">
      <div class="loading">
        <div class="spinner-border" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
    </div>
    <div *ngIf="!flyoutLoading">
      <div class="subtitle">ACH</div>
      <hr>
      <div class="ach-options">
        <div *ngFor="let option of achMethods">
          <div class="payment-option">
            <input type="radio" [(ngModel)]="selectedToken" value="{{option.token}}" (click)="displayExpirationMessage(option)">
            <img src="../../../../../assets/icons/account_balance.png" class="icon">
            <div class="option-info">
              <div class="bold">ACH</div>
              <div>Bank •••• {{ option.last4Digits }} <span class="default-label"
                  *ngIf="option.isDefaultPaymentMethod == 'true'">Default</span></div>
            </div>
          </div>
          <hr>
        </div>
        <div *ngIf="achMethods?.length == 0">
          No ACH payment methods saved.
        </div>
        <div class="earn-points-message">
          <span>
            Earn <span class="blue">0.75%</span> back in rewards each time you pay with ACH
          </span>
        </div>
      </div>
      <div class="subtitle">CREDIT CARD</div>
      <hr>
      <div class="cc-options">
        <div *ngFor="let option of ccMethods">
          <div class="payment-option">
            <input type="radio" [(ngModel)]="selectedToken" value="{{option.token}}" (click)="displayExpirationMessage(option)">
            <div class="cc-icon">
              <img src="../../../../../assets/images/card/chkt_visa.svg" class="icon" *ngIf="option.cardType == 'Visa'">
              <img src="../../../../../assets/images/card/chkt_mastercard.svg" class="icon"
                *ngIf="option.cardType == 'Mastercard'">
              <img src="../../../../../assets/images/card/chkt_amex.svg" class="icon" *ngIf="option.cardType == 'Amex'">
            </div>
            <div class="option-info">
              <div>
                <span class="bold">{{ option.cardType }} </span>
                <span>•••• {{ option.last4Digits }} • </span>
                <span [ngClass]="{expired : option.isExpired || option.isWarning}">
                  {{ option.ccexpiration?.substring(4,6) }}/{{ option.ccexpiration?.substring(0,4) }} </span>
                <img src="../../../../../assets/images/error_warning_icon.png"
                  *ngIf="option.isExpired || option.isWarning" class="mb-1">
                <span class="default-label" *ngIf="option.isDefaultPaymentMethod == 'true'">Default</span>
              </div>
            </div>
          </div>
          <hr>
        </div>
        <div *ngIf="ccMethods?.length == 0">
          No credit card payment methods saved.
        </div>
      </div>
      <div class="add-options" (click)="addNewPaymentMethod()"><img src="../../../../assets/icons/add.svg">Add New
        Payment Method</div>
      <div class="buttons">
        <button class="dw-primary-button icon-right" (click)="updatePaymentMethod()">Update Payment
          Method<mat-icon>keyboard_arrow_right</mat-icon></button>
      </div>
      <div class="error" *ngIf="flyoutSystemError">Something went wrong! Please try again later.</div>
      <div class="error-notification-section2 mt-3" *ngIf="flyoutExpiredMessage || flyoutWarningMessage">
        <app-notification-box [isDanger]="true">
          <div *ngIf="flyoutExpiredMessage">The payment method selected has expired. To continue, please select another
            or
            add a new one.</div>
          <div *ngIf="flyoutWarningMessage">The payment method selected is close to expiring. To continue, please select
            another or add a new one.</div>
        </app-notification-box>
      </div>
    </div>
  </div>
</div>