import { Component } from '@angular/core';
import { ONLStartWalletSession } from './shared/models/session.model';
import { merge, fromEvent, of, tap, debounceTime } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private translate: TranslateService) {
    translate.setDefaultLang('en');
    translate.use('en');
  }
  
  title = 'DigitalWalletApp';
  timeout:number=900000;
  // for 30 min =1800000 milliseconds
   // for 5min =300000 milliseconds
   // for 15min =900000 milliseconds
  ngOnInit()
  {
  merge(
    fromEvent(window,'mousemove'),
    fromEvent(window,'keypress'),
    fromEvent(window,'touchstart'),
    fromEvent(window,'blur'),
    of('load')
  ).pipe(tap((event:any)=>
  {
    console.log('wallet session activity tracker activated');
  }),
  debounceTime(this.timeout),
  tap((event:any)=>
  {
    
    let sessionobj:ONLStartWalletSession=new ONLStartWalletSession();
    
    
    if(sessionStorage.getItem('ONLWalletSession') !=undefined)
    sessionobj=JSON.parse(sessionStorage.getItem('ONLWalletSession') || '{}');
    
    if(sessionobj !=null && sessionobj !=undefined && sessionobj.callBackURL !=undefined )
    {
    window.location.href=sessionobj.callBackURL;
    sessionStorage.clear();
    }
  })
  ).subscribe();
}

}


