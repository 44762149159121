import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import { NgForm, FormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalContentComponent } from 'src/app/shared/components/modal-content/modal-content.component';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { WalletSummaryService } from 'src/app/shared/services/api/wallet-summary.service';
import { WalletSummary } from 'src/app/shared/models/wallet-summary.interface';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  showSubMenu: boolean = true;
  frequency: any;
  notificationForm!: FormGroup;
  walletSummary: WalletSummary | null = null;

  constructor(
    private router: Router,
    public route:ActivatedRoute,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private profileService: ProfileService,
    private walletSummaryService: WalletSummaryService
    ) {

      this.notificationForm = this.fb.group({
        emailSelection: [false],
        emailAddress: ['', Validators.email],
        textSelection: [false],
        phoneNumber: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
      //  phoneNumber: ['', Validators.pattern(/^\d{3}-\d{3}-\d{4}$/)],
        frequencySelection: ['daily', Validators.required],
        rewardsCashEarned: [null],
      rewardsCashRedeemed: [null],
      paymentMade: [null],
      accountInfoUpdated: [null]
      });
      
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showSubMenu = event.url.startsWith('/preferences');
      }
    });
  }

  submitForm(form: NgForm) {
    if (form.valid) {
      console.log(form.value);
      const modalRef = this.modalService.open(ModalContentComponent);
    }
  }

  updateNotification(){
    const modalRef = this.modalService.open(ModalContentComponent);
  }


  editLink() {
    this.router.navigate(['/my-account'], { relativeTo:this.route });
  }


  onSubmit(): void {
    if (this.notificationForm.valid) {
      const formData = this.notificationForm.value;
      const payload = {
      walletID: this.walletSummary?.wallet.walletID, 
        walletRole: 'admin',
        ...formData  
      };
  
      // Log the payload being sent to the server
      console.log('Sending payload to server:', payload);
  
      this.profileService.setNotificationPreferences(payload).subscribe(
        response => {
          if (response.success) {
            console.log('Update Success:', response);
          } else {
            console.error('Error updating preferences:', response.message);
          }
        },
        error => {
          // Catching HTTP errors and logging them
          console.error('HTTP Error:', error);
        }
      );
    } else {
      console.error('Form is not valid:', this.notificationForm.errors);
    }
  }
  

  ngOnInit(): void {
    this.walletSummaryService.walletSummary$.subscribe(summary => {
      this.walletSummary = summary;
      const clientName = this.walletSummary?.wallet.payment.ccHolderName; 
    });

    this.walletSummaryService.walletSummary$.subscribe(summary => {
      if (summary && summary.wallet) {
       
  
        this.profileService.getCustomerProfile(summary.wallet.walletID, 'admin').subscribe(profile => {
          if (profile.success) {
            this.notificationForm.patchValue({
              phoneNumber: profile.phoneNo,
              email: profile.email
            });
          }
        });
        this.profileService.getNotificationPreferences(summary.wallet.walletID, 'admin').subscribe(preferences => {
          if (preferences.success){
            console.log('preference success');
            this.notificationForm.patchValue({
              emailSelection: preferences.emailSelection,
              textSelection: preferences.textSelection,
              frequencySelection: preferences.frequencySelection,
              rewardsCashEarned: preferences.rewardsCashEarned,
              rewardsCashRedeemed: preferences.rewardsCashRedeemed,
              paymentMade: preferences.paymentMade,
              accountInfoUpdated: preferences.accountInfoUpdated
            });
          }
        })

      }
    });

  }

}
