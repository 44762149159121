import { Component, OnInit} from '@angular/core';
import { Router, NavigationEnd} from '@angular/router'

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  showHeaderAndFooter = true;

  constructor(private route:Router){}

  ngOnInit(): void {
    this.route.events.subscribe((event)=>{
      if (event instanceof NavigationEnd){
        const url = event.urlAfterRedirects;
        this.updateLayoutClass(url);
        this.showHeaderAndFooter = !event.url.includes('/logged-out');
      }
    });
  }
  
  updateLayoutClass(url: string) {
    const layoutDiv = document.querySelector('.layout');
    // Check if layoutDiv is not null before proceeding
    if (layoutDiv) {
        // First, clear all specific classes to reset the layout
        layoutDiv.classList.remove('home-page', 'about-page', 'redeem'); // Make sure to list all classes that you might add

        // Add class based on URL
        if (url.includes('/home')) {
            layoutDiv.classList.add('home-page');
        } else if (url.includes('/about')) {
            layoutDiv.classList.add('about-page');
        }
        const redeemPattern = /\/my-wallet\/all-rewards\/redeem\/.*/;
        if (redeemPattern.test(url)) {
            layoutDiv.classList.add('redeem');
        }
        // Continue with other conditions as necessary
    } else {
        // Handle the case where the layout div isn't found, if necessary
        console.error('Layout div not found');
    }
}


  selectedLink: string = 'my-wallet';

  // onLinkClicked(link: string){
  //   this.selectedLink = link;
  // }

  onLinkClicked(event: string) {
    console.log("Link clicked:", event);
    // Handle the navigation or any other logic here
}


}
