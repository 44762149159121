export class DWInitiateCashTransactionRequest
 {
     transferAmount:number=0;
     walletID:string='';
     walletRole:string='';
     transferType:string='';
     payeeID:string='';
     cashWalletAmount :number=0;
 }

 export class DWInitiateCashTransactionResponse
{
    status:boolean=false;
    message:string='';
    transactionRefNo:string='';
    transferAmount :number=0
    transferDate:string='';
}