import { Component, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-logged-out',
  templateUrl: './logged-out.component.html',
  styleUrls: ['./logged-out.component.scss'],
  encapsulation: ViewEncapsulation.None // Apply global styles
})


export class LoggedOutComponent {

  constructor(private location:Location){
    this.disableBackButton();
  }

  disableBackButton() {
    this.location.replaceState('/logged-out');
    window.history.pushState(null, '', '/logged-out');
    window.onpopstate = () => {
      this.location.go('/logged-out');
    };
  }


tabClose(): void {

  window.close();
  console.log('close clicked')
}

}
