import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.scss']
})
export class DeletePopupComponent {

  isDefaultPaymentMethod: boolean = false;

  constructor(
    private dialog: MatDialogRef<DeletePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isDefaultPaymentMethod = data?.isDefaultPaymentMethod;
  }

  ngOnInit() {}

  close() {
    this.dialog.close();
  }

  delete() {
    this.dialog.close('confirm');
  }

}
