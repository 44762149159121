<router-outlet></router-outlet>
<div class="sub-container">
  <h2 class="headline-highlight">My Cash</h2>

  <div class="balance-container">

    <div class="balance-details">

      <div class="wallet-details">
        <div class="balance-blocks">
          <div class="title">BALANCE</div>
          <div class="content">
            <span class="svg"><img src="../../../../assets/icons/monetization_on.svg"></span>
            <span class="value">{{ walletSummary?.wallet?.cash?.totalCashBalance ?? 0 | currency}}</span>
          </div>
        </div>
      </div>

      <div class="wallet-details">
        <div class="button-container">
          <div *ngIf="dataLoaded && !((cashEligible && !rewardEligible) || (autoPay === 1))" class="btn">
            <button (click)="goToRedeem()" mat-raised-button class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button primary">
              <span class="mdc-button__label">
                <span class="button-label">
                  <span>Redeem</span>
                  <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                </span>
              </span>
            </button>
          </div>
          <div class="btn">
            <button (click)="goToTransferBalance()" mat-raised-button class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button secondary">
              <span class="mdc-button__label">
                <span class="button-label">
                  <span>
                    Transfer To Bank
                  </span>
                  <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                </span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <h2 class="headline-highlight">Transaction History</h2>


  <div class="search-box-container box-border">
    <div class="search-container">
      <mat-form-field>
        <mat-icon matPrefix>search</mat-icon>
        <input class="search-box" matInput placeholder="Search Table" [(ngModel)]="searchValue" (keyup.enter)="applySearch(searchValue)">
      </mat-form-field>

      <div class="button-container">
        <div class="btn">
          <button (click)="applySearch(searchValue)" mat-raised-button class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button primary">
            <span class="mdc-button__label">
              <span class="button-label">
                <span>Search</span>
                <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
              </span>
            </span>
          </button>
        </div>
      </div>

    </div>

    <div class="radio-buttons">
      <mat-radio-group [(ngModel)]="rewardType" (change)="fetchCash()">
        <mat-radio-button value="cashEarn">Cash Received</mat-radio-button>
        <mat-radio-button value="cashSpend">Cash Spent/Transferred</mat-radio-button>
      </mat-radio-group>
    </div>

  </div>

  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="dw-header-cell">
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        disableRipple>
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)"
                        disableRipple>
          </mat-checkbox>
        </td>
      </ng-container>


      <!-- Earn Columns -->
      <ng-container *ngIf="rewardType === 'cashEarn'">
        <ng-container matColumnDef="earnDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell custom-sort-header">Date</th>
          <td mat-cell *matCellDef="let element">{{ element.earnDate | date: "MM/dd/YYYY" }}</td>
        </ng-container>

        <ng-container matColumnDef="cashEarned">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell custom-sort-header">Amount</th>
          <td mat-cell *matCellDef="let element">{{ element.cashEarned | currency }}</td>
        </ng-container>

        <ng-container matColumnDef="earnCategory">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell custom-sort-header">Funds Type</th>
          <!-- <td mat-cell *matCellDef="let element">{{ element.earnCategory === 'CashEarnClaimsPaid' ? 'Cash' : '-' }}</td> -->
          <td mat-cell *matCellDef="let element">Cash</td>
        </ng-container>

        <ng-container matColumnDef="earnIdentifierDescription">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell custom-sort-header">Details</th>
          <!-- <td mat-cell *matCellDef="let element">{{ element.earnCategoryDescription === 'ClaimNumber' ? 'Claims' : '-' }}</td> -->
          <td mat-cell *matCellDef="let element">{{ element.earnCategoryDescription === 'CashEarnClaimsPaid' ? 'Claims' : element.earnCategoryDescription }}</td>

        </ng-container>

        <ng-container matColumnDef="earnIdentifier">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell custom-sort-header">Reference Number</th>
          <td mat-cell *matCellDef="let element">{{ element.earnIdentifier }}</td>
        </ng-container>
      </ng-container>

      <!-- Spend Columns -->
      <ng-container *ngIf="rewardType === 'cashSpend'">
        <ng-container matColumnDef="spendDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell custom-sort-header">Date</th>
          <td mat-cell *matCellDef="let element">{{ element.spendDate | date: "MM/dd/YYYY" }}</td>
        </ng-container>

        <ng-container matColumnDef="spendAmount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell custom-sort-header">Amount</th>
          <td mat-cell *matCellDef="let element">{{ element.spendAmount | currency }}</td>
        </ng-container>

        <ng-container matColumnDef="spendCategory">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell custom-sort-header">Funds Type</th>
          <!-- <td mat-cell *matCellDef="let element">{{ element.spendCategory === 'CashSpendInvoice' || element.spendCategory === 'CashSpendTransfer' ? 'Cash' : '-' }}</td> -->
          <td mat-cell *matCellDef="let element">Cash</td>
        </ng-container>

        <ng-container matColumnDef="spendIdentifierDescription">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell custom-sort-header">Details</th>
          <td mat-cell *matCellDef="let element">
            {{ element.spendIdentifierDescription === 'InvoiceNumber' ? 'Invoice Redemption' : element.spendIdentifierDescription === 'PayableReferenceNumber' ? 'Cash Transfer' : element.spendIdentifierDescription }}
            <span *ngIf="element.modeOfTransfer === 'RTP'" class="instant-text"> - <i>Instant</i></span>
          </td>
        </ng-container>

        <ng-container matColumnDef="spendAccountAlias">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell custom-sort-header">Reference Number</th>
          <td mat-cell *matCellDef="let element">
            {{ element.spendIdentifierDescription === 'PayableReferenceNumber' ? element.spendIdentifier : (element.spendIdentifierDescription === 'InvoiceNumber' ? 'INV ' + element.spendIdentifier : '') }}
          </td>
        </ng-container>
        

        <ng-container matColumnDef="spendAccountBankName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="dw-header-cell custom-sort-header">Account</th>
          <td mat-cell *matCellDef="let element">
            {{ element.spendIdentifierDescription === 'InvoiceNumber' ? 'N/A' : 
               (element.spendIdentifierDescription === 'PayableReferenceNumber' ? 
                 (element.spendAccountRedactedDescription === 'ZELLE' ? 
                   'Zelle - ' + (element.spendAccountBankName === 'JPMORGAN CHASE BANK, NA' ? 
                     element.spendAccountBankName.replace('JPMORGAN ', '') : 
                     element.spendAccountBankName) + '***' + element.spendAccountAlias : 
                   (element.spendAccountBankName === 'JPMORGAN CHASE BANK, NA' ? 
                     element.spendAccountBankName.replace('JPMORGAN ', '') : 
                     element.spendAccountBankName) + '***' + element.spendAccountAlias) : 
               '') }}
          </td>
        </ng-container>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="dw-header-cell"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]" class="custom-paginator" showFirstLastButtons></mat-paginator>
  </div>
</div>
