import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';
import { Router, NavigationEnd} from '@angular/router'

@Directive({
  selector: '[appClickableTooltip]'
})
export class ClickableTooltipDirective implements OnDestroy {
  @Input('appClickableTooltip') tooltipInfo: { title: string; content: string };

  private tooltipElement: HTMLElement | null = null;
  private documentClickListener: any;
  private scrollListener: any;
  private routerSubscription: any; 

  constructor(private el: ElementRef, private router: Router) { // Listen to route changes
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.removeTooltip(); // Remove tooltip on route change
      }
    });
  }

  

  @HostListener('click') onClick() {
    if (!this.tooltipElement) {
      this.createTooltip();
      this.positionTooltip();
      this.attachDocumentClickListener();
      this.attachScrollListener();
    } else {
      //this.removeTooltip();
      event.stopPropagation();
    }
  }

  createTooltip() {
    const tooltip = document.createElement('div');
    tooltip.classList.add('clickable-tooltip');

    // Add close icon element
    const closeIcon = document.createElement('span');
    closeIcon.innerHTML = '&times;';
    closeIcon.style.position = 'absolute';
    closeIcon.style.top = '5px';
    closeIcon.style.right = '10px';
    closeIcon.style.cursor = 'pointer';
    closeIcon.style.fontSize = '20px';
    closeIcon.style.fontWeight = 'bold';
    closeIcon.style.color = '#000';
    closeIcon.addEventListener('click', () => this.removeTooltip());
    tooltip.appendChild(closeIcon);

    // Add title element
    const titleElement = document.createElement('h3');
    titleElement.innerText = this.tooltipInfo.title;
    titleElement.style.fontSize = '16px';
    titleElement.style.fontWeight = 'bold';
    tooltip.appendChild(titleElement);

    // Add content element
    const contentElement = document.createElement('p');
    contentElement.innerText = this.tooltipInfo.content;
    tooltip.appendChild(contentElement);

    document.body.appendChild(tooltip);
    this.tooltipElement = tooltip;
  }

  positionTooltip() {
    const hostRect = this.el.nativeElement.getBoundingClientRect();
    const tooltipHeight = this.tooltipElement!.offsetHeight; // Get tooltip height

    // Adjust these values to control offset
    const offsetX = 180; // Move tooltip X pixels to the left
    const offsetY = -115; // Move tooltip Y pixels above the element

    this.tooltipElement!.style.top = `${hostRect.top + offsetY}px`;
    this.tooltipElement!.style.left = `${hostRect.left - offsetX}px`;
  }

  removeTooltip() {
    if (this.tooltipElement) {
      this.tooltipElement.remove();
      this.tooltipElement = null;
      this.detachDocumentClickListener();
      this.detachScrollListener();
    }
  }

  attachDocumentClickListener() {
    this.documentClickListener = this.handleDocumentClick.bind(this);
    document.addEventListener('click', this.documentClickListener);
  }

  detachDocumentClickListener() {
    if (this.documentClickListener) {
      document.removeEventListener('click', this.documentClickListener);
      this.documentClickListener = null;
    }
  }

  attachScrollListener() {
    this.scrollListener = this.handleScroll.bind(this);
    document.addEventListener('scroll', this.scrollListener, true); // Use capture phase to catch scroll events on all elements
  }

  detachScrollListener() {
    if (this.scrollListener) {
      document.removeEventListener('scroll', this.scrollListener, true);
      this.scrollListener = null;
    }
  }

  handleDocumentClick(event: Event) {
    const target = event.target as HTMLElement;
    if (
      this.tooltipElement &&
      !this.el.nativeElement.contains(target) &&
      !this.tooltipElement.contains(target)
    ) {
      this.removeTooltip();
    }
  }

  handleScroll(event: Event) {
    if (this.tooltipElement) {
      this.removeTooltip();
    }
  }

  ngOnDestroy() {
    this.detachDocumentClickListener();
    this.detachScrollListener();
  }
}
