import { Component } from '@angular/core';
import { NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';


@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss']
})
export class PreferencesComponent {

  // Sidebar settings
  myAccountActive: boolean = false;
  contactUsActive: boolean = false;
  preferencesActive: boolean = false;
  managePaymentActive: boolean = false;
  autoRedeemActive: boolean = false;

  constructor(private router: Router) {
    // Set correct sidebar settings
    if (this.router?.url == '/my-account') {
      this.myAccountActive = true;
    } else if (this.router?.url == '/preferences' || this.router?.url == '/preferences/manage-payment') {
      this.preferencesActive = true;
      this.managePaymentActive = true;
    } else if (this.router?.url == '/preferences/auto-redeem') {
      this.preferencesActive = true;
      this.autoRedeemActive = true;
    } else if (this.router?.url == '/contact-us') {
      this.contactUsActive = true;
    }

    this.router.events.pipe(filter((event: any) => event instanceof NavigationStart)).subscribe(
      (events: NavigationStart) => {
        if (events?.url == '/preferences' || events?.url == '/preferences/manage-payment') {
          this.preferencesActive = true;
          this.managePaymentActive = true;
          this.autoRedeemActive = false;
        } else if (events?.url == '/preferences/auto-redeem') {
          this.preferencesActive = true;
          this.managePaymentActive = false;
          this.autoRedeemActive = true;
        }
      }
    );
  }
}
