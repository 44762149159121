export class PayoutTokens {
    walletID: string = '';
    walletRole: string = '';
    transferType: string = '';
}
export class DWDeletePayoutTokens{
    payeeId:string='';
    walletID: string = '';
    walletRole: string = '';
}

export class PayoutTokensResponse {
    payoutTokens: DWPayoutToken[] = [];
    success: boolean = false;
    message: string = '';
}
export class DWPayoutToken {

    WalletID: string = '';
    ProgramID: string = '';
    address: DWCounterPartyEntityAddress = new DWCounterPartyEntityAddress;
    PayeeID: string = '';
    PayeeName: string = '';
    RecordActiveStatusIndicator: string = '';
    ACHIndicator: string = '';
    ZelleIndicator: string = "F";
    TransferType: string = '';
    PayeeSite: string = 'WALLET';
    CounterpartyPayOrg: string = '';
    CounterPartyBankAccountType: string = '';
    CounterPartyBankAccountCategory: string = '';
    CounterPartyAccountNumber: string = '';
    CounterPartyAccountType: string = '';
    //  ZelleAccountName { get; set; }
    //  ZelleAccountNumber { get; set; }
    CreatedDate: string = '';
    CreatedBy: string = '';
    ModifiedDate: string = '';
    ModifiedBy: string = '';
    last4Digits:string='';
    ccexpiration:string='';
    cardType:string='';
    cardImg:string='';
    token:string='';
    rtpEligible:boolean=false;
}

export class DWCounterPartyEntityAddress {
    StreetAddress1: string = '';
    StreetAddress2: string = '';
    StreetAddress3: string = '';
    City: string = '';
    Country: string = '';
    Zip: string = '';
    StateProvince: string = '';
    PayeePhone: string = '';
    PayeeEmail: string = '';
}

export class  transferCarrier{
    token:string='';
    transferAmount:string='';
    totalTransferAmount:string='';
    charges:string='';
    accountType:string='';
    bankDetails!:BankDetails;
}
export class BankDetails
{
    imgSrc:string='';
    bankName:string='';
    accountDetails:string='';
}