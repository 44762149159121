import { Component, ViewChild, OnInit, Output, EventEmitter, HostListener, AfterViewInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router';
import { WalletSummaryService } from '../../services/api/wallet-summary.service';
import { WalletSummary } from '../../models/wallet-summary.interface';
import { SessionService } from '../../services/session.service';
import { ScriptService } from '../../services/script.service';
import { GetWalletNotificationService } from '../../services/api/get-wallet-notification.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  @Output() linkClicked = new EventEmitter<string>();
  @ViewChild('sidenav', { static: false }) sidenav!: MatSidenav;

  isSubMenuOpen = false;
  selectedLink: string = "";
  showWalletSubmenu = false;
  isMobileMenuOpen = false;
  isMobileWalletSubmenuOpen = false;
  showMenu = false;

  walletSummary: WalletSummary | null = null;
  displayName: string = "";
  objectUID: string = '';

  autoPay: number = 0;
  rewardPoints: number = 0;
  cashEligible = false;
  rewardEligible = false;

  walletId: string = '';
  walletID: string = '';
  walletRole: string = '';

  notifications: any[] = []; // Array to hold notifications
  errorMessage: string = ''; // Variable to hold error messages

  hasUnreadNotifications: boolean = false; // Flag to track if there are unread notifications

    // placeholder for dynamic data
    points:number;
    balance: number;
    userIniital: string;
  
    constructor(
      private walletSummaryService: WalletSummaryService,
      private sessionService: SessionService,
      public router:Router, 
      public route:ActivatedRoute,
      private scriptService: ScriptService,
      private walletNotificationService: GetWalletNotificationService
    ){
      this.points = 50000;
      this.balance = 1200.00;
      this.userIniital = 'Y';
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          const walletIdSes = JSON.parse(sessionStorage.getItem('WalletID') || '""');
          if(walletIdSes!='' && walletIdSes !=undefined)
          {
            this.loadWalletSummary();
          }
          
        }
      });
    }

    isDropdownOpen = false;

    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      if (this.isDropdownOpen) {
        // Fetch notifications when dropdown is opened
        this.fetchNotifications();
      } else {
        // Call setNotifications when the dropdown is closed
        this.sendUnreadNotifications();
      }
    }

  closeSidenav() {
    if (this.sidenav) {
      this.sidenav.close();
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    }
  }
  

toggleMenu() {
  this.showMenu = !this.showMenu;
}

toggleMobileMenu() {
  this.isMobileMenuOpen = !this.isMobileMenuOpen;
  if (this.isMobileMenuOpen) {
    this.sidenav.open(); // Open the sidenav when the mobile menu is toggled open
  } else {
    this.sidenav.close(); // Optionally, close the sidenav when the mobile menu is toggled off
  }
  this.isMobileWalletSubmenuOpen = false;
  console.log('menu clicked');
}

toggleMobileWalletSubmenu() {
  this.isMobileWalletSubmenuOpen = !this.isMobileWalletSubmenuOpen;
}

closeMenu(){
  this.showMenu = false;
}

onMenuClosed(){

}

  toggleWalletSubmenu(){
    this.showWalletSubmenu = !this.showWalletSubmenu;
  }


  onLinkClick(link: string, event: MouseEvent){
    this.linkClicked.emit(link);
    this.selectedLink = link;
    this.showWalletSubmenu = false;
    this.closeWalletSubmenu();
    this.closeMenu();
    event.preventDefault(); // Prevent default link behavior
    event.stopPropagation(); // Stop event propagation
  }

  onLinkClicked(event: MouseEvent){
    event.preventDefault(); // Prevent default link behavior
    event.stopPropagation(); // Stop event propagation
  }

  closeWalletSubmenu(){
    this.showWalletSubmenu = false;
  }

   @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    const isInsideMenu = targetElement.closest('.nav-flyout');
    if (!targetElement.closest('.main-menu')) {
      this.closeWalletSubmenu();
    }
    if (!isInsideMenu) {
      this.closeMenu();
    }
    const clickedInside = (event.target as HTMLElement).closest('.notifications, .notification-dropdown');
    if (!clickedInside && this.isDropdownOpen) {
      //this.closeDropdown();
      this.toggleDropdown();
    }
  }

  closeDropdown() {
    this.isDropdownOpen = false;
  }


  openedPanel: string | null = null;
  isSidenavOpen = false;

  toggleSidenav() {
    this.isSidenavOpen = !this.isSidenavOpen;
  }

  onSubMenuOpen(panelName: string) {
    this.openedPanel = this.openedPanel === panelName ? null : panelName;
    this.isSidenavOpen = this.openedPanel !== null;
    this.isSubMenuOpen = true;
    // Add any additional logic you need when the submenu opens
  }

  onSubMenuClose(panelName: string) {
   this.openedPanel = this.openedPanel === panelName ? null : panelName;
   this.isSidenavOpen = this.openedPanel !== null;
    this.isSubMenuOpen = false;
    // Add any additional logic you need when the submenu closes
  }

  closeTab(event: MouseEvent): void {
    event.preventDefault(); // Prevent default link behavior
    event.stopPropagation(); // Stop event propagation
    sessionStorage.clear();
   // window.close();
   this.router.navigate(['/logged-out'], { relativeTo:this.route });
  }

  ngOnInit(): void {
    this.walletSummaryService.walletSummary$.subscribe(summary => {
      this.walletSummary = summary;
      this.autoPay = Number(this.walletSummary?.wallet.isDWAutoPay) || 0;
      this.rewardPoints = Number(this.walletSummary?.wallet?.rewards?.totalRewardBalance) || 0;
      this.cashEligible = summary?.wallet?.cashEligible === "True";
      this.rewardEligible = summary?.wallet?.rewardEligible === "True";
    });
    this.sessionService.getDisplayName().subscribe(displayName => {
      this.displayName = displayName;
    });

    this.sessionService.getObjectUID().subscribe(objectUID =>{
      this.objectUID = objectUID;
    });

   // this.fetchNotifications();
   this.fetchNotificationsStatus();
  }

  // Fetch notification status to check if unread notifications exist
fetchNotificationsStatus(): void {
  this.walletSummaryService.walletSummary$.subscribe(summary => {
    if (summary && summary.wallet) {
      this.walletID = summary.wallet.walletID;
      this.walletRole = 'admin';
      if (this.walletID && this.walletRole) {
        this.walletNotificationService.fetchWalletNotifications(this.walletID, 'admin').subscribe(
          (response) => {
            if (response.success) {
              this.notifications = response.inAppNotifications;
              console.log("Response from Notification Status: ", this.notifications);
              this.hasUnreadNotifications = this.notifications.some((notification: any) => !notification.readStatus);
            } else {
              this.hasUnreadNotifications = false;
            }
          },
          (error) => {
            this.hasUnreadNotifications = false;
            console.error('Error fetching notification status:', error);
          }
        );
      }
    }
  });
}

// Fetch notifications only when the dropdown is opened
fetchNotifications(): void {
  this.walletSummaryService.walletSummary$.subscribe(summary => {
    if (summary && summary.wallet) {
      this.walletID = summary.wallet.walletID;
      this.walletRole = 'admin';
      if (this.walletID && this.walletRole) {
        this.walletNotificationService.fetchWalletNotifications(this.walletID, 'admin').subscribe(
          (response) => {
            if (response.success) {
              this.notifications = response.inAppNotifications;
              console.log("Response from Notifications:", this.notifications);
              this.hasUnreadNotifications = this.notifications.some((notification: any) => !notification.readStatus);
            } else {
              console.log('Failed to fetch notifications:', response.message);
            }
          },
          (error) => {
            console.error('Error fetching notifications:', error);
            this.hasUnreadNotifications = false;
          }
        );
      }
    }
  });
}

// Send unread notifications when the dropdown is closed
sendUnreadNotifications(): void {
  // Filter unread notifications
  const unreadNotifications = this.notifications.filter((notification: any) => !notification.readStatus);
  const notificationIds = unreadNotifications.map((notification: any) => notification.notificationID);

  if (notificationIds.length > 0) {
    const payload = {
      walletID: this.walletID,
      walletRole: this.walletRole,
      notificationIds: notificationIds
    };
    this.setNotifications(payload);
  } else {
    console.log('No unread notifications to send.');
  }
}

setNotifications(payload: any): void {
  this.walletNotificationService.setWalletNotifications(payload).subscribe(
    (response) => {
      console.log('Set Notification Success:', response);
    },
    (error) => {
      console.error('Error setting notifications:', error);
    }
  );
}

  private loadWalletSummary(): void {

    const walletIdSes = JSON.parse(sessionStorage.getItem('WalletID') || '""');
    const walletRoleSes = JSON.parse(sessionStorage.getItem('WalletRole') || '""');
     if (walletIdSes && walletRoleSes) {
       this.walletSummaryService.fetchWalletSummary(walletIdSes, walletRoleSes).subscribe({
         next: (summary) => {
           this.walletSummary = summary;
           this.autoPay = Number(this.walletSummary?.wallet.isDWAutoPay) || 0;
           this.rewardPoints = Number(this.walletSummary?.wallet?.rewards?.totalRewardBalance) || 0;
           console.log("WalletSummary reloaded:", summary);
         },
         error: (error) => console.error("Error reloading WalletSummary:", error)
       });
     }
     else {
       console.log("No walletID or walletRole found");
     }
   }

  
  loadDisplayName(){
    this.sessionService.getDisplayName().subscribe(displayName => {
      this.displayName = displayName;
    });
  }
  
  navigateToHome() {
   this.router.navigate(['/home']);
  }
}
