import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { InvoicesDetails } from "../../models/invoice-history.interface";
import { Observable } from "rxjs";
import { SessionService } from "../session.service";
import { DwPaymentProfiles, PayinTokens } from "../../models/payintokens.model";
import { DWDefaultPaymentRequest } from "../../models/DWDefaultPaymentRequest.model";
import { DWDeletePayRequest } from "../../models/DWDeletePayRequest.model";
import { GetGuidService } from "./get-guid.service";
import { AuthToken } from "../../models/session.model";
import { ApiEndpoints } from "src/config/api-endpoints";

@Injectable({
    providedIn: 'root'
})
export class GetPayinTokensService {
    httpOptions: any;

    // private apiUrl = 'https://dpatinside-dev-api.azurewebsites.net/api/DigitalWallet/DWGetTokenInfo';
    // private setdefaultpaymentUrl = 'https://dpatinside-dev-api.azurewebsites.net/api/DigitalWallet/DWSetDefaultPaymentMethod';
    // private deletepaymentUrl = 'https://dpatinside-dev-api.azurewebsites.net/api/DigitalWallet/DWDeletePaymentMethod';
    // private dwdpattokenapiUrl = 'https://ib-wallet-dev-api.azurewebsites.net/api/Authentication/GetDPATToken?userIn=Vampire%231&passwordIn=%231Vampire';

    private apiUrl = ApiEndpoints.dwTokenInfo;
    private setdefaultpaymentUrl = ApiEndpoints.setdefaultpaymentUrl;
    private deletepaymentUrl = ApiEndpoints.deletepaymentUrl;

    constructor(private http: HttpClient, private getheader: GetGuidService) {
        
    }

    // In transaction-history.service.ts
    fetchpayintokens(walletId: string): any {
        let token=sessionStorage.getItem("headers");
        let httpOptions = {
          headers: new HttpHeaders({
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST, GET",
            "Authorization": "bearer " + token
          }),
        };
        // Construct the request body according to the API's expected format
        const requestBody = { walletId };
        return this.http.post<PayinTokens>(this.apiUrl, requestBody, httpOptions);
    }


    getcardImage(cardtype: string,bankname:string): string {
        let cardimage = '';
        if (cardtype.toLowerCase() == 'mastercard') {
            cardimage = ' ../../../../assets/images/card/chkt_mastercard.svg';
        } else if (cardtype.toLowerCase() == 'visa') {
            cardimage = '../../../../assets/images/card/chkt_visa.svg';
        } else if (cardtype.toLowerCase() == 'amex') {
            cardimage = '../../../../assets/images/card/chkt_amex.svg';
        } else if (cardtype.toLowerCase() == 'jcb') {
            cardimage = '../../../../assets/images/card/chkt_jcb.svg';
        } else if (cardtype.toLowerCase() == 'discover') {
            cardimage = '../../../../assets/images/card/chkt_discover.svg';
        } else if (cardtype.toLowerCase() == 'unionpay') {
            cardimage = '../../../../assets/images/card/chkt_unionpay.svg';
        }
        else if (cardtype.toLowerCase() == 'ec' ) {
            if (bankname.toLowerCase().includes('wells')) {
                cardimage = ' ../../../../assets/icons/wellsFargo_icon_small.png';
            }
            else if (bankname.toLowerCase().includes('morgan')) {
                cardimage = ' ../../../../assets/icons/chase.png';
            }
            else{
            cardimage = '../../../../assets/icons/account_balance.png';
            }
        } else {
            cardimage = '../../../../assets/icons/creditcard-v2.png';
        }
        return cardimage;
    }

    updatedefaulttoken(DWDefaultPaymentRequest: DWDefaultPaymentRequest): any {
        let token=sessionStorage.getItem("headers");
let httpOptions = {
  headers: new HttpHeaders({
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST, GET",
    "Authorization": "bearer " + token
  }),
};
        return this.http.post<PayinTokens>(this.setdefaultpaymentUrl, DWDefaultPaymentRequest, httpOptions);


    }

    deletepaymentmethod(DWDeletePayRequest: DWDeletePayRequest): any {
        let token=sessionStorage.getItem("headers");
let httpOptions = {
  headers: new HttpHeaders({
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST, GET",
    "Authorization": "bearer " + token
  }),
};
        return this.http.post<PayinTokens>(this.deletepaymentUrl, DWDeletePayRequest, httpOptions);

    }

    }

