import { Component, AfterViewInit } from '@angular/core';
declare var $:any; 

@Component({
  selector: 'app-slick-carousel-home',
  templateUrl: './slick-carousel-home.component.html',
  styleUrls: ['./slick-carousel-home.component.scss']
})
export class SlickCarouselHomeComponent implements AfterViewInit {
    ngAfterViewInit(): void {
      $('.carousel').slick({
        dots:true,
        infinite:true,
        speed:300,
        slidesToShow: 1,
        adaptiveHeight:true,
        autoplay:true,
        autoplaySpeed:3000,
        arrows:true
      })
    }


}
