import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { InvoicesDetailService } from 'src/app/shared/services/api/invoices-detail.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Redeem_EarnService } from 'src/app/shared/services/api/redeem-earn.service';
import { GetPayinTokensService } from 'src/app/shared/services/api/get-payin-tokens.service';
import { PaymentSummaryDialogComponent } from '../payment-summary-dialog/payment-summary-dialog.component';
import { UserService } from 'src/app/shared/services/user.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalDefaultPaymentComponent } from 'src/app/shared/components/modal-default-payment/modal-default-payment.component';
import { GetGuidService } from 'src/app/shared/services/api/get-guid.service';
import { NgDialogAnimationService } from 'src/app/shared/services/flyout.service';
import { PaymentMethodFlyoutComponent } from '../payment-method-flyout/payment-method-flyout.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-outstanding-invoice',
  templateUrl: './outstanding-invoice.component.html',
  styleUrls: ['./outstanding-invoice.component.scss']
})
export class OutstandingInvoiceComponent implements OnInit {
  // User and account related details
  ONLpolicyDetails: any;
  walletDetails;
  ONLSessionDetails;

  // Collapsible panel state fields
  selectOutstandingInvoiceCollapsed: boolean = false; // Automatically open this panel for the user upon page load
  selectPaymentMethodCollapsed: boolean = true;
  allowUserToOpenOutstandingInvoicePanel: boolean = false;

  // Invoice and payment method form fields
  paymentOptionsFormGroup: UntypedFormGroup;
  paymentSummaryValues: any = {};
  invoiceList: any = [];
  selectedInvoice: any;
  currentDefaultPaymentMethod: any = {}; // Current selected payment method for 'default' option
  currentStep: any = 0; // Step 1 (0): Select invoice. Step 2 (1): Select payment method and submit payment
  invoiceSelected: boolean = false; // Boolean to see if an invoice is clicked (this enables/disables the 'next' button on the first page)

  // CSS-related fields
  disableReviewPaymentButton: boolean = true; // Once user selects an invoice to pay, this button will be enabled.
  summaryWrapperAlignTop: boolean = true; // When 'select payment method' panel is open, we want the payment summary box to be aligned to the bottom.

  // Error Messages
  systemError: boolean = false;
  disallowPageVisitAutopayMessage: boolean = false; // Autopay must be off to perform one-time payment
  disallowPageVisitDefaultMessage: boolean = false; // Default payment method must be true to perform one-time payment
  disallowPageVisitIS4UMessage: boolean = false; // IS4U users cannot view this page
  totalMismatchError: boolean = false; // Total amount input between the 3 payment methods doesn't match the invoice due amount
  defaultPaymentMethodError: boolean = false; // The selected default payment method token/cardType cannot be empty when you go to submit a payment
  ccExpiredMessage: boolean = false;
  ccWarningMessage: boolean = false;
  reviewPaymentError: boolean = false; // If any of the above 4 errors are true, this value should become true too, so the error notification box shows

  // Loading
  loading: boolean = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private invoiceservice: InvoicesDetailService,
    public dialog: MatDialog,
    public redeem_earnservice: Redeem_EarnService,
    public getpayintoken: GetPayinTokensService,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    public sessionService: GetGuidService,
    private flyoutDialog: NgDialogAnimationService
  ) {
    this.ONLSessionDetails = this.userService.getONLSessionDetails();
    this.paymentOptionsFormGroup = this.formBuilder.group({
      cashSelected: [{ value: false, disabled: false}],
      cashAmount: [{ value: '', disabled: true }, { validators: Validators.compose([Validators.required, Validators.min(0.01)]), updateOn: 'blur'}],
      rewardsSelected: [{ value: false, disabled: false}],
      rewardsAmount: [{ value: '', disabled: true }, { validators: Validators.compose([Validators.required, Validators.min(0.01)]), updateOn: 'blur'}],
      defaultSelected: [{ value: false, disabled: false}],
      defaultAmount: [{ value: '', disabled: true }, { validators: Validators.compose([Validators.required, Validators.min(0.01)]), updateOn: 'blur'}],
      useAutofill: [false]
    });
  }

  // Get wallet details -> Validate that the user is allowed to visit this page -> Retrieve and load invoices
  ngOnInit() {
    this.getWalletDetails();
  }

  ngOnDestroy() {
    sessionStorage.removeItem('currentInvoiceProgress');
  }

  getWalletDetails() {
    this.loading = true;
    let request = {
      walletID: JSON.parse(sessionStorage.getItem('WalletID')!),
      walletRole: JSON.parse(sessionStorage.getItem('WalletRole')!)
    }
    this.userService.getWalletSummary(request).subscribe(
      data => {
        // Retrieve wallet summary details and continue loading the page       
        if (data?.wallet) {
          sessionStorage.setItem('walletDetails', JSON.stringify(data.wallet));
          this.walletDetails = data.wallet;
        } else {
          // Try to retrieve information from sessionStorage.
          this.walletDetails = JSON.parse(sessionStorage.getItem('walletDetails')!);
        }

        if (this.walletDetails) {
          this.checkIfUserIsAllowedToViewPage();
        } else {
          // We weren't able to get the wallet details. Disable all functionality and don't allow them to do anything
          this.loading = false;
          this.systemError = true;

        }
      }, error => {
        // Even if the API fails, we can try to retrieve the information from sessionStorage
        this.walletDetails = JSON.parse(sessionStorage.getItem('walletDetails')!);
        if (this.walletDetails) {
          this.checkIfUserIsAllowedToViewPage();
        } else {
          // We weren't able to get the wallet details. Disable all functionality and don't allow them to do anything
          this.loading = false;
          this.systemError = true;
        }
      }
    );
  }

  // These are a few scenarios where the user should not be allowed to view this screen.
  // 1. If autopay is on, they must turn it off before making one-time payment. 
  // 2. If they do not have a default payment method set, they must create one first.
  // 3. If they are not rewards eligible (IS4U policy), they cannot make one-time payments altogether.
  // If none of the scenarios apply to the user, we continue by loading all the invoices.
  checkIfUserIsAllowedToViewPage() {
    if (this.walletDetails?.isDWAutoPay == "1") {
      this.loading = false;
      this.disallowPageVisitAutopayMessage = true;
    } else if (this.walletDetails?.defaultPaymentMethod?.toLowerCase() == "false") {
      let config = new MatDialogConfig();
      config.autoFocus = false;
      config.data = 'invoice payments';
      let dialogRef = this.dialog.open(ModalDefaultPaymentComponent, config);

      this.loading = false;
      this.disallowPageVisitDefaultMessage = true;
    } else if (this.walletDetails?.rewardEligible?.toLowerCase() == "false") {
      this.loading = false;
      this.disallowPageVisitIS4UMessage = true;
    } else {
      this.getInvoiceDetails();
    }
  }

  getInvoiceDetails() {
    this.loading = true;
    this.allowUserToOpenOutstandingInvoicePanel = true;
    let walletID = JSON.parse(sessionStorage.getItem('WalletID')!);
    let walletRole = JSON.parse(sessionStorage.getItem('WalletRole')!);
    let productSystem = this.ONLSessionDetails.productSystem;
    this.invoiceservice.fetchInvoiceDetails(walletID, walletRole, productSystem).subscribe(
      data => {
        if (data?.success == true && data?.result && data?.code == 200) {
          this.loading = false;
          this.ONLpolicyDetails = data.result;

          this.invoiceList = data.result.invoices;
          // Remove any status = Paid invoices
          this.invoiceList = this.invoiceList.filter(i => i.status != 'paid' && _.toNumber(i.dueAmount) > 0);
          // Remove any status = Planned invoices
          this.invoiceList = this.invoiceList.filter(i => i.status != 'Planned');
          // Remove any invoices with payments made already
          this.invoiceList = this.invoiceList.filter(i => {
            return !data.result.payments.find(p => p.invoiceNumber == _.toString(i.invoiceNumber));
          });
          // Remove any invoices with payments requested
          this.invoiceList = this.invoiceList.filter(i => {
            return !data.result.paymentRequests.find(p => p.invoiceNumber == _.toString(i.invoiceNumber)
              && ['Closed-Reversed', 'Closed-Declined', 'Closed-Error', 'Drafted-Error', 'Drafted-Declined', 'Drafted-Error'].indexOf(p.status) < 0);
          });

          // Close all panels, unselect all invoices, and perform some logic to determine if each invoice should show a message or not
          for (let i = 0; i < this.invoiceList.length; i++) {
            this.invoiceList[i].collapsed = true;
            this.invoiceList[i].selected = false;

            try {
              let currentDate = new Date();

              const [dueYear, dueMonth, dueDay] = this.invoiceList[i].dueDate?.split('-');
              let invoiceDueDate = new Date(Number(dueYear), Number(dueMonth) - 1, Number(dueDay), 23, 59, 59, 999); // For example: "2024-06-04" gets converted to June 4th, 11:59:59pm  
              
              const [billYear, billMonth, billDay] = this.invoiceList[i].invoiceBillDate?.split('-');
              let invoiceBillDate = new Date(Number(billYear), Number(billMonth) - 1, Number(billDay), 23, 59, 59, 999); // For example: "2024-06-04" gets converted to June 4th, 11:59:59pm  
              let invoiceBillDatePlus7 = new Date(invoiceBillDate?.getTime() + (7 * 24 * 60 * 60 * 1000));
      
              if (invoiceDueDate < currentDate) {
                // Invoice is past due, show the "due x days ago" message
                this.invoiceList[i].pastDueMessage = true;
                this.invoiceList[i].earlyBillPayMessage = false;
                this.invoiceList[i].normalBillPayMessage = false;
  
                let diff = Math.abs(currentDate.getTime() - invoiceDueDate.getTime());
                let diffDays = Math.ceil(diff / (1000 * 3600 * 24))
                this.invoiceList[i].pastDueDays = diffDays;    
              } else if (currentDate < invoiceBillDatePlus7) {
                // Once the invoice becomes payable (the bill date), we have a 7 day early period where the customer can earn points for paying the invoice early
                // Show the "earn x points if paid by invoiceBillDatePlus7 date message"
                this.invoiceList[i].pastDueMessage = false;
                this.invoiceList[i].earlyBillPayMessage = true;
                this.invoiceList[i].normalBillPayMessage = false;

                this.invoiceList[i].earlyBillPayPoints = Number((this.ONLpolicyDetails?.earlyBillPayConversion * this.invoiceList[i].dueAmount).toFixed(0));
                this.invoiceList[i].earlyBillPayDeadline = invoiceBillDatePlus7;
              } else {
                // The invoice is not past due, but has also passed the 7 day mark since the billed date. Show regular due in x days message
                this.invoiceList[i].pastDueMessage = false;
                this.invoiceList[i].earlyBillPayMessage = false;
                this.invoiceList[i].normalBillPayMessage = true;

                let diff = Math.abs(invoiceDueDate.getTime() - currentDate.getTime());
                let diffDays = Math.ceil(diff / (1000 * 3600 * 24))
                this.invoiceList[i].normalDueDays = Number((diffDays - 1).toFixed(0));
              }
            } catch(e) {}
          }

          // Using the default payment method returned to us from walletSummary, set it as the default payment for the second collapsible panel
          // The user can change this later
          this.currentDefaultPaymentMethod = this.walletDetails.payment;
          this.currentDefaultPaymentMethod.token = this.walletDetails.token;
          this.currentDefaultPaymentMethod.isDefaultPaymentMethod = "true";

          // Check if current default payment method is expired or not
          if (this.currentDefaultPaymentMethod?.cardType == 'Visa' || this.currentDefaultPaymentMethod?.cardType == 'Mastercard' || this.currentDefaultPaymentMethod?.cardType == 'Amex') {
            this.currentDefaultPaymentMethod.isExpired = this.checkCCExpiration(this.currentDefaultPaymentMethod.ccExpiration, 'expired');
            this.currentDefaultPaymentMethod.isWarning = this.checkCCExpiration(this.currentDefaultPaymentMethod.ccExpiration, 'warning'); 
          }

          // If the user left the page to visit DPAT to add a payment method via flyout, we save their progress in sessionStorage
          // Upon return to the page, this function checks to see where they left off and resume them at that step
          this.pickUpWhereILeftOff();
        } else {
          this.loading = false;
          this.systemError = true;
        }
      }, error => {
        this.loading = false;
        this.systemError = true;
      }
    );
  }

  pickUpWhereILeftOff() {
    // Only perform actions here if they came back from DPAT. If this is the user's first time coming to the page, this function will do nothing.
    let invoiceProgress = JSON.parse(sessionStorage.getItem('currentInvoiceProgress')!);
    if (invoiceProgress) {
      let invoiceFound = false;
      for (let i = 0; i < this.invoiceList.length; i++) { // could probably use array.find
        if (this.invoiceList[i].invoiceNumber == invoiceProgress?.selectedInvoice?.invoiceNumber) {
          this.invoiceList[i].selected = true;
          this.invoiceList[i].collapsed = false;
          invoiceFound = true;
        }
      }

      // If somehow we weren't able to find the pre-selected invoice, do nothing instead.
      // (I only really see this occuring if the call to retrieve invoice details somehow returns blank)
      if (invoiceFound) {
        this.disableReviewPaymentButton = false;

        this.selectedInvoice = invoiceProgress.selectedInvoice;
        // Reset all payment method information and add maximum validators to input fields
        // For cash/rewards: They cannot enter in a number greater than the cash/rewards they own
        // For default: They cannot enter in a number greater than the invoice due amount
        this.paymentOptionsFormGroup.reset();
        this.disableOptionsIfNoCashOrRewards();
        this.paymentOptionsFormGroup.controls.cashAmount.addValidators([Validators.max(this.walletDetails?.cash?.totalCashBalance)])
        this.paymentOptionsFormGroup.controls.rewardsAmount.addValidators([Validators.max(this.walletDetails?.rewards?.totalRewardValue)])
        this.paymentOptionsFormGroup.controls.defaultAmount.addValidators([Validators.max(this.selectedInvoice?.dueAmount)]);

        // Prepare the payment method form for them (prepopulate any possible values)
        this.currentDefaultPaymentMethod = invoiceProgress?.currentDefaultPaymentMethod;

        // Open the 'select payment method' panel.
        this.selectPaymentMethodCollapsed = false;
        setTimeout(() => {
          document.getElementsByClassName("collapsible-panel-wrapper payment-method-unique-identifier")[0]?.scrollIntoView();
          this.summaryWrapperAlignTop = false;
          this.currentStep = 1;
        }, 150)

        // Autofill the payment options based on what was selected before they left for DPAT
        if (invoiceProgress.useAutofill == true) {
          this.paymentOptionsFormGroup.controls.useAutofill.setValue(true);
          this.performAutofill();
        } else {
          this.paymentOptionsFormGroup.controls.useAutofill.setValue(false);

          if (invoiceProgress.paymentSummaryValues?.cash?.input) {
            this.paymentOptionsFormGroup.controls.cashSelected.setValue(true);
            this.paymentOptionsFormGroup.controls.cashAmount.setValue(invoiceProgress.paymentSummaryValues?.cash?.input);
          }

          if (invoiceProgress.paymentSummaryValues?.rewards?.input) {
            this.paymentOptionsFormGroup.controls.rewardsSelected.setValue(true);
            this.paymentOptionsFormGroup.controls.rewardsAmount.setValue(invoiceProgress.paymentSummaryValues?.rewards?.input);
          }

          if (invoiceProgress.paymentSummaryValues?.default?.input) {
            this.paymentOptionsFormGroup.controls.defaultSelected.setValue(true);
            this.paymentOptionsFormGroup.controls.defaultAmount.setValue(invoiceProgress.paymentSummaryValues?.default?.input);
          }

          // this.paymentSummaryValues should automatically update to what it was before (due to the valueChanges subscriptions when we set the values above)
        }

        // Re-open the flyout for the user automatically since that's where they were before leaving to DPAT
        setTimeout(() => {
          this.viewOtherPaymentMethods();
        }, 1000);
      } else {
        // Somehow we did not find the invoice selected in the list of invoices, so do nothing.
        this.currentStep = 0;
        this.invoiceSelected = false;
      }

      sessionStorage.removeItem('currentInvoiceProgress');
    }
  }

  openOutstandingInvoicePanel() {
    this.selectOutstandingInvoiceCollapsed = !this.selectOutstandingInvoiceCollapsed;

    // All of the below is for CSS purposes - scrollbar isn't visible as the collapsible panel expands, but only shows AFTER the transition has finished
    let page = document.getElementById('outstanding_invoice') as HTMLLinkElement;
    if (this.selectOutstandingInvoiceCollapsed) {
      page.style.overflowY = 'hidden';
      page.style.paddingRight = '8px';
    } else {
      setTimeout(() => {
        page.style.overflowY = 'scroll';
        page.style.paddingRight = '0px';
      }, 850);
    }
  }

  invoicePanel(event, invoice, source) {
    // If user clicks a radio button, the invoice panel should not collapse/uncollapse
    // Only if the user clicks anywhere else in the invoice panel, will the invoice panel collapse/uncollapse
    if (source == 'toggle-panel' && (event?.target?.checked == undefined || event?.target?.checked == null)) {
      for (let i = 0; i < this.invoiceList.length; i++) {
        if (invoice.invoiceNumber != this.invoiceList[i].invoiceNumber) {
          this.invoiceList[i].collapsed = true;
        }
      }
      invoice.collapsed = !invoice.collapsed;
    } else if (source == 'select-invoice') {
      // Unselect all radio buttons first (since the user is only allowed to select one invoice at a time)
      for (let i = 0; i < this.invoiceList.length; i++) {
        this.invoiceList[i].selected = false;
      }

      // Once the user selects at least one invoice, they cannot unselect it, so we do not need to worry about the unselect functionality.
      invoice.selected = true;
      this.selectedInvoice = invoice;
      this.paymentSummaryValues.total = { remainingDue: invoice.dueAmount };

      // If they click on an invoice, they will need to click on the 'next' button before proceeding to the next step (payment method)
      this.currentStep = 0;
      this.invoiceSelected = true;
      this.selectPaymentMethodCollapsed = true;
      this.summaryWrapperAlignTop = true;
      this.paymentOptionsFormGroup.reset();
    }
  }

  backStep() {
    // They were on payment methods step and clicked 'back', unselect all invoices and revert to first step screen.
    this.currentStep = 0;
    this.invoiceSelected = false;
    this.selectedInvoice = undefined;
    for (let i = 0; i < this.invoiceList.length; i++) {
      this.invoiceList[i].selected = false;
    }
    this.selectPaymentMethodCollapsed = true;
    this.paymentOptionsFormGroup.reset();

    setTimeout(() => {
      window.scrollTo(0,0);
      this.summaryWrapperAlignTop = true;
    }, 100)
  }

  nextStep() {
    this.currentStep = 1;
    this.disableReviewPaymentButton = false;

    // Reset all payment method information and add maximum validators to input fields
    // For cash/rewards: They cannot enter in a number greater than the cash/rewards they own
    // For default: They cannot enter in a number greater than the invoice due amount
    this.paymentOptionsFormGroup.reset();
    this.disableOptionsIfNoCashOrRewards();
    this.paymentOptionsFormGroup.controls.cashAmount.addValidators([Validators.max(this.walletDetails?.cash?.totalCashBalance)])
    this.paymentOptionsFormGroup.controls.rewardsAmount.addValidators([Validators.max(this.walletDetails?.rewards?.totalRewardValue)])
    this.paymentOptionsFormGroup.controls.defaultAmount.addValidators([Validators.max(this.selectedInvoice?.dueAmount)]);

    // Since an invoice is selected, open the 'select payment method' collapsible panel for them.
    this.openPaymentMethodPanel();
  }

  openPaymentMethodPanel() {
    this.selectPaymentMethodCollapsed = false;
    setTimeout(() => {
      document.getElementsByClassName("collapsible-panel-wrapper payment-method-unique-identifier")[0]?.scrollIntoView();
      this.summaryWrapperAlignTop = false;
      this.performAutofill();
    }, 100);
  }

  performAutofill() {
    let invoiceAmount = Number(this.selectedInvoice?.dueAmount);
    let remainingDue = invoiceAmount;
    let cashBalance = Number(this.walletDetails?.cash?.totalCashBalance);
    let cashApplied = Number(0);
    let rewardsBalance = Number(this.walletDetails?.rewards?.totalRewardValue);
    let rewardsApplied = Number(0);
    let defaultApplied = Number(0);

    // If user has more cash than the invoice due amount is, then apply the necessary cash to pay the invoice
    // If user has less cash than the invoice due amount, then apply all of their cash and move to next payment method
    if (cashBalance >= invoiceAmount) {
      cashApplied = invoiceAmount;
    } else {
      cashApplied = cashBalance;
    }

    // After each payment method is applied, update the remaining invoice balance due
    remainingDue = Number((invoiceAmount - cashApplied).toFixed(2));
    // If user has more rewards value than the remaining due amount, apply all of the necessary rewards points to pay for the invoice
    if (rewardsBalance >= remainingDue) {
      rewardsApplied = remainingDue;
    } else {
      rewardsApplied = rewardsBalance;
    }

    // Use default payment method for the rest of the payment if cash and rewards did not suffice.
    defaultApplied = Number((remainingDue - rewardsApplied).toFixed(2));

    // Only apply the value and select the payment method if a value above 0 was applied
    if (cashApplied > 0) {
      this.paymentOptionsFormGroup.controls.cashSelected.setValue(true);
      this.paymentOptionsFormGroup.controls.cashAmount.setValue(cashApplied.toFixed(2));
    } else {
      this.paymentOptionsFormGroup.controls.cashSelected.setValue(false);
      this.paymentOptionsFormGroup.controls.cashAmount.setValue('');
      this.paymentOptionsFormGroup.controls.cashAmount.disable();
    }

    if (rewardsApplied > 0) {
      this.paymentOptionsFormGroup.controls.rewardsSelected.setValue(true);
      this.paymentOptionsFormGroup.controls.rewardsAmount.setValue(rewardsApplied.toFixed(2));
    } else {
      this.paymentOptionsFormGroup.controls.rewardsSelected.setValue(false);
      this.paymentOptionsFormGroup.controls.rewardsAmount.setValue('');
      this.paymentOptionsFormGroup.controls.rewardsAmount.disable();
    }

    if (defaultApplied > 0) {
      this.paymentOptionsFormGroup.controls.defaultSelected.setValue(true);
      this.paymentOptionsFormGroup.controls.defaultAmount.setValue(defaultApplied.toFixed(2));
    } else {
      this.paymentOptionsFormGroup.controls.defaultSelected.setValue(false);
      this.paymentOptionsFormGroup.controls.defaultAmount.setValue('');
      this.paymentOptionsFormGroup.controls.defaultAmount.disable();
    }

    // Turn the mat slide toggle to 'on'
    // This line of code MUST remain underneath where we set the values for cashAmount/rewardsAmount/defaultAmount
    // since when those values changes, the valueChanges subscription will set useAutofill to false (it's assuming the user changed the value when in reality, it was us here)
    this.paymentOptionsFormGroup.controls.useAutofill.setValue(true);
  }

  updateForm() {
    if (this.paymentOptionsFormGroup.controls.useAutofill.value == true) {
      this.performAutofill();
    } else {
      this.paymentOptionsFormGroup.reset();
      this.disableOptionsIfNoCashOrRewards();
    }
  }

  disableOptionsIfNoCashOrRewards() {
    // If they have no cash and/or no rewards, make the checkbox for them unselectable
    if (this.walletDetails?.cash?.totalCashBalance <= 0) {
      this.paymentOptionsFormGroup.controls.cashSelected.setValue(false);
      this.paymentOptionsFormGroup.controls.cashSelected.disable();
    }
    
    if (this.walletDetails?.rewards?.totalRewardBalance <= 0) {
      this.paymentOptionsFormGroup.controls.rewardsSelected.setValue(false);
      this.paymentOptionsFormGroup.controls.rewardsSelected.disable();
    }
  }

  ngAfterViewInit() {
    // The first three valueChanges subscriptions are to detect when payment method options get checked/unchecked
    this.paymentOptionsFormGroup.controls.cashSelected.valueChanges.subscribe(val => {
      if (val == true) {
        this.paymentOptionsFormGroup.controls.cashAmount.enable();
        // Function checks for a few things, but mainly:
        // - Tries to use as much or all of the cash the user has, as long as it stays below the invoice due amount
        // - If user has already input a lot of $ into the rewards field, only fill in as much cash as needed to fulfill the invoice due amount, don't go over
        let cashInput = this.retrieveUpdatedCashInput('cash');
        let newDefaultInput = this.retrieveUpdatedCashInput('default');

        this.paymentOptionsFormGroup.controls.cashAmount.setValue(cashInput);
        if (this.paymentOptionsFormGroup.controls.defaultSelected.value == true) { // Only fill the default amount if user has the option selected.
          this.paymentOptionsFormGroup.controls.defaultAmount.setValue(newDefaultInput);
        }
      } else {
        this.paymentOptionsFormGroup.controls.useAutofill.setValue(false);
        this.paymentOptionsFormGroup.controls.cashAmount.setValue('');
        this.paymentOptionsFormGroup.controls.cashAmount.disable();
        this.paymentSummaryValues.cash = undefined;

        // When we unselect cash, move over previously selected reward funds into the default payment method option, if selected.
        // (This logic can be found in the rewardsAmount.valueChanges subscription, which gets triggered by the above line where we did setValue(''))
      }
    });

    this.paymentOptionsFormGroup.controls.rewardsSelected.valueChanges.subscribe(val => {
      // See above (cashSelected.valueChanges) for comments, logic is identical but for rewards.
      if (val == true) {
        this.paymentOptionsFormGroup.controls.rewardsAmount.enable();
        let rewardsInput = this.retrieveUpdatedRewardsInput('rewards');
        let newDefaultInput = this.retrieveUpdatedRewardsInput('default');

        this.paymentOptionsFormGroup.controls.rewardsAmount.setValue(rewardsInput);
        if (this.paymentOptionsFormGroup.controls.defaultSelected.value == true) {
          this.paymentOptionsFormGroup.controls.defaultAmount.setValue(newDefaultInput);
        }
      } else {
        this.paymentOptionsFormGroup.controls.useAutofill.setValue(false);
        this.paymentOptionsFormGroup.controls.rewardsAmount.setValue('');
        this.paymentOptionsFormGroup.controls.rewardsAmount.disable();
        this.paymentSummaryValues.rewards = undefined;
      }
    });

    this.paymentOptionsFormGroup.controls.defaultSelected.valueChanges.subscribe(val => {
      if (val == true) {
        this.paymentOptionsFormGroup.controls.defaultAmount.enable();

        // When this option is selected, take a look at how much is already input into cash + rewards, then fill remaining into default automatically
        // If cash + rewards is already equal to or more than the invoice due amount, just set the value to 0 for default.
        let totalDue = Number(this.selectedInvoice?.dueAmount);
        let cashInput = Number(this.paymentOptionsFormGroup.controls.cashAmount.value);
        let rewardsInput = Number(this.paymentOptionsFormGroup.controls.rewardsAmount.value);
        let remainingDue = Number((totalDue - cashInput - rewardsInput).toFixed(2));
        if (remainingDue > 0) {
          this.paymentOptionsFormGroup.controls.defaultAmount.setValue(remainingDue);
        } else {
          this.paymentOptionsFormGroup.controls.defaultAmount.setValue(0);
        }
      } else {
        this.paymentOptionsFormGroup.controls.useAutofill.setValue(false);
        this.paymentOptionsFormGroup.controls.defaultAmount.setValue('');
        this.paymentOptionsFormGroup.controls.defaultAmount.disable();
        this.paymentSummaryValues.default = undefined;
      }
    });

    // These next three valueChanges subscriptions are mainly for UI purposes
    // Ensure that the payment summary box shows the most up to date values
    this.paymentOptionsFormGroup.controls.cashAmount.valueChanges.subscribe(val => {
      // NOTE: All logic below is best when the formcontrol is on updateOn: 'blur' setting. Be careful using when updateOn: 'change'

      // Error handling case #1: User inputs value greater than the invoice due amount or the amount of cash they have. Call function to get valid values instead
      if (Number(val) > Number(this.selectedInvoice?.dueAmount) || Number(val) > Number(this.walletDetails?.cash?.totalCashBalance)) {
        let cashInput = this.retrieveUpdatedCashInput('cash');
        let newDefaultInput = this.retrieveUpdatedCashInput('default');
        this.paymentOptionsFormGroup.controls.cashAmount.setValue(cashInput);
        if (this.paymentOptionsFormGroup.controls.defaultSelected.value == true) {
          this.paymentOptionsFormGroup.controls.defaultAmount.setValue(newDefaultInput);
        }
        return;
      }    

      // Normal use case #1: Once value of cash amount changes (via input OR checkbox), update the default method to the remaining due, if the option is selected
      if (this.paymentOptionsFormGroup.controls.defaultSelected.value == true) {
        let totalDue = Number(this.selectedInvoice?.dueAmount);
        let cashInput = Number(val);
        let rewardsInput = Number(this.paymentOptionsFormGroup.controls.rewardsAmount.value);
        let remainingDue = Number((totalDue - cashInput - rewardsInput).toFixed(2));
  
        if (remainingDue < 0) {
          this.paymentOptionsFormGroup.controls.defaultAmount.setValue(0);
        } else {
          this.paymentOptionsFormGroup.controls.defaultAmount.setValue(remainingDue);
        }
      }   

      this.validateAndSetTotalAmount();
      this.paymentOptionsFormGroup.controls.useAutofill.setValue(false); // If input field changes values, always just set the autofill option to off.
      this.paymentSummaryValues.cash = {
        input: Number(val),
        remainingBalance: Number((this.walletDetails?.cash?.totalCashBalance - val).toFixed(2))
      };
    });

    this.paymentOptionsFormGroup.controls.rewardsAmount.valueChanges.subscribe(val => {
      // Error handling case #1: User inputs value greater than the invoice due amount or the amount of rewards they have. Call function to get valid values instead
      if (Number(val) > Number(this.selectedInvoice?.dueAmount) || Number(val) > Number(this.walletDetails?.rewards?.totalRewardValue)) {
        let rewardsInput = this.retrieveUpdatedRewardsInput('rewards');
        let newDefaultInput = this.retrieveUpdatedRewardsInput('default');

        this.paymentOptionsFormGroup.controls.rewardsAmount.setValue(rewardsInput);
        if (this.paymentOptionsFormGroup.controls.defaultSelected.value == true) {
          this.paymentOptionsFormGroup.controls.defaultAmount.setValue(newDefaultInput);
        }
        return;
      }

      // Normal use case #1: Once value of rewards amount changes (via input OR checkbox), update the default method to the remaining due, if the option is selected
      if (this.paymentOptionsFormGroup.controls.defaultSelected.value == true) {
        let totalDue = Number(this.selectedInvoice?.dueAmount);
        let rewardsInput = Number(val);
        let cashInput = Number(this.paymentOptionsFormGroup.controls.cashAmount.value);
        let remainingDue = Number((totalDue - cashInput - rewardsInput).toFixed(2));
  
        if (remainingDue < 0) {
          this.paymentOptionsFormGroup.controls.defaultAmount.setValue(0);
        } else {
          this.paymentOptionsFormGroup.controls.defaultAmount.setValue(remainingDue);
        }
      }  

      this.validateAndSetTotalAmount();
      this.paymentOptionsFormGroup.controls.useAutofill.setValue(false);
      this.paymentSummaryValues.rewards = {
        input: Number(val),
        inputInPoints: Number((val * 100).toFixed(0)),
        remainingBalanceInPoints: Number((this.walletDetails?.rewards?.totalRewardBalance - Number(val * 100)).toFixed(2)) // The input amount is in terms of dollars which we have to convert to points for remaining rewards balance
      };
    });

    this.paymentOptionsFormGroup.controls.defaultAmount.valueChanges.subscribe(val => {
      // Error handling case #1: If user inputs a value greater than invoice total due amount, change the value to the right amount based on what has already been inputted
      if (Number(val) > Number(this.selectedInvoice?.dueAmount)) {
        let totalDue = Number(this.selectedInvoice?.dueAmount);
        let cashInput = Number(this.paymentOptionsFormGroup.controls.cashAmount.value);
        let rewardsInput = Number(this.paymentOptionsFormGroup.controls.rewardsAmount.value);
        let remainingDue = Number((totalDue - cashInput - rewardsInput).toFixed(2));
        this.paymentOptionsFormGroup.controls.defaultAmount.setValue(remainingDue);
        return;
      }

      // Error handling case #2: This may occur through other autofill logic. If a value ever comes in as negative, just default to 0.
      if (Number(val) < 0) {
        this.paymentOptionsFormGroup.controls.defaultAmount.setValue(0);
        return;
      }

      this.validateAndSetTotalAmount();
      this.paymentOptionsFormGroup.controls.useAutofill.setValue(false);
      this.paymentSummaryValues.default = {
        input: Number(val)
      };
    });
  }

  validateAndSetTotalAmount() {
    let totalInputAmount = Number((Number(this.paymentOptionsFormGroup.controls.cashAmount.value) + Number(this.paymentOptionsFormGroup.controls.rewardsAmount.value) + Number(this.paymentOptionsFormGroup.controls.defaultAmount.value)).toFixed(2));
    let invoiceAmount = Number(Number(this.selectedInvoice?.dueAmount).toFixed(2));

    if ((totalInputAmount - invoiceAmount <= 0.001) && (totalInputAmount - invoiceAmount >= -0.001)) { // To deal with floating precision errors
      this.totalMismatchError = false;
      this.reviewPaymentError = false;
    } else {
      // Showing the total amount error message as they are entering in values is a bit invasive, so let's only display it when they try to click the 'review payment' button
      // There will be validation in the reivewPayment() function to display the error message if so.
    }

    // So the UI can display the correct remaining due balance
    this.paymentSummaryValues.total = { remainingDue: Number((invoiceAmount - totalInputAmount).toFixed(2)) };
  }

  // Flyout
  viewOtherPaymentMethods() {
    const dialogRef = this.flyoutDialog.open(PaymentMethodFlyoutComponent, {
      direction: 'ltr',
      animation: { to: "left" },
      position: { rowStart: "0" },
      data: {
        selectedInvoice: this.selectedInvoice,
        paymentSummaryValues: this.paymentSummaryValues,
        currentDefaultPaymentMethod: this.currentDefaultPaymentMethod,
        useAutofill: this.paymentOptionsFormGroup.controls.useAutofill.value
      }
    });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.currentDefaultPaymentMethod = result; // User selected a payment method in the flyout, update to that method.
        }
      }
    );
  }

  reviewPayment() {
    this.reviewPaymentError = false;
    this.totalMismatchError = false;
    this.defaultPaymentMethodError = false;
    this.ccExpiredMessage = false;
    this.ccWarningMessage = false;
    this.systemError = false;

    let totalInputAmount = Number((Number(this.paymentOptionsFormGroup.controls.cashAmount.value) + Number(this.paymentOptionsFormGroup.controls.rewardsAmount.value) + Number(this.paymentOptionsFormGroup.controls.defaultAmount.value)).toFixed(2));
    let invoiceAmount = Number(Number(this.selectedInvoice?.dueAmount).toFixed(2));
    // If either the form is invalid, or if the total amount entered does not match the invoice amount due, do not allow them to continue.
    // Also sometimes the default payment method token/cardType will come in as blank, in that case since we don't have the payment method info, we can't let them continue.
    if (this.paymentOptionsFormGroup.invalid) {
      this.paymentOptionsFormGroup.markAllAsTouched();
    } else if (Number(this.paymentOptionsFormGroup.controls.cashAmount.value) < 0 || Number(this.paymentOptionsFormGroup.controls.rewardsAmount.value) < 0 || Number(this.paymentOptionsFormGroup.controls.defaultAmount.value) < 0) {
      // Shouldn't happen, but just in case one of the values is autofilled to a negative amount, show a system error.
      this.systemError = true;
    } else if ((totalInputAmount - invoiceAmount >= 0.001) || (totalInputAmount - invoiceAmount <= -0.001)) { // To deal with floating precision errors
      this.totalMismatchError = true;
      this.reviewPaymentError = true;
    } else if (this.paymentOptionsFormGroup.controls.defaultSelected.value == true && !(this.currentDefaultPaymentMethod.token && this.currentDefaultPaymentMethod.cardType)) {
      // Validation just in case they somehow try to continue with a glitched payment method (we don't have the token or cardType on hand)
      this.defaultPaymentMethodError = true;
      this.reviewPaymentError = true;
    } else if (this.paymentOptionsFormGroup.controls.defaultSelected.value == true && this.currentDefaultPaymentMethod?.isExpired) {
      this.ccExpiredMessage = true;
      this.reviewPaymentError = true;
    } else if (this.paymentOptionsFormGroup.controls.defaultSelected.value == true && this.currentDefaultPaymentMethod?.isWarning) {
      this.ccWarningMessage = true;
      this.reviewPaymentError = true;
    } else {
      let config = new MatDialogConfig();
      config.autoFocus = false;
      config.disableClose = true;
      config.data = {
        selectedInvoice: this.selectedInvoice, // Used so we know which invoice to actually pay
        paymentSummaryValues: this.paymentSummaryValues, // Used to know which payment method options were selected, and for how much
        conversionRates: this.ONLpolicyDetails, // User earns points for cash/ACH and early bill pay, and the necessary conversion rates are in this object
        currentDefaultPaymentMethod: this.currentDefaultPaymentMethod // Used to know which default payment method was used (if ACH, we award them some points)
      }

      let dialogRef = this.dialog.open(PaymentSummaryDialogComponent, config);
      dialogRef.afterClosed().subscribe(
        result => {
          if (result == 'successful-invoice-paid') {
            this.router.navigate(['my-wallet/all-rewards/redeem/payment-confirmation']);
          } else {
            // They clicked the back button, do nothing.
          }
        }
      )
    }
  }

  backToHome() {
    this.router.navigate(['home']);
  }

  navigateToAutopayPage() {
    this.router.navigate(['preferences/auto-redeem']);
  }

  checkCCExpiration(ccExpiration, type) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns 0-based index
    const expirationYear = parseInt(ccExpiration.slice(0, 4));
    const expirationMonth = parseInt(ccExpiration.slice(4, 6));

    let isExpired;
    let isWarning;

    if (currentYear > expirationYear) {
      // Card is expired if current year is greater than expiration year
      isExpired = true;
      isWarning = false;
    } else if (currentYear === expirationYear && currentMonth > expirationMonth) {
      // Card is expired if current year is equal to expiration year and current month is greater than expiration month
      isExpired = true;
      isWarning = false;
    } else if (currentYear === expirationYear && currentMonth === expirationMonth) {
      const daysInMonth = this.daysInMonth(currentMonth, currentYear);
      const daysUntilExpiration = daysInMonth - currentDate.getDate();
      // Card technically does not expire until the end of the month, but if there are 5 or fewer days until expiration
      // Disallow them from using the payment method - so we set it as 'warning' and not 'expired' but user not allowed to use the payment method in either case.
      if (daysUntilExpiration <= 5) {
        isExpired = false;
        isWarning = true;
      } else {
        // There are still more than 5 days in the month before card expires, let them use the payment method.
        isExpired = false;
        isWarning = false;
      }
    } else {
      // Card is not expired
      isExpired = false;
      isWarning = false;
    }

    if (type == 'expired') {
      return isExpired;
    } else if (type == 'warning') {
      return isWarning;
    }
  }

  daysInMonth(m, y) {
    // month is 0-based: Jan = 0, Dec = 11
    // (m-1 ? Not February : February) checks if month is Feb
    // y & (y % 25 ? 3 : 15) is falsy for leap years, hence 31-2 days in Feb- otherwise, it's 31-3 days
    // m % 7 makes it so m is even for months with 31 days, odd for rest
    // subtracting lowest bit &1 results in 31-1 days for April/June/Sept/Nov, 31-0 for the rest
    // referenced stackexchange post for this function
    return 31 - (m - 1 ? m % 7 & 1 : y & (y % 25 ? 3 : 15) ? 3 : 2);
  }

  retrieveUpdatedCashInput(source) {
    // Couple different scenarios
    // 1: If rewards is sufficient to pay the invoice currently, when cash is selected we prefill with 0
    // 2: Rewards is not sufficient, so then we try to use as much cash (up to the max) that we have to fulfill the invoice. If more money is still needed, we then put it into default.
    let totalDue = Number(this.selectedInvoice?.dueAmount);
    let maxCashValue = Number(this.walletDetails?.cash?.totalCashBalance);
    let rewardsInput = Number(this.paymentOptionsFormGroup.controls.rewardsAmount.value);
    let newDefaultInput; // Can only ever equal one of 2 things: 0 or totalDue - cashInput - rewardsInput
    let cashInput; // Can only ever equal one of 4 things: 0, maxCashValue, totalDue - rewardsInput, or totalDue

    if (rewardsInput >= totalDue) {
      cashInput = 0;
      newDefaultInput = 0;
    } else {
      if (Number((totalDue - rewardsInput).toFixed(2)) >= maxCashValue) {
        // The total due after applying current rewards is more than the cash we have, so we are safe to max cash and autofill the rest into default
        cashInput = maxCashValue;
        newDefaultInput = Number((totalDue - cashInput - rewardsInput).toFixed(2));
      } else {
         // The total due after applying current rewards is less than the rewards we have, so we use as much cash as is necessary and zero out the default value
         cashInput = Number((totalDue - rewardsInput).toFixed(2));
         newDefaultInput = 0;
      }
    }

    if (source == 'cash') {
      return cashInput;
    } else if (source == 'default') {
      return newDefaultInput;
    } else {
      return 0;
    }
  }

  retrieveUpdatedRewardsInput(source) {
    let totalDue = Number(this.selectedInvoice?.dueAmount);
    let cashInput = Number(this.paymentOptionsFormGroup.controls.cashAmount.value);
    let maxRewardsValue = Number(this.walletDetails?.rewards?.totalRewardValue);
    let newDefaultInput; // Can only ever equal one of 2 things: 0 or totalDue - cashInput - rewardsInput
    let rewardsInput; // Can only ever equal one of 4 things: 0, maxRewardsValue, totalDue - cashInput, or totalDue

    if (cashInput >= totalDue) {
      rewardsInput = 0;
      newDefaultInput = 0;
    } else {
      if (Number((totalDue - cashInput).toFixed(2)) >= maxRewardsValue) {
        // The total due after applying current cash is more than the rewards we have, so we are safe to max rewards and autofill the rest into default
        rewardsInput = maxRewardsValue;
        newDefaultInput = Number((totalDue - cashInput - rewardsInput).toFixed(2));
      } else {
        // The total due after applying current cash is less than the rewards we have, so we use as much rewards as is necessary and zero out the default value
        rewardsInput = Number((totalDue - cashInput).toFixed(2));
        newDefaultInput = 0;
      }
    }

    if (source == 'rewards') {
      return rewardsInput;
    } else if (source == 'default') {
      return newDefaultInput;
    } else {
      return 0;
    }
  }
}
