import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ONLStartWalletSession, ONLWalletSession, ONLWalletSessionRequest, ONLWalletSessionResponse } from '../models/session.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiEndpoints } from 'src/config/api-endpoints';
import { ScriptService } from './script.service';


@Injectable({
  providedIn: 'root'
})

export class SessionService {
   // apiURL:string ='';
 //  apiURL:string = "https://ib-wallet-dev-api.azurewebsites.net/api/Authentication/ONLValidateWalletSession?sessionId=";
    apiURL:string = ApiEndpoints.sessionjwt;
 
   //apiURL:string = ApiEndpoints.
     sessionobj:ONLWalletSession=new ONLWalletSession();

     private displayNameSource = new BehaviorSubject<string>('Guest');
     private displayobjectUIDSource = new BehaviorSubject<string>('N');

  constructor(private http:HttpClient, private scriptService:ScriptService) { 
   // this.apiURL = "https://ib-wallet-dev-api.azurewebsites.net/api/Authentication/ONLValidateWalletSession?sessionId=";
  }
  
fetchSessionObject(sessionId: string): Observable<ONLWalletSessionResponse> {
    let onlSessionRequest= new ONLWalletSessionRequest();
    onlSessionRequest.sessionId=sessionId;
    onlSessionRequest.secret=ApiEndpoints.secret;
    onlSessionRequest.user=ApiEndpoints.userIn;
    onlSessionRequest.pass=ApiEndpoints.passwordIn;
    
  return this.http.post<ONLWalletSessionResponse>(this.apiURL,onlSessionRequest).pipe(
    tap((res: ONLWalletSessionResponse) => {
      
      this.sessionobj = {...res};
      sessionStorage.setItem('ONLWalletSession', JSON.stringify(res.onlStartWalletSession));
      sessionStorage.setItem('WalletID', JSON.stringify(res.walletID));
      sessionStorage.setItem('WalletRole', JSON.stringify(res.walletRole));
      sessionStorage.setItem('sessionapimessage', JSON.stringify(res.message));
      sessionStorage.setItem('headers', res.dpatJWT);
      sessionStorage.setItem('walletheaders', res.ibJWT);
      this.storeSessionData(res);
      this.scriptService.pendoInit();
    }),
    map((res) => res)
  );
}

  getDisplayName(): Observable<string> {
    return this.displayNameSource.asObservable();
  }

  getObjectUID(): Observable<string>{
    return this.displayobjectUIDSource.asObservable();
  }


  private storeSessionData(data: ONLWalletSessionResponse): void {
    sessionStorage.setItem('ONLWalletSession', JSON.stringify(data.onlStartWalletSession));
    sessionStorage.setItem('WalletID', JSON.stringify(data.walletID));
    sessionStorage.setItem('WalletRole', JSON.stringify(data.walletRole));
    sessionStorage.setItem('sessionapimessage', JSON.stringify(data.message));
    sessionStorage.setItem('headers', data.dpatJWT);
    sessionStorage.setItem('walletheaders', data.ibJWT);

    const sessionData = this.getSessionData();
    this.displayNameSource.next(sessionData?.displayName || 'Guest');
    this.displayobjectUIDSource.next(sessionData?.objectUID || 'n');
  }

  private getSessionData(): ONLStartWalletSession | null {
    const sessionDataString = sessionStorage.getItem('ONLWalletSession');
    if (sessionDataString) {
      return JSON.parse(sessionDataString);
    } else {
      return null;
    }
  }


}