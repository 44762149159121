import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { TransactionHistory } from '../../models/transaction-history.interface';
import { ApiEndpoints } from 'src/config/api-endpoints';

@Injectable({
  providedIn: 'root'
})
export class TransactionHistoryService {

 // private apiUrl = 'https://ib-wallet-dev-api.azurewebsites.net/api/DigitalWalletAPI/dwgettransactionhistory';
 private apiUrl = ApiEndpoints.transactionHistory;
  constructor(private http: HttpClient) {}

// In transaction-history.service.ts
fetchTransactionHistory(walletID: string, walletRole: string, transactionType: string = 'all'): Observable<TransactionHistory> {
  // Construct the request body according to the API's expected format
  const requestBody = { walletID, walletRole, transactionType };
  let wallettoken=sessionStorage.getItem("walletheaders");
    let httpOptions = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET",
        'Content-Type': 'application/json',
        "Authorization": "bearer " + wallettoken
      }),
    };
  return this.http.post<TransactionHistory>(this.apiUrl, requestBody,httpOptions);
}


}
