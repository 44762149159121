import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-update-myaccount-dialog',
  templateUrl: './update-myaccount-dialog.component.html',
  styleUrls: ['./update-myaccount-dialog.component.scss']
})
export class UpdateMyaccountDialogComponent {

  constructor(
    // public activeModal:NgbActiveModal,
    public router:Router, 
    public route:ActivatedRoute, 
    private dialog: MatDialogRef<UpdateMyaccountDialogComponent>
  ){}

  gotoHomePage(){
    this.router.navigate(['/home'], { relativeTo:this.route });
    // this.activeModal.close();
    this.dialog.close();
   }

  close() {
    this.dialog.close();
  }

}
