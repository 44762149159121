

  <router-outlet></router-outlet>


    <!-- <h2 class="headline-highlight">Apply Your Balance</h2>

    <p>Auto Pay: OFF <span class="">Set Up</span></p>


 
    <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" class="dw-redeem-accordion">
     
        <ngb-panel title="Select Outstanding Invoice">
          <ng-template ngbPanelContent>
           Please choose your connected solution to redeem your points or pay wiht your stored payment options. 
  
           <div class="connected">
            <span class="icon"><img src="../../../../assets/icons/frame.svg"></span>
            <span class="title">DeliveryDefense™ Address ConfidenceItem<img src="../../../../assets/icons/open_in_new.svg"></span>
         </div>
         <hr />
        <div class="connected">
            <a href="https://www.insureshield.com/">
                <span class="icon"><img src="../../../../assets/icons/frame.svg"></span>
                <span class="title">InsureShield® Shipping Insurance<img src="../../../../assets/icons/open_in_new.svg"></span>
            </a>
        </div>
        </ng-template>
        </ngb-panel>
   

   
        <ngb-panel title="Select Payment Method">
          <ng-template ngbPanelContent>
         Text/Content
          </ng-template>
        </ngb-panel>
 
       
      </ngb-accordion>
     -->
      


