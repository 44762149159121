<div class="popup-global-layout width-650 p-4 p-md-5">
  <div *ngIf="currentStep == 0">
    <div class="d-flex justify-content-end"><mat-icon class="icon" (click)="close()">close</mat-icon></div>
    <div class="global-title">

    <ng-container *ngIf="isAutoPay; else unenrollTemplate">
      <p class="info">Are you sure to enroll to Auto Pay & Redeem?</p>
    </ng-container>
    
    <ng-template #unenrollTemplate>
      <div class="info">Are you sure you want to stop Auto Pay &amp; Redeem?</div>
      <div class="global-yellow-bar"></div>
    </ng-template>
   </div>
    

   <ng-container *ngIf="isAutoPay; else unenrollContent">
    <div class="global-yellow-bar"></div>
    <div class="global-subtitle">Selected Payment Method(s)</div>
    <div class="global-text">
      <div *ngIf="selectedMethods.length === 0">
        <p>You have not selected any method; you will not be enrolled in Auto Pay & Redeem.</p>
      </div>
      <ol *ngIf="selectedMethods.length > 0">
        <li *ngFor="let method of selectedMethods">
          <div class="methods">
            <div *ngIf="method == 'rewards'">My Rewards</div>
            <div *ngIf="method == 'cash'">My Cash</div>
            <div *ngIf="method == 'default'">{{ defaultMethod?.cardType }} <span *ngIf="defaultMethod?.cardType == 'EC' || defaultMethod?.cardType == 'ECP'">Bank</span> •••• {{ defaultMethod?.lastFourDigits }}</div>
            <img src="assets/icons/star_rate.svg" class="img-icon" *ngIf="method == 'rewards'">
            <img src="assets/icons/monetization_on.svg" class="img-icon" *ngIf="method == 'cash'">
            <img src="assets/icons/account_balance.png" class="img-icon" *ngIf="method == 'default' && (defaultMethod?.cardType == 'EC' || defaultMethod?.cardType == 'ECP')">
            <img src="assets/images/card/chkt_visa.svg" class="img-icon" *ngIf="method == 'default' && defaultMethod?.cardType == 'Visa'">
            <img src="assets/images/card/chkt_mastercard.svg" class="img-icon" *ngIf="method == 'default' && defaultMethod?.cardType == 'Mastercard'">
            <img src="assets/images/card/chkt_amex.svg" class="img-icon" *ngIf="method == 'default' && defaultMethod?.cardType == 'Amex'">
            <span class="default" *ngIf="method == 'default'">Default</span>
          </div>
        </li>
      </ol>
    </div>
  </ng-container>

  <ng-template #unenrollContent>
    <div class="global-text">
      <p>By unenrolling in Auto Pay & Redeem, you will now need to submit manual one-time payments</p>
    </div>
  </ng-template>

    <div class="global-button-format">
      <button class="dw-primary-button icon-right" (click)="confirmRedeem()" *ngIf="!buttonLoading">Continue<mat-icon class="black">keyboard_arrow_right</mat-icon></button>
      <button class="dw-primary-button icon-right" disabled *ngIf="buttonLoading">Continue<span class="spinner-border"></span></button>
      <button class="dw-secondary-button icon-right" (click)="close()">Cancel<mat-icon class="blue">keyboard_arrow_right</mat-icon></button>
    </div>
    <div class="errors" *ngIf="systemError">Something went wrong! Please try again later.</div>
  </div>
  
  <div *ngIf="currentStep == 1">
    <div class="d-flex justify-content-end"><mat-icon class="icon" (click)="close()">close</mat-icon></div>
    <div class="global-title">Update Confirmed!</div>
    <div class="global-yellow-bar"></div>
    <div class="global-subtitle">Your Autopay preferences were saved successfully.</div>
    <div class="global-button-format">
      <button (click)="thankYou()" class="dw-primary-button icon-right">Thank You<mat-icon class="black">keyboard_arrow_right</mat-icon></button>
    </div>
  </div>
</div>