import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-default-payment-method',
  templateUrl: './default-payment-method.component.html',
  styleUrls: ['./default-payment-method.component.scss']
})
export class DefaultPaymentMethodComponent {

  constructor(
    public dialogRef: MatDialogRef<DefaultPaymentMethodComponent>,private router:Router,
    @Inject(MAT_DIALOG_DATA) public data:any){
      this.dialogRef.updateSize('40%','40%');
    }

    
   

    onAccept(): void{
      this.dialogRef.close('accept');

    }

    onDecline(): void{
     this.dialogRef.close('decline');
    }

}
