<div class="sub-container transfer-requested">
    <h2 class="headline-highlight">Transfer Requested</h2>
   
    <div class="validation" *ngIf="errorMessage!=''">
        <img src="../../../../../assets/icons/error-24.png">
        <div>
        <div id="errormsg">Please try again</div>
               &nbsp;<div name="errorMessage">{{errorMessage}}</div>
                </div>
                </div>
    <div class="payment-confirmation" *ngIf="errorMessage==''">
       
    
        <div class="image-container">
            <div class="flipper">
              <!-- <img src="./../../../../assets/icons/LIKE BUTTON.svg" alt="Like Button" class="image1"> -->
              <img src="../../../../../assets/icons/likegreen.svg" alt="Green Button" class="image1">
              <img src="../../../../../assets/icons/likegreen.svg" alt="Green Button" class="image2">
              
            </div>
          </div>
       
        
        <h4>Thank you for your transfer!</h4>
        <p>Your cash transfer requested on {{transferDate}}. The transaction details are as follows:</p> 

        <p>Transfer Reference Number: {{transactionRefNo}}</p>
        <p>Transfer Amount: {{transfer.transferAmount}}</p>
        <p *ngIf="transfer.accountType=='RTP'">Service Fee: {{transfer.charges}}</p>
        <p>Total Transfer Amount: {{transfer.totalTransferAmount}}</p>
        <p>Date of Transfer: {{transferDate}}</p>
        

        <p>If you have any questions, please go here <a href="https://www.insureshield.com/us/en/resources/help-and-support/digital-wallet.html"><img src="./../../../../assets/icons/open_in_new.png" alt="Open in new" class="image1" ></a> </p>

        
    </div>
    <div class="button-container">
        <div class="btn">
            <button (click)="goToHome()" type="submit" mat-raised-button class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button primary">
                <span class="mdc-button__label">
                    <span class="button-label">
                        <span>Go Back to Home Page</span>
                        
                    <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                    </span>
                </span>
            </button>
        </div>
    </div>
</div>

