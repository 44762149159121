export interface RewardCommon {
    date: string; // earnDate or spendDate
    amount: string; // pointsEarned; pointsAmount; or paymentAmount
    productName: string;
    category: string; // earnCategory or spendCategory // Connected Solution
    identifier: string; // earnIdentifier, spendIdentifier, etc. - Reference Number
    identifierDescription: string; // earnIdentifierDescription, spendIdentifierDescription, etc - Details.

  }
  
  export class redeemRewards
  {
      customerid!: string;
      points_to_redeem!: number;
      invoiceid!: string;
      description!: string;
  }
  export class redeemRewardsResponse
  {
    redeemed_points: number=0;
    available_points:number= 0;
    wallet_history_id!: string;
  }

  export class cashtransaction
  {
      entityId!: string;
      transactionType!: string;
      description!: string;
      amount!: string;
      txnRef!: string;
      txnOrigin!: string;
  }
  export class cashtransactionResponse
  {
    statusCode!: number;
    data!: data;     
    message!: string;
  }

  export class data{
    result!: result[];
    pagination!: pagination;
  }

  export class result {
    
      accountNo!: string;
        time!: string;
    amount!: string;
    description!: string;
    transactionAmount!: string;
    transactionStatus!: string;
    preBalance!: string;
    postBalance!: string;
    transactionType!: string;
    txnOrigin!: string;
    externalTransactionId!: string;
    authCode!: string;
    convertedAmount!: number;
    transactionCurrencyCode!: string;
    exchangeRate!: number;
    markupServiceTax!: number;
    markupRate!: number;
    markUpAmount!: number;
    markUpServiceTaxAmount!: number;
    txnFees!: number;
    serviceTax!: number;
    crDr!: string;
    prepaidWallet!: prepaidWallet;
    merchantName!: string; 
    merchantLocation!: string;
    merchantId!: string;
}
export class prepaidWallet {
  walletName!: string;
  walletType!: string;
};

  

  export class pagination {
    list!: true;
    pageSize!: 0;
    pageNo!: 0;
    totalPages!: 0;
    totalElements!: 0;
  }