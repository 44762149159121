<div class="carousel">

    <div>
        <img src="../../../../assets/images/banner_1.png">
    </div>

    <div >
        <img src="../../../../assets/images/banner_2.png">
    </div>

    <div>
        <img src="../../../../assets/images/banner_3.png">
    </div>


</div>
