<header class="app-navigation">
<div class="top-nav-container">
<mat-toolbar class="custom-toolbar">
    <div class="logo">
      <img src="../../../../assets/icons/Logo.svg" alt="Logo" width="50">
    </div>
    
 

    <button mat-button routerLink="/home">Home</button>
    <!-- Desktop and mobile "My Wallet" handling -->
    <ng-container *ngIf="isHandset$ | async; else desktopMenu">
      <button mat-button [matMenuTriggerFor]="mobileMenu">Menu</button>
      <mat-menu #mobileMenu="matMenu">
        <button mat-menu-item routerLink="/home">Home</button>
        <button mat-menu-item routerLink="/connected-solutions">Connected Solutions</button>
        <!-- Placeholder for other mobile menu items -->
      </mat-menu>
    
    </ng-container>
    <ng-template #desktopMenu>
      <button mat-button (click)="toggleWalletPanel()">My Wallet</button>
      <button mat-button routerLink="/connected-solutions">Connected Solutions</button>
    </ng-template>
  

    <span class="spacer"></span>
    
    <button mat-icon-button [matMenuTriggerFor]="mobileMenu" *ngIf="isHandset$ | async">
      <mat-icon>menu</mat-icon>
    </button>
  </mat-toolbar>
</div>
  
  <mat-menu #mobileMenu="matMenu">
    <button mat-menu-item routerLink="/home">Home</button>
    <button mat-menu-item [matMenuTriggerFor]="walletSubMenu">My Wallet</button>
    <button mat-menu-item routerLink="/connected-solutions">Connected Solutions</button>
  </mat-menu>

  <mat-menu #walletSubMenu="matMenu">
    <button mat-menu-item>My Wallet Details</button>
    <button mat-menu-item>All Rewards</button>
    <!-- Add other submenu items here -->
</mat-menu>
  
  <!-- Submenu for My Wallet -->
  <div class="wallet-panel"  *ngIf="showWalletPanel && !(isHandset$ | async)">
    <div class="wallet-column">
      <h4>My Wallet</h4>
      <p>My Wallet Details</p>
    </div>
    <div class="wallet-column">
      <h4>My Rewards</h4>
      <p>All Rewards</p>
      <p>Redeem</p>
      <p>Ways To Earn</p>
    </div>
    <div class="wallet-column">
      <h4>My Cash</h4>
      <p>All Cash</p>
      <p>Redeem</p>
      <p>Transfer</p>
    </div>
    <div class="wallet-column">
      <h4>My Payment</h4>
      <p>All Payments</p>
    </div>
  </div>

<!-- Mobile View -->


</header>
  