<div class="app-header">
    <div class="logo">
        <img src="../../../../assets/icons/Logo.svg" alt="logo">
    </div>
</div>

<div class="logged-out">
    <p>Thank you for using UPS Wallet! To log back into your wallet, please visit the <a href="https://www.insureshield.com/">InsureShield online portal.</a></p>
     <p>If you have any questions or need assistance, feel free to contact us at 1-811-263-8772 or email us at capital&#64;ups.com. We're always here to help! </p>  
</div>

