<div id="bootstrap-overrides">
  <div class="sub-container modal-header">
      <h2 class="headline-highlight">Update Confirmed!</h2>
  </div>
  
  <div class="modal-body">
    <p>Your default payment method has been successfully set up!</p>
  </div>
  
  <div class="modal-footer">
    <div class="btn">
      <button (click)="gotoNotification()" type="submit" mat-raised-button class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button primary">
          <span class="mdc-button__label">
              <span class="button-label">
                  <span>Thank you</span>
                  <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
              </span>
          </span>
      </button>
  </div> 
  </div>

</div>
    