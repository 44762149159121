import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators'
import { WalletSummary } from '../../models/wallet-summary.interface';
import { ApiEndpoints } from 'src/config/api-endpoints';

@Injectable({
  providedIn: 'root'
})
export class WalletSummaryService {

  //private apiUrl = 'https://ib-wallet-dev-api.azurewebsites.net/api/DigitalWalletAPI/dwwalletsummary';
  private walletSummarySubject = new BehaviorSubject<WalletSummary | null>(null);
  walletSummary$ = this.walletSummarySubject.asObservable();
  private apiUrl=ApiEndpoints.walletSummary;

  constructor(private http:HttpClient) { }

  fetchWalletSummary(walletID: string, walletRole: string): Observable<WalletSummary> {
    let wallettoken=sessionStorage.getItem("walletheaders");
    let httpOptions = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET",
        'Content-Type': 'application/json',
        "Authorization": "bearer " + wallettoken
      }),
    };
    var sessionID=sessionStorage.getItem("SessionID");
    const body = JSON.stringify({ walletID, walletRole,sessionID });
    
    return this.http.post<WalletSummary>(this.apiUrl, body, httpOptions).pipe(
      tap(summary => {
        this.walletSummarySubject.next(summary);
      })
    )
  }

  getCurrentWalletSummary(): WalletSummary | null{
    return this.walletSummarySubject.value;
  }

  updateWalletSummary(request: any): Observable<any> {
    let wallettoken = sessionStorage.getItem("walletheaders");
    let httpOptions = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET",
        'Content-Type': 'application/json',
        "Authorization": "bearer " + wallettoken
      }),
    };

    // Use the existing apiUrl with an endpoint for updates
    const updateUrl = `${this.apiUrl}/update-wallet-summary`;

    return this.http.post(updateUrl, JSON.stringify(request), httpOptions);
  }
  
}
