<div class="col-md-12 loading-container" *ngIf="loading">
  <div class="loading">
    <div class="spinner-border" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
</div>
<div class="col-md-12" *ngIf="!loading">
  <div class="success-wrapper">
    <div class="header-section">
      <div class="title">Apply Your Balance</div>
      <div class="yellow-bar"></div>   
    </div>
    <div class="cash-rewards-section mobile">
      <div class="cash">
        <div class="type">CASH</div>
        <div class="max-amount">{{ walletDetails?.cash?.totalCashBalance | currency: 'USD' }}</div>
      </div>
      <div class="gray-divider"></div>
      <div class="rewards">
        <div class="type">REWARDS</div>
        <div class="max-amount">{{ walletDetails?.rewards?.totalRewardBalance | number }}<span
            class="supination">PTS</span>
        </div>
      </div>
    </div>
    <div class="success-box">
      <div><img src="../../../../../assets/icons/Property 1=Variant2.svg" class="img-format"></div>
      <div class="title">Thank you for your payment</div>
      <!-- If they earned at least 1 point when paying the invoice flow -->
      <div *ngIf="totalPointsEarned > 0">Congratulations! You just earned <span class="green">{{ totalPointsEarned | number: '1.0-0' }} pts</span> for Invoice <span class="bold">#{{ paidInvoiceDetails?.invoiceNumber }}</span></div>
      <div class="points-earned" *ngIf="totalPointsEarned > 0">
        <ul class="no-bullet-points">
          <li *ngIf="cashPointsEarned > 0"><div>My Cash Payment: <span class="green">+{{ cashPointsEarned | number: '1.0-0' }} pts</span></div></li>
          <li *ngIf="achPointsEarned > 0"><div>ACH Payment: <span class="green">+{{ achPointsEarned | number: '1.0-0' }} pts</span></div></li>
          <li *ngIf="earlyPointsEarned > 0"><div>Early Bill Payment: <span class="green">+{{ earlyPointsEarned | number: '1.0-0' }} pts</span></div></li>
        </ul>
      </div>
      <!-- If they earned no points when paying the invoice flow -->
      <div *ngIf="totalPointsEarned == 0 || !totalPointsEarned">Your payment has been made for the following invoice:</div>
      <div *ngIf="totalPointsEarned == 0 || !totalPointsEarned" class="invoice-number">#12345678</div>
      <div class="summary">All payment details can be found in your account summary. You will receive a confirmation email shortly.</div>
      <div class="buttons"><button class="dw-primary-button icon-right" (click)="backToRewards()">Go Back to My Rewards<mat-icon>keyboard_arrow_right</mat-icon></button></div>
    </div>
  </div>
</div>


