
<div class="payment-sub-container">

    <div class="modal-terms">
    
    <h2 class="headline-highlight">Payment Method</h2>
    <button mat-icon-button class="close-button" aria-label="Close dialog" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>

  <div  class="dialog-content">
    <div class="sections"><label class="account-type">ACH</label></div><br>
    <div *ngIf="ectokens==undefined" class="sections">
      <label class="noachtookens" >No ACH on file</label><br>
     <div class="chargestxt"><br> <label class="chargeslbl" >Earn</label> <label class="percentage">0.75%</label> <label class="chargeslbl" >back in rewards each time you pay with ACH</label></div><br>
    </div>
    <div *ngIf="ectokens!=undefined">
    <div  *ngFor="let ectoken of ectokens" class="tokendiv">
      
        <input type="radio"  [(ngModel)]="selectedtoken" value="{{ectoken.token}}"/>  <img class="payment-icons"src="{{ectoken.cardImg}}">
      
        <div class="pay-info">
          <span >{{ectoken.cardType}}</span>
            <span >Bank **** {{ectoken.last4Digits}}</span>
        </div>
        <div *ngIf="ectoken.isDefaultPaymentMethod.toLowerCase()=='true'" class="default-div">
          <label class="default">Default</label>
      </div>
    </div>
    </div>
    <hr>
    <div class="sections"><label class="account-type">Credit Card</label></div><br>
    <div *ngIf="cctokens==undefined" class="sections">
      <label class="noachtookens" >No credit card on file</label><br>
    </div>
    <div *ngIf="cctokens!=undefined">
    <div  *ngFor="let cctoken of cctokens" class="tokendiv">
      
        <input type="radio"  [(ngModel)]="selectedtoken" value="{{cctoken.token}}"/>  <img class="payment-icons" src="{{cctoken.cardImg}}">
      
        <div class="pay-info">
          <span >{{cctoken.cardType}}</span>
            <span >Bank **** {{cctoken.last4Digits}} &nbsp;&nbsp;*&nbsp;&nbsp;{{cctoken.ccexpiration}}</span>
        </div>
        <div *ngIf="cctoken.isDefaultPaymentMethod.toLowerCase()=='true'" class="default-div">
          <label class="default">Default</label>
      </div>
    </div>
  </div>
  </div><br>
  <div class="add-payment" (click)="addPaymentMethod()"> 
    <img src="../../../../assets/icons/add.svg"><a>Add New Payment Method</a>
</div>
    <!-- Your terms and conditions content here -->
  <div mat-dialog-actions>

    <div class="button-container">
        <div class="btn">
            <button (click)="updateDefaultPayment()"  type="submit" mat-raised-button class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button primary">
                <span class="mdc-button__label">
                    <span class="button-label">
                        <span>Update Payment Method</span>
                        <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                    </span>
                </span>
            </button>
        </div>
    </div>

    <!-- <button mat-button (click)="onAccept()" [disabled]="!canAccept()">Accept</button>
    <button mat-button (click)="onDecline()">Decline</button> -->
  </div>

</div>
</div>
