<div class="sub-container">
  <h2 class="headline-highlight">How Does It Work?</h2>
  <app-how-it-works [showHeader]="false" [showLearnMoreButton]="false" [showDivider]="true"></app-how-it-works>

  <h2 class="headline-highlight">How To Earn Rewards &amp; Cash?</h2>

  <table class="earn-rewards-cash">
    <!-- Header row -->
    <tr>
      <th></th> <!-- Placeholder for the first column -->
      <th>Ways to Earn</th>
      <th>DeliveryDefense<sup>TM</sup> Address Confidence <br>
        <p class="terms">
          <a (click)="openDeliveryDefenseModal()">Terms and conditions
            <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
          </a>
        </p>
      </th>
      <th>InsureShield Shipping Address
        <p class="terms">
          <a (click)="openInsureShieldModal()">Terms and conditions
            <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
          </a>
        </p>
      </th>
    </tr>
    <!-- Rows for REWARDS -->
    <tr *ngFor="let item of tableData; let i = index">
      <td *ngIf="i === 0" rowspan="11" class="rewards-cell">REWARDS</td>
      <td>
        <span [innerHTML]="item.column1"></span>
        <span *ngIf="item.hasDialog" class="material-icons clickable-text" [appClickableTooltip]="getTooltipInfo(item)">
          <img src="../../../../assets/icons/info.svg" alt="Info">
        </span>
      </td>
      <td class="column-2 coming-soon-cell" [class.coming-soon]="item.column2 === 'N/A' && i === 0">
        <span class="original-text">{{ item.column2 }}</span>
      </td>
      <td [ngClass]="{'disabled-cell': item.column3 === 'N/A'}">{{ item.column3 }}</td>
    </tr>
    <!-- Rows for CASH -->
    <tr *ngFor="let item of cashData; let i = index">
      <td *ngIf="i === 0" rowspan="2" class="cash-cell">CASH</td>
      <td>
        {{ item.column1 }}
        <span *ngIf="item.hasDialog" class="material-icons clickable-text" [appClickableTooltip]="getTooltipInfo(item)">
          <img src="../../../../assets/icons/info.svg" alt="Info">
        </span>
      </td>
      <td class="column-2 coming-soon-cell" >
        <span class="original-text">{{ item.column2 }}</span>
      </td>
      <td [ngClass]="{'disabled-cell': item.column3 === 'N/A'}">{{ item.column3 }}</td>
    </tr>
  </table>
</div>
