import { Component, ViewChild, AfterViewInit, OnInit} from '@angular/core';
import { NgbCarousel, NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';
import { ButtonClickService } from 'src/app/shared/services/button-click.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { SessionService } from 'src/app/shared/services/session.service';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { WalletSetupService } from 'src/app/shared/services/api/wallet-setup.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalTncComponent } from 'src/app/shared/components/modal-tnc/modal-tnc.component';
import { WalletSummaryService } from 'src/app/shared/services/api/wallet-summary.service';
import { TransactionHistoryService } from 'src/app/shared/services/api/transaction-history.service';
import { WalletSummary } from 'src/app/shared/models/wallet-summary.interface';
import { GetGuidService } from 'src/app/shared/services/api/get-guid.service';
import { ONLStartWalletSession } from 'src/app/shared/models/session.model';
import { HttpClient } from '@angular/common/http';
import { GetWalletNotificationService } from 'src/app/shared/services/api/get-wallet-notification.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})

export class HomeComponent implements AfterViewInit, OnInit {
  id: string = '';
  walletId: string = '';
  walletRole: string = '';
  autoPay: number = 0; 

  policyNumber: string = '';
  productType: string = '';
  productSystem: string = '';

  walletSummary: WalletSummary | null = null;
  loading: boolean = true; // Add loading flag

  totalCashBalance: string | null = null;
  totalRewardBalance: string | null = null;
  totalRewardValue: string | null = null;

  cashEligible = false;
  rewardEligible = false;
 
  claimPayments: any[] = [];

  notifications: any[] = []; // Array to hold notifications
  errorMessage: string = ''; // Variable to hold error messages


  @ViewChild('carousel', { static: true }) carousel!: NgbCarousel;
  images = [
    { title: 'First Slide', src: 'assets/images/DigitalWallet_Images_1.jpg' },
    { title: 'Second Slide', src: 'assets/images/DigitalWallet_Images_2.jpg' },
    { title: 'Third Slide', src: 'assets/images/DigitalWallet_Images_3.jpg' },
  ];
  activeSlideIndex = 0;
  walletID: string;


  constructor(
    public route: ActivatedRoute, 
    public sessionService: SessionService,
    private profileService: ProfileService,
    private walletSetupService: WalletSetupService,
    public buttonClick: ButtonClickService,
    public dialog: MatDialog,
    public router: Router,
    public walletSummaryService: WalletSummaryService,
    private transactionHistoryService: TransactionHistoryService,
    public getTokenService: GetGuidService,
    private http:HttpClient,
    private walletNotificationService: GetWalletNotificationService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        
      }
    });
  }

  ngOnInit(): void {
    const sessionId = sessionStorage.getItem('sessionId');
    if (sessionId) {
      this.id = sessionId;
      console.log('Browser Refreshed. Session ID : ', this.id);
      this.initializeWalletSession();
    } else {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      if (this.id) {
     //   console.log("Session ID received:", this.id);
        this.initializeWalletSession();
       
      } else {
      //  console.error("No session ID provided in route.");
        this.checkSessionAndRedirect();
      }
    });
  }

    this.walletSummaryService.walletSummary$.subscribe(summary => {
      this.walletSummary = summary;
      this.autoPay = Number(this.walletSummary?.wallet.isDWAutoPay) || 0;

      this.cashEligible = summary?.wallet?.cashEligible === "True";
      this.rewardEligible = summary?.wallet?.rewardEligible === "True";

      if (this.walletSummary?.wallet?.defaultPaymentMethod !== undefined) {
        console.log('Default Payment Method', this.walletSummary.wallet.defaultPaymentMethod);
      } else {
        console.log('Default Payment Method: undefined');
      }
      this.determineProductType();
      this.loading = false; // 
    });
 //  this.fetchNotifications();
  }

//   fetchNotifications(): void {
//     this.walletSummaryService.walletSummary$.subscribe(summary => {
//         if (summary && summary.wallet) {
//             this.walletID = summary.wallet.walletID;
//             this.walletRole = 'admin'; // Replace 'admin' with the actual value from API
//             if (this.walletID && this.walletRole) {
//                 this.walletNotificationService.fetchWalletNotifications(this.walletID, 'admin').subscribe(
//                     (response) => {
//                         if (response.success) {
//                             this.notifications = response.inAppNotifications;
//                             console.log('App Notification Success: ', response);
//                         } else {
//                             this.errorMessage = response.message; // Handle error message
//                             console.log('App Notification Failure');
//                         }
//                     },
//                     (error) => {
//                         console.error('Error fetching notifications:', error);
//                         this.errorMessage = 'Failed to fetch notifications'; // Handle error
//                     }
//                 );
//             } else {
//                 this.errorMessage = 'Wallet ID or Wallet Role is missing';
//             }
//         }
//     });
// }


  private loadWalletSummary(): void {
    this.loading = true; // Set loading to true when starting to load wallet summary
   const walletIdSes = this.walletId
   const walletRoleSes = this.walletRole;
    console.log("Wallet ID: ", walletIdSes);
    console.log("Wallet Role: ", walletRoleSes);
    if (walletIdSes && walletRoleSes) {
      this.walletSummaryService.fetchWalletSummary(walletIdSes, walletRoleSes).subscribe({
        next: (summary) => {
          this.walletSummary = summary;
          this.autoPay = Number(this.walletSummary?.wallet.isDWAutoPay) || 0;
    //      console.log("WalletSummary reloaded:", summary);
          this.loading = false; // Set loading to false when data is loaded
        },
        error: (error) => console.error("Error reloading WalletSummary:", error)
      });
    }
    else {
      console.log("No walletID or walletRole found");
    }
  }

  private determineProductType(): void {
    if (this.walletSummary?.wallet.cashEligible && !this.walletSummary?.wallet.rewardEligible) {
      this.productType = 'is4uupsbuild';
    } else {
      this.productType = '';
    }
  }
  
  private checkSessionAndRedirect(): void {
    
    let sessionobj:ONLStartWalletSession=new ONLStartWalletSession();
    
    if(sessionStorage.getItem('ONLWalletSession') !=undefined)
    sessionobj=JSON.parse(sessionStorage.getItem('ONLWalletSession') || '{}');
    
    if(sessionobj !=null && sessionobj !=undefined && sessionobj.callBackURL !=undefined )
    {
      const walletID = sessionStorage.getItem('WalletID');
    if (!walletID) {
      // Redirect to the ONL login page if no walletID is found
      
    window.location.href=sessionobj.callBackURL;
    sessionStorage.clear();
    }
    
  }
    
    // If walletID exists, it should allow the user to stay on the page
  }

  
  initializeWalletSession(): void {
   
    this.sessionService.fetchSessionObject(this.id).subscribe({
      next: (sessionRes) => {
    //    console.log("Session data fetched:", sessionRes);
        if(sessionRes.message.toLowerCase()=='success')
        {
        this.walletId = sessionRes.walletID;
        this.walletRole = sessionRes.walletRole;
        this.policyNumber = sessionRes.onlStartWalletSession.productSystemIdentifier1;
        this.productType = sessionRes.onlStartWalletSession.productType || '';
        this.productSystem = sessionRes.onlStartWalletSession.productSystem;
        this.storeSessionDataAndCheckRedirect(sessionRes);
        this.checkTcAcceptanceAndProceed();
        this.loadWalletSummary();
        }
        else{
          sessionStorage.setItem('WalletID', '');
          this.checkSessionAndRedirect();
        }
      },
      error: (error) => {
        console.error("Failed to fetch session object:", error);
        this.checkSessionAndRedirect();
      }
    });
  }
  

  private storeSessionDataAndCheckRedirect(sessionRes: any): void {
    sessionStorage.setItem('ONLWalletSession', JSON.stringify(sessionRes.onlStartWalletSession));
    sessionStorage.setItem('WalletID', sessionRes.walletID);
    sessionStorage.setItem('WalletRole', sessionRes.walletRole);
    sessionStorage.setItem('sessionapimessage', sessionRes.message);
    this.checkSessionAndRedirect();
  }
  
checkTcAcceptanceAndProceed(): void {
  console.log("checking tcAcceptance from home component: ");
  this.walletSummaryService.fetchWalletSummary(this.walletId, this.walletRole).subscribe({
    next: (summary) => {
      summary.wallet.tcAcceptance === 0;
   //   console.log("Wallet summary fetched:", summary);
      this.walletSummary = summary;
  //    console.log("tcAcceptance value:", summary.wallet.tcAcceptance);  // This is added to check tcAcceptance value

      // Assuming tcAcceptance could be a string, explicitly convert to number if necessary
      const tcAcceptance = Number(summary.wallet.tcAcceptance);
 //     console.log("Converted tcAcceptance to number:", tcAcceptance);

      if (tcAcceptance === 0) {
        console.log("tcAcceptance is 0, should show modal");
        this.showTermsModal();
      } else {
        console.log("tcAcceptance is not 0, should load data");
        this.loadData(this.walletId, this.walletRole);
        sessionStorage.setItem('walletDetails', JSON.stringify(summary?.wallet))
      }
    },
    error: (error) => {
      console.error("Error fetching wallet summary:", error);
    }
  });
}

  
  showTermsModal(): void {
    console.log("Attempting to open modal");
    const dialogRef = this.dialog.open(ModalTncComponent, {
      width: '800px',
      height: '700px',
      disableClose: true,
      data: { policyNumber: this.policyNumber,
        walletId: this.walletId,
        productType: this.productType,
        productSystem: this.productSystem,
        checkTcAcceptanceAndProceed: this.checkTcAcceptanceAndProceed.bind(this)
    }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log("Modal closed with result:", result);
      if (result === 'accept') {
    //    console.log("accept clicked");

        const walletSetupData = {
          policyNumber: this.policyNumber,
          walletId: this.walletId,
          productType: this.productType,
          productSystem: this.productSystem
        };

        this.checkTcAcceptanceAndProceed();
      } else {
        this.router.navigate(['logged-out']);
      }
    });
  }
  

  initialCheckAndHandleModal(): void {
    this.sessionService.fetchSessionObject(this.id).subscribe({
      next: (sessionRes) => {
        this.walletId = sessionRes.walletID;
        this.walletRole = sessionRes.walletRole;
        this.walletSummaryService.fetchWalletSummary(this.walletId, this.walletRole).subscribe({
          next: (summary) => {
            if (summary.wallet.tcAcceptance === 0 && !sessionStorage.getItem('hasAcceptedTnc')) {
              this.showTermsModal();
            } else {
              this.loadData(this.walletId, this.walletRole);
            }
          },
          error: (error) => console.error('Error fetching wallet summary:', error)
        });
      },
      error: (error) => console.error('Failed to fetch session object:', error)
    });
  }
  
  checkTermsAndLoadData(): void {
    const hasAcceptedTnc = sessionStorage.getItem('hasAcceptedTnc');
    if (hasAcceptedTnc || this.walletSummary?.wallet.tcAcceptance === 1) {
      this.loadData(this.walletId, this.walletRole);
    } else {
      this.showTermsModal();
    }
  }
  

  ngAfterViewInit(): void {
    // This lifecycle hook can remain empty if there's no specific code to run after view initialization
  }

  loadData(walletID: string, walletRole: string): void {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      if (this.id) {
        this.sessionService.fetchSessionObject(this.id).subscribe({
          next: (res) => {
            this.walletId = res.walletID;
            this.walletRole = res.walletRole;
  
            if (this.walletId && this.walletRole) {
              this.walletSummaryService.fetchWalletSummary(this.walletId, this.walletRole).subscribe({
                next: (summary) => {
                  this.walletSummary = summary; // Ensure this is set to update the view
                  if (summary && summary.wallet) {
                    this.totalCashBalance = summary.wallet.cash ? summary.wallet.cash.totalCashBalance : 'N/A';
                    this.totalRewardBalance = summary.wallet.rewards ? summary.wallet.rewards.totalRewardBalance : 'N/A';
                    this.totalRewardValue = summary.wallet.rewards ? summary.wallet.rewards.totalRewardValue : 'N/A';
                  }
                },
                error: (error) => console.error('Error fetching wallet summary:', error),
              });
  
              this.fetchTransactionHistory();
            }
          },
          error: (error) => console.error('Failed to fetch session object', error)
        });
      }
    });
  }
  

  fetchTransactionHistory(): void {
    this.transactionHistoryService.fetchTransactionHistory(this.walletId, this.walletRole, 'all').subscribe({
      next: (data) => {
        if (data && data.walletTransactionsList && data.walletTransactionsList.payments) {
          this.claimPayments = data.walletTransactionsList.payments.claimPayments;
        }
      },
      error: (error) => console.error('Error fetching transaction history:', error)
    });
  }

  initializeSessionAndCheckTnc(): void {
    this.sessionService.fetchSessionObject(this.id).subscribe({
      next: (sessionRes) => {
        this.walletSummaryService.fetchWalletSummary(sessionRes.walletID, sessionRes.walletRole).subscribe({
          next: (summary) => {
            // Directly using tcAcceptance as it is already a number
            const tcAcceptance = summary.wallet.tcAcceptance;
  
            if (tcAcceptance === 1) {
              // If tcAcceptance is exactly 1, bypass the modal and load data
              this.loadData(sessionRes.walletID, sessionRes.walletRole);
            } else {
              // If tcAcceptance is not 1, show the modal
              this.showTermsModal();
            }
          },
          error: (error) => console.error('Error fetching wallet summary:', error),
        });
      },
      error: (error) => console.error('Failed to fetch session object:', error)
    });
  }

  
  
  clearSession() {
    sessionStorage.clear();
    localStorage.clear();
  }

  onSlide(event: NgbSlideEvent): void {
    this.activeSlideIndex = parseInt(event.current, 10);
  }

  navigateToSlide(slideIndex: number): void {
    this.carousel.select(slideIndex.toString());
    this.activeSlideIndex = slideIndex;
  }

  goToRedeem(): void {
    this.buttonClick.goToRedeem();
  }

  goToTransferBalance(): void {
    this.buttonClick.goToTransferBalance();
  }
}



