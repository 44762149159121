import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-insure-shield-tnc-dialog',
  templateUrl: './insure-shield-tnc-dialog.component.html',
  styleUrls: ['./insure-shield-tnc-dialog.component.scss']
})
export class InsureShieldTncDialogComponent {

  isExpanded = false;

  constructor(public activeModal:NgbActiveModal){}

  close() {
    this.activeModal.dismiss('Cross click');
 }

 toggleExpand(event: Event) {
  event.preventDefault(); // Prevent the default link behavior
  this.isExpanded = !this.isExpanded;
}
}
