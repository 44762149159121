<div class="loading-container" *ngIf="loading">
  <div class="loading">
    <div class="spinner-border" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
</div>
<div class="content-container" *ngIf="!loading">
  <div class="header-container">
    <div class="header-content">
      <h2 class="headline-highlight">Auto Redeem & Pay</h2>
      <div class="mat-toggle" *ngIf="!disableButton">
        <mat-slide-toggle [(ngModel)]="autopayToggle" (change)="onToggleChange($event)">
          {{ toggleText }}
        </mat-slide-toggle>
      </div>
    </div>
  </div>
  <div class="mt-3 success-box" *ngIf="successMessage">
    <app-notification-box [isSuccess2]="true">
      <div>Autopay preferences were saved successfully!</div>
    </app-notification-box>
  </div>
  <div class="box-border">
    <h4>Select Payment Method</h4>
    <p>Please select the payment methods you would like to use with Auto Pay. If you elect to use
      your Rewards and Cash,
      please sort your selections in the order you would like them to be used.
    </p>
    <!-- Drag and drop items -->
    <div cdkDropList class="drag-and-drop-items" (cdkDropListDropped)="drop($event)">
      <div *ngFor="let item of autopayOptions" class="item" cdkDrag [cdkDragDisabled]="item.disabled"
        [ngClass]="{disable_animation : item.disabled}">
        <div class="select-pmt">
          <div class="left-content">
            <input type="checkbox" (change)="onChange($event, item)" [checked]="item.checked"
              [ngClass]="{unclickable : (autopayToggle && item.key == 'default')}">
            <img src="assets/icons/star_rate.svg" *ngIf="item.key == 'rewards'">
            <div class="content" *ngIf="item.key == 'rewards'">
              <span class="title">My Rewards</span>
              <span class="detail">After all reward points have been used, the remaining balance will be charged using
                the stored default payment method.</span>
            </div>
            <img src="assets/icons/monetization_on.svg" *ngIf="item.key == 'cash'">
            <div class="content" *ngIf="item.key == 'cash'">
              <span class="title">My Cash</span>
              <span class="detail">After all cash has been used, the remaining balance will be charged using the stored
                default payment method.</span>
            </div>
            <img src="assets/icons/account_balance.png"
              *ngIf="item.key == 'default' && (cardType == 'EC' || cardType == 'ECP')">
            <img src="assets/images/card/chkt_visa.svg" *ngIf="item.key == 'default' && cardType == 'Visa'">
            <img src="assets/images/card/chkt_mastercard.svg" *ngIf="item.key == 'default' && cardType == 'Mastercard'">
            <img src="assets/images/card/chkt_amex.svg" *ngIf="item.key == 'default' && cardType == 'Amex'">
            <div class="content" *ngIf="item.key == 'default'">
              <span class="title">{{ cardType }}</span>
              <span class="digits" *ngIf="cardType == 'EC' || cardType == 'ECP'">Bank •••• {{ lastFourDigits }}<span class="default">Default</span></span>
              <span class="digits" *ngIf="cardType == 'Visa' || cardType == 'Mastercard' || cardType == 'Amex'">•••• {{
                lastFourDigits }} • <span [ngClass]="{expired : isExpired || isWarning}">{{ ccExpiration?.substring(4,6) }}/{{ ccExpiration?.substring(0,4) }}
                <span class="default">Default</span>
              </span>
              </span>
              <span class="default" *ngIf="!cardType">Default</span> <!-- Just in case somehow cardType comes in blank...show them something at least -->
            </div>
          </div>
          <div><a (click)="gotoSetDefaultPayment()" class="change-link" *ngIf="item.key == 'default'">Change</a></div>
          <img src="assets/images/drag_dots.png" *ngIf="item.key == 'rewards' || item.key == 'cash'">
        </div>
      </div>
    </div>
  </div>
  <!-- confirm update button -->
  <div class="button-container mt-3">
    <button (click)="openAutoRedeemDialog()" *ngIf="!buttonLoading" [disabled]="disableButton" class="dw-primary-button icon-right">
      Confirm Updates<mat-icon class="black">keyboard_arrow_right</mat-icon>   
    </button>
    <button class="dw-primary-button icon-right" disabled *ngIf="buttonLoading">Confirm Update<span class="spinner-border"></span></button>
    
  </div>
  <div class="mt-2 error-notification-section" *ngIf="isExpired || isWarning">
    <app-notification-box [isDanger]="true">
      <div *ngIf="isExpired">The default payment method selected has expired. To continue, please update your default
        payment method.</div>
      <div *ngIf="isWarning">The default payment method selected is close to expiring. To continue, please update your
        default payment method.</div>
    </app-notification-box>
  </div>
  <div class="mt-2 error" *ngIf="systemError">Something went wrong! Please try again later.</div>
  <div class="mt-2 error" *ngIf="defaultError">To proceed with setting up Auto Redeem & Pay, please ensure that you
    have set your default payment method under Manage Payment Methods.</div>
  <div class="mt-2 error" *ngIf="is4uError">This account is not eligible for Auto Redeem & Pay through digital wallet.
  </div>
</div>