import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { HomeComponent } from './modules/home/home.component';
import { MyWalletComponent } from './modules/my-wallet/my-wallet.component';
import { PreferencesComponent } from './modules/preferences/preferences.component';
import { ConnectedSolutionsComponent } from './modules/connected-solutions/connected-solutions.component';
import { MyPaymentComponent } from './modules/my-payment/my-payment.component';
import { MyWalletDetailsComponent } from './modules/my-wallet/my-wallet-details/my-wallet-details.component';
import { AllRewardsComponent } from './modules/my-rewards/all-rewards/all-rewards.component';
import { MyAccountComponent } from './modules/my-account/my-account.component';
import { DefaultPaymentMethodComponent } from './shared/components/default-payment-method/default-payment-method.component';
import { DefaultPaymentComponent } from './modules/preferences/default-payment/default-payment.component';
import { AutoRedeemComponent } from './modules/preferences/auto-redeem/auto-redeem.component';
import { AllPaymentsComponent } from './modules/my-payment/all-payments/all-payments.component';
import { RedeemComponent } from './modules/my-rewards/redeem/redeem.component';
import { WaysToEarnComponent } from './modules/my-rewards/ways-to-earn/ways-to-earn.component';
import { ContactUsComponent } from './modules/contact-us/contact-us.component';
import { OutstandingInvoiceComponent } from './modules/my-rewards/redeem/outstanding-invoice/outstanding-invoice.component';
import { PaymentConfirmationComponent } from './modules/my-rewards/redeem/payment-confirmation/payment-confirmation.component';
import { AllCashComponent } from './modules/my-cash/all-cash/all-cash.component';
import { TransferBalanceComponent } from './modules/my-cash/transfer-balance/transfer-balance.component';
import { TransferRequestedComponent } from './modules/my-cash/transfer-requested/transfer-requested.component';
import { ReviewSummaryComponent } from './modules/my-cash/review-summary/review-summary.component';
import { ManagePaymentComponent } from './modules/preferences/manage-payment/manage-payment.component';
import { LoggedOutComponent } from './shared/components/logged-out/logged-out.component';
import { LoggedOutTncComponent } from './shared/components/logged-out-tnc/logged-out-tnc.component';
import { PaymentTokensComponent } from './modules/payment-tokens/payment-tokens.component';
import { BlankLayoutComponent } from './shared/components/blank-layout/blank-layout.component';
import { AuthGuard } from './auth.guard';
import { UnauthorizedComponent } from './modules/unauthorized/unauthorized.component';


const routes: Routes = 

[
  { path: '', redirectTo: '/home',pathMatch:'full'},
  { path: 'home/:id', component: HomeComponent, canActivate: [AuthGuard]}, 
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
  { path: 'my-wallet', component: MyWalletComponent, canActivate: [AuthGuard],
      children: [
        { path: 'my-wallet-details', component:MyWalletDetailsComponent, canActivate: [AuthGuard]},
        { path: 'all-rewards', component:AllRewardsComponent, canActivate: [AuthGuard]}, 
        { path: 'all-rewards/redeem', component:RedeemComponent,
              children: [
                { path: '', redirectTo: 'outstanding-invoice', pathMatch: 'full'},
                {path: 'outstanding-invoice', component:OutstandingInvoiceComponent, canActivate: [AuthGuard]},
                {path: 'payment-confirmation', component:PaymentConfirmationComponent, canActivate: [AuthGuard]}      
              ]
        },
        { path: 'all-rewards/ways-to-earn', component:WaysToEarnComponent, canActivate: [AuthGuard]},
        { path: 'my-cash/all-cash', component:AllCashComponent, canActivate: [AuthGuard]},
        { path: 'my-cash/transfer-balance', component:TransferBalanceComponent, canActivate: [AuthGuard]},
        { path: 'my-cash/review-summary', component:ReviewSummaryComponent, canActivate: [AuthGuard]},
        { path: 'my-cash/transfer-requested', component:TransferRequestedComponent, canActivate: [AuthGuard]}, 
        {path: 'all-payments', component:AllPaymentsComponent, canActivate: [AuthGuard]}
        ]},
  { path: 'connected-solution', component:ConnectedSolutionsComponent, canActivate: [AuthGuard]},
  { path: 'my-payment', component:MyPaymentComponent, canActivate: [AuthGuard]},
  { path: 'my-account', component:MyAccountComponent, canActivate: [AuthGuard]},
  { path: 'preferences', component:PreferencesComponent, canActivate: [AuthGuard], 
      children: [
        { path: '', component:ManagePaymentComponent, canActivate: [AuthGuard]},
        { path: 'default-payment', component:DefaultPaymentComponent, canActivate: [AuthGuard] },
        { path: 'manage-payment', component:ManagePaymentComponent, canActivate: [AuthGuard]},
        { path: 'auto-redeem', component:AutoRedeemComponent, canActivate: [AuthGuard]},
      ]},
  { path: 'contact-us', component:ContactUsComponent, canActivate: [AuthGuard]},
  {
    path: 'logged-out',
    component: BlankLayoutComponent,
    children: [
      {
        path: '',
        component: LoggedOutComponent
      }
    ]
  },
  { path: 'default-payment-method', component:DefaultPaymentMethodComponent, canActivate: [AuthGuard]},
  { path: 'payment-tokens', component:PaymentTokensComponent, canActivate: [AuthGuard]},
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'logged-out-tnc', component:LoggedOutTncComponent}
];


@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top'  // ensures scroll position starts at the top for all navigations
    })
  ],
  exports: [RouterModule]
     
})

export class AppRoutingModule { }
