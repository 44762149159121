<div class="section how-it-works" [ngClass]="{'disabled': disabled}">
    <div class="sub-container">
   
        <h2 *ngIf="showHeader">How Does It Work?</h2>
   
            <div class="section-container">
                <ng-container *ngFor="let item of sectionItems; let last = last">
                    <div class="section-item">
                        <div class="icon">
                            <img [src]="item.iconUrl">
                        </div>
                        <div class="title">{{ item.title }}</div>
                        <p>{{ item.description }}</p>
                    </div>
                    <!-- Divider with *ngIf directive to control its presence -->
                    <hr *ngIf="showDivider && !last" />
                </ng-container>
            </div>


    <div (click)="learnMore()" *ngIf="showLearnMoreButton" class="button-container">
        <div class="btn">
            <button [ngClass]="{'disabled': disabled}" mat-raised-button class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button primary">
                <span class="mdc-button__label">
                    <span class="button-label">
                        <span>Learn More</span>
                        <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>   
                    </span>
                </span>
            </button>
        </div>
    </div>
    </div>
    <div class="lock-icon" *ngIf="disabled">
        <svg xmlns="http://www.w3.org/2000/svg" width="76" height="76" viewBox="0 0 76 76" fill="none">
          <g opacity="0.9">
            <circle cx="38" cy="38" r="38" fill="#E9E9E9" fill-opacity="0.95"/>
            <mask id="mask0_10988_85173" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="14" y="14" width="48" height="48">
              <rect x="14" y="14" width="48" height="48" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_10988_85173)">
              <path d="M26 58.001C24.9 58.001 23.9583 57.6093 23.175 56.826C22.3917 56.0426 22 55.101 22 54.001V34.001C22 32.901 22.3917 31.9593 23.175 31.176C23.9583 30.3926 24.9 30.001 26 30.001H28V26.001C28 23.2343 28.975 20.876 30.925 18.926C32.875 16.976 35.2333 16.001 38 16.001C40.7667 16.001 43.125 16.976 45.075 18.926C47.025 20.876 48 23.2343 48 26.001V30.001H50C51.1 30.001 52.0417 30.3926 52.825 31.176C53.6083 31.9593 54 32.901 54 34.001V54.001C54 55.101 53.6083 56.0426 52.825 56.826C52.0417 57.6093 51.1 58.001 50 58.001H26ZM26 54.001H50V34.001H26V54.001ZM38 48.001C39.1 48.001 40.0417 47.6093 40.825 46.826C41.6083 46.0426 42 45.101 42 44.001C42 42.901 41.6083 41.9593 40.825 41.176C40.0417 40.3926 39.1 40.001 38 40.001C36.9 40.001 35.9583 40.3926 35.175 41.176C34.3917 41.9593 34 42.901 34 44.001C34 45.101 34.3917 46.0426 35.175 46.826C35.9583 47.6093 36.9 48.001 38 48.001ZM32 30.001H44V26.001C44 24.3343 43.4167 22.9176 42.25 21.751C41.0833 20.5843 39.6667 20.001 38 20.001C36.3333 20.001 34.9167 20.5843 33.75 21.751C32.5833 22.9176 32 24.3343 32 26.001V30.001Z" fill="#8C857E"/>
            </g>
          </g>
        </svg>
      </div>
</div>



