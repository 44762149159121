<div class="settings-wrapper">
  <div class="sidebar-wrapper">
    <div class="sidebar-options">
      <div class="subtab-option" [class.active]="myAccountActive" [routerLink]="['/my-account']">
        <img src="../../../../assets/images/customers_major.png" class="subtab-icon">
        <span>My Account</span>
      </div>
      <div class="subtab-dropdown" [class.active]="preferencesActive">
        <div [routerLink]="['/preferences']">
          <img src="../../../../assets/images/settings.png" class="subtab-icon">
          <span>Preferences</span>
        </div>
        <div class="dropdown-wrapper">
          <div class="dropdown-content">
            <div class="dropdown-option" [routerLink]="['/preferences/manage-payment']">
              <div class="vertical-yellow-bar" [class.active]="managePaymentActive"></div>
              <div>Manage Payment Methods</div>
            </div>
            <div class="dropdown-option" [routerLink]="['/preferences/auto-redeem']">
              <div class="vertical-yellow-bar" [class.active]="autoRedeemActive"></div>
              <div>Auto Redeem & Pay</div>
            </div>
          </div>
        </div>
      </div>
      <div class="subtab-option" [class.active]="contactUsActive" [routerLink]="['/contact-us']">
        <img src="../../../../assets/images/contact_support.png" class="subtab-icon">
        <span>Contact Us</span>
      </div>
    </div>
  </div>
  <div class="main-content-wrapper">
    <router-outlet></router-outlet>
  </div>
</div>