<div class="popup-global-layout width-800">
  <div class="payment-summary-box">
    <div class="title">Payment Summary</div>
    <div class="subtitle">Connected Solution</div>
    <hr>
    <div class="solution-text">InsureShield<sup>®</sup> Shipping Insurance</div>
    <div class="subtitle">Selected Invoice</div>
    <hr>
    <div class="invoices-list" *ngIf="selectedInvoice">
      <div>INV {{ selectedInvoice?.invoiceNumber }}</div>
      <div>|</div>
      <div>Due {{ selectedInvoice?.dueDate | date:'MM/dd/yyyy' }}</div>
      <div>|</div>
      <div>{{ selectedInvoice?.dueAmount | currency: 'USD' }}</div>
    </div>
    <div class="invoices-list" *ngIf="!selectedInvoice">
      No invoice selected.
    </div>
    <div class="subtitle">Payment Method</div>
    <hr>
    <div class="methods" *ngIf="paymentSummaryValues?.cash?.input">
      <div class="method-text">
        <img src="../../../../../assets/icons/monetization_on.svg" class="icon">
        <div>My Cash</div>
      </div>
      <div class="method-balance">-{{ paymentSummaryValues?.cash?.input | currency: 'USD' }}</div>
    </div>
    <div class="methods" *ngIf="paymentSummaryValues?.rewards?.input">
      <div class="method-text">
        <img src="../../../../../assets/icons/star_rate.svg" class="icon">
        <div>My Rewards</div>
      </div>
      <div class="method-balance">-{{ paymentSummaryValues?.rewards?.input | currency: 'USD' }} (- {{
        paymentSummaryValues?.rewards?.inputInPoints | number }} pts)</div>
    </div>
    <div class="methods" *ngIf="paymentSummaryValues?.default?.input">
      <div class="method-text">
        <img src="../../../../../assets/icons/account_balance.png" class="icon" *ngIf="currentDefaultPaymentMethod?.cardType == 'ECP' || currentDefaultPaymentMethod?.cardType == 'EC'">
        <img src="../../../../../assets/images/card/chkt_visa.svg" class="icon" *ngIf="currentDefaultPaymentMethod?.cardType == 'Visa'">
        <img src="../../../../../assets/images/card/chkt_mastercard.svg" class="icon" *ngIf="currentDefaultPaymentMethod?.cardType == 'Mastercard'">
        <img src="../../../../../assets/images/card/chkt_amex.svg" class="icon" *ngIf="currentDefaultPaymentMethod?.cardType == 'Amex'">
        <div *ngIf="currentDefaultPaymentMethod?.cardType == 'ECP' || currentDefaultPaymentMethod?.cardType == 'EC'">ACH Payment</div>
        <div *ngIf="!(currentDefaultPaymentMethod?.cardType == 'ECP' || currentDefaultPaymentMethod?.cardType == 'EC')">{{ currentDefaultPaymentMethod?.cardType }} Payment</div> <!-- should be 'Visa', 'Mastercard', or 'Amex'-->
      </div>
      <div class="method-balance">-{{ paymentSummaryValues?.default?.input | currency: 'USD' }}</div>
    </div>
    <div class="methods"
      *ngIf="paymentSummaryValues?.total?.remainingDue || paymentSummaryValues?.total?.remainingDue == 0">
      <div class="text">Remaining Due</div>
      <div>{{ paymentSummaryValues?.total?.remainingDue | currency: 'USD' }}</div>
    </div>
    <hr>
    <div class="earn">
      <div class="earn-type" *ngIf="paymentSummaryValues?.cash?.input">
        <div class="earn-text">* Earn Points for My Cash Payment</div>
        <div class="earn-amount">+ {{ cashPointsEarned | number: '1.0-0' }} PTS</div>
      </div>
      <div class="earn-type" *ngIf="paymentSummaryValues?.default?.input && (currentDefaultPaymentMethod?.cardType == 'ECP' || currentDefaultPaymentMethod?.cardType == 'EC')">
        <div class="earn-text">* Earn Points for ACH Payment</div>
        <div class="earn-amount">+ {{ achPointsEarned | number: '1.0-0' }} PTS</div>
      </div>
      <div class="earn-type" *ngIf="selectedInvoice?.earlyBillPayMessage">
        <div class="earn-text">* Earn Points for Early Bill Payment</div>
        <div class="earn-amount">+ {{ earlyPointsEarned | number: '1.0-0' }} PTS</div>
      </div>

    </div>
  </div>
  <div class="global-button-format">
    <button class="dw-secondary-button icon-left" (click)="close()"><mat-icon class="blue">keyboard_arrow_left</mat-icon>Back</button>
    <button class="dw-primary-button icon-right" (click)="submit()" *ngIf="!loadingButton">Submit<mat-icon class="black">keyboard_arrow_right</mat-icon></button>
    <button class="dw-primary-button icon-right" disabled *ngIf="loadingButton">Submit<span class="spinner-border"></span></button>
  </div>
  <div class="error" *ngIf="systemError">Something went wrong! Please try again later.</div>
</div>