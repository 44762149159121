
<div class="review-summary">
    <h2 class="headline-highlight">Review Summary</h2>
    <p class="title">Transfer to</p>
    <hr>

    <div class="transfer-detail">
        <img src="{{transferCarrier?.bankDetails?.imgSrc}}">
        <div class="info">
            <span class="bank-info">{{transferCarrier?.bankDetails?.bankName}}</span>
            <span class="account-info">{{transferCarrier?.bankDetails?.accountDetails}}</span>
        </div>
    </div>

    <p class="title">Transfer Method</p>

    <hr>

    <p *ngIf="transferCarrier.accountType=='RTP'">Instant</p>
    <p *ngIf="transferCarrier.accountType!='RTP'" class="regular-pay">
        <span class="left"> Regular (1-3 business day)</span>
        <span >Estimated by {{estimatedDate}}</span>
    </p>
    <br>
    <p class="inv-info">Total Transfer Balance</p>
    <hr>
  
    <div class="transfer-balance">
        <p>
            <span class="left">Transfer Amount</span>
            <span class="right">{{transferCarrier?.transferAmount}}</span>
        </p>
        <p *ngIf="transferCarrier.accountType=='RTP'">
            <span class="left">Instant Transfer Fee</span>
            <span class="right red">-{{transferCarrier?.charges}}</span>
        </p>
        <p >
            <span class="left">Total Transfer Amount</span>
            <span class="right total">{{transferCarrier?.totalTransferAmount}}</span>
        </p>
    </div>
    


    <form >
        <div class="button-container">
            <button class="dw-secondary-button icon-left" (click)="returnToBack()"><mat-icon>keyboard_arrow_left</mat-icon>Back</button>
            <button class="dw-primary-button icon-right" (click)="submitTransfer()" *ngIf="!disablebtn">Submit<mat-icon>keyboard_arrow_right</mat-icon></button>
            <button class="dw-primary-button icon-right" disabled *ngIf="disablebtn">Submit<span class="spinner-border"></span></button>
        </div>
    </form>

</div>


