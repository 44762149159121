
<div class="sub-container">

    <div class="modal-terms">
    
    <h2 class="headline-highlight">Set Default Payment Method</h2>
    <button mat-icon-button class="close-button" aria-label="Close dialog" (click)="onDecline()">
      <mat-icon>close</mat-icon>
    </button>

  <div mat-dialog-content class="dialog-content">
    <!-- Your terms and conditions content here -->
    <p>To proceed with redeem and pay your invoice,please ensure that you have set your default payment method.</p>
    </div>

  <div mat-dialog-actions>

    <div class="button-container">
        <div class="btn">
            <button mat-button (click)="onDecline()" mat-raised-button class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button secondary">
                <span class="mdc-button__label">
                    <span class="button-label">
                        <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_left</mat-icon>                           
                        <span>
                           Cancel
                        </span>
                    </span>
                </span>
            </button>
        </div>
        <div class="btn">
            <button (click)="onAccept()"  type="submit" mat-raised-button class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button primary">
                <span class="mdc-button__label">
                    <span class="button-label">
                        <span>Set My Default Payment</span>
                        <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                    </span>
                </span>
            </button>
        </div>
    </div>

    <!-- <button mat-button (click)="onAccept()" [disabled]="!canAccept()">Accept</button>
    <button mat-button (click)="onDecline()">Decline</button> -->
  </div>

</div>
</div>
