import { Component,OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { WalletSummaryService } from '../../services/api/wallet-summary.service';
import { WalletSummary } from '../../models/wallet-summary.interface';
import { TransactionHistoryService } from '../../services/api/transaction-history.service';
import { MatButton } from '@angular/material/button';
import { SelectionModel } from '@angular/cdk/collections';
import { RewardCommon } from '../../models/RewardCommon';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin } from 'rxjs';


@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss']
})

export class TransactionHistoryComponent implements OnInit{


  selectedTab: string = 'all';
  walletEarningData: any[] = [];
  walletSpendingData: any[] = [];


  transactionType = 'all'; // 'all', 'rewards', 'cash', 'payments'
  walletID: string = '';
  walletRole: string = '';
  cashEligible = false;
  rewardEligible = false;

  rewardType: string = 'earned';
 // transactionType: string = 'all';

  walletSummary: WalletSummary | null = null; 

  constructor(
    public router:Router,
    private walletSummaryService: WalletSummaryService,
    private transactionHistoryService: TransactionHistoryService
    ){}

    ngOnInit(): void {
      this.walletSummaryService.walletSummary$.subscribe(summary => {
        if (summary && summary.wallet) {
          this.walletID = summary.wallet.walletID;
          this.walletRole = 'admin'; // Replace 'admin' with the actual logic to determine walletRole
          this.walletSummary = summary;
          this.fetchTransactionData();
          this.cashEligible = summary.wallet.cashEligible === "True";
          this.rewardEligible = summary.wallet.rewardEligible === "True";
        }
      });
    }

    selectTab(tab: string): void {
      this.selectedTab = tab;
      this.fetchTransactionData();
    }

    fetchTransactionData(): void {
      this.transactionHistoryService.fetchTransactionHistory(this.walletID, this.walletRole).subscribe(
        (data) => {
          this.processData(data.walletTransactionsList);
          console.log('Transaction History Data:', data)
        },
        (error) => {
          console.error('Error fetching transaction data:', error);
        }
      );
    }

    processData(walletTransactionsList: any): void {
      this.walletEarningData = [];
      this.walletSpendingData = [];

      if(this.cashEligible && !this.rewardEligible){ 
        if (this.selectedTab === 'all' || this.selectedTab === 'cash') {
          this.processCashData(walletTransactionsList.cash);
        }
      } else {
      if (this.selectedTab === 'all' || this.selectedTab === 'rewards') {
        this.processRewardsData(walletTransactionsList.rewards);
      }
  
      if (this.selectedTab === 'all' || this.selectedTab === 'cash') {
        this.processCashData(walletTransactionsList.cash);
      }
  
      if (this.selectedTab === 'all' || this.selectedTab === 'payments') {
        this.processPaymentsData(walletTransactionsList.payments);
      }
    }
      // Sort the data by date
      this.walletEarningData.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
      this.walletSpendingData.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    }

    processRewardsData(rewardsData: any): void {
      this.walletEarningData.push(
        ...rewardsData.rewardsEarn.map((item: any) => ({
          productName: item.productName,
          date: item.earnDate,
          amount: item.pointsEarned,
          transactionID: item.transactionID,
          earnIdentifier: item.earnIdentifier,
          earnIdentifierDescription: item.earnIdentifierDescription,
          earnCategoryDescription: item.earnCategoryDescription,
          type: 'points'
        }))
      );
    
      this.walletSpendingData.push(
        ...rewardsData.rewardsSpends.map((item: any) => ({
          productName: item.productName,
          date: item.spendDate,
          amount: item.pointSpend,
          transactionID: item.transactionID,
          spendIdentifier: item.spendIdentifier,
          spendIdentifierDescription: item.spendIdentifierDescription,
          spendCategoryDescription: item.spendCategoryDescription,
          paymentIdentifierDescription: item.paymentIdentifierDescription,
          type: 'points'
        }))
      );
    }
  
    processCashData(cashData: any): void {
      this.walletEarningData.push(
        ...cashData.cashEarn.map((item: any) => ({
          productName: item.productName,
          date: item.earnDate,
          amount: item.cashEarned,
          transactionID: item.transactionID,
          earnIdentifier: item.earnIdentifier,
          earnIdentifierDescription: item.earnIdentifierDescription,
          earnCategoryDescription: item.earnCategoryDescription, 
          type: 'cash'  
        }))
      );
  
      this.walletSpendingData.push(
        ...cashData.cashSpend.map((item: any) => ({
          productName: item.productName,
          date: item.spendDate,
          amount: item.spendAmount,
          transactionID: item.transactionID,
          spendAccountAlias:item.spendAccountAlias,
          spendIdentifier: item.spendIdentifier,
          spendAccountBankName: this.formatBankName(item.spendAccountBankName),
          spendIdentifierDescription: item.spendIdentifierDescription,
          spendCategoryDescription: item.spendCategoryDescription,
          spendAccountRedacted: item.spendAccountRedacted,
          spendAccountRedactedDescription: item.spendAccountRedactedDescription,
          modeOfTransfer: item.modeOfTransfer === 'RTP' ? ' - Instant' : '', 
          type: 'cash'  
        }))
      );
    }
  
    processPaymentsData(paymentsData: any): void {
      this.walletSpendingData.push(
        ...paymentsData.invoicePayments.map((item: any) => ({
          productName: item.productName,
          date: item.paymentDate,
          amount: item.paymentTotal,
          transactionID: item.transactionID,
          spendIdentifier: item.paymentIdentifier,
          spendIdentifierDescription: item.paymentIdentifierDescription,
          spendCategoryDescription: item.spendCategoryDescription,
          paymentIdentifierDescription: item.paymentIdentifierDescription,
          type: 'payment' // New type for payments
        }))
      );
    }
    

    getRouterLink(type?: string): string[]{
      if (this.cashEligible && !this.rewardEligible) {
        return ['/my-wallet', 'my-cash', 'all-cash'];
      }

      if (type == 'redeemed') {
        sessionStorage.setItem('navigationSource', 'redeemed'); // When navigating to the table, they should have the spending radio button enabled instead and we can use this to track.
      }

      switch(this.selectedTab){
        case 'rewards':
          return [ '/my-wallet', 'all-rewards'];
        case 'cash':
          return ['/my-wallet', 'my-cash', 'all-cash'];
        case 'payments':
          return [ '/my-wallet', 'all-payments'];
          default:
            return ['/my-wallet', 'my-wallet-details'];
      }
    }

    private formatBankName(bankName: string): string {
      if (bankName.startsWith('JPMORGAN CHASE BANK, NA')) {
        return 'CHASE BANK, NA';
      }
      return bankName;
    }
    

  selectButton(selectedButton: MatButton) {
    const buttons = document.querySelectorAll('.button') as NodeListOf<HTMLButtonElement>;
    buttons.forEach((button: HTMLButtonElement) => {
      button.classList.remove('selected');
    });
    selectedButton._elementRef.nativeElement.classList.add('selected');
  }

  getTransactionType(item: any): string {
    let type = '';
    if (item.pointsEarned !== undefined || item.pointSpend !== undefined) {
      type = 'rewards';
    } else if (item.cashEarned !== undefined || item.spendAmount !== undefined) {
      type = 'cash';
    }
    console.log(`Transaction type for item ${item.transactionID}: ${type}`); // Debugging output
    return type;
  }

  isDefaultCase(item: any): boolean {
    return !(
      item.earnCategoryDescription === 'CashEarnClaimsPaid' ||
      item.earnCategoryDescription === 'CashSpendTransferReversal'  ||
      item.spendCategoryDescription === 'CashSpendInvoice' ||
      item.spendCategoryDescription === 'CashSpendTransfer' ||
      item.spendCategoryDescription === 'RewardsSpendInvoice' ||
      item.paymentIdentifierDescription === 'InvoiceNo' ||
      item.earnCategoryDescription === 'Cash Bill Pay' ||
      item.earnCategoryDescription === 'Early Bill Pay' ||
      item.earnCategoryDescription === 'ACH Bill Pay'
    );
  }
  
  

 
}
