import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HomeComponent } from './modules/home/home.component';
import { MyWalletComponent } from './modules/my-wallet/my-wallet.component';
import { MyWalletDetailsComponent } from './modules/my-wallet/my-wallet-details/my-wallet-details.component';
import { MyRewardsComponent } from './modules/my-rewards/my-rewards.component';
import { MyCashComponent } from './modules/my-cash/my-cash.component';
import { AppRoutingModule } from './app-routing.module';
import { PreferencesComponent } from './modules/preferences/preferences.component';
import { MyAccountComponent } from './modules/my-account/my-account.component';
import { MyPaymentComponent } from './modules/my-payment/my-payment.component';
import { ConnectedSolutionsComponent } from './modules/connected-solutions/connected-solutions.component';
import { ConnectedProductsComponent } from './modules/connected-products/connected-products.component';
import { MatIconModule} from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatRadioModule} from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule} from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule} from '@angular/material/grid-list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { HowItWorksComponent } from './shared/components/how-it-works/how-it-works.component';
import { RedeemCardComponent } from './shared/components/redeem-card/redeem-card.component';
import { TransactionHistoryComponent } from './shared/components/transaction-history/transaction-history.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AllRewardsComponent } from './modules/my-rewards/all-rewards/all-rewards.component';
import { RedeemComponent } from './modules/my-rewards/redeem/redeem.component';
import { WaysToEarnComponent } from './modules/my-rewards/ways-to-earn/ways-to-earn.component';
import { AllCashComponent } from './modules/my-cash/all-cash/all-cash.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalContentComponent } from './shared/components/modal-content/modal-content.component';
import { DefaultPaymentMethodComponent } from './shared/components/default-payment-method/default-payment-method.component';
import { DefaultPaymentComponent } from './modules/preferences/default-payment/default-payment.component';
import { AutoRedeemComponent } from './modules/preferences/auto-redeem/auto-redeem.component';
import { NotificationsComponent } from './modules/preferences/notifications/notifications.component';
import { AllPaymentsComponent } from './modules/my-payment/all-payments/all-payments.component';
import { ContactUsComponent } from './modules/contact-us/contact-us.component';
import { OutstandingInvoiceComponent } from './modules/my-rewards/redeem/outstanding-invoice/outstanding-invoice.component';
import { PaymentSummaryDialogComponent } from './modules/my-rewards/redeem/payment-summary-dialog/payment-summary-dialog.component';
import { PaymentConfirmationComponent } from './modules/my-rewards/redeem/payment-confirmation/payment-confirmation.component';
import { TransferBalanceComponent } from './modules/my-cash/transfer-balance/transfer-balance.component';
import { ReviewSummaryComponent } from './modules/my-cash/review-summary/review-summary.component';
import { TransferRequestedComponent } from './modules/my-cash/transfer-requested/transfer-requested.component';
import { SlickCarouselHomeComponent } from './shared/components/slick-carousel-home/slick-carousel-home.component';
import { ManagePaymentComponent } from './modules/preferences/manage-payment/manage-payment.component';
import { TransferFlyoutComponent } from './modules/my-cash/transfer-flyout/transfer-flyout.component';
import { NavigationComponent } from './shared/components/navigation/navigation.component';
import { LayoutModule } from '@angular/cdk/layout';
import { provideHttpClient } from '@angular/common/http';
import { SessionService } from './shared/services/session.service';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ModalTncComponent } from './shared/components/modal-tnc/modal-tnc.component';
import { LoggedOutComponent } from './shared/components/logged-out/logged-out.component';
import { AutoRedeemDialogComponent } from './modules/preferences/auto-redeem-dialog/auto-redeem-dialog.component';
import { TransactionHistoryTableComponent } from './shared/components/transaction-history-table/transaction-history-table.component';
import { NameInitialsPipe } from './shared/pipes/name-initials.pipe';
import { UpdateMyaccountDialogComponent } from './modules/my-account/update-myaccount-dialog/update-myaccount-dialog.component';
import { DeliveryDefenseTncDialogComponent } from './shared/dialog/delivery-defense-tnc-dialog/delivery-defense-tnc-dialog.component';
import { InsureShieldTncDialogComponent } from './shared/dialog/insure-shield-tnc-dialog/insure-shield-tnc-dialog.component';
import { BlankLayoutComponent } from './shared/components/blank-layout/blank-layout.component';
import { PaymentTokensComponent } from './modules/payment-tokens/payment-tokens.component';
import { TransactionValuePipe } from './shared/pipes/transaction-value.pipe';
import { ModalDefaultPaymentComponent } from './shared/components/modal-default-payment/modal-default-payment.component';
import { CdkDrag, CdkDropList, CdkDropListGroup } from '@angular/cdk/drag-drop';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { DecimalPipe } from '@angular/common';
import { NotificationBoxComponent } from './shared/components/notification-box/notification-box.component';
import { NotificationBoxModule } from './shared/components/notification-box/notification-box.module';
import { PaymentMethodFlyoutComponent } from './modules/my-rewards/redeem/payment-method-flyout/payment-method-flyout.component';
import { DeletePopupComponent } from './modules/preferences/delete-popup/delete-popup.component';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { NgProgressRouterModule } from 'ngx-progressbar/router';
import { NoCommaPipe } from './shared/pipes/no-comma.pipe';
import { ReplaceIdentifierDescriptionPipe } from './shared/pipes/replace-identifier-description.pipe';
import { ReplaceSpendIdentifierDescriptionPipe } from './shared/pipes/replace-spend-identifier-description.pipe';
import { ClickableTooltipDirective } from './shared/directives/clickable-tooltip.directive';
import { UnauthorizedComponent } from './modules/unauthorized/unauthorized.component';
import { DynamicPopupComponent } from './shared/components/dynamic-popup/dynamic-popup';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TransferDeleteComponent } from './modules/my-cash/transfer-delete/transfer-delete.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    MyWalletComponent,
    MyWalletDetailsComponent,
    MyRewardsComponent,
    MyCashComponent,
    PreferencesComponent,
    MyAccountComponent,
    MyPaymentComponent,
    ConnectedSolutionsComponent,
    ConnectedProductsComponent,
    HowItWorksComponent,
    RedeemCardComponent,
    TransactionHistoryComponent,
    AllRewardsComponent,
    RedeemComponent,
    WaysToEarnComponent,
    AllCashComponent,
    ModalContentComponent,
    DefaultPaymentComponent,
    DefaultPaymentMethodComponent,
    AutoRedeemComponent,
    NotificationsComponent,
    AllPaymentsComponent,
    ContactUsComponent,
    OutstandingInvoiceComponent,
    PaymentSummaryDialogComponent,
    PaymentConfirmationComponent,
    TransferBalanceComponent,
    ReviewSummaryComponent,
    TransferRequestedComponent,
    SlickCarouselHomeComponent,
    ManagePaymentComponent,
    TransferFlyoutComponent,
    NavigationComponent,
    ModalTncComponent,
    LoggedOutComponent,
    AutoRedeemDialogComponent,
    TransactionHistoryTableComponent,
    NameInitialsPipe,
    UpdateMyaccountDialogComponent,
    DeliveryDefenseTncDialogComponent,
    InsureShieldTncDialogComponent,
    BlankLayoutComponent,
    DefaultPaymentComponent,
    PaymentTokensComponent,
    TransactionValuePipe,
    ModalDefaultPaymentComponent,
    NotificationBoxComponent,
    PaymentMethodFlyoutComponent,
    DeletePopupComponent,
    NoCommaPipe,
    ReplaceIdentifierDescriptionPipe,
    ReplaceSpendIdentifierDescriptionPipe,
    ClickableTooltipDirective,
    UnauthorizedComponent,
    DynamicPopupComponent,
    TransferDeleteComponent
  ],
  
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatIconModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatInputModule,
    MatChipsModule,
    MatFormFieldModule,
    MatDialogModule,
    MatOptionModule,
    MatRadioModule,
    MatSelectModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatButtonModule,
    MatMenuModule,
    MatExpansionModule,
    MatToolbarModule,
    MatGridListModule,
    MatSidenavModule,
    MatListModule,
    MatTooltipModule,
    AppRoutingModule,
    FormsModule,
    LayoutModule,
    ReactiveFormsModule,
    NgbModule ,
    HttpClientModule,
    CdkDrag,
    CdkDropList,
    CdkDropListGroup,
    CurrencyMaskModule,
    NotificationBoxModule,
    NgProgressModule.withConfig({
      color: '#0662BB',
      spinner: false,
    }),
    NgProgressHttpModule,
    NgProgressRouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'en'
    })
  ],

  providers: [
    provideHttpClient(),SessionService,
    DecimalPipe
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
