import { Component, Inject, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-modal-default-payment',
  templateUrl: './modal-default-payment.component.html',
  styleUrls: ['./modal-default-payment.component.scss']
})
export class ModalDefaultPaymentComponent {
 constructor(
  public dialogRef: MatDialogRef<ModalDefaultPaymentComponent>,
  public router:Router, 
  public route:ActivatedRoute,
  private ngZone: NgZone,
  @Inject(MAT_DIALOG_DATA) public data: any // We re-use this component in multiple places so display text accordingly
 ){
  
 }

  cancelSetDefaultPayment() {
    this.dialogRef.close('back');
  }

  gotoSetDefaultPayment(event: MouseEvent) {
    console.log('Button clicked');
    event.preventDefault();
    this.dialogRef.close('accept');
    
    // Use a short delay to allow the dialog to close completely
    setTimeout(() => {
      console.log('Navigating after dialog closed');
      this.router.navigate(['/preferences/manage-payment'], { relativeTo: this.route });
    }, 100); // 100ms should be sufficient
  }

}
