import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { GetAutoPaySequenceService } from 'src/app/shared/services/api/get-auto-pay-sequence.service';

@Component({
  selector: 'app-auto-redeem-dialog',
  templateUrl: './auto-redeem-dialog.component.html',
  styleUrls: ['./auto-redeem-dialog.component.scss']
})
export class AutoRedeemDialogComponent {
  selectedMethods: string[] = [];
  currentSelection: any;
  autopayOptions: any;
  defaultMethod: any;
  isAutoPay: boolean;
  skipFirstStep: boolean;
  skipConfirmation: boolean;

  systemError: boolean = false;
  buttonLoading: boolean = false;
  currentStep: any = 0;

  @Output() autopayResult = new EventEmitter<any>();

  constructor(
    public dialogRef: MatDialogRef<AutoRedeemDialogComponent>,
    public router: Router,
    public route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private getAutoPaySequence: GetAutoPaySequenceService
  ) {
    this.currentSelection = data?.currentSelection;
    this.autopayOptions = data?.autopayOptions;
    this.defaultMethod = data?.defaultMethod;
    this.isAutoPay = data?.isAutoPay;
    this.skipFirstStep = data?.skipFirstStep;
    this.skipConfirmation = data.skipConfirmation;


    // Ensure that we push the selected methods in the correct order (autopayOptions object is always in the right order)
    for (let i = 0; i < this.autopayOptions.length; i++) {
      if (this.autopayOptions[i].checked == true) {
        if (this.autopayOptions[i].key == 'rewards') { this.selectedMethods.push('rewards'); }
        else if (this.autopayOptions[i].key == 'cash') { this.selectedMethods.push('cash'); }
        else if (this.autopayOptions[i].key == 'default') { this.selectedMethods.push('default'); }
      }
    }

      // Skip to the final step if skipFirstStep is true
       if (this.skipFirstStep) {
        this.currentStep = 1;
      }

  }
  
  ngOnInit() {
    this.autopayResult.emit(false);
  }

  confirmRedeem() {
    this.buttonLoading = true;
    this.getAutoPaySequence.setAutoPay(this.currentSelection).subscribe(
      data => {
        this.buttonLoading = false;
        if (data.success) {
          this.currentStep = 1;
          this.autopayResult.emit(true);
        } else {
          this.systemError = true;
          this.autopayResult.emit(false);
        }
      }, error => {
        this.buttonLoading = false;
        this.systemError = true;
        this.autopayResult.emit(false);
      }
    );
  }

  close() {
    this.autopayResult.emit('cancel');
    this.dialogRef.close();
}

  thankYou() {
    this.dialogRef.close();
  }
}
