import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiEndpoints } from 'src/config/api-endpoints';

@Injectable({
  providedIn: 'root'
})
export class GetAutoPaySequenceService {

  private apiUrl = ApiEndpoints.getAutoPaySequence;
  private setAutoPayUrl = ApiEndpoints.setAutoPay;

  constructor(private http:HttpClient) { }

  getAutoPaySequenceResponse(walletID: string, walletRole: string): Observable<any> {
    const url = `${this.apiUrl}`;
    let wallettoken=sessionStorage.getItem("walletheaders");
    let httpOptions = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET",
        'Content-Type': 'application/json',
        "Authorization": "bearer " + wallettoken
      }),
    };
    return this.http.post<any>(url, { walletID, walletRole },httpOptions);
  }

  setAutoPay(data: any): Observable<any> {
    let token=sessionStorage.getItem("headers");
    let httpOptions = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET",
        "Authorization": "bearer " + token
      }),
    };
    const url = `${this.setAutoPayUrl}`;
    return this.http.post<any>(url, data, httpOptions);
  }


}
