import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DWInitiateCashTransactionRequest, DWInitiateCashTransactionResponse } from 'src/app/shared/models/DWInitiateCashTransactionRequest.model';
import { transferCarrier } from 'src/app/shared/models/payouttokens.model';
import { PayoutTokensService } from 'src/app/shared/services/api/get-payout-tokens.service';

@Component({
  selector: 'app-review-summary',
  templateUrl: './review-summary.component.html',
  styleUrls: ['./review-summary.component.scss']
})
export class ReviewSummaryComponent implements OnInit{

  constructor(public route:Router,public payouttokens: PayoutTokensService){}
  transferCarrier:transferCarrier= new transferCarrier(); 
  estimatedDate:any;
  disablebtn:boolean=false;
  
  returnToBack(){
    this.disablebtn=true;
    this.route.navigate(['my-wallet', 'my-cash', 'transfer-balance']);
  }

  submitTransfer(){
    
    this.disablebtn=true;
    let sessionobj:transferCarrier=new transferCarrier();
    sessionobj=JSON.parse(sessionStorage.getItem('transferCarrier') || '{}');
    if(sessionobj !=null)
    {
          let WalletID=JSON.parse(sessionStorage.getItem('WalletID') || '{}');
      let WalletRole=JSON.parse(sessionStorage.getItem('WalletRole') || '{}');
      let cashtransfer:DWInitiateCashTransactionRequest= new DWInitiateCashTransactionRequest();
      cashtransfer.cashWalletAmount=parseFloat(sessionobj.transferAmount.replace('$','').replaceAll(',',''));
      cashtransfer.payeeID=sessionobj.token;
      cashtransfer.transferAmount=parseFloat(sessionobj.totalTransferAmount.replace('$','').replaceAll(',',''));
      cashtransfer.transferType=sessionobj.accountType;
      cashtransfer.walletID=WalletID;
      cashtransfer.walletRole=WalletRole;

      
      this.payouttokens.initiateCashTransfer(cashtransfer).subscribe({
        next: (data: DWInitiateCashTransactionResponse) => {
          if(data.status==true){
          sessionStorage.setItem('transactionRefNo',data.transactionRefNo);
          sessionStorage.setItem('errorMsg',"");
          }
          else{
            sessionStorage.setItem('transactionRefNo',data.transactionRefNo);
          sessionStorage.setItem('errorMsg',data.message);
          }
            this.route.navigate(['my-wallet', 'my-cash', 'transfer-requested']);
          
          
    }});
    
  }
  }

  addDays(date: Date, days: number): Date {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  addBusinessDays(startDate: Date, days: number): Date {
    let currentDate = new Date(startDate);
    let addedDays = 0;
  
    while (addedDays < days) {
      currentDate.setDate(currentDate.getDate() + 1);
      // Check if the current day is a weekday (Monday to Friday)
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        addedDays++;
      }
    }
  
    return currentDate;
  }


 
  ngOnInit()
  {
    let sessionobj:transferCarrier=new transferCarrier();
    sessionobj=JSON.parse(sessionStorage.getItem('transferCarrier') || '{}');
    if(sessionobj !=null)
    {
      this.transferCarrier=sessionobj;
      const datepipe: DatePipe = new DatePipe('en-US');
      let date=this.addBusinessDays(new Date(),3);
      let day=date.toLocaleString('default', {
        weekday: 'long',
      });
      this.estimatedDate= day+ datepipe.transform(date, ' , MMMM dd');

      
    }
  }

}
