import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { WalletSummaryService } from 'src/app/shared/services/api/wallet-summary.service';
import { WalletSummary } from 'src/app/shared/models/wallet-summary.interface';
import { TransactionHistoryService } from 'src/app/shared/services/api/transaction-history.service';
import { InvoicePayment } from 'src/app/shared/models/transaction-history.interface';
import { PaymentDetails } from 'src/app/shared/models/transaction-history.interface';
import  jsonData from '../../../../assets/json/transactions.json';
import { transition } from '@angular/animations';

@Component({
  selector: 'app-all-payments',
  templateUrl: './all-payments.component.html',
  styleUrls: ['./all-payments.component.scss']
})
export class AllPaymentsComponent implements OnInit {

  displayedColumns: string[] = ['select', 'paymentDate', 'paymentTotal', 'paymentIdentifier', 'paymentCategory'];
  dataSource = new MatTableDataSource<InvoicePayment>([]);

  constructor(
        public route:Router,
        private walletSummaryService: WalletSummaryService,
        private transactionHistoryService: TransactionHistoryService){}

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  selection = new SelectionModel<InvoicePayment>(true, []);
  searchValue: string = '';

  transactions = jsonData;
  walletSummary: WalletSummary | null = null;
  
  selectAll(event: Event) {
    const target = event.target as HTMLInputElement;
    const isChecked = target.checked;
    
    this.transactions.forEach(transaction => {
      transaction.selected = isChecked;
    });
  }

  managePayMethod(){
    this.route.navigate(['/preferences', 'manage-payment']);
  }

  ngOnInit(): void {
  
    this.walletSummaryService.walletSummary$.subscribe(summary =>{
      if (summary && summary.wallet) {
        // Extracting walletID from the wallet object
        const walletID = summary.wallet.walletID;
        // Assuming walletRole or similar property to identify the role; adjust according to your actual data
        const walletRole = 'admin'; // Replace 'yourRole' with the actual logic to determine walletRole
        console.log('Subscribed to wallet summary:', summary); // Check this log
        this.walletSummary = summary;
        this.fetchTransactionHistory(walletID, walletRole);
      }
    });
  }

  fetchTransactionHistory(walletID: string, walletRole: string) {
    // Assuming you have a way to get these values
    this.transactionHistoryService.fetchTransactionHistory(walletID, walletRole, 'all').subscribe(data => {
      this.dataSource.data = data.walletTransactionsList.payments.invoicePayments;
      console.log('Invoice Payment: ', this.dataSource.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: InvoicePayment): string {
    if (!row) return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.paymentDate + row.paymentTotal}`;
  }

  
  applySearch(searchValue: string) {
    const formattedSearchValue = searchValue.trim().toLowerCase();
    this.dataSource.filter = formattedSearchValue;
   // this.dataSource.filter = searchValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
