import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DwPaymentProfiles, PayinTokens } from 'src/app/shared/models/payintokens.model';
import { DWDPATSessionModel } from 'src/app/shared/models/session.model';
import { GetGuidService } from 'src/app/shared/services/api/get-guid.service';
import { GetPayinTokensService } from 'src/app/shared/services/api/get-payin-tokens.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ApiEndpoints } from 'src/config/api-endpoints';

@Component({
  selector: 'app-payment-tokens',
  templateUrl: './payment-tokens.component.html',
  styleUrls: ['./payment-tokens.component.scss']
})
export class PaymentTokensComponent {
paymenttokens:any;
selectedtoken:any;
cctokens:any;
ectokens:any;
dwdpatsession:DWDPATSessionModel=new DWDPATSessionModel;
WalletID:any;
walletDetails:any;
  constructor(public dialogRef: MatDialogRef<PaymentTokensComponent>,private router:Router,
    @Inject(MAT_DIALOG_DATA) public data:any, public getpayintoken:GetPayinTokensService,public sessionService:GetGuidService,public userService:UserService){
      this.dialogRef.updateSize('80%','100%');
      this.dialogRef.updatePosition({left:'75%'})
    }


    ngOnInit()
    {
      this.walletDetails=this.userService.getWalletDetails();
      
     let listoftokens= this.getpayintoken.fetchpayintokens(this.data.WalletID).subscribe({
      next: (data:PayinTokens) => {
        console.log("payintokens"+ data);
        this.paymenttokens= data.paymentMethods;
        this.paymenttokens.forEach((token:any) => {
          token.cardImg= this.getpayintoken.getcardImage(token.cardType,token.ccholderName);
          let expiration=token.ccexpiration;
          token.ccexpiration=expiration.slice(4,6)+"/"+ expiration.slice(2,4)
        });
        
        this.cctokens=this.paymenttokens.filter((x:any)=>x.paymentMethod=='CC');
        console.log('CCTOKENS'+ this.cctokens);
        this.ectokens=this.paymenttokens.filter((x:any)=>x.paymentMethod=='ECP');
        console.log('ECTOKENS'+ this.ectokens);
      }
     });

    }

    updateDefaultPayment(): void{

      console.log(this.selectedtoken);
      if(this.selectedtoken !='')
      {
      sessionStorage.setItem('OTDefaultToken',this.selectedtoken);
      this.dialogRef.close('update');
      }
      
      //this.router.navigate(['/payment-tokens']);

    }

    closeDialog(): void{
      this.dialogRef.close('close');
    }

    addPaymentMethod():void{


      if(sessionStorage.length>0)
{
  this.WalletID=JSON.parse(sessionStorage.getItem("WalletID") || '{}');
}    
    
   
this.dwdpatsession=new DWDPATSessionModel()
this.dwdpatsession.action='addpayinpayment';
this.dwdpatsession.callBackURL=`${ApiEndpoints.startWalletSessionCallBack}/my-wallet/all-rewards/redeem/outstanding-invoice` ;
this.dwdpatsession.country='US';
this.dwdpatsession.createdByUserId='appdw';
this.dwdpatsession.locale='en';
this.dwdpatsession.navigatingSystem='dw';
this.dwdpatsession.paymentDirection='payin';
this.dwdpatsession.walletID=this.WalletID;
this.dwdpatsession.cashBalance=this.walletDetails?.cash?.totalCashBalance.toString();
this.dwdpatsession.rewardsBalance=this.walletDetails?.rewards?.totalRewardBalance.toString();


this.sessionService.createdwdpatwalletsession(this.dwdpatsession);
    }
  
   

    
}
