<div id="dw-container">
   <div class="dw-header"  *ngIf="showHeaderAndFooter">
     <app-header (linkClicked)="onLinkClicked($event)"></app-header>

   </div>

   
    <div class="layout">

        <router-outlet></router-outlet>

        <div *ngIf="showHeaderAndFooter">
         <app-footer></app-footer>
        </div>

    </div>
  
</div>
