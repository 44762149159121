import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { InvoicesDetails } from "../../models/invoice-history.interface";
import { Observable } from "rxjs";
import { SessionService } from "../session.service";
import { ApiEndpoints } from "src/config/api-endpoints";

@Injectable({
    providedIn: 'root'
  })
  export class InvoicesDetailService {
   // private apiUrl = 'https://ib-wallet-dev-api.azurewebsites.net/api/DigitalWalletAPI/dwgetinvoicedetails';

   private apiUrl = ApiEndpoints.invoicedetails;
   
  
    constructor(private http: HttpClient) {}
  
  // In transaction-history.service.ts
  fetchInvoiceDetails(walletID: string, walletRole: string, sourceSystem: string = 'gw'): Observable<any> {
    // Construct the request body according to the API's expected format
    const requestBody = { walletID, walletRole, sourceSystem };
    let wallettoken=sessionStorage.getItem("walletheaders");
    let httpOptions = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET",
        'Content-Type': 'application/json',
        "Authorization": "bearer " + wallettoken
      }),
    };
    return this.http.post<InvoicesDetails>(this.apiUrl, requestBody,httpOptions);
  }
}