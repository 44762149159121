<div class="transfer-popup-container">
<div mat-dialog-content>
    <button class="close" (click)="closeFlyout()"><img src="../../../../assets/icons/close icon.svg"></button>
    <h2 class="headline-highlight">Are You Sure You want to Deactivate Auto-Transfer?</h2>
    <p>By disabling Auto Transfer, future claim payments will not be automatically transferred from your UPS Wallet to your bank account. You will need to manage transfers manually through your wallet from now on.
     <br />
        If you wish to proceed with deactivating Auto Transfer, select the <span class="strong">Confirm Deactivation</span> button.</p>
</div>
  


  <div class="button-container">
    <div class="btn">
        <button mat-button (click)="onConfirm()" type="submit" mat-raised-button class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button primary" [disabled]="isProcessing">
            <span class="mdc-button__label">
                <span class="button-label">
                  <span>Confirm Deactivation</span>
                    <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon> 
                    &nbsp;&nbsp;
                    <span *ngIf="isProcessing" class="spinner-border"></span> <!-- Show spinner only when isProcessing is true -->    
                </span>
            </span>
        </button>
    </div>
    <div class="btn">
        <button (click)="onCancel()" style="height: 36px;" mat-raised-button class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button secondary">
            <span class="mdc-button__label">
                <span class="button-label">
                  <span class="cancel">Cancel</span>
                  <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                </span>
            </span>
        </button>
    </div>
</div>
</div>
 
