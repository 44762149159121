import { Component } from '@angular/core';

@Component({
  selector: 'app-redeem',
  templateUrl: './redeem.component.html',
  styleUrls: ['./redeem.component.scss']
})
export class RedeemComponent {

  public panels: any = [
    { "sizeX": 3, "sizeY": 1, "row": 0, "col": 0, content: '<div class="content">Panel 1</div>' },
    { "sizeX": 4, "sizeY": 1, "row": 0, "col": 3, content: '<div class="content">Panel 2</div>' },
    // Adjust sizeX values to achieve a total width of 70%
  ];
  
}
