import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthToken, DWDPATSessionModel, sessionResponse } from '../../models/session.model';
import { Router } from '@angular/router';
import { ApiEndpoints } from 'src/config/api-endpoints';
import { GetTokenRequest } from '../../models/GetTokenRequest.model';

@Injectable({
  providedIn: 'root'
})
export class GetGuidService {

private dwdpatsessionapiUrl = ApiEndpoints.startWalletSession;

  httpOptions:any;
 
  constructor(private http: HttpClient, public router: Router) { 
    
  }

  /* fetchWalletID(guid: string): Observable<any> {
    let wallettoken=sessionStorage.getItem("walletheaders");
    let httpOptions = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET",
        'Content-Type': 'application/json',
        "Authorization": "bearer " + wallettoken
      }),
    };
    return this.http.get<any>(`${this.apiUrl}${guid}`,httpOptions);
  } */

  createdwdpatwalletsession(dwdpatsessionmodel: DWDPATSessionModel):void{
    
    let token=sessionStorage.getItem("headers");
    let httpOptions = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET",
        "Authorization": "bearer " + token
      }),
    };
        this.http.post(this.dwdpatsessionapiUrl, dwdpatsessionmodel, httpOptions).subscribe(
          (data: any) => {
            window.location.replace(data.url);
          });
    
        }
        /* gettoken(): void{
          let wallettoken=sessionStorage.getItem("walletheaders");
    let httpOptions = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET",
        "Authorization": "bearer " + wallettoken
      }),
    };
    if(wallettoken!=''&& wallettoken!=undefined){
          this.http.get<any>(`${this.dwdpattokenapiUrl}`,httpOptions).subscribe(
            (data: any) => {
       
              if (data.success) {
                
                sessionStorage.setItem("headers",data.token);
              }
              
            });
          }
        } */
         /*  getwalletapptoken(){
            console.log("token url"+this.tokenforWallet);
            let getTokenRequest:GetTokenRequest=new GetTokenRequest();
            getTokenRequest.UserIn=ApiEndpoints.userIn;
            getTokenRequest.PasswordIn=ApiEndpoints.passwordIn;
            return this.http.post<any>(this.tokenforWallet,getTokenRequest).pipe(
              tap((res: AuthToken) => {
                if(res.success)
                {
                sessionStorage.setItem('walletheaders', res.token);
                }
                
              }),
              map((res) => res)
            );
            } */
      
}
  
     
  
 


