<div class="content-container">
    <h2 class="headline-highlight">Notification Preference</h2>   
    

<form [formGroup]="notificationForm" (ngSubmit)="onSubmit()">
  <div class="content-box">
    <h4>Communication Preference</h4>
    <div>
      <mat-checkbox formControlName="emailSelection" class="wide-check-box">Email</mat-checkbox>
      <div class="contact-info" *ngIf="notificationForm.controls['email'].value as email" >
        <span>  {{ email }} </span>
        <a (click)="editLink()" class="edit-link">Edit</a>
      </div>
    </div>
    <hr>
    <div>
      <mat-checkbox formControlName="textSelection">Phone</mat-checkbox>
      <div class="contact-info" *ngIf="notificationForm.controls['phoneNumber'].value as phoneNumber">
        <span>Phone: {{ phoneNumber }}</span>
        <a (click)="editLink()" class="edit-link">Edit</a>
      </div>
    </div>
  </div>

  <div class="content-box">
    <h4>Frequency Preferences</h4>
    <div class="frequency-preferences">
      <mat-radio-group formControlName="frequencySelection">
        <mat-radio-button value="daily">Daily Summary</mat-radio-button>
        <mat-radio-button value="weekly">Weekly Summary</mat-radio-button>
        <mat-radio-button value="monthly">Monthly Summary</mat-radio-button>
        <mat-radio-button value="events">When Events Occur</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
 
    <div *ngIf="notificationForm.get('frequencySelection')?.value === 'events'">
      <div class="content-box">
        <div class="event-preferences">
      <h4>Event Preferences</h4>
      <p>Choose at least one option (Select All that Apply)</p>
      <mat-checkbox formControlName="rewardsCashEarned">When rewards/cash are earned</mat-checkbox>
      <mat-checkbox formControlName="rewardsCashRedeemed">When rewards/cash are redeemed</mat-checkbox>
      <mat-checkbox formControlName="paymentMade">When payment has been made</mat-checkbox>
      <mat-checkbox formControlName="accountInfoUpdated">When account information (Profiles, T&amp;C's etc) has been updated</mat-checkbox>
    </div>
    </div>
  </div>

  

  <div class="btn">
    <button (click)="updateNotification()" type="submit" mat-raised-button class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button primary">
      <span class="mdc-button__label">
        <span class="button-label">
          <span>Update Notification Preference</span>
          <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
        </span>
      </span>
    </button>
  </div>
</form>
</div>
