
<div class="sub-container">

    <div class="modal-terms">
    
    <h2 class="headline-highlight">Terms and Conditions </h2>
    <button mat-icon-button class="close-button" aria-label="Close dialog" (click)="onDecline()">
      <mat-icon>close</mat-icon>
    </button>

  <div mat-dialog-content>
    <!-- Your terms and conditions content here -->
    <h4>UPS<sup>®</sup> Wallet</h4>
    <p>IMPORTANT: PLEASE READ THESE UPS<sup>®</sup> WALLET TERMS AND CONDITIONS CAREFULLY BEFORE USING THE UPS WALLET OFFERING. 
    </p>
    <p class="tnc-link"><a href="../../../../assets/pdf/Digital Wallet Terms and Conditions.pdf" alt="UPS Digital Wallet Terms and Conditions" target="_blank">Read full terms & conditions</a></p>
    <mat-checkbox [(ngModel)]="agreeTermA" [disableRipple]="true">I agree with UPS Wallet Terms and Conditions</mat-checkbox>
  
    <h4>Rewards Program</h4>
    <p>IMPORTANT: PLEASE READ THESE REWARDS PROGRAM TERMS AND CONDITIONS CAREFULLY BEFORE PARTICIPATING IN THE REWARDS PROGRAM. 
    </p>
    <p class="tnc-link"><a href="../../../../assets//pdf/UPS InsureShield Reward Program Terms.pdf" alt="InsureShield Rewards Program" target="_blank">Read full terms & conditions</a></p>
    <mat-checkbox [(ngModel)]="agreeTermB" [disableRipple]="true">I agree with Rewards Program Terms and Conditions</mat-checkbox>
  </div>

  <div mat-dialog-actions>

    <div class="button-container">
        <div class="btn">
            <button mat-button (click)="onDecline()" mat-raised-button class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button secondary">
                <span class="mdc-button__label">
                    <span class="button-label">
                        <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_left</mat-icon>                           
                        <span>
                           Decline
                        </span>
                    </span>
                </span>
            </button>
        </div>
        <div class="btn">
            <button (click)="onAccept()" [disabled]="!canAccept()" type="submit" mat-raised-button class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button primary">
                <span class="mdc-button__label">
                    <span class="button-label">
                        <span>Accept</span>
                        <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                    </span>
                </span>
            </button>
        </div>
    </div>

    <!-- <button mat-button (click)="onAccept()" [disabled]="!canAccept()">Accept</button>
    <button mat-button (click)="onDecline()">Decline</button> -->
  </div>

</div>
</div>
