import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noComma'
})
export class NoCommaPipe implements PipeTransform {
  transform(value: number | string): string {
    return value.toString().replace(/,/g, '');
  }
}
