import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transfer-pop-up',
  templateUrl: './transfer-pop-up.component.html',
  styleUrls: ['./transfer-pop-up.component.scss']
})

export class TransferPopUpComponent {
    constructor(public dialogRef: MatDialogRef<TransferPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private router: Router
  ){}

  isProcessing: boolean = false; // Track button state

  onConfirm(): void {
    if (this.isProcessing) return; // Prevent multiple clicks
    this.isProcessing = true; // Disable button
  
    const autoTransferData = {
      isAutoTransfer: true,
      transferType: this.data.transferType,
      defaultToken: this.data.defaultToken,
    };
    
    // Save to sessionStorage
    sessionStorage.setItem('AutoTransfer', JSON.stringify(autoTransferData));
    this.dialogRef.close(true);

    // Navigate to the Auto-Transfer page
    this.router.navigate(['/my-wallet/my-cash/auto-transfer']);
  }

  onCancel(): void{
    this.dialogRef.close(false);
  }

}
