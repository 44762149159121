import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { WalletSummaryService } from 'src/app/shared/services/api/wallet-summary.service';
import { WalletSummary } from 'src/app/shared/models/wallet-summary.interface';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {

  insureShieldShippingInsurance: SafeHtml;

  // Sidebar settings
  myAccountActive: boolean = false;
  contactUsActive: boolean = false;
  preferencesActive: boolean = false;
  managePaymentActive: boolean = false;
  autoRedeemActive: boolean = false;

  walletSummary: WalletSummary | null = null;
  cashEligible = false;
  rewardEligible = false;

  constructor(
    private router: Router,
    private walletSummaryService: WalletSummaryService,
    private sanitizer: DomSanitizer, 
    private translate: TranslateService
  ) {
    // Set correct sidebar settings
    if (this.router?.url == '/my-account') {
      this.myAccountActive = true;
    } else if (this.router?.url == '/preferences' || this.router?.url == '/preferences/manage-payment') {
      this.preferencesActive = true;
      this.managePaymentActive = true;
    } else if (this.router?.url == '/preferences/auto-redeem') {
      this.preferencesActive = true;
      this.autoRedeemActive = true;
    } else if (this.router?.url == '/contact-us') {
      this.contactUsActive = true;
    }

    this.translate.get('contactUs.insureShieldShippingInsurance').subscribe((res: string) => {
      this.insureShieldShippingInsurance = this.sanitizer.bypassSecurityTrustHtml(res);
    });
  }

  ngOnInit() {
    this.walletSummaryService.walletSummary$.subscribe(summary => {
      this.walletSummary = summary;
      this.cashEligible = summary?.wallet?.cashEligible === "True";
      this.rewardEligible = summary?.wallet?.rewardEligible === "True";
    })
  }
}
