
<ng-container *ngIf="!isLoading; else loadingTemplate">

<div class="transaction-history-container">
    <div class="sub-container">
      <div class="left-content">
        <h2 class="headline-highlight">Transaction History</h2>
      </div>
      <div class="right-content" *ngIf="!cashEligible || rewardEligible">
        <div class="button-container-wrapper">
          <div class="tabs button-container">
            <button class="tab-button" mat-raised-button
                    [ngClass]="{'selected': selectedTab === 'all'}"
                    (click)="selectTab('all')">ALL</button>
            <button class="tab-button" mat-raised-button
                    [ngClass]="{'selected': selectedTab === 'rewards'}"
                    (click)="selectTab('rewards')">REWARDS</button>
            <button class="tab-button" mat-raised-button
                    [ngClass]="{'selected': selectedTab === 'cash'}"
                    (click)="selectTab('cash')">CASH</button>
            <button class="tab-button" mat-raised-button
                    [ngClass]="{'selected': selectedTab === 'payments'}"
                    (click)="selectTab('payments')">PAYMENTS</button>
          </div>
        </div>
      </div>
    </div>



<div class="sub-container wallet-transaction-table">
        <div class="wallet-detail earning">
            <div class="wallet-detail-title">
                <h3>Wallet Earning</h3>  
                <a [routerLink]="getRouterLink()"><span class="right-link">See All <img src="../../../../assets/icons/arrow_right_alt.svg"></span></a>
              </div>
                <div class="item" *ngFor="let item of walletEarningData | slice:0:10">
                    <!-- <div class="icon">
                        <img src="../../../../assets/icons/frame.svg">
                    </div> -->
                    <div class="icon-wrapper" *ngIf="item.earnCategoryDescription !== 'CashSpendTransferReversal'">
                      <div class="icon" >
                        <img src="../../../../assets/icons/frame.svg">
                      </div>
                    </div>
                    
              <div class="icon-wrapper icon-wrapper-cash" *ngIf="item.earnCategoryDescription === 'CashSpendTransferReversal'">
                <div class="icon cash-transfer"> 
                  <img src="../../../../assets/icons/Group 22684.png">
                </div>
              </div>
                    <div class="info">
                        <div class="title">
                          <ng-container *ngIf="item.earnCategoryDescription === 'CashSpendTransferReversal'; else otherTitles">
                            Cash Transfer Reversal
                          </ng-container>
                          <ng-template #otherTitles>
                          <ng-container *ngIf="['ISComplete', 'ISConnect', 'IS4U'].includes(item.productName); else defaultText">
                            InsureShield<sup>&reg;</sup> Shipping Insurance
                          </ng-container>
                          <ng-template #defaultText>-</ng-template>
                        </ng-template>
                        </div>
                     
                        <!-- <div class="details">{{ item.earnIdentifierDescription | replaceIdentifierDescription }} # {{ item.earnIdentifier }}</div> -->
                        <div class="details">
                          <!-- Specific condition for RewardsEarnProfileValidation -->
                          <ng-container *ngIf="item.earnCategoryDescription === 'RewardsEarnProfileValidation'; else otherConditions">
                            Online Portal Profile Validation
                          </ng-container>
                      
                          <!-- Handle other conditions in the else block -->
                          <ng-template #otherConditions>
                            <ng-container *ngIf="item.earnCategoryDescription === 'CashEarnClaimsPaid'; else earlyBillPayCondition">
                              Claims # {{ item.earnIdentifier }}
                            </ng-container>
                          </ng-template>
                      
                          <ng-template #earlyBillPayCondition>
                            <ng-container *ngIf="item.earnCategoryDescription === 'Early Bill Pay'; else cashReversalCondition">
                              {{ item.earnCategoryDescription }} - Invoice #{{ item.earnIdentifier }}
                            </ng-container>
                          </ng-template>

                          <ng-template #cashReversalCondition>
                            <ng-container *ngIf="item.earnCategoryDescription === 'CashSpendTransferReversal'; else cashBillPayCondition">
                              Transfer - #{{ item.earnIdentifier }}
                            </ng-container>
                          </ng-template>
                      
                          <ng-template #cashBillPayCondition>
                            <ng-container *ngIf="item.earnCategoryDescription === 'Cash Bill Pay'; else finalConditions">
                              {{ item.earnCategoryDescription }} - Invoice #{{ item.earnIdentifier }}
                            </ng-container>
                          </ng-template>
                      
                          <ng-template #finalConditions>
                            <ng-container *ngIf="item.earnCategoryDescription === 'ACH Bill Pay'; else defaultCondition">
                              {{ item.earnCategoryDescription }} - Invoice #{{ item.earnIdentifier }}
                            </ng-container>
                          </ng-template>
                      
                          <!-- Default case for other conditions -->
                          <ng-template #defaultCondition>
                            {{ item.earnCategoryDescription }}
                          </ng-template>
                        </div>
                    </div>
                    <div class="data">
                        <div class="value-earning">
                            <ng-container *ngIf="item.type === 'cash'; else pointsTemplate">
                             +{{ item.amount | currency }}
                            </ng-container>
                            <ng-template #pointsTemplate>
                             +{{ item.amount }} pts
                            </ng-template>
                          </div>
                        <div class="date">{{ item.date | date: 'MM/dd/YYYY' }}</div>
                    </div>
                </div>
        </div>

        <div class="wallet-detail spending">
            <div class="wallet-detail-title">
                <h3>Wallet Spending</h3>
                <a [routerLink]="getRouterLink('redeemed')"> <span class="right-link">See All <img src="../../../../assets/icons/arrow_right_alt.svg"></span></a>
            </div>
                  
            <div class="item" *ngFor="let item of walletSpendingData | slice:0:10">

              <div class="icon-wrapper" *ngIf="item.spendIdentifierDescription !== 'PayableReferenceNumber'">
                <div class="icon">
                  <img src="../../../../assets/icons/frame.svg">
                </div>
                <div class="icon cash-transfer" *ngIf="item.spendIdentifierDescription === 'PayableReferenceNumber'"> 
                  <img src="../../../../assets/icons/Group 22684.png">
                </div>
              </div>

              <div class="icon-wrapper icon-wrapper-cash" *ngIf="item.spendIdentifierDescription === 'PayableReferenceNumber'">
                <div class="icon cash-transfer"> 
                  <img src="../../../../assets/icons/Group 22684.png">
                </div>
              </div>
                <div class="info">
                    <!-- <div class="title">
                      <ng-container *ngIf="['ISComplete', 'ISConnect', 'IS4U'].includes(item.productName); else defaultText">
                        InsureShield<sup>&reg;</sup> Shipping Insurance
                      </ng-container>
                      <ng-template #defaultText>-</ng-template>
                    </div> -->

                    <div class="title">
                      <ng-container *ngIf="item.spendIdentifierDescription === 'PayableReferenceNumber'; else normalTitle">
                        Cash Transfer
                      </ng-container>
                      <ng-template #normalTitle>
                        <ng-container *ngIf="['ISComplete', 'ISConnect', 'IS4U'].includes(item.productName); else defaultText">
                          InsureShield<sup>&reg;</sup> Shipping Insurance
                        </ng-container>
                        <ng-template #defaultText>-</ng-template>
                      </ng-template>
                    </div>
                    <div class="details">
                      <!-- {{ item | replaceSpendIdentifierDescription }} -->

                      <div class="details">
                        <ng-container *ngIf="item.spendCategoryDescription === 'CashSpendInvoice'">
                            Invoice # {{ item.spendIdentifier }}  <i>{{ item.modeOfTransfer }}</i>
                        </ng-container>
                        <ng-container *ngIf="item.spendCategoryDescription === 'CashSpendTransfer'">
                            <ng-container *ngIf="item.spendAccountRedactedDescription === 'ZELLE'">
                              Zelle - {{ item.spendAccountRedacted }} <!-- Display Zelle- followed by spendAccountRedacted -->
                            </ng-container>
                            <ng-container *ngIf="item.spendAccountRedactedDescription !== 'ZELLE'">
                            {{ item.spendAccountBankName }} *** {{ item.spendAccountAlias }}  <i>{{ item.modeOfTransfer }}</i>
                              </ng-container>
                        </ng-container>
                        <ng-container *ngIf="item.spendCategoryDescription === 'RewardsSpendInvoice' || item.spendCategoryDescription === 'RewardsSpendInvoicePaid'">
                            Invoice # {{ item.spendIdentifier }}  <i>{{ item.modeOfTransfer }}</i>
                        </ng-container>
                        <ng-container *ngIf="item.paymentIdentifierDescription === 'InvoiceNo'">
                          Invoice # {{ item.spendIdentifier }}  <i>{{ item.modeOfTransfer }}</i>
                      </ng-container>
                        <!-- More conditions below -->
                        <ng-container *ngIf="isDefaultCase(item) && item.spendCategoryDescription !== 'RewardsSpendInvoicePaid'">
                          {{ item.spendCategoryDescription }}  <i>{{ item.modeOfTransfer }}</i>
                        </ng-container>
                    </div>

                    </div>
                </div>
                <div class="data">
                    <div class="value-spending">
                        <ng-container *ngIf="item.type === 'cash' || 'payments'; else pointsTemplate">
                          - {{ item.amount | currency }}
                        </ng-container>
                        <ng-template #pointsTemplate>
                          - {{ item.amount }} pts
                        </ng-template>
                      </div>
                    <div class="date">{{ item.date | date: 'MM/dd/YYYY' }}</div>
                </div>
            </div>
        </div>

    </div>

</div>
</ng-container>

<ng-template #loadingTemplate>
  <app-skeleton type="text"></app-skeleton>
  <app-skeleton type="button"></app-skeleton>
</ng-template>