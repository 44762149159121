import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'transfer-delete',
  templateUrl: './transfer-delete.component.html',
  styleUrls: ['./transfer-delete.component.scss']
})
export class TransferDeleteComponent {

  constructor(
    public dialogRef: MatDialogRef<TransferDeleteComponent>,private router:Router,
    @Inject(MAT_DIALOG_DATA) public data:any){
      // this.dialogRef.updateSize('592px');
    }

    
   

    onAccept(): void{
      this.dialogRef.close('accept');

    }

    onDecline(): void{
     this.dialogRef.close('decline');
    }

}
