<div class="transfer-popup-container">
<div mat-dialog-content>
    <button class="close" (click)="onCancel()"><img src="../../../../assets/icons/close icon.svg"></button>
    <div class="sub-container">
        <h2 class="headline-highlight">Activate Auto Transfer for Seamless Transfers!</h2>

        <p>By selecting "Yes, Turn on Auto Transfer," you authorize automatic transfers to all approved claim payouts from your UPS<sup>&reg;</sup> Wallet to your 
            designated bank account.</p> 

        <p>Please note:</p>
        <ul class="custom-list">
            <li>When Auto Transfer is enabled, cash balance rewards cannot be used for UPS Capital<sup>&reg;</sup> invoice payments.</li>
            <li>Instant Transfers require a minimum cash balance of $3.00.</li>
        </ul>

        <p><i>Ready to proceed? Click the button below to continue.</i></p>
        
    </div>

</div>

  <div class="button-container">
    <div class="btn">
        <button mat-button (click)="onConfirm()" type="submit" mat-raised-button class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button primary" [disabled]="isProcessing" >
            <span class="mdc-button__label">
                <span class="button-label">
                  <span>Yes, Turn on Auto Transfer</span>
                    <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>                           
                </span>
            </span>
        </button>
    </div>
    <div class="btn">
        <button (click)="onCancel()" mat-raised-button class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button secondary">
            <span class="mdc-button__label">
                <span class="button-label">
                  <span class="cancel">No, I Prefer to Transfer Manually</span>
                  <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                </span>
            </span>
        </button>
    </div>
</div>
  
</div>