import { AbstractControl, ValidationErrors } from '@angular/forms';

export function phoneNumberValidator(control: AbstractControl): ValidationErrors | null {
  const valid = /^(?:\(\d{3}\)|\d{3}-)?\d{3}-\d{4}$|^\d{10}$/.test(control.value);
  return valid ? null : { invalidPhoneNumber: true };
}

export function zipCodeValidator(control: AbstractControl): ValidationErrors | null {
  const valid = /^\d{5}$/.test(control.value);
  return valid ? null : { invalidZipCode: true };
}