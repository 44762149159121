import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SessionService } from './shared/services/session.service';
import { GetGuidService } from './shared/services/api/get-guid.service';
import { environment } from 'src/environments/environment';
import { UserService } from './shared/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private sessionService: SessionService, public getTokenService: GetGuidService, private userService: UserService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const sessionId = route.paramMap.get('id');
    const walletID = sessionStorage.getItem('WalletID');

    if (sessionId) {
     sessionStorage.setItem("SessionID",sessionId);
      // Validate session ID using SessionService
      return this.sessionService.fetchSessionObject(sessionId).pipe(
        map((session) => {
          if (session && session.walletID) {
            return true; //Replace this line with the below line of code to activate route guard // Session is valid
            // return this.checkAccess(route, state); 
          } else {
            // Invalid session, clear session storage, redirect to login
            sessionStorage.clear();
            window.location.href = environment.loginUrl;
            return false;
          }
        }),
        catchError((error) => {
          // Error occurred, clear session storage, redirect to login,
          sessionStorage.clear();
          window.location.href = environment.loginUrl;
          return of(false);
        })
      );
        
        return true; //Replace this line with the below line of code to activate route guard
        // return this.checkAccess(route, state);
    } else if (walletID) {
      // Session exists in session storage, allow access
      return true; //Replace this line with the below line of code to activate route guard
      // return this.checkAccess(route, state);
    } else {
      // No valid session, redirect to login
      window.location.href = environment.loginUrl;
      return false;
    }
  }

  checkAccess(route, state) {
    // Sample example of one page within IB application
    if (state.url.startsWith('/my-wallet/all-rewards/redeem/outstanding-invoice')) {
      if (!this.userService.isRewardEligible()) { // Not reward eligible users (is4u) cannot view this URL
        this.router.navigate(['/unauthorized']);
        return false;
      } else {
        return true;
      }
    } else if (state.url.startsWith('/enter-all-other-urls-here')) { 
      let scenariosWhereUserIsNotAllowedToViewThisPage; // Replace with whatever the condition is (not reward eligible, autopay is on, etc)
      if (scenariosWhereUserIsNotAllowedToViewThisPage) {
        this.router.navigate(['/unauthorized']);
        return false;
      } else {
        return true;
      }
    } else if (state.url.startsWith('/enter-all-other-urls-here-2')) {
      return true;
    } else {
      return true; // For any pages that do not need routeGuard auth, we can simply return true at the very bottom else block
    }
  }
}
