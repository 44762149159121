import { Component, OnInit, Input, ViewChild, SimpleChanges, ChangeDetectorRef, OnChanges  } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { TransactionHistoryService } from '../../services/api/transaction-history.service';
import { TransactionHistory, RewardEarn } from '../../models/transaction-history.interface';


@Component({
  selector: 'app-transaction-history-table',
  templateUrl: './transaction-history-table.component.html',
  styleUrls: ['./transaction-history-table.component.scss']
})
export class TransactionHistoryTableComponent implements OnInit {
  @Input() walletId: string | undefined;
  @Input() walletRole: string | undefined;

  payments: any; // Assuming a suitable interface/type for your payments

  displayedColumns: string[] = ['select', 'earnDate', 'pointsEarned', 'productName', 'earnCategory'];
  dataSource = new MatTableDataSource<RewardEarn>([]);
  @ViewChild(MatSort, { static: true }) sort!: MatSort ;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  selection = new SelectionModel<RewardEarn>(true, []);
  searchValue: string ='';

  constructor(
    private transactionHistoryService: TransactionHistoryService,
    private changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    console.log('Transaction History Table OnChanges - changes:', changes);
    console.log('Transaction History Table OnChanges - walletId:', this.walletId, 'walletRole:', this.walletRole);
    // This checks if either walletId or walletRole has changed and if they are both defined
    if (changes['walletId']?.currentValue && changes['walletRole']?.currentValue) {
      this.fetchTransactionHistory();
      console.log('After fetching - walletId:', this.walletId, 'walletRole:', this.walletRole);
      this.changeDetectorRef.detectChanges(); // Manually trigger change detection
    }
  }

  ngOnInit(): void {
    console.log('Transaction History Table OnInit - walletId:', this.walletId, 'walletRole:', this.walletRole);
    if (this.walletId && this.walletRole) {
      this.fetchTransactionHistory();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.fetchTransactionHistory();
    }, 500); // Adjust delay as needed

    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage(); // Ensure the paginator resets to the first page
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: RewardEarn): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.earnDate}`;
  }

  fetchTransactionHistory(): void {
    if (this.walletId && this.walletRole) {
      this.transactionHistoryService.fetchTransactionHistory(this.walletId, this.walletRole, 'all').subscribe({
        next: (data: TransactionHistory) => {
          if (data && data.walletTransactionsList && data.walletTransactionsList.rewards) {
            this.dataSource.data = data.walletTransactionsList.rewards.rewardsEarn;
          }
    //      console.log('Fetched Transaction History Data:', data);
          this.payments = data.walletTransactionsList.payments; // Ensure this path matches your data structure
        },
        error: (error) => console.error('Error fetching transaction history:', error)
      });
    } else {
      console.log('walletId or walletRole is undefined', this.walletId, this.walletRole);
    }
  }
  


}
