import { Component, Inject, inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WalletSetupService } from '../../services/api/wallet-setup.service';

@Component({
  selector: 'app-modal-tnc-wallet',
  templateUrl: './modal-tnc-wallet.component.html',
  styleUrls: ['./modal-tnc-wallet.component.scss']
})
export class ModalTncWalletComponent {

  agreeTermA: boolean = false;
  showAdditionalContent: boolean = false;
  showRemindMeLaterContent: boolean = false;
  message: string = 'Congratulations, Your InsureShield<sup>&reg;</sup> Experience Just Got Better!';
  @Output() acceptClicked = new EventEmitter<void>();

  //onAcceptCallback: () => void;

  constructor(
    public dialogRef: MatDialogRef<ModalTncWalletComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private walletSetupService: WalletSetupService
    ){}
      // @Inject(MAT_DIALOG_DATA) public data: { onAcceptCallback?: () => void }
      // ) {
      //   this.onAcceptCallback = data.onAcceptCallback || (() => {});
      // }

      onAccept(): void {
        if (this.data.walletRole === 'tandc') {
          this.showAdditionalText();
          this.acceptClicked.emit();
          this.dialogRef.updateSize('832px', '300px');
          console.log('tandc selected');
         this.proceedWithTandCFlow();
        } else {
          console.log('onAccept clicked');
          this.proceedWithNormalFlow();
        }
      //  this.proceedWithNormalFlow();
      }   

      proceedWithTandCFlow():void{
        const walletSetupData = {
          policyNumber: this.data.policyNumber,
          walletId: this.data.walletId,
          productType: this.data.productType,
          productSystem: this.data.productSystem
        };

        this.walletSetupService.updateWalletSetup(walletSetupData).subscribe({
          next: (response: any) => {
            console.log('Wallet setup updated successfully', response);
          },
          error: (error: any) => {
            console.error('Failed to update wallet setup', error);
          }
        });
      }

      proceedWithNormalFlow(): void{
   
      const walletSetupData = {
        policyNumber: this.data.policyNumber,
        walletId: this.data.walletId,
        productType: this.data.productType,
        productSystem: this.data.productSystem
      };

      this.walletSetupService.updateWalletSetup(walletSetupData).subscribe({
        next: (response: any) => {
          console.log('Wallet setup updated successfully', response);
          this.dialogRef.close('accept');
          this.data.checkTcAcceptanceAndProceed(); // Call the checkTcAcceptanceAndProceed function from the parent component
        },
        error: (error: any) => {
          console.error('Failed to update wallet setup', error);
          this.dialogRef.close('error');
        }
      });

     // this.dialogRef.close('accept');
    //  this.onAcceptCallback();
    }

    remindMeLater(): void{
      const dialogContent = document.querySelector('div[mat-dialog-content]');
      const dialogAction = document.querySelector('div[mat-dialog-actions]');
      const dialogImages = document.getElementById('modal-content-images');
      if (dialogContent) {
        dialogContent.classList.add('hidden');
      }
      if (dialogAction) {
        dialogAction.classList.add('hidden');
      }
      if (dialogImages) {
        dialogImages.classList.add('hidden');
      }  
       // Show additional content
    this.showRemindMeLaterContent = true;
    this.message = "Sorry to See You Go!";

    }


    showAdditionalText(): void {
      // Hide the contents of the <div mat-dialog-content>
      const dialogContent = document.querySelector('div[mat-dialog-content]');
      const dialogAction = document.querySelector('div[mat-dialog-actions]');
      const dialogImages = document.getElementById('modal-content-images');
      if (dialogContent) {
        dialogContent.classList.add('hidden');
      }
      if (dialogAction) {
        dialogAction.classList.add('hidden');
      }
      if (dialogImages) {
        dialogImages.classList.add('hidden');
      }
      // Show additional content
      this.showAdditionalContent = true;
      this.message = 'Great News! Your UPS Wallet is Now Activated';
    }

    redirectToLoggedOut(): void {
      window.location.href = '/logged-out';
    }

    redirectToLoggedOutTnc(): void {
      window.location.href = '/logged-out-tnc';
    }

    onDecline(): void{
      
      if (this.showRemindMeLaterContent || this.showAdditionalContent) {
        // If the modal is in a "remind me later" or "additional content" state, redirect
        this.redirectToLoggedOut();
      } else if (this.data.walletRole === 'admin' || this.data.walletRole === 'tandc') {
        this.remindMeLater();
        this.acceptClicked.emit();
        console.log('Remind Me Later on Admin');
      } else {
        this.dialogRef.close('decline');
      }
    }

    canAccept(): boolean {
      return this.agreeTermA;
    }

}
