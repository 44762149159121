import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { GetGuidService } from './get-guid.service';
import { AuthToken, DWDPATSessionModel, sessionResponse } from '../../models/session.model';
import { DWWalletSetup } from '../../models/DWWalletSetup.interface';
import { ApiEndpoints } from 'src/config/api-endpoints';
import { PayinTokens } from '../../models/payintokens.model';

@Injectable({
  providedIn: 'root'
})
export class WalletSetupService {


    private walletSetupUrl = ApiEndpoints.walletSetup;

    httpOptions:any;

    constructor(private http: HttpClient, private getheader: GetGuidService) {
     
  }

  
  updateWalletSetup(data:any): any {
    let token=sessionStorage.getItem("headers");
    let httpOptions = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET",
        "Authorization": "bearer " + token
      }),
    };
    const url = `${this.walletSetupUrl}`;
    return this.http.post<any>(url, data, httpOptions);


}

  setNotificationPreferences(data: any): Observable<any> {
    const url = `${this.walletSetupUrl}/dwsetnotificationpreferences`;
    return this.http.post<any>(url, data);
  }
}
