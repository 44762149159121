import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UpdateMyaccountDialogComponent } from './update-myaccount-dialog/update-myaccount-dialog.component';
import { WalletSummaryService } from 'src/app/shared/services/api/wallet-summary.service';
import { WalletSummary } from 'src/app/shared/models/wallet-summary.interface';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { Router } from '@angular/router';
import { phoneNumberValidator, zipCodeValidator } from './custom-validator';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';


@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  userIniital: string;
  myForm: FormGroup;
  states = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];
  walletSummary: WalletSummary | null = null;
  payment: string = "";
  ccHolderName: any = '';
  memberSince: any = '';
  getPhone: string = '';

  displayName: string = "";

  // Sidebar settings
  myAccountActive: boolean = false;
  contactUsActive: boolean = false;
  preferencesActive: boolean = false;
  managePaymentActive: boolean = false;
  autoRedeemActive: boolean = false;

  cashEligible = false;
  rewardEligible = false;

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private profileService: ProfileService,
    private walletSummaryService: WalletSummaryService,
    private sessionService: SessionService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.myForm = this.fb.group({
      phoneNumber: ['', [Validators.required, phoneNumberValidator]],
      email: ['', [Validators.required, Validators.email]],
      streetAddress: ['', Validators.required],
      apartment: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zipCode: ['', [Validators.required, zipCodeValidator]]
    });
    this.userIniital = 'YB';

    // Set correct sidebar settings
    if (this.router?.url == '/my-account') {
      this.myAccountActive = true;
    } else if (this.router?.url == '/preferences' || this.router?.url == '/preferences/manage-payment') {
      this.preferencesActive = true;
      this.managePaymentActive = true;
    } else if (this.router?.url == '/preferences/auto-redeem') {
      this.preferencesActive = true;
      this.autoRedeemActive = true;
    } else if (this.router?.url == '/contact-us') {
      this.contactUsActive = true;
    }
  }

  onSubmit(): void {
    if (this.myForm.valid) {
      const formData = this.myForm.value;
      const dataToSend = {
        walletID: this.walletSummary?.wallet.walletID,
        walletRole: 'admin',
        phoneNo: formData.phoneNumber,
        email: formData.email,
        streetAddressLine1: formData.streetAddress,
        streetAddressLine2: formData.apartment,
        city: formData.city,
        state: formData.state,
        zip: formData.zipCode
      };

      console.log('Data being sent to the API:', dataToSend); // Log the data being sent

      this.profileService.setCustomerProfile(dataToSend).subscribe({
        next: (response) => {
          console.log('Profile updated successfully:', response);
        },
        error: (error) => {
          console.error('Error updating profile:', error);
          console.error('Failed request payload:', dataToSend); // data that failed to be processed correctly
        }
      });
    } else {
      console.error('Form is not valid:', this.myForm.value);
    }
  }

  ngOnInit(): void {
    this.walletSummaryService.walletSummary$.subscribe(summary => {
      if (summary && summary.wallet) {
        this.walletSummary = summary;
        this.cashEligible = summary.wallet.cashEligible === "True";
        this.rewardEligible = summary.wallet.rewardEligible === "True";
        this.ccHolderName = summary.wallet.payment.ccHolderName;
        this.memberSince = summary.wallet.tcAcceptanceDate;

        this.profileService.getCustomerProfile(summary.wallet.walletID, 'admin').subscribe(profile => {
          if (profile.success) {
            this.myForm.patchValue({
              phoneNumber: profile.phoneNo,
              email: profile.email,
              streetAddress: profile.address.streetAddressLine1,
              apartment: profile.address.streetAddressLine2,
              city: profile.address.city,
              state: profile.address.state,
              zipCode: profile.address.zipCode
            });
          }
        });
      }
    });

    this.sessionService.getDisplayName().subscribe(displayName => {
      this.displayName = displayName;
    });

  }

  updateMyAccount() {
    if (this.myForm.valid) {
      // const modalRef = this.modalService.open(UpdateMyaccountDialogComponent);
      let config = new MatDialogConfig();
      config.autoFocus = false;

      let dialogRef = this.dialog.open(UpdateMyaccountDialogComponent, config);
    } else {
      this.myForm.markAllAsTouched(); // Mark all fields as touched to show validation messages
      console.error('Form is not valid:', this.myForm.value);
    }
  }

  loadDisplayName() {
    this.sessionService.getDisplayName().subscribe(displayName => {
      this.displayName = displayName;
    });
  }
}
