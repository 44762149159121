import { Component } from '@angular/core';

@Component({
  selector: 'app-my-cash',
  templateUrl: './my-cash.component.html',
  styleUrls: ['./my-cash.component.scss']
})
export class MyCashComponent {

}
