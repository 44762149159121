import { Component } from '@angular/core';

@Component({
  selector: 'app-logged-out-tnc',
  templateUrl: './logged-out-tnc.component.html',
  styleUrls: ['./logged-out-tnc.component.scss']
})
export class LoggedOutTncComponent {

}
