<ng-container *ngIf="!isLoading; else loadingTemplate">



<div class="sub-container">

    <div class="tile-container">
       
             <!--  Rewards Block -->  

        <div class="tile reward-pts" [ngClass]="{'disabled-tile': cashEligible && !rewardEligible}" (click)="showTooltip($event, 'rewards')" #rewardsTile>
            <div class="icon-container">
              <div class="svg-icon rewards-icon">
                <img src="../../../../assets/icons/reward_icon.png" height="18px" width="18px">
              </div>
              <div class="tile-title mobile">
                <span class="value" *ngIf="walletSummary">{{ walletSummary.wallet.rewards.rewardsEarnedThisMo | number }} pts</span>
              </div>
              <div class="button">
                <button mat-icon-button [matMenuTriggerFor]="menu" [disabled]="cashEligible && !rewardEligible">
                  <mat-icon>more_horiz</mat-icon>
                </button>
              </div>
            </div>
            <mat-menu #menu="matMenu" class="redeem-menu" [overlapTrigger]="false" (closed)="onMenuClosed()">
              <ul class="flyout-menu-list">
                <li *ngIf="autoPay === 0"><a [routerLink]="['/my-wallet', 'all-rewards', 'redeem']" (click)="onLinkClicked($event)">Redeem my points</a></li>
                <li><a [routerLink]="['/my-wallet', 'all-rewards']" (click)="onLinkClicked($event)">View my rewards</a></li>
                <li><a [routerLink]="['/my-wallet', 'all-rewards', 'ways-to-earn']" (click)="onLinkClicked($event)">Earn more points</a></li>
              </ul>
            </mat-menu>
            <div class="lock-icon" *ngIf="cashEligible && !rewardEligible">
              <svg xmlns="http://www.w3.org/2000/svg" width="76" height="76" viewBox="0 0 76 76" fill="none">
                <g opacity="0.9">
                  <circle cx="38" cy="38" r="38" fill="#E9E9E9" fill-opacity="0.95" />
                  <mask id="mask0_10988_85173" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="14" y="14" width="48" height="48">
                    <rect x="14" y="14" width="48" height="48" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_10988_85173)">
                    <path d="M26 58.001C24.9 58.001 23.9583 57.6093 23.175 56.826C22.3917 56.0426 22 55.101 22 54.001V34.001C22 32.901 22.3917 31.9593 23.175 31.176C23.9583 30.3926 24.9 30.001 26 30.001H28V26.001C28 23.2343 28.975 20.876 30.925 18.926C32.875 16.976 35.2333 16.001 38 16.001C40.7667 16.001 43.125 16.976 45.075 18.926C47.025 20.876 48 23.2343 48 26.001V30.001H50C51.1 30.001 52.0417 30.3926 52.825 31.176C53.6083 31.9593 54 32.901 54 34.001V54.001C54 55.101 53.6083 56.0426 52.825 56.826C52.0417 57.6093 51.1 58.001 50 58.001H26ZM26 54.001H50V34.001H26V54.001ZM38 48.001C39.1 48.001 40.0417 47.6093 40.825 46.826C41.6083 46.0426 42 45.101 42 44.001C42 42.901 41.6083 41.9593 40.825 41.176C40.0417 40.3926 39.1 40.001 38 40.001C36.9 40.001 35.9583 40.3926 35.175 41.176C34.3917 41.9593 34 42.901 34 44.001C34 45.101 34.3917 46.0426 35.175 46.826C35.9583 47.6093 36.9 48.001 38 48.001ZM32 30.001H44V26.001C44 24.3343 43.4167 22.9176 42.25 21.751C41.0833 20.5843 39.6667 20.001 38 20.001C36.3333 20.001 34.9167 20.5843 33.75 21.751C32.5833 22.9176 32 24.3343 32 26.001V30.001Z" fill="#8C857E" />
                  </g>
                </g>
              </svg>
            </div>
            <div class="tile-title">
              <span class="value" *ngIf="walletSummary">{{ walletSummary.wallet.rewards.rewardsEarnedThisMo | number }} pts</span>
            </div>
            <div class="tile-info">
              Rewards points earned this month
            </div>
            <div class="tooltip" *ngIf="tooltipVisibleForRewards" #tooltipRewards>
              <button class="tooltip-close" (click)="hideTooltip($event)">×</button>
              <p>Unlock rewards and save by upgrading policy. To learn more, speak with one of our experts at 1-877-242-7930 and uscinfo&#64;ups.com.</p>
            </div>
          </div>
    
      
           <!--  Cash Block -->  
        
        <div class="tile cash-received">
    
            <div class="icon-container">
                <div class="svg-icon cash-icon">
                    <img src="../../../../assets/icons/cash_icon.png" height="18px" width="18px">
                </div>
    
                <div class="tile-title mobile">
                    <span class="value" *ngIf="walletSummary">{{ walletSummary.wallet.cash.cashEarnedThisMo| currency}} </span>
                </div>

                <div class="button">
                    <button mat-icon-button [matMenuTriggerFor]="menuCash">
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                </div>
            </div>
    
            <mat-menu #menuCash="matMenu" class="redeem-menu" [overlapTrigger]="false" (closed)="onMenuClosed()">
                <ul class="flyout-menu-list">
                    <li *ngIf="autoPay === 0"><a *ngIf="!(cashEligible && !rewardEligible)" [routerLink]="['/my-wallet', 'all-rewards', 'redeem']" (click)="onLinkClicked($event)">Redeem my cash</a></li>
                    <li><a [routerLink]="['/my-wallet', 'my-cash', 'all-cash']" (click)="onLinkClicked($event)">View my cash</a></li>
                    <li><a [routerLink]="['/my-wallet', 'my-cash', 'transfer-balance']"   (click)="onLinkClicked($event)">Transfer to bank</a></li>
                </ul> 
              </mat-menu>
              
    
            <div class="tile-title">
                <span class="value" *ngIf="walletSummary">{{ walletSummary.wallet.cash.cashEarnedThisMo| currency}} </span>
            </div>
            <div class="tile-info">
                Cash received this month
            </div>
           
        </div>

        <!--  Bank Block -->  
        
        <div class="tile bank" [ngClass]="{'disabled-tile': cashEligible && !rewardEligible}" (click)="showTooltip($event, 'banks')" >
            <div class="icon-container">
            <div class="svg-icon bank-icon"> 
                <img src="../../../../assets/icons/bank_icon.png" height="18px" width="18px">
            </div>
    
            <div class="tile-title mobile">
              <span class="value" *ngIf="walletSummary && !(cashEligible && !rewardEligible) && !(!defaultPayment)">
                {{ walletSummary.wallet.payment.cardType }}(**** {{ walletSummary.wallet.payment.last4Digits | noComma }})
              </span>
                    <span class="value" *ngIf="walletSummary && !(cashEligible && !rewardEligible) && autoPay === 0">
                        <a [routerLink]="['/preferences', 'manage-payment']" (click)="onLinkClicked($event)">Set My Default
                            <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                        </a>
                    </span>
                    <span *ngIf="cashEligible && !rewardEligible" class="value">N/A</span> 
            </div>

            <div class="button">
                <button mat-icon-button [matMenuTriggerFor]="menuBank" [disabled]="cashEligible && !rewardEligible">
                    <mat-icon>more_horiz</mat-icon>
                </button>
            </div>
        </div>
    
        <mat-menu #menuBank="matMenu" class="redeem-menu" [overlapTrigger]="false" (closed)="onMenuClosed()">
            <ul class="flyout-menu-list">
                <li><a [routerLink]="['/preferences', 'manage-payment']" (click)="onLinkClicked($event)">Manage Payment Method</a></li>
                <li><a [routerLink]="['/my-wallet', 'all-payments']" (click)="onLinkClicked($event)">View Payment History</a></li>
            </ul> 
          </mat-menu>
        
          <div class="lock-icon" *ngIf="cashEligible && !rewardEligible">
            <svg xmlns="http://www.w3.org/2000/svg" width="76" height="76" viewBox="0 0 76 76" fill="none">
                <g opacity="0.9">
                  <circle cx="38" cy="38" r="38" fill="#E9E9E9" fill-opacity="0.95"/>
                  <mask id="mask0_10988_85173" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="14" y="14" width="48" height="48">
                    <rect x="14" y="14" width="48" height="48" fill="#D9D9D9"/>
                  </mask>
                  <g mask="url(#mask0_10988_85173)">
                    <path d="M26 58.001C24.9 58.001 23.9583 57.6093 23.175 56.826C22.3917 56.0426 22 55.101 22 54.001V34.001C22 32.901 22.3917 31.9593 23.175 31.176C23.9583 30.3926 24.9 30.001 26 30.001H28V26.001C28 23.2343 28.975 20.876 30.925 18.926C32.875 16.976 35.2333 16.001 38 16.001C40.7667 16.001 43.125 16.976 45.075 18.926C47.025 20.876 48 23.2343 48 26.001V30.001H50C51.1 30.001 52.0417 30.3926 52.825 31.176C53.6083 31.9593 54 32.901 54 34.001V54.001C54 55.101 53.6083 56.0426 52.825 56.826C52.0417 57.6093 51.1 58.001 50 58.001H26ZM26 54.001H50V34.001H26V54.001ZM38 48.001C39.1 48.001 40.0417 47.6093 40.825 46.826C41.6083 46.0426 42 45.101 42 44.001C42 42.901 41.6083 41.9593 40.825 41.176C40.0417 40.3926 39.1 40.001 38 40.001C36.9 40.001 35.9583 40.3926 35.175 41.176C34.3917 41.9593 34 42.901 34 44.001C34 45.101 34.3917 46.0426 35.175 46.826C35.9583 47.6093 36.9 48.001 38 48.001ZM32 30.001H44V26.001C44 24.3343 43.4167 22.9176 42.25 21.751C41.0833 20.5843 39.6667 20.001 38 20.001C36.3333 20.001 34.9167 20.5843 33.75 21.751C32.5833 22.9176 32 24.3343 32 26.001V30.001Z" fill="#8C857E"/>
                  </g>
                </g>
              </svg>
          </div>
    
    
            <div class="tile-title">
                <div class="tile-title">
                  <span class="value" *ngIf="walletSummary && !(cashEligible && !rewardEligible) && !(!defaultPayment)">
                    {{ walletSummary.wallet.payment.cardType }}(**** {{ walletSummary.wallet.payment.last4Digits | noComma }})
                  </span>                     
                  <span class="value" *ngIf="walletSummary && !(cashEligible && !rewardEligible)  && (!defaultPayment)">
                        <a [routerLink]="['/preferences', 'manage-payment']" (click)="onLinkClicked($event)">Set My Default
                            <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                        </a> 
                    </span>
                    <span *ngIf="cashEligible && !rewardEligible" class="value">N/A</span> 
                </div>
            </div>
            <div class="tile-info">
                My default payment method
            </div>
            <div class="tooltip" *ngIf="tooltipVisibleForBank" #tooltipBank>
              <button class="tooltip-close" (click)="hideTooltip($event)">×</button>
              <p>Unlock rewards and save by upgrading policy. To learn more, speak with one of our experts at 1-877-242-7930 and uscinfo&#64;ups.com.</p>
            </div>
        
        </div>
        
        <!-- Auto Redeem Block -->

        <div class="tile auto-redeem" [ngClass]="{'disabled-tile': cashEligible && !rewardEligible}" (click)="showTooltip($event, 'autoRedeem')">
            <div class="icon-container">
            <div class="svg-icon auto-redeem-icon">
                <img src="../../../../assets/icons/autoRedeem_icon.png" height="18px" width="18px">
            </div>

            <div class="tile-title mobile">
                <span *ngIf="!(cashEligible && !rewardEligible)" class="value">Auto-Redeem</span> 
                <span *ngIf="cashEligible && !rewardEligible" class="value">N/A</span> 
                <span class="on-off on-mode" *ngIf="!(cashEligible && !rewardEligible) && autoPay === 1">ON</span>
                <span class="on-off" *ngIf="!(cashEligible && !rewardEligible) && autoPay === 0">OFF</span>
                <!-- <span class="on-off" *ngIf="walletSummary?.wallet?.defaultPaymentMethod ==='False' "> OFF</span>
                <span *ngIf="walletSummary?.wallet?.defaultPaymentMethod === 'undefined' && walletSummary?.wallet?.defaultPaymentMethod === 'True'">ON</span>  -->
             </div>
    
            <div class="button">
                <button mat-icon-button [matMenuTriggerFor]="menuRedeem" [disabled]="cashEligible && !rewardEligible">
                    <mat-icon>more_horiz</mat-icon>
                </button>
            </div>
        </div>

        <mat-menu #menuRedeem="matMenu" class="redeem-menu" [overlapTrigger]="false" (closed)="onMenuClosed()">
            <ul class="flyout-menu-list">
                <!-- <li><a [routerLink]="['/preferences', 'auto-redeem']"   (click)="onLinkClicked($event)">Turn off Auto-Redeem</a></li> -->
                <li *ngIf="autoPay === 1">
                    <a [routerLink]="['/preferences', 'auto-redeem']" (click)="onLinkClicked($event)">Turn off Auto-Redeem</a>
                </li>
                <!-- Show "Turn on Auto-Redeem" when cashEligible is false and rewardEligible is true -->
                <li *ngIf="autoPay === 0">
                    <a [routerLink]="['/preferences', 'auto-redeem']" (click)="onLinkClicked($event)">Turn on Auto-Redeem</a>
                </li>
            </ul> 
          </mat-menu>
          
    
          <div class="lock-icon" *ngIf="cashEligible && !rewardEligible">
            <svg xmlns="http://www.w3.org/2000/svg" width="76" height="76" viewBox="0 0 76 76" fill="none">
                <g opacity="0.9">
                  <circle cx="38" cy="38" r="38" fill="#E9E9E9" fill-opacity="0.95"/>
                  <mask id="mask0_10988_85173" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="14" y="14" width="48" height="48">
                    <rect x="14" y="14" width="48" height="48" fill="#D9D9D9"/>
                  </mask>
                  <g mask="url(#mask0_10988_85173)">
                    <path d="M26 58.001C24.9 58.001 23.9583 57.6093 23.175 56.826C22.3917 56.0426 22 55.101 22 54.001V34.001C22 32.901 22.3917 31.9593 23.175 31.176C23.9583 30.3926 24.9 30.001 26 30.001H28V26.001C28 23.2343 28.975 20.876 30.925 18.926C32.875 16.976 35.2333 16.001 38 16.001C40.7667 16.001 43.125 16.976 45.075 18.926C47.025 20.876 48 23.2343 48 26.001V30.001H50C51.1 30.001 52.0417 30.3926 52.825 31.176C53.6083 31.9593 54 32.901 54 34.001V54.001C54 55.101 53.6083 56.0426 52.825 56.826C52.0417 57.6093 51.1 58.001 50 58.001H26ZM26 54.001H50V34.001H26V54.001ZM38 48.001C39.1 48.001 40.0417 47.6093 40.825 46.826C41.6083 46.0426 42 45.101 42 44.001C42 42.901 41.6083 41.9593 40.825 41.176C40.0417 40.3926 39.1 40.001 38 40.001C36.9 40.001 35.9583 40.3926 35.175 41.176C34.3917 41.9593 34 42.901 34 44.001C34 45.101 34.3917 46.0426 35.175 46.826C35.9583 47.6093 36.9 48.001 38 48.001ZM32 30.001H44V26.001C44 24.3343 43.4167 22.9176 42.25 21.751C41.0833 20.5843 39.6667 20.001 38 20.001C36.3333 20.001 34.9167 20.5843 33.75 21.751C32.5833 22.9176 32 24.3343 32 26.001V30.001Z" fill="#8C857E"/>
                  </g>
                </g>
              </svg>
          </div>
    
            <div class="tile-title">
                <span *ngIf="!(cashEligible && !rewardEligible)" class="value">Auto-Redeem</span> 
                <span *ngIf="cashEligible && !rewardEligible" class="value">N/A</span> 
                <span class="on-off on-mode" *ngIf="!(cashEligible && !rewardEligible) && autoPay === 1">ON</span>
               <span class="on-off" *ngIf="!(cashEligible && !rewardEligible) && autoPay === 0">OFF</span>
               <!-- <span class="on-off" *ngIf="walletSummary?.wallet?.defaultPaymentMethod ==='False' "> OFF</span>
               <span *ngIf="walletSummary?.wallet?.defaultPaymentMethod === 'undefined' && walletSummary?.wallet?.defaultPaymentMethod === 'True'">ON</span>  -->
            </div>
            <div class="tile-info">
                Preference setting
            </div>
            <div class="tooltip" *ngIf="tooltipVisibleForAutoRedeem" #tooltipAutoRedeem>
              <button class="tooltip-close" (click)="hideTooltip($event)">×</button>
                <p>Unlock rewards and save by upgrading policy. To learn more, speak with one of our experts at 1-877-242-7930 and uscinfo&#64;ups.com.</p>
            </div>
    
        
        </div>
    
    </div>
    </div>

  </ng-container>

    <ng-template #loadingTemplate>
      <app-skeleton type="text"></app-skeleton>
      <app-skeleton type="button"></app-skeleton>
    </ng-template>