<div *ngIf="isNonInstantConfirmation">
    <div class="sub-container outerdiv">

        <div class="modal-terms">


            <img id="nonrtp" src="../../../../assets/icons/nonRTP.png" />

        </div>
        <div>
            <div class="headertxt">
                <label class="wd-117">Want Your Money Faster?</label>
            </div>


            <!-- Your terms and conditions content here -->
            <div class="div-subtxt">
                <span class="subtxt wd-74">Skip the wait and access your money immediately - use Instant Transfer to get your
                    bank transfers done within minutes!</span>

            </div>
            <div class="button-container">
                <div class="btn">
                    <button (click)="onAccept()" type="submit" class="btn-balance dw-primary-button">
                        <span class="mdc-button__label">
                            <span class="button-label">
                                <span>Transfer Instantly </span><img src="../../../../assets/icons/bolt.png" />

                            </span>
                        </span>
                    </button>
                </div>
                <div class="btn">
                    <button class="dw-secondary-button icon-right transfer-btn" (click)="onDecline()">No,
                        thanks<mat-icon class="blue">keyboard_arrow_right</mat-icon></button>
                </div>

            </div>

            <!-- <button mat-button (click)="onAccept()" [disabled]="!canAccept()">Accept</button>
    <button mat-bloseutton (click)="onDecline()">Decline</button> -->
        </div>
    </div>
</div>
<div *ngIf="isDeleteConfirmed">
<div class="generic-sub-container">
    
    <div class="generic-modal-terms">
        <mat-icon class="ml"(click)="onDecline()">close</mat-icon>
    <h2 class="headline-highlight"><p>Sure to Delete Default</p>
        <p>Payment method?</p>
    </h2>
    

  <div class="dialog-content">
    <!-- Your terms and conditions content here -->
    <p>If you delete your default payment method, you will be</p>
    <p>automatically unenrolled from automatic payments if</p>
    <p>you are currently enrolled in auto-pay.</p>
    </div>


    <div class="generic-button-container">
        <div class="">
            <button (click)="onAccept()"  class="btn-balance dw-primary-button">
                        <span>
                           Thank you
                        </span>
                        <mat-icon class="black">keyboard_arrow_right</mat-icon>
            </button>
        </div>
        
    </div>
</div>
</div>
</div>

<div *ngIf="isUpdateConfirmed">
    <div class="generic-sub-container">
        
        <div class="generic-modal-terms">
                <mat-icon class="ml"(click)="onDecline()">close</mat-icon>
        <h2 class="headline-highlight">Update Confirmed!</h2>
        
    
      <div class="dialog-content">
        <!-- Your terms and conditions content here -->
        <p>Your default payment method has been successfully</p>
        <p>set up! You may now complete <b><i>Automatic Payments </i></b></p>
        <p>or <b><i>Redemption</i></b></p>
        </div>
    
    
        <div class="generic-button-container">
            <div class="">
                <button (click)="onAccept()"  class="btn-balance dw-primary-button">
                            <span>
                               Thank you
                            </span>
                            <mat-icon class="black">keyboard_arrow_right</mat-icon>
                </button>
            </div>
            
        </div>
    </div>
    </div>
    </div>