import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiEndpoints as API } from 'src/config/api-endpoints';
import { environment as ENV } from 'src/environments/environment';
import { ONLSessionDetails, WalletDetails, WalletPayload } from '../models/user.interface';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  walletDetails: WalletDetails = {};
  ONLSessionDetails: ONLSessionDetails = {};

  constructor(
    private http: HttpClient
  ) { }

  // Getter functions
  getWalletDetails() {
    this.walletDetails = JSON.parse(sessionStorage.getItem('walletDetails')!);
    return this.walletDetails;
  }

  getONLSessionDetails() {
    this.ONLSessionDetails = JSON.parse(sessionStorage.getItem('ONLWalletSession')!);
    return this.ONLSessionDetails;
  }

  // API Calls
  getWalletSummary(details: WalletPayload) { // I used an interface here, but angular/typescript is super lenient to the 'any' type, so you really don't have to.
    let wallettoken=sessionStorage.getItem("walletheaders");
    let httpOptions = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET",
        'Content-Type': 'application/json',
        "Authorization": "bearer " + wallettoken
      }),
    };
    return this.http.post<any>(`${ENV.walletbaseApiUrl}${API.getWalletSummary}`, details, httpOptions);
  }

  getAutoPaySequence(details: any) {
    let wallettoken=sessionStorage.getItem("walletheaders");
    let httpOptions = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET",
        'Content-Type': 'application/json',
        "Authorization": "bearer " + wallettoken
      }),
    };
    return this.http.post<any>(`${ENV.walletbaseApiUrl}${API.getAutoPaySequence2}`, details, httpOptions);
  }

  isRewardEligible() {
    let walletDetails = JSON.parse(sessionStorage.getItem('walletDetails'));
    return walletDetails?.rewardEligible == 'True' ? true : false;
  }
}
