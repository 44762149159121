<div class="loading-container" *ngIf="loading">
    <div class="loading">
        <div class="spinner-border" role="status">
            <span class="sr-only"></span>
        </div>
    </div>
</div>
<div *ngIf="autoPay === 0&& !loading  && showBanner" class="banner">
    <div class="banner-content">
        <span class="banner-text"><img src="../../../../assets/icons/Error_16x16.svg"> Do you want to use your cash to
            pay your bills?</span>
        <div class="btn">
            <button (click)="gotoPayMyBill()" mat-raised-button
                class="btn-balance mdc-button mdc-button--raised mat-mdc-raised-button primary">
                <span class="mdc-button__label">
                    <span class="button-label">
                        <span>Pay My Bills</span>
                        <mat-icon
                            class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                    </span>
                </span>
            </button>
        </div>
        <div class="spacer"></div>
        <button class="close-btn" (click)="closeBanner()">
            <i class="material-icons">close</i>
        </button>
    </div>
</div>


<div class="sub-container" *ngIf="!loading">
    <h2 class="headline-highlight">Cash Transfer</h2>

    <div class="transfer-options trans-margin">
        <label class="labeltxt">TRANSFER TYPE</label><br>
    </div>
    <div class="transfer-options">

        <div class="box-border option" id="instant" (click)="selectedtile(true)">
            <img src="assets/icons/bolt.svg">
            <h4>Instant</h4>
            <p class="info">1.5% service fee will be applied</p>
        </div>
        <div class="box-border option" id="non-instant" (click)="selectedtile(false)">
            <img alt="bank-logo" src="assets/icons/account_balance.svg">
            <h4>1-3 business days</h4>
            <p class="info">No service fee</p>
        </div>
    </div>

    <div class="transfer-to">
        <label class="labeltxt">TRANSFER ACCOUNT</label>

        <div class="box-rounded">

            <div *ngIf="nopayments">
                <div class="add-payment" (click)="addPaymentMethod()">
                    <img src="../../../../assets/icons/add.svg"><a>Add New Transfer Account</a>
                </div>
            </div>
            <div *ngIf="!nopayments">
                
                    <div *ngFor="let ectoken of payoutaccounts">
                        <div class="tokendiv">
                            <div class="iconbox"><img class="pay-icon" src="{{ectoken.cardImg}}">
                            </div>
                            <div class="pay-info" *ngIf="ectoken.achIndicator =='T'">

                                <span>{{ectoken.counterpartyPayOrg}}&nbsp;{{ectoken.counterPartyBankAccountCategory}}&nbsp;{{ectoken.counterPartyBankAccountType}}</span>
                                <span>Bank **** {{ectoken.counterPartyAccountNumber}}</span>
                            </div>
                            <div class="pay-info" *ngIf="ectoken.zelleIndicator =='T'">
                                <span>ZELLE</span>
                                <span>{{ectoken.counterPartyAccountNumber}}</span>
                            </div>
                        </div>
                        <div *ngIf="!ectoken.rtpEligible && ectoken.achIndicator =='T'" class="style_italic">
                            *This bank does not support instant transfer
                        </div>

                    </div>

                
            </div>
        </div>
        <div class="dv-autotransfer">
            <label class="labeltxt">AUTO-TRANSFER</label>
            <span class="mat-toggle labeltxt ml-auto">
                <label class="labeltxt toggletxt">Off</label>
                <mat-slide-toggle [(ngModel)]="autoTransferToggle" (change)="onToggleChange($event)"></mat-slide-toggle>
                
                <label class="labeltxt toggletxt">On</label>
            </span>
        </div>


        <p class="manage-transfer" *ngIf="!nopayments">
            <a class="" (click)="blurRest()">Manage Auto Transfer
                <mat-icon
                    class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
            </a>
        </p>
    </div>

    <div class="validation" *ngIf="errorMessage!=''">
        <img src="../../../../../assets/icons/error-24.png">
        <div>
            <div id="errormsg">Please try again</div>
            &nbsp;<div name="errorMessage">{{errorMessage}}</div>
        </div>
    </div>
    
</div>

