<div class="sub-container">
    <h2 class="headline-highlight">Connected Solutions</h2>
<div class="connected-solution">
    <div class="column">
     
      <div class="content">
        <img src="../../../assets/images/connected_service3.jpg" alt="Conected Service">
      <h3>DeliveryDefense™ Address Confidence</h3>
      <p class="connected"><span>Connected</span></p>
        <div class="policy-info">
            <p class="title">Policy Number</p>
            <p class="info"> {{ deliveryDefencePolicyNo }}</p>
       </div>
   
        <div class="links">
          <a href="https://www.insureshield.com/us/en/delivery-defense.html" target="_blank">Learn More
          <span class="icon"> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <mask id="mask0_13370_60553" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
              <rect width="20" height="20" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_13370_60553)">
              <path d="M4.16667 17.5C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H10V4.16667H4.16667V15.8333H15.8333V10H17.5V15.8333C17.5 16.2917 17.3368 16.684 17.0104 17.0104C16.684 17.3368 16.2917 17.5 15.8333 17.5H4.16667ZM8.08333 13.0833L6.91667 11.9167L14.6667 4.16667H11.6667V2.5H17.5V8.33333H15.8333V5.33333L8.08333 13.0833Z" fill="#0662BB"/>
            </g>
          </svg></span>
        </a>
      </div>
    </div>
    </div>
    
    <div class="column">
    
      
      <div class="content">
        <img src="../../../assets/images/connected_service2.jpg" alt="Connected Service 2">
        <h3>InsureShield<sup>®</sup> Shipping Insurance</h3>
      <p class="connected"><span>Connected</span></p>
        <div class="policy-info">
            <p class="title">Policy Number</p>
            <p class="info">{{ insureShieldPolicyNo }}</p>

      </div>

      <div class="policy-info">
        <p class="title">Policy Effective Date</p>
        <p class="info">{{ insureShieldPolicyStartDate | date }} - {{ insureShieldPolicyEndDate | date}}</p>
      </div>

        <div class="links">
         
            <a href="https://www.insureshield.com/us/en/home.html" target="_blank">Learn More
              <span class="icon"> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <mask id="mask0_13370_60553" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                  <rect width="20" height="20" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_13370_60553)">
                  <path d="M4.16667 17.5C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H10V4.16667H4.16667V15.8333H15.8333V10H17.5V15.8333C17.5 16.2917 17.3368 16.684 17.0104 17.0104C16.684 17.3368 16.2917 17.5 15.8333 17.5H4.16667ZM8.08333 13.0833L6.91667 11.9167L14.6667 4.16667H11.6667V2.5H17.5V8.33333H15.8333V5.33333L8.08333 13.0833Z" fill="#0662BB"/>
                </g>
              </svg></span>               
            </a>
        </div>
    </div>
    </div>
  </div>
  <div class="connected-solution-bottom">
    <div class="contact-us">
      <img src="../../../assets/images/connected_service1.jpg" class="contact-us-photo">
      <div>
        <div class="contact-title">Contact Us</div>
        <div class="global-yellow-bar"></div>
        <div class="contact-text">Chat with a representative today.</div>
        <button class="dw-primary-button icon-right" (click)="contactUs()">Contact Us<mat-icon>keyboard_arrow_right</mat-icon></button>
      </div>
    </div>
  </div>
</div>


