import { Component, Inject, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-modal-default-payment',
  templateUrl: './modal-default-payment.component.html',
  styleUrls: ['./modal-default-payment.component.scss']
})
export class ModalDefaultPaymentComponent {
  origin: string;

 constructor(
  public dialogRef: MatDialogRef<ModalDefaultPaymentComponent>,
  public router:Router, 
  public route:ActivatedRoute,
  private ngZone: NgZone,
  @Inject(MAT_DIALOG_DATA) public data: any // We re-use this component in multiple places so display text accordingly
 ){
  this.origin = data?.origin || 'set-default';
 }

 cancelSetDefaultPayment() {
  this.dialogRef.close('back');  // Close the modal first

  // Use a short delay to allow the modal to close before navigating
    if (this.data.origin !== 'ways-to-earn') {
    setTimeout(() => {
      this.router.navigate(['/home']); // Navigate to the home page
    }, 100); // 100ms delay to ensure smooth navigation
  }
}


  gotoSetDefaultPayment(event: MouseEvent) {
    console.log('Button clicked');
    event.preventDefault();
    this.dialogRef.close('accept');
    
    // Use a short delay to allow the dialog to close completely
    setTimeout(() => {
      this.router.navigate(['/preferences/manage-payment'], {
        queryParams: { from: this.origin }
      });
    }, 100); // 100ms should be sufficient
  }

}
