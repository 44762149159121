import { Component } from '@angular/core';

@Component({
  selector: 'app-connected-products',
  templateUrl: './connected-products.component.html',
  styleUrls: ['./connected-products.component.scss']
})
export class ConnectedProductsComponent {

}
