import { Component, Inject, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WalletSetupService } from '../../services/api/wallet-setup.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dynamic-popup',
  templateUrl: './dynamic-popup.html',
  styleUrls: ['./dynamic-popup.scss']
})
export class DynamicPopupComponent {

  title:string='';
  content:string='';
  actionbtnContext:string='';
  cancelbtnText:string='';
  isNonInstantConfirmation:boolean=false;
  isUpdateConfirmed:boolean=false;
  isDeleteConfirmed:boolean=false;


  //onAcceptCallback: () => void;

  constructor(public dialogRef: MatDialogRef<DynamicPopupComponent>,private router:Router,
    @Inject(MAT_DIALOG_DATA) public data:any){
      
      if(data=="NonInstantConfirmation"){
this.isNonInstantConfirmation=true;
      }
      if(data=="UpdateConfirmed"){
        this.isUpdateConfirmed=true;
        this.dialogRef.updateSize('592px');
              }
              if(data=="DeleteConfirmed"){
                this.isDeleteConfirmed=true;
                this.dialogRef.updateSize('592px');
              }
    }


    ngOnInit()
    {
      
     

    }

    onAccept(): void{

      this.dialogRef.close('accept');
      
      
     // this.router.navigate(['/preferences/auto-redeem']);

    }

    onDecline(): void{
      this.dialogRef.close('close');
    }

}
