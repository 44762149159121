import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-payment-confirmation',
  templateUrl: './payment-confirmation.component.html',
  styleUrls: ['./payment-confirmation.component.scss']
})
export class PaymentConfirmationComponent {

  loading: boolean = true;

  walletDetails;
  paidInvoiceDetails;
  cashPointsEarned;
  achPointsEarned;
  earlyPointsEarned;
  totalPointsEarned;

  constructor(
    public router:Router,
    private userService: UserService
  ){}

  ngOnInit() {
    this.paidInvoiceDetails = JSON.parse(sessionStorage.getItem('paidInvoice'));
    if (this.paidInvoiceDetails) {
      this.cashPointsEarned = this.paidInvoiceDetails.cashPointsEarned;
      this.achPointsEarned = this.paidInvoiceDetails.achPointsEarned;
      this.earlyPointsEarned = this.paidInvoiceDetails.earlyPointsEarned;
      this.totalPointsEarned = Number(parseInt(this.cashPointsEarned) + parseInt(this.achPointsEarned) + parseInt(this.earlyPointsEarned)).toFixed(0);

      this.getWalletDetails(); // Once we make an invoice payment, we need to update wallet details. But even if it fails, show the details of the invoice they just paid
    } else {
      this.router.navigate(['home']);
    }
  }

  ngOnDestroy() {
    sessionStorage.removeItem('paidInvoice');
  }

  backToRewards(){
    this.router.navigate(['my-wallet/all-rewards/']);
  }

  getWalletDetails() {
    this.loading = true;
    let request = {
      walletID: JSON.parse(sessionStorage.getItem('WalletID')!),
      walletRole: JSON.parse(sessionStorage.getItem('WalletRole')!)
    }
    this.userService.getWalletSummary(request).subscribe(
      data => {       
        if (data?.wallet) {
          sessionStorage.setItem('walletDetails', JSON.stringify(data.wallet));
          this.walletDetails = data.wallet;
        } else {
          // Try to retrieve information from sessionStorage.
          this.walletDetails = JSON.parse(sessionStorage.getItem('walletDetails')!);
        }

        window.scrollTo(0, 0);
        this.loading = false;
      }, error => {
        // Even if the API fails, we can try to retrieve the information from sessionStorage
        this.walletDetails = JSON.parse(sessionStorage.getItem('walletDetails')!);
        window.scrollTo(0, 0);
        this.loading = false;
      }
    );
  }
}
