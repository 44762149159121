import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ApiEndpoints } from "src/config/api-endpoints";
import { DWSetAutoTransferRequest, DWSetAutoTransferResponse } from "../../models/AutoTransfer.model";
import { ApiEndpoints as API } from 'src/config/api-endpoints';
import { environment as ENV } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
  })

export class AutoTransferService{

    private setautotransferurl = ApiEndpoints.setautotransfer;

  httpOptions:any;
 
  constructor(private http: HttpClient, public router: Router) { 
    
}
setautotransfer(isAutoTransfer:boolean, payeeID:string,transferType:string,cashBalance:string) {

  const walletDetails = JSON.parse(sessionStorage.getItem('walletDetails') || '{}');
  const setAutoTransferRequest: DWSetAutoTransferRequest = {
    cashAmount: parseFloat(cashBalance),
    isAutoTransfer,
    payeeID,
    transferType,
    walletID: JSON.parse(sessionStorage.getItem("WalletID") || '{}'),
    walletRole: JSON.parse(sessionStorage.getItem("WalletRole") || '{}'),
    sessionID: sessionStorage.getItem("SessionID"),
  };


  // if (walletDetails) {
  //   cashBalance= parseFloat(walletDetails.cash.totalCashBalance.toString()).toFixed(2);
    
  //   }
      // let setautotransfer=new DWSetAutoTransferRequest();
  
      // setautotransfer.cashAmount=parseFloat(cashBalance);
      // setautotransfer.isAutoTransfer=isAutoTransfer;
      // setautotransfer.payeeID=payeeID;
      // setautotransfer.transferType=transferType;
      // setautotransfer.walletID=JSON.parse(sessionStorage.getItem("WalletID") || '{}');
      // setautotransfer.walletRole=JSON.parse(sessionStorage.getItem("WalletRole") || '{}');

    const wallettoken=sessionStorage.getItem("walletheaders");
    let httpOptions = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET",
        'Content-Type': 'application/json',
        "Authorization": "bearer " + wallettoken
      }),
    };
    return this.http.post<DWSetAutoTransferResponse>(this.setautotransferurl, setAutoTransferRequest, httpOptions);
  }


  /* updateAutoTransfer(isAutoTransfer: boolean, payeeID: string, transferType: string) {
    const walletDetails = JSON.parse(sessionStorage.getItem('walletDetails') || '{}');
    const cashBalance = walletDetails?.cash?.totalCashBalance || 0;

    const request: DWSetAutoTransferRequest = {
      cashAmount: parseFloat(cashBalance),
      isAutoTransfer,
      payeeID,
      transferType,
      walletID: JSON.parse(sessionStorage.getItem("WalletID") || '{}'),
      walletRole: JSON.parse(sessionStorage.getItem("WalletRole") || '{}'),
    };

    const wallettoken = sessionStorage.getItem("walletheaders");
    const httpOptions = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET",
        'Content-Type': 'application/json',
        "Authorization": "bearer " + wallettoken
      }),
    };

    return this.http.post<DWSetAutoTransferResponse>(
      `https://ib-wallet-euat-api.azurewebsites.net/api/DigitalWalletAPI/dwsetwalletautotransfer`,
      request,
      httpOptions
    );
  } */
}