<!-- transaction-history-table.component.html -->
<div *ngIf="payments">
    <h3>Payments</h3>
  
    <h4>Invoice Payments</h4>
    <table *ngIf="payments.invoicePayments.length > 0">
      <thead>
        <tr>
          <th>Source System</th>
          <th>Product Name</th>
          <th>Payment Date</th>
          <th>Payment Total</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let payment of payments.invoicePayments">
          <td>{{ payment.sourceSystem }}</td>
          <td>{{ payment.productName }}</td>
          <td>{{ payment.paymentDate }}</td>
          <td>{{ payment.paymentTotal }}</td>
        </tr>
      </tbody>
    </table>
    <p *ngIf="payments.invoicePayments.length === 0">No invoice payments available.</p>
  
    <h4>Claim Payments</h4>
    <table *ngIf="payments.claimPayments.length > 0">
      <thead>
        <tr>
          <th>Source System</th>
          <th>Product Name</th>
          <th>Payment Date</th>
          <th>Payment Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let payment of payments.claimPayments">
          <td>{{ payment.sourceSystem }}</td>
          <td>{{ payment.productName }}</td>
          <td>{{ payment.paymentDate }}</td>
          <td>{{ payment.paymentAmount }}</td>
        </tr>
      </tbody>
    </table>
    <p *ngIf="payments.claimPayments.length === 0">No claim payments available.</p>
  </div>
  <div *ngIf="!payments">
    <p>No payment history available.</p>
  </div>

  
  <!-- MAT TABLE: transaction-history-table.component.html -->

  <div class="mat-elevation-z8">
    <div class="search-container">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Search</mat-label>
        <input matInput [(ngModel)]="searchValue" placeholder="Type to search...">
      </mat-form-field>
      <button mat-button (click)="applyFilter(searchValue)">Search</button>
      <!-- Placeholder for radio buttons -->
      <mat-radio-group aria-label="Select a reward type">
        <mat-radio-button value="earned">Rewards Earned</mat-radio-button>
        <mat-radio-button value="redeemed">Rewards Redeemed</mat-radio-button>
      </mat-radio-group>
    </div> 
    </div>
  
<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
  
  <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox
        (change)="masterToggle()"
        [checked]="isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()"
        aria-label="Select all rows">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="selection.toggle(row)"
                    [checked]="selection.isSelected(row)"
                    aria-label="Select row">
      </mat-checkbox>
    </td>
  </ng-container>

  <!-- Date Column -->
  <ng-container matColumnDef="earnDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
    <td mat-cell *matCellDef="let element">{{ element.earnDate }}</td>
  </ng-container>

  <!-- Amount Column -->
  <ng-container matColumnDef="pointsEarned">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
    <td mat-cell *matCellDef="let element">{{ element.pointsEarned }}</td>
  </ng-container>

  <!-- Connected Solution Column -->
  <ng-container matColumnDef="productName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Connected Solution</th>
    <td mat-cell *matCellDef="let element">{{ element.productName }}</td>
  </ng-container>

  <!-- Details Column -->
  <ng-container matColumnDef="earnCategory">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Details</th>
    <td mat-cell *matCellDef="let element">{{ element.earnCategory }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

