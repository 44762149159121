<header class="app-header">
  <div class="top-nav-container">
    <div class="logo">
        <img src="../../../../assets/icons/Logo.svg" alt="logo" (click)="navigateToHome()">
    </div>

    <div class="main-menu desktop-menu">

        <a routerLink="/home" routerLinkActive="selected" [routerLinkActiveOptions]="{exact:true}" (click)="closeWalletSubmenu()">Home</a>
           
        <div class="submenu">
        <a  (click)="toggleWalletSubmenu(); $event.preventDefault()">My Wallet</a>
          
        </div>
        <a *ngIf="!(cashEligible && !rewardEligible)" routerLink="/connected-solution" routerLinkActive="selected" (click)="closeWalletSubmenu()">Connected Solutions</a>
    </div>

 <!-- Mobile menu -->
<!-- Mobile menu -->






<div class="mobile-menu">

    <span class="user-initial"><a routerLink="my-account">{{ displayName | slice:0:1 }}</a></span> 


    <span class="notifications" (click)="toggleDropdown()">
        <img [src]="hasUnreadNotifications ? '../../../../assets/icons/notifications_unread.svg' : '../../../../assets/icons/notifications.svg'">
    </span>

    <!-- Greyed-out overlay (only visible when dropdown is open) -->
    <div *ngIf="isDropdownOpen" class="overlay" (click)="toggleDropdown()"></div>           <!-- Notification Dropdown -->
            <div *ngIf="isDropdownOpen" class="notification-dropdown" (clickOutside)="closeDropdown()">
                <h3>Notifications</h3>
                <div *ngIf="notifications && notifications.length > 0; else noNotifications">
                    <div *ngFor="let notification of notifications | slice:0:10" class="notification-item"
                         [ngClass]="{
                           'unread': notification.readStatus === false,
                           'read': notification.readStatus === true
                         }">
 
                        <div class="item">
                            <div class="icon-wrapper">
                                <div class="icon">
                                    <img class="icon-notification" src="../../../../assets/icons/frame.svg">
                                </div>
                            </div>
                  
                        <div class="info">
                            <div class="row">
                            <div class="column title-column">
                                <div class="title" [innerHTML]="(notification.notificationProductName === 'ISConnect' || 
                                notification.notificationProductName === 'IS4U' || 
                                notification.notificationProductName === 'ISComplete') 
                                ? 'InsureShield<sup>&reg;</sup> Shipping Insurance' 
                                : notification.notificationProductName">
                                </div>
                            </div>

                            <div class="column data-column">
                                <div class="notification-data" [ngClass]="{
                                    'transfer-initiated': notification.notificationData === 'Transfer Initiated',
                                    'payment-initiated': notification.notificationData === 'Payment Initiated',
                                    'transfer-completed': notification.notificationData === 'Transfer Completed',
                                    'payment-completed': notification.notificationData === 'Payment Completed',
                                    'transfer-failed': notification.notificationData === 'Transfer Failed',
                                    'payment-failed': notification.notificationData === 'Payment Failed'
                                    }">
                                {{ notification.notificationData }}
                                </div>
                                <div class="date">{{ notification.notificationDate | date:'MM/dd/yyyy' }}</div>
                                </div>
                            </div>
                               <div class="details">{{ notification.notificationText }}</div>
                        </div>
                        </div>
                    </div>
                  </div>
                  

                <ng-template #noNotifications>
                    <p class="no-notification">No new notifications</p>
                </ng-template>
            </div>
    
    <button class="hamburger-btn" (click)="toggleMobileMenu()">
      <mat-icon>menu</mat-icon>
    </button>
  
    <div class="mobile-menu-content" *ngIf="isMobileMenuOpen">

        <div class="user-info-mobile">
            <span class="star" ><img src="../../../../assets/icons/star_rate.svg"></span>
            <span class="points">{{ points | number }} pts </span>
            <span class="divider"> | </span>
            <span class="money"> <img src="../../../../assets/icons/monetization_on.svg"></span>
            <span class="balance">{{ balance | currency }}</span> 
        </div>


        <mat-sidenav-container hasBackdrop="false">
            <mat-sidenav #sidenav mode="over" opened="true" class="full-width-sidenav">
                <mat-nav-list>
                    <a mat-list-item routerLink="/home" (click)="closeSidenav()">Home</a>

                    <mat-expansion-panel  
                        [expanded]="openedPanel === 'panel1'" 
                        (opened)="onSubMenuOpen('panel1')" 
                        (closed)="onSubMenuClose('null')">

                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          My Wallet
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                        <h4>My Wallet</h4>
                        <a mat-list-item [routerLink]="['/my-wallet', 'my-wallet-details']" class="sub-menu-link" routerLinkActive="selected" (click)="closeSidenav()">My Wallet Details
                            <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color" (click)="toggleWalletSubmenu(); $event.preventDefault()">chevron_right</mat-icon>
                        </a>

                        <h4>My Rewards</h4>    
                        <a  mat-list-item [routerLink]="['/my-wallet', 'all-rewards']" class="sub-menu-link" routerLinkActive="selected" (click)="closeSidenav()">All Rewards
                            <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                        </a> 
                        <a mat-list-item [routerLink]="['/my-wallet/all-rewards', 'redeem']" class="sub-menu-link" routerLinkActive="selected" (click)="closeSidenav()">Redeem
                            <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                        </a>
                        <a mat-list-item [routerLink]="['/my-wallet/all-rewards', 'ways-to-earn']" class="sub-menu-link">Ways to Earn                          
                            <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                        </a>

                        <h4>My Cash</h4>
                        <a mat-list-item [routerLink]="['/my-wallet', 'my-cash', 'all-cash']" class="sub-menu-link" routerLinkActive="selected" (click)="closeSidenav()">All Cash
                            <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                        </a>
                         <a mat-list-item [routerLink]="['/my-wallet', 'all-rewards', 'redeem']" class="sub-menu-link" routerLinkActive="selected" (click)="closeSidenav()">Redeem  
                        <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                        </a>
                        <a mat-list-item [routerLink]="['/my-wallet', 'my-cash', 'transfer-balance']" class="sub-menu-link" routerLinkActive="selected" (click)="closeSidenav()">Transfer
                            <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>                 
                        </a>

                        <h4>My Payment</h4>
                        <a mat-list-item [routerLink]="['/my-wallet', 'all-payments']" class="sub-menu-link" routerLinkActive="selected" (click)="closeSidenav()">All Payments
                            <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                        </a>
                 
                      <!-- Add more links as needed -->
                    </mat-expansion-panel>

                    <a *ngIf="!(cashEligible && !rewardEligible)" mat-list-item routerLink="/connected-solution" (click)="closeSidenav()">Connected Solution</a>

                    <mat-expansion-panel
                    [expanded]="openedPanel === 'panel2'"
                    (opened)="onSubMenuOpen('panel2')"
                    (closed)="onSubMenuClose('null')"
                  >
                    <mat-expansion-panel-header>
                      <mat-panel-title>Settings</mat-panel-title>
                    </mat-expansion-panel-header>
                   
                    <h4>My Account</h4>
                    <a mat-list-item [routerLink]="['/my-account']" class="sub-menu-link" routerLinkActive="selected" (click)="closeSidenav()">My Account
                        <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                    </a>

                    <h4>Preferences</h4>
                    <a mat-list-item [routerLink]="['/preferences', 'notifications']" class="sub-menu-link" routerLinkActive="selected" (click)="closeSidenav()">Notification
                        <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                    </a>
                    <a mat-list-item [routerLink]="['manage-payment']" class="sub-menu-link" routerLinkActive="selected" (click)="closeSidenav()">Manage Payment Methods
                        <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                    </a>
                    <a mat-list-item [routerLink]="['/preferences', 'auto-redeem']" class="sub-menu-link" routerLinkActive="selected" (click)="closeSidenav()">Auto Redeem &amp; Pay
                        <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                    </a>

                    <h4>Contact Us</h4>
                    <a mat-list-item [routerLink]="['/contact-us']" class="sub-menu-link" routerLinkActive="selected" (click)="closeSidenav()">Contact Us
                        <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                    </a>

                  </mat-expansion-panel>

                  <hr class="hr-line">
                  
                  <div class="connected">
                    <a href="https://www.insureshield.com/">
                        <span class="icon"><img src="../../../../assets/icons/frame.svg"></span>
                            <span class="title">InsureShield®</span>
                        <span class="link-icon"><img src="../../../../assets/icons/open_in_new.svg"></span>   
                    </a>
                    <a href="https://www.parcelpro.com//">
                        <span class="icon"><img src="../../../../assets/icons/ParcelPro_Sapphire 1.png"></span>
                            <span class="title">Parcel Pro</span>
                        <span class="link-icon"><img src="../../../../assets/icons/open_in_new.svg"></span>   
                    </a>
                    <a href="https://www.insureshield.com/">
                        <span class="icon"><img src="../../../../assets/icons/frame.svg"></span>
                            <span class="title">Delivery Defense</span>
                        <span class="link-icon"><img src="../../../../assets/icons/open_in_new.svg"></span>   
                    </a>
                  </div>

                  <p class="wallet-close" (click)="closeTab($event)"><span class="icon"><img src="../../../../assets/icons/logout.svg"></span> Exit Wallet</p>
                 


                  </mat-nav-list>
                  
            </mat-sidenav>
            <mat-sidenav-content>
              <button type="button" (click)="sidenav.toggle()">☰</button>
              <!-- Your main content here -->
            </mat-sidenav-content>
          </mat-sidenav-container>


    </div>
  </div>

</div>


<div class="submenu-content wallet-menu" *ngIf="showWalletSubmenu">
    <div class="menu-block-container">
                
        <div class="menu-block">
            <h3>MY WALLET</h3> 
            <a [routerLink]="['/my-wallet', 'my-wallet-details']" class="sub-menu-link" [ngClass]="{'disabled': cashEligible && !rewardEligible}" routerLinkActive="selected" (click)="toggleWalletSubmenu(); $event.preventDefault()">My Wallet Details
                <mat-icon *ngIf="!(cashEligible && !rewardEligible)" class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                <mat-icon *ngIf="cashEligible && !rewardEligible" class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">lock_outline</mat-icon>        
            </a> 
        </div>
    
        <div class="menu-block">
            <h3>MY REWARDS</h3>
            <a [routerLink]="['/my-wallet', 'all-rewards']" class="sub-menu-link" [ngClass]="{'disabled': cashEligible && !rewardEligible}" routerLinkActive="selected" (click)="toggleWalletSubmenu(); $event.preventDefault()">All Rewards
                <mat-icon *ngIf="!(cashEligible && !rewardEligible)" class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                <mat-icon *ngIf="cashEligible && !rewardEligible" class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">lock_outline</mat-icon>        
            </a> 
            <a [routerLink]="['/my-wallet/all-rewards', 'redeem']" class="sub-menu-link"  [ngClass]="{'disabled': (cashEligible && !rewardEligible) || autoPay === 1}" routerLinkActive="selected" (click)="toggleWalletSubmenu(); $event.preventDefault()">Redeem
                <mat-icon *ngIf="!(cashEligible && !rewardEligible) && autoPay === 0" class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                <mat-icon *ngIf="(cashEligible && !rewardEligible) || autoPay === 1" class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">lock_outline</mat-icon>        
            </a>
            <a [routerLink]="['/my-wallet/all-rewards', 'ways-to-earn']" class="sub-menu-link" [ngClass]="{'disabled': cashEligible && !rewardEligible}">Ways to Earn                          
                <mat-icon *ngIf="!(cashEligible && !rewardEligible)" class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                <mat-icon *ngIf="cashEligible && !rewardEligible" class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">lock_outline</mat-icon>        
            </a>
            
        </div>

        <div class="menu-block">
            <h3>MY CASH</h3>
            <a [routerLink]="['/my-wallet', 'my-cash', 'all-cash']" class="sub-menu-link">All Cash
                <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>            
            </a>
            <a [routerLink]="['/my-wallet', 'all-rewards', 'redeem']" class="sub-menu-link" [ngClass]="{'disabled': (cashEligible && !rewardEligible) || autoPay === 1}">Redeem  
                <mat-icon *ngIf="!(cashEligible && !rewardEligible) && autoPay === 0" class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                <mat-icon *ngIf="(cashEligible && !rewardEligible) || autoPay === 1" class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">lock_outline</mat-icon>        
            </a>
            <a [routerLink]="['/my-wallet', 'my-cash', 'transfer-balance']" class="sub-menu-link">Transfer
                <mat-icon class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>                 
            </a>
        </div>

        <div class="menu-block">
            <h3>My Payment</h3>
            <a [routerLink]="['/my-wallet', 'all-payments']" class="sub-menu-link" [ngClass]="{'disabled': cashEligible && !rewardEligible}" routerLinkActive="selected" (click)="toggleWalletSubmenu(); $event.preventDefault()">All Payments
                <mat-icon *ngIf="!(cashEligible && !rewardEligible)" class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">chevron_right</mat-icon>
                <mat-icon *ngIf="cashEligible && !rewardEligible" class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color">lock_outline</mat-icon>        
            </a>
        </div>
    </div>
</div>

    <div class="user-info">
            <span class="star">
                <img [src]="(cashEligible && !rewardEligible) ? '../../../../assets/icons/star_rate_grey.svg' : '../../../../assets/icons/star_rate.svg'">
              </span>
              
            <span class="points" [ngClass]="{'disabled': cashEligible && !rewardEligible}" *ngIf="walletSummary">{{ walletSummary?.wallet?.rewards?.totalRewardBalance ?? 0 | number }} pts</span>
            <span class="divider">|</span>
            <span class="money"><img src="../../../../assets/icons/monetization_on.svg"></span>
            <span class="balance" *ngIf="walletSummary">{{ walletSummary.wallet.cash.totalCashBalance | currency}}</span> 
            <span class="divider">|</span>
       
            <a routerLink="my-account"><span class="user-initial" *ngIf="walletSummary">{{ displayName| slice:0:1 }}</span></a> 
            
            <span class="notifications" (click)="toggleDropdown()">
                <img [src]="hasUnreadNotifications ? '../../../../assets/icons/notifications_unread.svg' : '../../../../assets/icons/notifications.svg'">
            </span>

            <!-- Greyed-out overlay (only visible when dropdown is open) -->
            <div *ngIf="isDropdownOpen" class="overlay" (click)="toggleDropdown()"></div>

            <!-- Notification Dropdown -->
            <div *ngIf="isDropdownOpen" class="notification-dropdown" (clickOutside)="closeDropdown()">
                <h3>Notifications</h3>
                <div *ngIf="notifications && notifications.length > 0; else noNotifications">
                    <div *ngFor="let notification of notifications | slice:0:10" class="notification-item"
                         [ngClass]="{
                           'unread': notification.readStatus === false,
                           'read': notification.readStatus === true
                         }">
                      <div class="item">
                        <div class="icon-wrapper">
                          <div class="icon"><img class="icon-notification" src="../../../../assets/icons/frame.svg"></div>
                        </div>
                  
                        <div class="info">
                          <div class="title" [innerHTML]="(notification.notificationProductName === 'ISConnect' || 
                                                           notification.notificationProductName === 'IS4U' || 
                                                           notification.notificationProductName === 'ISComplete') 
                                                           ? 'InsureShield<sup>&reg;</sup> Shipping Insurance' 
                                                           : notification.notificationProductName">
                          </div>
                  
                          <div class="details">{{ notification.notificationText }}</div>
                        </div>
                  
                        <div class="data">
                          <div class="notification-data" [ngClass]="{
                               'transfer-initiated': notification.notificationData === 'Transfer Initiated',
                               'payment-initiated': notification.notificationData === 'Payment Initiated',
                               'transfer-completed': notification.notificationData === 'Transfer Completed',
                               'payment-completed': notification.notificationData === 'Payment Completed',
                               'transfer-failed': notification.notificationData === 'Transfer Failed',
                               'payment-failed': notification.notificationData === 'Payment Failed'
                            }">
                            {{ notification.notificationData }}
                          </div>
                          <div class="date">{{ notification.notificationDate | date:'MM/dd/yyyy' }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  

                <ng-template #noNotifications>
                    <p class="no-notification">No new notifications</p>
                </ng-template>
            </div>

        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>apps</mat-icon>
          </button>
          
          <mat-menu #menu="matMenu" class="nav-flyout" backdropClass="overlay-menu" [overlapTrigger]="false" (closed)="onMenuClosed()">
              <div class="flyout-top-section">  
                <p>My Settings</p>
                <p>
                    <button mat-icon-button (click)="closeMenu()">
                        <mat-icon>close</mat-icon>
                    </button>
                </p>
              </div>

            <ul class="flyout-menu-list">
                <li><a routerLink="my-account">My Account</a></li>
                <li><a *ngIf="!(cashEligible && !rewardEligible)"  routerLink="preferences" >Preferences</a></li>
                <li><a routerLink="contact-us">Contact Us</a></li>
            </ul>
            

            <hr>

            <p class="wallet-close" (click)="closeTab($event)"><span class="icon"><img src="../../../../assets/icons/logout.svg"></span> Exit Wallet</p>
       
          
          </mat-menu>
        
    </div>

</header>
